import React, { useState } from 'react'
import PageHeading from '../../components/ui/page-heading'
import { Container, NavItem, TabContent, TabPane } from 'reactstrap'
import Shimmer from '../../components/ui/shimmer'
import { getClientDePayroll } from '../../services/api'
import { useFetch } from '../../helpers/hooks'
import { useFilters } from '../AdminPanel/pages/cards/use-filters'
import { PageNav } from '../../components/page-nav'
import ClientPayroll from './ClientPayroll'

export const PAYROLL_DETAILS_TABS = {
  currentCircle: { value: '1', label: 'Current cycle' },
  pastReports: { value: '2', label: 'Past reports' },
}

export default function Payroll() {
  const [activeTab, setActiveTab] = useState(
    PAYROLL_DETAILS_TABS.currentCircle.value,
  )

  const [filters, handleFiltersChange] = useFilters({
    country_id: '',
    page: 1,
    search: '',
    entity_id: '',
  })

  const [filterDate, setFilterDate] = useState('')

  function setPage(page) {
    handleFiltersChange('page', page)
  }

  function onQuery(searchText) {
    handleFiltersChange('search', searchText ?? '', { action: 'clear' })
  }

  function onTabChange(value) {
    setActiveTab(value)
    onQuery('')
  }

  function filterByCountry(countryId) {
    handleFiltersChange('country_id', countryId)
  }

  const {
    data: clientPayrollList,
    isLoading: isClientPayrollListLoading,
    paginator: clientPayrollPaginator,
    completed: fetchClientPayrolCompleted,
  } = useFetch(
    {
      action: getClientDePayroll,
      autoFetch: true,
      body: filters,
    },
    [filters?.entity_id, filters?.search, filters?.country_id],
  )

  const pageLoading = isClientPayrollListLoading || !fetchClientPayrolCompleted

  const tabOptions = [
    {
      label: PAYROLL_DETAILS_TABS.currentCircle.label,
      id: PAYROLL_DETAILS_TABS.currentCircle.value,
    },
    {
      label: PAYROLL_DETAILS_TABS.pastReports.label,
      id: PAYROLL_DETAILS_TABS.pastReports.value,
    },
  ]

  return (
    <div className='page-content tw-overflow-visible'>
      <PageHeading>
        <PageHeading.Title>Global payroll</PageHeading.Title>
      </PageHeading>

      <Container className='tw-m-0 tw-p-0' fluid>
        <>
          <div className='tw-mb-6 tw-w-full tw-rounded tw-bg-white'>
            {pageLoading ? (
              <div className='tw-flex tw-h-[73px] tw-items-center tw-gap-4 tw-px-4'>
                <Shimmer width='152px' height='28px' />
                <Shimmer width='152px' height='28px' />
              </div>
            ) : (
              <>
                <PageNav>
                  {tabOptions.map((tab) => (
                    <NavItem key={tab.id}>
                      <PageNav.Link
                        isActive={activeTab === tab.id}
                        tag={activeTab === tab.value ? 'div' : 'button'}
                        to={tab.id}
                        onClick={() => onTabChange(tab.id)}
                      >
                        {tab.label}
                      </PageNav.Link>
                    </NavItem>
                  ))}
                </PageNav>
              </>
            )}
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId={PAYROLL_DETAILS_TABS.currentCircle.value}>
              <ClientPayroll
                payrollData={clientPayrollList}
                paginator={clientPayrollPaginator}
                page={filters.page}
                setPage={setPage}
                filterByCountry={filterByCountry}
                onQuery={onQuery}
                onEntityChange={(val) =>
                  handleFiltersChange('entity_id', val, { action: 'clear' })
                }
                loading={isClientPayrollListLoading}
              />
            </TabPane>
            <TabPane tabId={PAYROLL_DETAILS_TABS.pastReports.value}>
              <ClientPayroll
                payrollData={clientPayrollList}
                paginator={clientPayrollPaginator}
                page={filters.page}
                setPage={setPage}
                filterByCountry={filterByCountry}
                onQuery={onQuery}
                onEntityChange={(val) =>
                  handleFiltersChange('type', val, { action: 'clear' })
                }
                loading={isClientPayrollListLoading}
                setFilterDate={setFilterDate}
                filterDate={filterDate}
              />
            </TabPane>
          </TabContent>
        </>
      </Container>
    </div>
  )
}
