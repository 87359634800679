import React from 'react'
import { flushSync } from 'react-dom'
import { useSelector } from 'react-redux'
import { Input } from 'reactstrap'
import toastr from 'toastr'
import { v4 as uuidV4 } from 'uuid'

import { cn } from 'ui'
import HeaderLogoLink from '../../components/Common/header-logo'
import Head from '../../components/head'
import { useFetch } from '../../helpers/hooks'
import { sendMessage } from '../../services/api-ai'
import { FLOW_ID, SENDER } from './constants'

const randomSessionId = uuidV4()

export function Chat() {
  const messagesContainerRef = React.useRef(null)

  const [message, setMessage] = React.useState('')
  const [messages, setMessages] = React.useState([])

  function changeMessages(payload) {
    flushSync(() => {
      setMessages(payload)
    })

    // scroll to bottom
    messagesContainerRef.current.scrollTop =
      messagesContainerRef.current.scrollHeight
  }

  const user = useSelector((state) => state.Account?.user)

  const { startFetch: sendMessageRequest } = useFetch({
    action: sendMessage,
    onComplete: (data) => {
      changeMessages((prevMessages) => {
        return [
          ...prevMessages.filter((m) => m.id !== FLOW_ID.LOADING_ID),
          { id: Date.now(), message: data?.output, sender: SENDER.SYSTEM },
        ]
      })
    },
    onError: () => {
      changeMessages((prevMessages) => {
        return [
          ...prevMessages.filter((m) => m.id !== FLOW_ID.LOADING_ID),
          { id: FLOW_ID.ERROR, message: 'Error occurred' },
        ]
      })
    },
  })

  function sendRequest({ message }) {
    sendMessageRequest({
      input: message,
      user_id: String(user?.id),
      session_id: randomSessionId,
    })

    // add loading message
    changeMessages((prevMessages) => [
      ...prevMessages,
      { id: FLOW_ID.LOADING_ID, sender: SENDER.SYSTEM },
    ])
  }

  function handleSendMessage(e) {
    e?.preventDefault()

    if (!message) {
      toastr.error('Please enter a message')
      return
    }

    const messageCopy = message

    setMessage('')
    changeMessages((prevMessages) => [
      ...prevMessages,
      { id: Date.now(), message: messageCopy, sender: SENDER.USER },
    ])

    sendRequest({ message: messageCopy })
  }

  return (
    <div
      style={{
        '--nav-header-height': '3.5rem',
        '--page-body-height': 'calc(100svh - var(--nav-header-height))',
      }}
    >
      <Head title='Chat' />

      <header className='tw-h-[--nav-header-height] tw-bg-white tw-px-4 tw-shadow-sm'>
        <div className='tw-flex tw-h-full tw-items-center tw-justify-between tw-gap-4 tw-py-2'>
          <HeaderLogoLink className='tw-ms-0' />
        </div>
      </header>
      <main>
        <div className='tw-mx-auto tw-flex tw-h-[--page-body-height] tw-w-full tw-max-w-screen-sm tw-flex-col tw-px-4'>
          <div
            className='tw-flex tw-flex-grow tw-flex-col tw-gap-1 tw-overflow-auto tw-px-4 tw-py-4'
            ref={messagesContainerRef}
          >
            {messages.map((message) => {
              if (Object.values(FLOW_ID).includes(message.id)) {
                return <MessageFlow key={message.id} message={message} />
              } else if (message.sender === 'user') {
                return <MessageUser key={message.id} message={message} />
              } else if (message.sender === SENDER.SYSTEM) {
                return <MessageSystem key={message.id} message={message} />
              }
              return 'Message type unknown'
            })}
          </div>

          <form className='tw-relative tw-pb-4' onSubmit={handleSendMessage}>
            <Input
              type='textarea'
              className='!tw-h-28 !tw-w-full !tw-rounded-2xl !tw-border !tw-border-surface-40 !tw-bg-surface-30 !tw-p-4'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            <button className='tw-mt-2 tw-rounded-lg tw-bg-primary-100 tw-px-4 tw-py-2 tw-font-semibold tw-text-white tw-transition-colors hover:tw-bg-primary-110 focus-visible:tw-ring-2 focus-visible:tw-ring-primary-100/50'>
              Send
            </button>
          </form>
        </div>
      </main>
    </div>
  )
}

function MessageSystem({ message }) {
  return (
    <MessageWrapper timestamp={message.id} className='tw-me-8'>
      <MessageBubble className='tw-self-start'>
        <MessageText>{message.message}</MessageText>
      </MessageBubble>
    </MessageWrapper>
  )
}
function MessageUser({ message }) {
  return (
    <MessageWrapper
      timestamp={message.id}
      className='tw-ms-tw-me-8 tw-text-end'
    >
      <MessageBubble className='tw-self-end tw-border-primary-40 tw-bg-primary-30'>
        <MessageText>{message.message}</MessageText>
      </MessageBubble>
    </MessageWrapper>
  )
}
function MessageFlow({ message }) {
  return (
    <MessageWrapper className='tw-me-8'>
      <MessageBubble className='tw-h-[42px] tw-self-start'>
        <MessageText className='tw-flex tw-h-full'>
          {message.id === FLOW_ID.LOADING_ID ? (
            <div className='tw-mt-auto tw-flex tw-gap-1 tw-opacity-70'>
              <div className='tw-size-1.5 tw-animate-bounce tw-rounded-full tw-bg-secondary-120' />
              <div className='tw-size-1.5 tw-animate-bounce tw-rounded-full tw-bg-secondary-120 tw-delay-200' />
              <div className='tw-size-1.5 tw-animate-bounce tw-rounded-full tw-bg-secondary-120 tw-delay-500' />
            </div>
          ) : message.id === FLOW_ID.ERROR ? (
            <div className='tw-text-systemRed-100'>
              {message.message || 'ERROR Occurred'}
            </div>
          ) : (
            <div className='tw-text-systemGold-100'>UNKNOWN</div>
          )}
        </MessageText>
      </MessageBubble>
    </MessageWrapper>
  )
}

function MessageText({ children, className }) {
  return (
    <div
      className={cn(
        'tw-whitespace-pre-line tw-text-pretty tw-text-start tw-text-sm tw-font-medium tw-text-text-100',
        className,
      )}
    >
      {children}
    </div>
  )
}
function MessageBubble({ children, className }) {
  return (
    <div
      className={cn(
        'tw-rounded-2xl tw-border tw-border-surface-20 tw-bg-secondary-10 tw-px-4 tw-py-2.5',
        className,
      )}
    >
      {children}
    </div>
  )
}
function MessageWrapper({ timestamp, children, className }) {
  return (
    <div className={cn('tw-group tw-flex tw-flex-col tw-gap-px', className)}>
      {children}

      <div className='tw-text-xs tw-text-text-80 tw-opacity-0 tw-transition-opacity tw-duration-75 group-hover:tw-opacity-100'>
        {formatTimestamp({ timestamp })}
      </div>
    </div>
  )
}

function formatTimestamp({ timestamp }) {
  if (timestamp === undefined || typeof timestamp !== 'number') {
    return ''
  }

  return new Date(timestamp).toLocaleString()
}
