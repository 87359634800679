import React, { useState } from 'react'
import { Container, Row } from 'reactstrap'
import { t } from 'i18next'
import { DownloadSimple, Info } from '@phosphor-icons/react'
import BorderedNavTabs from '../../../../components/ui/nav-tabs/bordered'
import { useFetch } from '../../../../helpers/hooks'
import {
  adminDownloadEORQuote,
  downloadEORQuote,
} from '../../../../services/api'
import { downloadFile } from '../../utils/helpers'
import EmployerTaxView from './EmployerTaxView'

export function numberWithCommas(number) {
  return Math.round(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const QuoteView = ({ quote, customerCurrencyCode }) => {
  const [period, setPeriod] = useState('monthly')

  const isAdmin = location.pathname.startsWith('/admin')

  const DownloadPDFButton = ({ downloadQuote }) => {
    return (
      <button
        onClick={(e) => {
          e.preventDefault()
          downloadQuote.startFetch()
        }}
        className='tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-border-0 tw-bg-white tw-text-sm tw-font-medium tw-text-primary'
      >
        <DownloadSimple className='!tw-mb-0.5 !tw-me-1' size={18} />{' '}
        {t('Download PDF')}
      </button>
    )
  }

  const downloadQuote = useFetch({
    action: isAdmin ? adminDownloadEORQuote : downloadEORQuote,
    withAdminAccess: isAdmin,
    body: {
      country_code: quote?.input?.code,
      annual_salary: quote?.input?.salary,
      currency: customerCurrencyCode,
    },
    onComplete: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      downloadFile(url, 'Quotation.pdf')
    },
  })
  return (
    <>
      <Container fluid className='mx-auto mx-md-0'>
        <Row style={{ marginBottom: '24px' }}>
          <BorderedNavTabs
            options={[
              { label: t('Monthly'), value: 'monthly' },
              { label: t('Yearly'), value: 'yearly' },
            ]}
            activeOption={period}
            trail={<DownloadPDFButton downloadQuote={downloadQuote} />}
            onClickOption={(option) => {
              setPeriod(option.value)
            }}
          />
        </Row>
        <EmployerTaxView
          customerCurrencyCode={customerCurrencyCode}
          quote={quote}
          isMonthly={period === 'monthly'}
        />

        <Row className='custom-alert bg-blue-20 border rounded p-4 font-size-14 font-weight-light flex-nowrap'>
          <span className='tw-mt-0.5 tw-pe-3 tw-text-primary-110'>
            <Info size={20} />
          </span>
          <span className='alert-text'>
            {t('EmploymentQuote', { quoteName: quote?.input?.name })}
          </span>
        </Row>
      </Container>
    </>
  )
}

export default QuoteView
