const FEATURE_FLAGS = {
  SENTRY_LOGGING: !!JSON.parse(process.env.REACT_APP_SENTRY_LOGGING ?? false),
  REMOTEPASS_EOR: !!JSON.parse(process.env.REACT_APP_REMOTEPASS_EOR ?? false),
  REMOTEPASS_ANIMATED_CARD: !!JSON.parse(
    process.env.REACT_APP_REMOTEPASS_ANIMATED_CARD ?? false,
  ),

  PAYMENT_METHOD_BREX: !!JSON.parse(
    process.env.REACT_APP_PAYMENT_METHOD_BREX ?? false,
  ),

  PAYSEND_INTEGRATION: !!JSON.parse(
    process.env.REACT_APP_PAYSEND_INTEGRATION ?? false,
  ),

  OPEN_API: !!JSON.parse(process.env.REACT_APP_OPEN_API ?? false),

  EXTERNAL_INVOICES: parseFlagEnv(process.env.REACT_APP_EXTERNAL_INVOICES),
  NEW_DIRECT_EMPLOYEE: parseFlagEnv(process.env.REACT_APP_NEW_DIRECT_EMPLOYEE),
  ORGANIZATION_CHART: parseFlagEnv(process.env.REACT_APP_ORGANIZATION_CHART),
  SLACK_NOTIFICATION: parseFlagEnv(process.env.REACT_APP_SLACK_NOTIFICATION),
  BULK_INVITATIONS: parseFlagEnv(process.env.REACT_APP_BULK_INVITATIONS),
  BILL_PAYMENTS: parseFlagEnv(process.env.REACT_APP_BILL_PAYMENTS),
  BILL_PAYMENTS_OCR: parseFlagEnv(process.env.REACT_APP_BILL_PAYMENTS_OCR),
  CLIENT_NEW_BANNER: parseFlagEnv(process.env.REACT_APP_CLIENT_NEW_BANNER),
  AUTOMATION_COMPANIES_TAB: parseFlagEnv(
    process.env.REACT_APP_AUTOMATION_COMPANIES_TAB,
  ),
  TIMEOFF_DOCUMENTS_UPLOAD: parseFlagEnv(
    process.env.REACT_APP_TIMEOFF_DOCUMENTS_UPLOAD,
  ),
  OKTA_SIGN_IN: parseFlagEnv(process.env.REACT_APP_OKTA_SIGN_IN),
  RECURRING_EXPENSES: parseFlagEnv(process.env.REACT_APP_RECURRING_EXPENSES),
  NEW_CALENDAR: parseFlagEnv(process.env.REACT_APP_NEW_CALENDAR),
  // Flag for custom integrations
  INTEGRATION_APP: parseFlagEnv(process.env.REACT_APP_INTEGRATION_APP),
  // Flag for integrations.app integrations
  INTEGRATION_APP_V2: parseFlagEnv(process.env.REACT_APP_INTEGRATION_APP_V2),
  LOCALIZATION: parseFlagEnv(process.env.REACT_APP_LOCALIZATION),
  GLOBAL_PAYROLL: parseFlagEnv(process.env.REACT_APP_GLOBAL_PAYROLL),

  AI_CHAT: parseFlagEnv(process.env.REACT_APP_AI_CHAT),
}

function parseFlagEnv(env, { defaultValue = false } = {}) {
  return !!JSON.parse(env ?? defaultValue)
}

export default FEATURE_FLAGS
