import React, { useState, useCallback } from 'react'
import { t } from 'i18next'
import { Col, Row } from 'reactstrap'
import { CaretDown, CaretUp } from '@phosphor-icons/react'
import { Trans } from 'react-i18next'
export const EmployerTaxView = ({ quote, isMonthly, customerCurrencyCode }) => {
  const employer = quote?.employer

  const [showDetails, setShowDetails] = useState(false)

  const toggleDetails = (e) => {
    e.preventDefault()
    setShowDetails(!showDetails)
  }

  const formatter = useCallback(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currencyDisplay: 'code',
        minimumFractionDigits: 0,
        currency: customerCurrencyCode || 'USD',
      }),
    [customerCurrencyCode],
  )

  return (
    <Row className='text-sm md:text-base group relative hover:shadow-lg'>
      <Row className='p-0 m-0 w-100 justify-content-center mb-4'>
        <Col className='px-0'>
          <div className='bg-primary-20 border border-primary rounded p-4'>
            <div className='justify-content-center d-flex text-secondary mb-2'>
              <Trans
                i18nKey='Total cost to employer'
                values={{ frequency: isMonthly ? t('Monthly') : t('annual') }}
                components={{
                  span: <span className='tw-lowercase' />,
                }}
              />
            </div>
            <h6 className='justify-content-center d-flex rp-font-bold font-size-24 text-primary'>
              {formatter().format(
                isMonthly ? employer?.cost?.monthly : employer?.cost?.annual,
              )}
            </h6>
          </div>
        </Col>
      </Row>
      <div className='d-flex flex-column mb-4 p-4 border rounded w-100 font-size-14'>
        <div className='d-flex align-items-center justify-content-between mb-4'>
          <span className='rp-font-light'>
            <Trans
              i18nKey='Gross salary costs'
              values={{ frequency: isMonthly ? t('Monthly') : t('annual') }}
              components={{
                span: <span className='tw-lowercase' />,
              }}
            />
          </span>
          <span className='rp-font-medium'>
            {formatter().format(
              isMonthly ? employer?.gross?.monthly : employer?.gross?.annual,
            )}
          </span>
        </div>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <span className='rp-font-light'>
            {t('Estimated taxes & contributions')}
          </span>
          <span className='rp-font-medium'>
            {formatter().format(
              isMonthly
                ? employer?.total_taxes?.monthly
                : employer?.total_taxes?.annual,
            )}
          </span>
        </div>
        {showDetails && (
          <ul className='text-text-80 tw-mb-4 tw-ps-3 tw-text-sm tw-font-light'>
            {employer?.taxes?.map((taxItem) => (
              <li
                className='tw-pb-2 tw-pe-0 tw-ps-0 tw-pt-2'
                key={`${Object.keys(taxItem)[0]}`}
              >
                <div
                  className='d-flex align-items-center justify-content-center'
                  key={`${Object.keys(taxItem)[0]}`}
                >
                  <div style={{ flex: 1 }}>{Object.keys(taxItem)[0]} </div>
                  <div>
                    {formatter().format(
                      isMonthly
                        ? Object.values(taxItem)[0]?.monthly
                        : Object.values(taxItem)[0]?.annual,
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}

        {!showDetails && <div className='border-top w-100 h-1 mb-4 mt-4'></div>}

        <button
          className='d-flex align-items-center justify-content-center font-size-14 text-primary font-weight-medium cursor-pointer bg-white border-0'
          onClick={toggleDetails}
        >
          {showDetails ? t('Show Less') : t('Show More')}
          {showDetails ? (
            <CaretUp className='!tw-ms-1' size={20} />
          ) : (
            <CaretDown className='!tw-ms-1' size={20} />
          )}
        </button>
      </div>
    </Row>
  )
}

export default EmployerTaxView
