import { Eye } from '@phosphor-icons/react'
import { format } from 'date-fns'
import React from 'react'
import { t } from 'i18next'
import { cn } from 'ui'
import CustomSelect from '../../../../../components/Forms/CustomSelect/CustomSelect'
import Shimmer from '../../../../../components/ui/shimmer'
import { formatDays } from '../../../../../utils/formatters/format-days'
import { getOptionFromList } from '../../../../../utils/map-to-option'
import {
  PRORATA_CALCULATION_TYPE,
  PRORATA_TYPE,
  WORKING_DAYS,
} from './constants'
import { useProrataState } from './prorata-context'

function RecapItem({ label, value, labelClassName }) {
  return (
    <span className='tw-flex tw-items-center tw-justify-between tw-py-4'>
      <span
        className={cn(
          'tw-text-sm tw-text-text last:tw-font-semibold',
          labelClassName,
        )}
      >
        {label}
      </span>
      <span className='tw-text-sm tw-font-semibold'>{value}</span>
    </span>
  )
}

export default function CalculatedProrataBody({
  prorataType,
  calculationType,
  workDays,
  dates,
  formatter,
}) {
  const { gettingProrataData, prorataData: data } = useProrataState()
  const workWeek = [
    {
      label: `${t('Monday')} ${t('To')} ${t('Friday')}`,
      value: WORKING_DAYS.MONDAY_FRIDAY,
    },
    {
      label: `${t('Sunday')} ${t('To')} ${t('Thursday')}`,
      value: WORKING_DAYS.SUNDAY_THURSDAY,
    },
  ]

  const CALCULATION_TYPE_OPTION = [
    { label: t('Working days'), value: PRORATA_CALCULATION_TYPE.WORKING_DAYS },
    {
      label: t('Calendar days'),
      value: PRORATA_CALCULATION_TYPE.CALENDAR_DAYS,
    },
  ]
  return (
    <>
      <CustomSelect
        label={t('Calculation type')}
        options={CALCULATION_TYPE_OPTION}
        wrapperClassName='tw-mb-4'
        value={getOptionFromList(
          CALCULATION_TYPE_OPTION,
          calculationType.value,
        )}
        onChange={(val) => calculationType.onChange(val.value)}
      />

      {calculationType.value === PRORATA_CALCULATION_TYPE.WORKING_DAYS && (
        <CustomSelect
          label={t('Work week')}
          options={workWeek}
          value={getOptionFromList(workWeek, workDays.value)}
          onChange={(val) => workDays.onChange(val.value)}
        />
      )}

      <hr className='-tw-mx-6' />
      <span className='tw-mb-2 tw-flex tw-items-center tw-text-base tw-font-semibold tw-text-black'>
        <Eye className='tw-me-2 tw-fill-primary' size={20} />
        {t('Recap')}
      </span>
      <div className='tw-divide-y tw-divide-surface-30'>
        <RecapItem
          label={t('Monthly rate')}
          value={formatter.format(data.contract_amount)}
        />
        <RecapItem
          label={t('Period')}
          value={`${t(format(new Date(dates.startDate), 'MMM'))} ${format(new Date(dates.startDate), 'dd')} to ${t(format(new Date(dates.startDate), 'MMM'))}  ${format(new Date(dates.firstPaymentDate), 'dd')}, ${new Date(dates.firstPaymentDate).getFullYear()}`}
        />
        <RecapItem
          label={
            prorataType === PRORATA_TYPE.CALCULATED
              ? t('Work days')
              : t('Calendar days')
          }
          value={
            gettingProrataData ? (
              <Shimmer height={17} />
            ) : (
              formatDays(data.days_worked)
            )
          }
        />
        <RecapItem
          label={t('Daily rate')}
          labelClassName='tw-self-start'
          value={
            gettingProrataData ? (
              <Shimmer height={data.daily_rate.length === 1 ? 17 : undefined} />
            ) : (
              <table className='table !tw-mb-0'>
                <tbody>
                  {data.daily_rate.map((rate) => {
                    return (
                      <tr key={rate}>
                        <td className='!tw-border-t-0 !tw-px-0 !tw-py-0.5 tw-text-end tw-font-normal tw-text-text-80'>
                          {t(rate.month)},
                        </td>
                        <td className='!tw-border-t-0 !tw-px-1 !tw-py-0.5 tw-font-normal tw-text-text-80'>
                          {formatDays(rate.prorated_days)}
                        </td>
                        <td className='!tw-border-t-0 !tw-px-1 !tw-py-0.5 tw-font-semibold'>
                          {formatter.format(rate.rate)} / {t('Day')}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )
          }
        />
        <RecapItem
          label={t('Total to pay')}
          value={
            gettingProrataData ? (
              <Shimmer height={17} />
            ) : (
              formatter.format(data.pro_rata_amount)
            )
          }
        />
      </div>
    </>
  )
}
