import { t } from 'i18next'
import {
  CheckCircle,
  DotsThreeCircleVertical,
  Info,
  Money,
  Repeat,
  Warning,
  X,
  XCircle,
} from '@phosphor-icons/react'
import cx from 'classnames'
import { format } from 'date-fns'
import { isNil } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Alert, UncontrolledTooltip } from 'reactstrap'
import toastr from 'toastr'

import { Avatar } from 'ui'
import ApprovalOverride from '../../../components/approval-override'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import BadgeX from '../../../components/Table/BadgeX'
import BadgeV2 from '../../../components/ui/badge-v2'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../components/ui/side-menu'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  approvePayments,
  cancelPayment,
  declinePayments,
  getApprovalFlowTimeline,
  revertCancelledPayment,
} from '../../../services/api'
import { updateToPayList } from '../../../store/payment/actions'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import isNill from '../../../utils/is-nill'
import { usePayrollApprovalEnabled } from '../../CompanySetting'
import { AedPayrollActions } from '../../due-payments-page/aed-payroll-actions'
import { getPaymentIds } from '../../payInvoices'

export function PaymentApprovals({
  isAdmin,
  contract,
  state,
  toggle,
  onUpdate,
  loadingPayments,
}) {
  const [actionOverridden, setActionOverridden] = useState(false)

  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const paymentItems = state.data.works?.map((item) => {
    return {
      id: item.work_id,
      title: item.name,
      description: item.details,
      amount: item.amount,
      currency: state.data.currency?.code,
    }
  })
  paymentItems.unshift({
    id: state.data.id,
    title: t('Total'),
    description: t('Payment amount'),
    amount: state.data.amount,
    currency: state.data.currency?.code,
    em: true,
  })

  const { data: flowTimeline, isLoading: loadingFlowTimeline } = useFetch(
    {
      action: getApprovalFlowTimeline,
      body: { item_id: state.data.id, type: 'payment' },
      autoFetch: !!state.data.id,
    },
    [state.data.id],
  )
  const firstPending = flowTimeline?.find((item) => item.status === 'Pending')

  const loading = loadingPayments

  const { hasAccess } = usePermissions()

  const canPay = hasAccess(permissions.PrepareTransactions)
  const canProcessPayment = !isNill(state.data?.is_processable)
    ? state.data?.is_processable
    : true

  const alreadyApproved = !!flowTimeline?.find(
    (item) => item.user_id === user.id && item.status === 'Approved',
  )

  const notApproved =
    String(state.data.approval_status)?.toLowerCase() !== 'approved'

  const approvalFlowEnabled = !!contract?.approval_flow?.id

  const actionable =
    (approvalFlowEnabled ? true : !loadingFlowTimeline) &&
    !isAdmin &&
    String(state.data.approval_status)?.toLowerCase() !== 'declined' &&
    ['unpaid', 'cancelled'].includes(
      String(state.data.status)?.toLowerCase(),
    ) &&
    (firstPending?.user_id === user.id ||
      !!userProfile?.is_company_creator ||
      actionOverridden ||
      (!contract?.approval_flow?.id && state?.data?.can_approve) ||
      canPay) &&
    (notApproved ? !alreadyApproved : true)

  return (
    <SideMenu
      onClose={toggle}
      isOpen={state.show}
      className='!tw-z-[1050] !tw-w-full tw-max-w-[532px] tw-text-black'
      itemListClassName={cx(
        'tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain',
        loading
          ? 'tw-grid-rows-1'
          : actionable
            ? 'tw-grid-rows-[auto_1fr_91px]'
            : 'tw-grid-rows-[auto_1fr]',
      )}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <SideMenuHeader toggle={toggle} className='!tw-items-start'>
            <div>
              <div className='tw-flex tw-items-center tw-gap-2'>
                <span className='tw-font-semibold'>
                  #{state.data.payment_ref}
                </span>
                <PaymentStatusBadge
                  status={state.data.status}
                  approvalStatus={state.data.approval_status}
                  size='md'
                  id={state.data.payment_ref}
                />
              </div>
              <span className='tw-text-sm tw-text-text-60'>{t('Payment')}</span>
            </div>
          </SideMenuHeader>

          <SideMenuBody>
            <h4 className='tw-font-semibold'>{t('Details')}</h4>
            <div>
              {paymentItems.map((item) => {
                return <PaymentDetail key={item?.id} {...item} />
              })}
            </div>

            <Timeline
              flowTimeline={flowTimeline}
              loading={loadingFlowTimeline}
              loadingLength={contract?.approval_flow?.steps?.length}
              isAdmin={isAdmin}
              actionOverridden={actionOverridden}
              setActionOverridden={setActionOverridden}
              contract={contract}
            />

            {canProcessPayment ? null : (
              <>
                <hr className='tw--mx-4 tw-px-3 md:tw--mx-6' />
                <Alert color='danger' className='d-flex gap-16 mb-4 px-3 py-4'>
                  <Warning
                    className='flex-shrink-0 tw-fill-red-100'
                    size={20}
                  />

                  <div className='text-text-black'>
                    {state.data?.resolution_message}
                  </div>
                </Alert>
              </>
            )}
          </SideMenuBody>

          {!actionable ? null : (
            <SideMenuFooter>
              <ActionSection
                contract={contract}
                item={state.data}
                onUpdate={() => {
                  onUpdate?.()
                  toggle?.()
                }}
                showStatus={false}
                loading={loadingPayments}
              />
            </SideMenuFooter>
          )}
        </>
      )}
    </SideMenu>
  )
}

function PaymentDetail({ title, description, amount, currency, em }) {
  const formatter = getCurrencyFormatter(currency)

  return (
    <div className='tw-flex tw-items-center tw-justify-between tw-gap-3 tw-border-b tw-border-surface-30 tw-py-4 tw-text-black'>
      <div className='tw-text-sm'>
        {!title ? null : <div className='tw-font-semibold'>{title}</div>}
        {!description ? null : (
          <div className='tw-text-text-80'>{description}</div>
        )}
      </div>
      <div
        className={cx(
          'tw-text-base',
          em ? 'tw-font-semibold' : 'tw-text-text-100',
        )}
      >
        {formatter.format(amount)}
      </div>
    </div>
  )
}

const getStatusColor = (status) => {
  switch (status) {
    case 'Processing':
      return 'primary'

    case 'Pending':
    case 'pending':
      return 'warning'

    case 'Paid':
    case 'approved':
    case 'Approved':
      return 'success'

    case 'Overdue':
    case 'Unpaid':
    case 'Declined':
    case 'declined':
    case 'Cancelled':
      return 'danger'

    default:
      return 'primary'
  }
}

export function PaymentStatusBadge({
  status,
  approvalStatus,
  id,
  size = 'sm',
}) {
  const isDeclined = approvalStatus?.toLowerCase() === 'declined'
  const isPending = status === 'Unpaid' && !isDeclined

  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  function getTooltipText(status) {
    switch (status?.toLowerCase()) {
      case 'approved':
        return t('Payment has been approved')
      case 'paid':
        return t('Payment has been received and applied to your account')
      case 'processing':
        return t(
          'We are currently awaiting receipt of the payment. The company has indicated that they are in the process of sending it',
        )
      case 'pending':
        return t('The company has not processed the payment yet')
      case 'cancelled':
      case 'declined':
        return t('The company has canceled the payment')
      default:
        return ''
    }
  }

  function getStatus(status) {
    if (isPending) {
      return 'Pending'
    }

    if (isDeclined) {
      return 'Declined'
    }

    return status
  }
  const _status = getStatus(status)

  const _tooltipText = getTooltipText(_status)
  const showTooltip = _tooltipText && userProfile?.type === userTypes.CONTRACTOR

  return (
    <>
      <BadgeV2
        status={getStatusColor(_status)}
        size={size}
        id={`REF${id}TT`}
        rightIcon={showTooltip ? <Info /> : null}
        className={cx({ 'tw-cursor-help': showTooltip })}
      >
        {t(_status)}
      </BadgeV2>
      {!showTooltip ? null : (
        <UncontrolledTooltip target={`REF${id}TT`}>
          {t(_tooltipText)}
        </UncontrolledTooltip>
      )}
    </>
  )
}

export function PendingApprovalsStatus({ item }) {
  const isApproved = item.approval_status === 'approved'
  if (isApproved) {
    return (
      <PaymentStatusBadge
        status={t('Approved')}
        approvalStatus={item.approval_status}
        id={item.payment_ref + item.id + 'PAS'}
      />
    )
  }

  const isDeclined = item.approval_status === 'declined'
  if (isDeclined) {
    return (
      <PaymentStatusBadge
        status={t('Declined')}
        approvalStatus={item.approval_status}
        id={item.payment_ref}
      />
    )
  }

  const name = item?.next_approver
  if (!name) {
    return (
      <PaymentStatusBadge
        status={item.status}
        approvalStatus={item.approval_status}
        id={item.payment_ref}
      />
    )
  }

  return (
    <BadgeX
      pill
      className='!tw-bg-secondary-20 !tw-text-black'
      leftIcon={<Avatar size='sm' name={name} className='-tw-ml-1' />}
    >
      {name}
    </BadgeX>
  )
}

function ActionSection({
  contract,
  item,
  isAdmin,
  onUpdate,
  showStatus = true,
}) {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [revertModalOpen, setRevertModalOpen] = useState(false)

  const { hasAccess } = usePermissions()

  const payrollApprovalEnabled = usePayrollApprovalEnabled()

  const canManagePayments = item?.can_approve
  const canProcessPayment = !isNill(item?.is_processable)
    ? item?.is_processable
    : true

  const user = useSelector((state) => state.Account?.user)
  const dispatch = useDispatch()
  const history = useHistory()

  function onSuccess(message = 'Action successful', data) {
    if (data?.success === false) {
      toastr.error(t('Something went wrong'))
    } else {
      onUpdate?.()
      toastr.success(message)
    }
  }

  const { startFetch: cancelItem, isLoading: cancelingItem } = useFetch({
    action: cancelPayment,
    onComplete: (data) =>
      onSuccess(t('Successfully cancelled the payment'), data),
  })
  const { startFetch: _approvePayment, isLoading: approvingPayments } =
    useFetch({
      action: approvePayments,
      onComplete: (data) =>
        onSuccess(t('Successfully approved the payment'), data),
      onError: () => {
        toastr.error(t('Error approving payment'))
      },
    })
  const { startFetch: _declinePayment, isLoading: decliningPayments } =
    useFetch({
      action: declinePayments,
      onComplete: (data) =>
        onSuccess(t('Successfully declined the payment'), data),
      onError: () => {
        toastr.error(t('Error declining payment'))
      },
    })
  const { startFetch: revertPayment, isLoading: revertingPayment } = useFetch({
    action: revertCancelledPayment,
    onComplete: (data) => {
      setRevertModalOpen(false)
      onSuccess(t('Successfully reverted the payment'), data)
    },
    onError: () => {
      toastr.error(t('Error reverting payment'))
    },
  })

  function handleClickPay() {
    const url = new URL(window.location.href)
    const path = url.pathname + url.search

    history.push(`/pay-invoices?from=${encodeURIComponent(path)}`)
  }

  useEffect(() => {
    const ids = getPaymentIds([item])
    dispatch(updateToPayList(ids))
  }, [dispatch, item])

  if (!!isAdmin || user?.type !== userTypes.COMPANY) {
    return null
  }

  const userCanRevert = !isAdmin && user?.type === userTypes.COMPANY
  if (item.status === 'Cancelled' && userCanRevert) {
    return (
      <>
        <Button
          block
          onClick={() => setRevertModalOpen(true)}
          icon={<Repeat size={18} />}
          color='light'
          outline
          loading={revertingPayment}
          disabled={revertingPayment}
        >
          {t('Revert')}
        </Button>

        <ConfirmationModal
          toggle={() => setRevertModalOpen((o) => !o)}
          isOpen={revertModalOpen}
          title={t('Revert cancelled payment')}
          caption={t('Revert cancelled payment')}
          buttonColor='danger'
          onConfirm={() => revertPayment({ payment_id: item?.id })}
          message={t('Are you sure you want to revert this cancelled payment?')}
          negativeCaption={t('Close')}
          confirmLoading={revertingPayment}
        />
      </>
    )
  }

  const isPendingOrDeclined = ['pending', 'declined'].includes(
    item?.approval_status,
  )

  const showApprovalProcess =
    (payrollApprovalEnabled || !!contract?.approval_flow?.id) &&
    item.status === 'Unpaid'
  const approvalProcessLoading = approvingPayments || decliningPayments

  const canBeCancelled = item?.can_cancel

  if (showApprovalProcess && isPendingOrDeclined) {
    return !canManagePayments ? null : (
      <>
        {!canBeCancelled ? null : (
          <Button
            color='danger'
            onClick={() => _declinePayment({ payment_ids: [item?.id] })}
            loading={decliningPayments}
            disabled={approvalProcessLoading}
            className='tw-mr-auto'
          >
            {t('Decline')}
          </Button>
        )}

        <Button
          color='success'
          onClick={() => _approvePayment({ payment_ids: [item?.id] })}
          loading={approvingPayments}
          disabled={!canProcessPayment || approvalProcessLoading}
        >
          {t('Approve')}
        </Button>
      </>
    )
  }

  const hasPermissions = hasAccess(permissions.PrepareTransactions)
  const showPayButton =
    hasPermissions &&
    item.status === 'Unpaid' &&
    item.processing_type === 'internal'
  const showCancelButton =
    hasPermissions && item.status !== 'Cancelled' && item.status === 'Unpaid'

  if (!showCancelButton && !showPayButton && !showStatus) {
    return null
  }

  return (
    <>
      {!showCancelButton || !canBeCancelled ? null : (
        <Button
          color='danger'
          onClick={() => setShowConfirmation(true)}
          icon={<X size={18} />}
          loading={cancelingItem}
          disabled={cancelingItem}
          className='tw-mr-auto'
        >
          {t('Cancel')}
        </Button>
      )}

      {showPayButton ? (
        item?.amount === 0 ? null : contract.is_external_payroll_provider !==
          1 ? (
          <Button
            onClick={handleClickPay}
            icon={<Money size={18} />}
            disabled={cancelingItem || !canProcessPayment}
          >
            {t('Pay')}
          </Button>
        ) : (
          <AedPayrollActions
            buttonText={t('Pay')}
            rawTotalToPay={item.amount}
            buttonIcon={<Money size={18} />}
            onCompleted={onUpdate}
            disabled={!canProcessPayment}
            fromDetails
          />
        )
      ) : null}

      <ConfirmationModal
        toggle={() => setShowConfirmation((o) => !o)}
        isOpen={showConfirmation}
        title={t('Cancel Payment')}
        caption={t('Cancel Payment')}
        buttonColor='danger'
        onConfirm={() => {
          setShowConfirmation(false)
          cancelItem({ payment_id: item?.id })
        }}
        message={t('Are you sure you want to cancel this payment?')}
        negativeCaption={t('Close')}
      />
    </>
  )
}

export function getDateAndTime(dateNumber) {
  if (!dateNumber) {
    return { date: '', time: '' }
  }
  let dateObject = null

  if (dateNumber > 0) {
    dateObject = new Date(dateNumber * 1000)
  } else {
    dateObject = new Date(dateNumber)
  }

  const date = format(dateObject, 'MMM. dd, yyyy')
  const time = format(dateObject, 'HH:mm:ss')

  return { date, time }
}

export function Timeline({
  loading,
  flowTimeline,
  loadingLength = 2,
  isAdmin,
  actionOverridden,
  setActionOverridden,
  isDeclined,
  contract,
  showOverride: showOverrideExternal,
  title,
}) {
  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const firstPending = flowTimeline?.find((item) => item.status === 'Pending')

  const timelineItems =
    flowTimeline?.map((item) => {
      const sendReminder = item.user_id === firstPending?.user_id

      return {
        status: item.status,
        description: item.user_name,
        ...getDateAndTime(item.date),
        sendReminder,
      }
    }) ?? []

  const showOverrideBlock = !isNil(showOverrideExternal)
    ? showOverrideExternal
    : !isAdmin &&
      !userProfile?.is_company_creator &&
      flowTimeline?.find((item) => item.user_id === user.id)?.can_override &&
      !isDeclined

  const approvalFlowEnabled = !!contract?.approval_flow?.id

  if (!approvalFlowEnabled) {
    return null
  }

  return (
    <>
      {title ?? (
        <h4 className='tw-mb-4 tw-mt-8 tw-font-semibold'>{t('Timeline')}</h4>
      )}
      {loading ? (
        <LoadingFlowSteps length={loadingLength} itemHeight='73px' />
      ) : (
        <div className='tw-rounded tw-border tw-border-surface-30'>
          {timelineItems.length <= 0 ? (
            <div className='tw-p-4 tw-text-text-80'>
              {t('No timeline items')}
            </div>
          ) : (
            timelineItems.map((item, index) => {
              return (
                <TimelineItem
                  key={index}
                  {...item}
                  onClickReminder={item.sendReminder ? () => {} : null}
                  isLast={timelineItems.length - 1 === index}
                />
              )
            })
          )}
        </div>
      )}

      {!showOverrideBlock ? null : (
        <ApprovalOverride
          shouldOverride={actionOverridden}
          onOverride={(event) => setActionOverridden(event.target.checked)}
        />
      )}
    </>
  )
}

function TimelineItem({
  description,
  date,
  time,
  status,
  onClickReminder,
  sendReminder,
  isLast,
}) {
  const icon = useMemo(() => {
    switch (String(status)?.toLowerCase()) {
      case 'approved': {
        return <CheckCircle className='tw-h-6 tw-w-6 tw-text-systemGreen-100' />
      }
      case 'pending': {
        return (
          <DotsThreeCircleVertical className='tw-h-6 tw-w-6 tw-text-systemGold-100' />
        )
      }
      case 'declined':
      case 'cancelled': {
        return <XCircle className='tw-h-6 tw-w-6 tw-text-systemRed-100' />
      }
      case 'reverted': {
        return <Repeat className='tw-h-6 tw-w-6 tw-text-secondary-100' />
      }
      default: {
        return null
      }
    }
  }, [status])

  return (
    <div
      className={cx(
        'tw-flex tw-items-center tw-justify-between tw-gap-4 tw-p-4 tw-text-sm',
        { 'tw-border-b tw-border-surface-30': !isLast },
      )}
    >
      <div className='tw-flex tw-items-center tw-gap-4'>
        {icon}

        <div className=''>
          {!status ? null : (
            <div className='tw-font-semibold tw-capitalize'>{status}</div>
          )}
          {!description ? null : (
            <div className='tw-text-text-80'>{description}</div>
          )}
        </div>
      </div>
      <div className=''>
        {sendReminder && typeof onClickReminder === 'function' ? (
          <>
            {/* <Button
             iconRight={<BellSimpleRinging size={20} />}
             color='link'
             className='!tw-px-0'
            >
             Send Reminder
            </Button> */}
          </>
        ) : (
          <>
            {!date ? null : <div className='tw-text-text-100'>{date}</div>}
            {!time ? null : <div className='tw-text-text-60'>{time}</div>}
          </>
        )}
      </div>
    </div>
  )
}

export function LoadingFlowSteps({
  length,
  className,
  itemClassName,
  itemHeight = '76px',
}) {
  return (
    <LoadingItems
      length={length}
      className={cx('tw-rounded tw-border tw-border-surface-20', className)}
      itemHeight={itemHeight}
      item={({ className, style, isLast }) => {
        return (
          <div
            className={cx(
              'tw-flex tw-animate-pulse tw-items-center tw-justify-between tw-gap-4 tw-p-4 tw-text-sm',
              { 'tw-border-b tw-border-surface-30': !isLast },
              className,
              itemClassName,
            )}
            style={style}
          >
            <div className='tw-flex tw-items-center tw-gap-4'>
              <div className='tw-h-6 tw-w-6 tw-flex-shrink-0 tw-rounded-full tw-bg-surface-30' />
              <div className='tw-h-4 tw-w-12 tw-rounded-full tw-bg-surface-30' />
            </div>
            <div className='tw-h-4 tw-w-1/4 tw-rounded-full tw-bg-surface-30' />
          </div>
        )
      }}
    />
  )
}

export function LoadingItems({
  length,
  className,
  itemClassName,
  itemHeight = '76px',
  item: Item = 'div',
  noParent,
}) {
  const items = Array.from({ length }).map((_, index) => {
    return (
      <Item
        key={index}
        index={index}
        className={cx(itemClassName)}
        style={{ height: itemHeight }}
        isLast={length - 1 === index}
      />
    )
  })

  if (noParent) {
    return items
  }

  return <div className={cx(className)}>{items}</div>
}

export function useApprovalsNotEnabled({ contract }) {
  const payrollApprovalEnabled = useSelector(
    (state) =>
      state.userProfile?.userProfile?.company?.is_payroll_approval_enabled?.toString() ===
      '1',
  )

  const approvalsNotEnabled =
    !contract?.approval_flow?.id && !payrollApprovalEnabled

  return approvalsNotEnabled
}
