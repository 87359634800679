import React, { useState } from 'react'
import {
  CalendarCheck,
  DotsThreeOutlineVertical,
  GridFour,
  IdentificationBadge,
  IdentificationCard,
  Info,
  ListChecks,
  Medal,
  SuitcaseSimple,
  Tag,
  TreeStructure,
  UserList,
  Users,
  UsersThree,
} from '@phosphor-icons/react'
import { t } from 'i18next'
import { TabContent, TabPane } from 'reactstrap'
import { InView } from 'react-intersection-observer'
import { Avatar, cn } from 'ui'

import { DetailsList } from './view-policy'
import { ContractBadge } from '../../../Contract/ContractDetail/contract-timeline'
import { TimeOffPolicyTypes } from '../../../Contract/utils/constants'
import { WORKER_ACTION_TEXT, workerTabs } from './manage-policy-workers'
import Loader from '../../../../components/ui/loader'
import SearchBar from '../../../../components/SearchBar'
import Button from '../../../../components/ui/button'
import { PrimaryAlert } from '../../../../components/ui/alert'
import { UserProfile } from '../../../org-chart/organization-chart'
import { Checkbox2 } from '../../../../components/controlled-checkbox'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { getOptionFromList } from '../../../../utils/map-to-option'
import Accordion from '../../../../components/Accordion'
import BadgeV2 from '../../../../components/ui/badge-v2'
import CustomRadio from '../../../../components/custom-radio'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'

export default function ManagePolicyBody({
  contractDetails,
  selectedContract,
  activeTab,
  tabs,
  fetchingContractDetails,
  assignedWorkersSearch,
  unAssignedWorkersSearch,
  setAssignedWorkersSearch,
  setUnAssignedWorkersSearch,
  setSelectedContract,
  isFetchingNextPage,
  fetchNextPage,
  workers,
  removeWorker,
  assignWorker,
  policy,
  companyPolicies,
  selectedWorkers,
  setSelectedWorkers,
  isReAssigning,
  setIsReAssigning,
  earningDate,
  setEarningDate,
}) {
  if (isReAssigning) {
    return (
      <BulkSelectionBody
        companyPolicies={companyPolicies}
        isReAssigning={isReAssigning}
        setIsReAssigning={setIsReAssigning}
        policy={policy}
        selectedWorkers={selectedWorkers}
        earningDate={earningDate}
        setEarningDate={setEarningDate}
      />
    )
  }

  if (!selectedContract) {
    return (
      <SelectContractBody
        activeTab={activeTab}
        tabs={tabs}
        assignedWorkersSearch={assignedWorkersSearch}
        unAssignedWorkersSearch={unAssignedWorkersSearch}
        setAssignedWorkersSearch={setAssignedWorkersSearch}
        setUnAssignedWorkersSearch={setUnAssignedWorkersSearch}
        setSelectedContract={setSelectedContract}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        workers={workers}
        removeWorker={removeWorker}
        assignWorker={assignWorker}
        policy={policy}
        companyPolicies={companyPolicies}
        selectedWorkers={selectedWorkers}
        setSelectedWorkers={setSelectedWorkers}
      />
    )
  }

  if (fetchingContractDetails) {
    return <Loader minHeight='max(50vh, 550px)' />
  }

  return (
    <ContractDetails
      contractDetails={contractDetails}
      selectedContract={selectedContract}
      tabs={tabs}
    />
  )
}

const SelectContractBody = ({
  activeTab,
  tabs,
  assignedWorkersSearch,
  unAssignedWorkersSearch,
  setAssignedWorkersSearch,
  setUnAssignedWorkersSearch,
  setSelectedContract,
  isFetchingNextPage,
  fetchNextPage,
  workers,
  removeWorker,
  assignWorker,
  policy,
  companyPolicies,
  selectedWorkers,
  setSelectedWorkers,
}) => (
  <TabContent activeTab={activeTab}>
    {tabs.map(({ value }) => (
      <TabPane tabId={value} key={value}>
        <Workers
          search={
            value === workerTabs.assignedWorkers
              ? assignedWorkersSearch
              : unAssignedWorkersSearch
          }
          setSearch={
            value === workerTabs.assignedWorkers
              ? setAssignedWorkersSearch
              : setUnAssignedWorkersSearch
          }
          onUserNameClick={(worker) => setSelectedContract(worker.ref)}
          loading={
            value === workerTabs.assignedWorkers
              ? false
              : status === 'pending' && !unAssignedWorkersSearch
          }
          loadingSearch={
            value === workerTabs.assignedWorkers ? null : status === 'pending'
          }
          isFetchingNextPage={
            value === workerTabs.assignedWorkers ? null : isFetchingNextPage
          }
          fetchNextPage={
            value === workerTabs.assignedWorkers ? null : fetchNextPage
          }
          workers={workers}
          workerActionText={
            value === workerTabs.assignedWorkers
              ? WORKER_ACTION_TEXT.REMOVE
              : WORKER_ACTION_TEXT.ASSIGN
          }
          onWorkerClick={(worker) => {
            if (value === workerTabs.assignedWorkers) {
              removeWorker(worker)
            } else {
              assignWorker(worker)
            }

            setUnAssignedWorkersSearch('')
          }}
          policy={policy}
          companyPolicies={companyPolicies}
          selectedWorkers={selectedWorkers}
          setSelectedWorkers={setSelectedWorkers}
          activeTab={activeTab}
        />
      </TabPane>
    ))}
  </TabContent>
)

const ContractDetails = ({ contractDetails, selectedContract }) => (
  <div>
    <DetailsList
      title={t('Contract details')}
      items={[
        {
          icon: <IdentificationBadge size={24} />,
          label: t('Contract ID'),
          value: '#' + selectedContract,
        },
        {
          icon: <Tag size={24} />,
          label: t('Status'),
          value: <ContractBadge status={contractDetails?.status} />,
        },
        contractDetails?.name && {
          icon: <SuitcaseSimple size={24} />,
          label: t('Role'),
          value: contractDetails?.name,
        },
        contractDetails?.start_date && {
          icon: <CalendarCheck size={24} />,
          label: t('Start date'),
          value: contractDetails?.start_date,
        },
        contractDetails?.employment_term && {
          icon: <Medal size={24} />,
          label: t('Tenure'),
          value: contractDetails?.employment_term,
        },
        contractDetails?.type && {
          icon: <IdentificationCard size={24} />,
          label: t('Hire type'),
          value: contractDetails?.type,
        },
      ].filter(Boolean)}
    />

    <hr className='tw-my-8' />

    <DetailsList
      title={t('Organizational information')}
      items={[
        contractDetails?.department && {
          icon: <GridFour size={24} />,
          label: t('Department'),
          value: contractDetails?.department,
        },
        {
          icon: <TreeStructure size={24} />,
          label: t('Head of department'),
          value: contractDetails?.is_head_of_department ? t('Yes') : t('No'),
        },
        contractDetails?.reporting_to && {
          icon: <Users size={24} />,
          label: t('Reporting to'),
          value: contractDetails?.reporting_to,
        },
        {
          icon: <UserList size={24} />,
          label: t('Direct reports'),
          value: contractDetails?.direct_reports?.length || t('None'),
        },
      ].filter(Boolean)}
    />
  </div>
)

function Workers({
  workers = [],
  onWorkerClick,
  workerActionText,
  loading,
  loadingSearch,
  fetchNextPage,
  search,
  setSearch,
  isFetchingNextPage,
  policy,
  companyPolicies,
  onUserNameClick,
  selectedWorkers,
  setSelectedWorkers,
  activeTab,
}) {
  const isDefaultPolicy =
    policy.type.id === TimeOffPolicyTypes.DEFAULT_POLICY_TYPE
  const hasDefaultPolicy = companyPolicies.find(
    (policy) => policy.type.id === TimeOffPolicyTypes.DEFAULT_POLICY_TYPE,
  )
  const companyAccruedPolicy = companyPolicies.find(
    (policy) => policy.type.is_accrued === 1,
  )

  function getInfo(type) {
    return type.toLowerCase() === 'assign'
      ? isDefaultPolicy
        ? t('policyMessageAssign', { policy: companyAccruedPolicy?.name })
        : t('policyMessageAssign_default')
      : t('policyMessageRemove', {
          policy: isDefaultPolicy ? '' : t('or Default time off'),
        })
  }

  if (loading) {
    return <Loader minHeight='max(50vh, 550px)' />
  }

  const conditionForWarning =
    (policy.type.is_accrued === 1 && hasDefaultPolicy) ||
    (isDefaultPolicy && companyAccruedPolicy && !!workers.length) ||
    isDefaultPolicy
  const isBulkSelecting =
    !!selectedWorkers && activeTab === workerTabs.assignedWorkers

  const onWorkerSelection = (event) => {
    if (event.target.checked) {
      setSelectedWorkers((prev) => [
        ...prev,
        workers.find(({ id }) => id === Number(event.target.value)),
      ])
    } else
      setSelectedWorkers((prev) =>
        prev.filter((item) => item.id !== Number(event.target.value)),
      )
  }

  return (
    <>
      <div className='tw-sticky tw-top-0 tw-z-10 tw-flex tw-gap-2 tw-bg-white tw-px-6 tw-pb-3 tw-pt-6'>
        <SearchBar
          placeholder={t('Search by name, contract ID')}
          query={search}
          onQueryChanged={setSearch}
          isClearable
          className='tw-flex-1'
        />

        {activeTab === workerTabs.assignedWorkers && (
          <Button
            type='button'
            color={isBulkSelecting ? 'light' : 'primary'}
            outline
            icon={isBulkSelecting ? null : <ListChecks size={20} />}
            className='!tw-px-6'
            onClick={() => setSelectedWorkers((prev) => (prev ? null : []))}
          >
            {isBulkSelecting ? t('Cancel') : t('Select')}
          </Button>
        )}
      </div>

      {conditionForWarning && (
        <PrimaryAlert color='warning' className='tw-mx-6 tw-mb-6'>
          {getInfo(workerActionText)}
        </PrimaryAlert>
      )}

      <div className='tw-mt-3 tw-flex tw-flex-col tw-gap-4 tw-px-6 tw-pb-6'>
        {loadingSearch ? (
          <Loader minHeight='max(50vh, 550px)' />
        ) : workers.length <= 0 || workers.length <= 0 ? (
          <div className='tw-text-text-80'>{t('No workers')}</div>
        ) : (
          workers.map((worker) => {
            const { id, name, role, flag, photo } = worker
            const props = { name, role, flag, photo, id }
            return (
              <div
                key={id}
                className={cn('tw-group/worker hover:tw-bg-surface-10', {
                  'tw-rounded tw-border tw-border-primary':
                    selectedWorkers?.includes(String(id)),
                })}
              >
                <div className='tw-relative'>
                  <UserProfile
                    data={{
                      ...props,
                      handless: true,
                      linkToContract: true,
                      linkToContractHash: '&tab=timeOff',
                      className:
                        'tw-w-auto tw-bg-transparent tw-rounded-b-none',
                    }}
                    onUserNameClick={() => onUserNameClick(worker)}
                  />

                  <div className='tw-absolute tw-end-3 tw-top-1/2 -tw-translate-y-1/2'>
                    {isBulkSelecting ? (
                      <Checkbox2
                        checked={selectedWorkers?.some(
                          (worker) => worker.id === id,
                        )}
                        onChange={onWorkerSelection}
                        value={id}
                      />
                    ) : workerActionText === WORKER_ACTION_TEXT.REMOVE &&
                      conditionForWarning ? null : (
                      <Button
                        className='!tw-text-secondary-100 !tw-transition-all md:tw-opacity-0 md:group-hover/worker:tw-opacity-100'
                        size='sm'
                        color='link'
                        onClick={() => onWorkerClick(worker)}
                      >
                        {t(workerActionText)}
                      </Button>
                    )}
                  </div>
                </div>

                <div className='tw-rounded-b tw-border-x tw-border-b tw-border-surface-30 tw-bg-surface-10 tw-p-4 tw-text-xs tw-text-text-80'>
                  {[
                    worker?.contractorType,
                    worker?.companyName,
                    worker?.contractType,
                  ]
                    .filter(Boolean)
                    .join(' | ')}
                </div>
              </div>
            )
          })
        )}

        {!fetchNextPage || typeof fetchNextPage !== 'function' ? null : (
          <InView
            onChange={(isView) => {
              if (isView) {
                fetchNextPage()
              }
            }}
            as='div'
            rootMargin='400px 0px 0px 0px'
          >
            {!isFetchingNextPage ? null : (
              <div className='tw-rounded tw-border tw-border-surface-30'>
                <div className='tw-flex tw-items-center tw-gap-4 tw-p-4'>
                  <div className='tw-h-12 tw-w-12 tw-animate-pulse tw-rounded-full tw-bg-surface-20' />
                  <div>
                    <div className='tw-mt-2 tw-h-4 tw-w-32 tw-animate-pulse tw-rounded tw-bg-surface-20' />
                    <div className='tw-mt-1 tw-h-4 tw-w-16 tw-animate-pulse tw-rounded tw-bg-surface-20' />
                  </div>
                </div>
                <div className='tw-border-t tw-border-t-surface-30 tw-bg-surface-10 tw-p-4'>
                  <div className='tw-mt-1 tw-h-4 tw-w-32 tw-animate-pulse tw-rounded tw-bg-surface-20' />
                </div>
              </div>
            )}
          </InView>
        )}
      </div>
    </>
  )
}

const AccordionButton = ({ selectedWorkers, currentPolicy }) => (
  <>
    <span className='tw-rounded tw-bg-secondary-20 tw-p-2'>
      <UsersThree className='tw-size-6 tw-fill-secondary' />
    </span>
    <span className='tw-flex-1 tw-text-start'>
      <div className='tw-font-semibold'>{t('Selected workers')}</div>
      <span className='tw-text-xs tw-text-text-80'>
        {t('From')} <strong>{currentPolicy.name}</strong>
      </span>
    </span>
    <BadgeV2
      className='!tw-bg-primary'
      leftIcon={<Info weight='fill' size={12} />}
    >
      {`${selectedWorkers.length} ${t('worker', { count: selectedWorkers.length })}`}
    </BadgeV2>
  </>
)

const AccordionItems = ({ worker }) => (
  <div
    className='tw-flex tw-border-b tw-border-b-surface-30 tw-py-4 last:tw-border-b-0'
    key={worker.id}
  >
    <Avatar
      photo={worker.photo}
      name={worker.name}
      flag={worker.flag}
      icon={
        !worker.name ? (
          <DotsThreeOutlineVertical
            size={20}
            className='tw-fill-systemGold-110'
          />
        ) : undefined
      }
      className={cn({ 'tw-bg-systemGold-20': !worker.name })}
    />
    <div className='tw-ms-2 tw-flex-1'>
      <span className='tw-font-semibold'>
        {worker.name || t('Pending Onboarding')}
      </span>
      <div className='tw-text-xs tw-text-text-80'>{t('24 Days balance')}</div>
    </div>
    <div className='tw-ms-4 tw-text-xs'>
      <span className='tw-text-text-80'>{t('Earning date')}</span>
      <div className='tw-text-end tw-font-semibold'>03/03/2024</div>
    </div>
  </div>
)

const BulkSelectionBody = ({
  companyPolicies,
  isReAssigning,
  setIsReAssigning,
  policy,
  selectedWorkers,
  earningDate,
  setEarningDate,
}) => {
  const [earningDateType, setEarningDateType] = useState('startDate')
  const selectablePolicies = companyPolicies.filter(
    (_policy) => _policy.id !== policy.id,
  )
  const policyOptions = selectablePolicies.map((policy) => ({
    label: t(policy.name),
    value: policy.id,
    description: `${t(policy.type.name)} | ${
      policy.type.is_accrued ? t('Accrual') : t('Non-accrual')
    }`,
  }))
  return (
    <div className='tw-p-6'>
      <CustomSelect
        placeholder={t('Select new policy')}
        options={policyOptions}
        value={getOptionFromList(policyOptions, isReAssigning.policy)}
        onChange={(option) =>
          setIsReAssigning((prev) => ({ ...prev, policy: option.value }))
        }
      />
      <hr className='-tw-mx-6 tw-my-6' />

      <div className='tw-mb-6'>
        <Accordion
          label={
            <AccordionButton
              selectedWorkers={selectedWorkers}
              currentPolicy={policy}
            />
          }
          className='tw-justify-start tw-gap-4 tw-rounded tw-border tw-border-surface-30'
          value={selectedWorkers.map((worker) => (
            <AccordionItems worker={worker} key={worker.id} />
          ))}
          contentWrapperClassName='tw-bg-primary-10 tw-py-0'
        />
      </div>

      <div className='tw-border tw-border-surface-30 tw-p-6'>
        <div className='tw-mb-6 tw-flex'>
          <span className='tw-me-4 tw-h-fit tw-rounded tw-bg-surface-20 tw-p-2'>
            <CalendarCheck className='tw-fill-secondary' size={24} />
          </span>
          <span>
            <div className='tw-font-semibold'>{t('Earning date')}</div>
            <span className='tw-text-xs tw-text-text-80'>
              {t('When does the worker start earning time off balance?')}
            </span>
          </span>
        </div>

        <CustomRadio
          items={[
            { label: t('Contract start date'), value: 'startDate' },
            { label: t('Custom date'), value: 'customDate' },
          ]}
          renderItem={(item) => item.label}
          className='tw-me-2 tw-py-4'
          name='earningDate'
          checkedItem={earningDateType}
          onSelect={(event) => {
            const value = event.target.value
            setEarningDateType(value)
            if (value === 'startDate') {
              setEarningDate(undefined)
            } else {
              setEarningDate(null)
            }
          }}
        />
        {earningDateType === 'customDate' && (
          <span className='-tw-mx-6 tw-mt-4 tw-block tw-border-t tw-border-t-surface-30 tw-bg-surface-10 tw-p-6'>
            <CustomDatePicker
              placeholder={t('Earning date')}
              value={earningDate}
              handleOnChange={(date) => setEarningDate(date)}
            />
          </span>
        )}
      </div>
    </div>
  )
}
