import { t } from 'i18next'
import React from 'react'
import { InputGroup } from 'reactstrap'

import ControlledCurrencyInput from '../../../../components/ControlledCurrencyInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import {
  customOption,
  customSingleValue,
} from '../../components/Forms/select-components'

export function YearlySalaryField({
  control,
  label,
  placeholder,
  name = 'amount',
  errors,
  currencyOptions,
  onBlur,
  hideError = true,
}) {
  return (
    <>
      {!label ? null : <label htmlFor={name}>{label}</label>}

      <InputGroup className='!tw-flex-nowrap'>
        <ControlledCurrencyInput
          control={control}
          name={name}
          id={name}
          placeholder={placeholder}
          className='!tw-be-0 !tw-rounded-e-none'
          hideError={hideError}
          onBlur={onBlur}
          error={errors?.[name]}
        />

        <ControlledSelect
          className='input-group-text tw-rounded-s-0 !tw-border-0 !tw-p-0'
          leftRounded
          placeholder={t('Currency')}
          name='currency_id'
          control={control}
          classNamePrefix='RS-Addon'
          options={currencyOptions}
          customComponents={{
            Option: customOption,
            Value: customSingleValue,
            SingleValue: customSingleValue,
          }}
          error={errors?.currency_id}
        />
      </InputGroup>
    </>
  )
}
