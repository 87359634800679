import { flattenDeep } from 'lodash'
import { DEPARTMENT_ROOT_ID, MANAGER_ROOT_ID } from './constants'

export function getDirectChildren({ tree, id }) {
  if (id === MANAGER_ROOT_ID) {
    return tree.filter((c) => !c.parent_id).map((c) => c.id)
  }

  const children = tree?.find((c) => c.id === id)?.children_contracts_ids

  if (!children) return []

  return children
}

export function getChildrenLevel({ tree, id, level = Infinity }) {
  const children = getDirectChildren({ tree, id })

  if (!children || level === 0) return []

  return [
    ...children,
    ...flattenDeep(
      children.map((id) => getAllChildren({ tree, id, level: level - 1 })),
    ),
  ]
}

export function getAllChildren({ tree, id }) {
  return getChildrenLevel({ tree, id })
}

export function getDepartmentRootHeads({ tree, all = false }) {
  return tree?.departments
    ?.map((department) => {
      let childrenIds = []

      const heads = department.children.filter((e) => e.is_head_of_department)
      const noHeads = heads.length <= 0

      if (all || noHeads) {
        childrenIds = department.children.map((c) => c.id)
      } else {
        childrenIds = heads.map((h) => h.id)
      }

      return [Number(department.id), ...(childrenIds ?? [])]
    })
    .flat()
}

export function getAllDepartmentChildren({ tree, id, node, all = false }) {
  if (id === DEPARTMENT_ROOT_ID) {
    const children = tree?.departments

    if (!children) return []

    return getDepartmentRootHeads({ tree, all })
  } else {
    if (!node.data.employeeIds) return []

    return node.data.employeeIds.split(',').map(Number)
  }
}

export function getDepartmentsTree({ departments }) {
  if (!departments) {
    return []
  }

  const rootId = DEPARTMENT_ROOT_ID

  const rootChildren = departments.map((department) => {
    const sortedEmployees = department.employees.sort((a, b) => {
      if (a.is_head_of_department && !b.is_head_of_department) {
        return -1
      }
      if (!a.is_head_of_department && b.is_head_of_department) {
        return 1
      }
      return 0
    })

    return { id: String(department.id), children: sortedEmployees }
  })

  return { rootId, departments: rootChildren }
}

export function getShowingParentManagers({ tree, contractId, hiddenNodes }) {
  const contractItem = tree.find((c) => c.id === contractId)
  const parentId = contractItem?.parent_id

  const isParentHidden = hiddenNodes.includes(parentId)
  const parent = tree.find((c) => c.id === parentId) || { id: MANAGER_ROOT_ID }

  return [
    parent,
    ...(isParentHidden || parent.id !== MANAGER_ROOT_ID
      ? getShowingParentManagers({ tree, contractId: parentId, hiddenNodes })
      : []),
  ].filter(Boolean)
}
