import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useFetch } from '../../../../helpers/hooks'
import {
  Container,
  Table,
  UncontrolledTooltip,
  NavItem,
  TabContent,
  TabPane,
} from 'reactstrap'
import SearchBar from '../../../../components/SearchBar'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Flag, { getFlagUrlFromIso2 } from '../../../../components/ui/flag'
import { mapCountryToOption } from '../../../../utils/map-to-option'
import welcomeImage from '../../../../assets/images/welcome.svg'
import TabEmpty from '../../../Contract/components/tab/tab-empty'
import { Eye, FolderOpen } from '@phosphor-icons/react'
import { format, isValid } from 'date-fns'
import { cn, Pagination } from 'ui'
import Shimmer from '../../../../components/ui/shimmer'
import NoContent from '../../../../components/ui/no-content'
import Button from '../../../../components/ui/button'
import BadgeV2 from '../../../../components/ui/badge-v2'
import { DATE_FORMAT } from '../../../../utils/formatters/date-picker-date-format'
import PageHeading from '../../../../components/ui/page-heading'
import { getAdminDePayroll } from '../../../../services/api'
import { useFilters } from '../cards/use-filters'
import { PageNav } from '../../../../components/page-nav'
import { getFullName } from '../../../../utils/get-full-name'

export const PAYROLL_DETAILS_TABS = {
  currentCircle: { value: '1', label: 'Current cycle' },
  pastReports: { value: '2', label: 'Past reports' },
}

export default function GlobalPayroll() {
  const [activeTab, setActiveTab] = useState(
    PAYROLL_DETAILS_TABS.currentCircle.value,
  )

  const [filters, handleFiltersChange] = useFilters({
    country_id: '',
    page: 1,
    search: '',
    entity_id: '',
    client_id: '',
  })

  const [filterDate, setFilterDate] = useState('')

  function setPage(page) {
    handleFiltersChange('page', page)
  }

  function onQuery(searchText) {
    handleFiltersChange('search', searchText ?? '', { action: 'clear' })
  }

  function onTabChange(value) {
    setActiveTab(value)
    onQuery('')
  }

  function filterByCountry(countryId) {
    handleFiltersChange('country_id', countryId)
  }

  const {
    data: adminPayrollList,
    isLoading: isAdminPayrollListLoading,
    paginator: adminPayrollPaginator,
    completed: fetchAdminPayrollCompleted,
  } = useFetch(
    {
      action: getAdminDePayroll,
      withAdminAccess: true,
      autoFetch: true,
      body: filters,
    },
    [
      filters?.entity_id,
      filters?.search,
      filters?.country_id,
      filters?.client_id,
    ],
  )

  const pageLoading = isAdminPayrollListLoading || !fetchAdminPayrollCompleted

  const tabOptions = [
    {
      label: PAYROLL_DETAILS_TABS.currentCircle.label,
      id: PAYROLL_DETAILS_TABS.currentCircle.value,
    },
    {
      label: PAYROLL_DETAILS_TABS.pastReports.label,
      id: PAYROLL_DETAILS_TABS.pastReports.value,
    },
  ]

  return (
    <div className='page-content tw-overflow-visible'>
      <PageHeading>
        <PageHeading.Title>Global payroll</PageHeading.Title>
      </PageHeading>

      <Container className='tw-m-0 tw-p-0' fluid>
        <>
          <div className='tw-mb-6 tw-w-full tw-rounded tw-bg-white'>
            {pageLoading ? (
              <div className='tw-flex tw-h-[73px] tw-items-center tw-gap-4 tw-px-4'>
                <Shimmer width='152px' height='28px' />
                <Shimmer width='152px' height='28px' />
              </div>
            ) : (
              <>
                <PageNav>
                  {tabOptions.map((tab) => (
                    <NavItem key={tab.id}>
                      <PageNav.Link
                        isActive={activeTab === tab.id}
                        tag={activeTab === tab.value ? 'div' : 'button'}
                        to={tab.id}
                        onClick={() => onTabChange(tab.id)}
                      >
                        {tab.label}
                      </PageNav.Link>
                    </NavItem>
                  ))}
                </PageNav>
              </>
            )}
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId={PAYROLL_DETAILS_TABS.currentCircle.value}>
              <AdminPayroll
                payrollData={adminPayrollList}
                paginator={adminPayrollPaginator}
                page={filters.page}
                setPage={setPage}
                filterByCountry={filterByCountry}
                onQuery={onQuery}
                onEntityChange={(val) =>
                  handleFiltersChange('entity_id', val, { action: 'clear' })
                }
                onClientChange={(val) =>
                  handleFiltersChange('client_id', val, { action: 'clear' })
                }
                loading={pageLoading}
              />
            </TabPane>
            <TabPane tabId={PAYROLL_DETAILS_TABS.pastReports.value}>
              <AdminPayroll
                payrollData={adminPayrollList}
                paginator={adminPayrollPaginator}
                page={filters.page}
                setPage={setPage}
                filterByCountry={filterByCountry}
                onQuery={onQuery}
                onEntityChange={(val) =>
                  handleFiltersChange('entity_id', val, { action: 'clear' })
                }
                onClientChange={(val) =>
                  handleFiltersChange('client_id', val, { action: 'clear' })
                }
                loading={pageLoading}
                setFilterDate={setFilterDate}
                filterDate={filterDate}
              />
            </TabPane>
          </TabContent>
        </>
      </Container>
    </div>
  )
}

function AdminPayroll({
  onQuery,
  onEntityChange,
  onClientChange,
  payrollData,
  ...props
}) {
  const { paginator, page, setPage, filterDate, setFilterDate } = props
  const [query, setQuery] = useState(null)

  const isListEmpty =
    payrollData?.length === 0 && !props?.loading && query !== ''

  const { contract_statuses: status, countries } = useSelector(
    (state) => state?.Layout?.staticData ?? {},
  )
  const profile = useSelector((state) => state?.userProfile?.userProfile)

  const isPastPayrollReport = Boolean(setFilterDate)

  function handleSearch(searchText) {
    setQuery(searchText)
    onQuery(searchText)
  }

  const clientOptions = () => {
    const uniqueClients = new Set()

    return payrollData?.reduce((acc, payroll) => {
      if (!uniqueClients.has(payroll.client.id)) {
        acc.push({
          value: payroll?.client?.id,
          label: getFullName(payroll?.client),
        })
        uniqueClients.add(payroll.client.id)
      }

      return acc
    }, [])
  }

  const entityOptions = () => {
    const uniqueEntities = new Set()

    return payrollData?.reduce((acc, payroll) => {
      if (!uniqueEntities.has(payroll?.entity_name)) {
        acc.push({
          value: payroll?.entity_name, // @todo: replace with id when BE sends it
          label: payroll?.entity_name,
          // @todo: uncomment when BE sends country info
          // icon: (
          //   <Flag
          //     url={getFlagUrlFromIso2(entity.country.iso2)}
          //     className='tw-mr-2'
          //   />
          // ),
        })
        uniqueEntities.add(payroll.entity_name)
      }

      return acc
    }, [])
  }

  const filtersBlock = (
    <Container fluid className='tw-p-4 md:tw-px-6'>
      <div className='tw-flex tw-flex-col tw-flex-wrap tw-gap-2 sm:tw-flex-row'>
        <SearchBar
          className='tw-w-full tw-min-w-[11rem] tw-flex-1'
          query={query}
          placeholder='Search'
          onQueryChanged={handleSearch}
          isClearable
          inputClassName='!tw-h-auto'
        />
        {isPastPayrollReport && (
          <CustomDatePicker
            wrapperClassName='tw-w-full tw-min-w-[11rem] tw-flex-1'
            placeholder='Month'
            clearable
            showMonthYearPicker
            showFullMonthYearPicker
            handleClear={() => {
              setFilterDate('')
            }}
            selected={filterDate ? new Date(filterDate) : null}
            handleOnChange={setFilterDate}
          />
        )}
        <CustomSelect
          isLoading={props?.loading}
          isDisabled={props?.loading}
          options={clientOptions()}
          wrapperClassName='tw-flex-1 tw-min-w-44'
          placeholder='Client'
          onChange={(val) => onClientChange?.(val?.value)}
          isClearable
        />

        <CustomSelect
          isLoading={props?.loading}
          isDisabled={props?.loading}
          options={entityOptions()}
          wrapperClassName='tw-flex-1 tw-min-w-44'
          placeholder='Entity'
          onChange={(val) => onEntityChange?.(val?.value)}
          isClearable
        />

        <CustomSelect
          wrapperClassName='tw-flex-1 tw-min-w-44'
          isClearable
          placeholder='Country'
          options={countries?.map((c) => mapCountryToOption(c))}
          onChange={(country) => {
            props.filterByCountry?.(country?.id)
          }}
        />
      </div>
    </Container>
  )

  return !props?.loading && profile.entities?.length > 1 ? (
    <div
      className='tw-rounded tw-bg-white'
      style={{ boxShadow: '0px 1px 0px #dfe1e6' }}
    >
      {filtersBlock}

      <>
        <div className='table-responsive'>
          {isListEmpty ? (
            <TabEmpty
              title='No data available'
              icon={
                <FolderOpen
                  size={240}
                  color='var(--primary)'
                  weight='duotone'
                />
              }
            />
          ) : props?.loading || props?.filtering ? (
            <div
              className={cn('w-100 px-3 pb-3')}
              style={{ '--s-height': '57px' }}
            >
              <Shimmer width='100%' height='var(--s-height)' className='mb-1' />
              <Shimmer width='100%' height='var(--s-height)' className='mb-1' />
              <Shimmer width='100%' height='var(--s-height)' />
            </div>
          ) : (
            <Table className='table-centered table-nowrap text-muted mb-0'>
              <thead>
                <tr
                  style={{
                    borderTop: 'hidden',
                    borderBottom: '1px solid #E7E8F2',
                    width: '100px',
                    maxWidth: '100px',
                  }}
                >
                  <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                    Country
                  </th>

                  <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                    Entity
                  </th>

                  <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                    Employees
                  </th>

                  <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                    Cycle
                  </th>

                  <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                    Cut off date
                  </th>

                  {isPastPayrollReport && (
                    <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                      Total Payroll
                    </th>
                  )}

                  <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                    Status
                  </th>

                  <th className='tw-px-4 tw-text-sm tw-font-semibold tw-text-black'>
                    Actions
                  </th>
                </tr>
              </thead>
              {!props.filtering && (
                <tbody>
                  {payrollData?.map((payroll) => {
                    return (
                      <PayrollLine
                        key={`payroll-${payroll?.id}`}
                        payroll={payroll}
                        isPastPayrollReport={isPastPayrollReport}
                      />
                    )
                  })}
                </tbody>
              )}
            </Table>
          )}
        </div>

        {!paginator || isListEmpty || props?.loading ? null : (
          <div className='mt-2 mt-md-0 d-flex justify-content-md-end p-4.5'>
            <Pagination
              innerClass='pagination mb-0'
              activePage={page}
              onChange={setPage}
              itemsCountPerPage={paginator?.per_page ?? 50}
              totalItemsCount={paginator?.total ?? 0}
            />
          </div>
        )}
      </>
    </div>
  ) : (
    <div
      className='tw-rounded tw-bg-white'
      style={{ boxShadow: '0px 1px 0px #dfe1e6' }}
    >
      {filtersBlock}
      <NoContent
        image={welcomeImage}
        subtitle='Manage and run payroll for direct employees.'
        headline='Direct Employee Payroll'
      />
    </div>
  )
}

const PayrollLine = ({ payroll, isPastPayrollReport }) => {
  const payrollCountryFlag = getFlagUrlFromIso2(payroll?.country?.iso2) ?? null
  const getColor = () => {
    switch (payroll.status) {
      case 'approved':
      case 'paid':
        return 'success'
      case 'pending':
        return 'warning'
      default:
        return 'primary'
    }
  }

  return (
    <tr
      className={cn('tw-border-b tw-border-b-surface-30 odd:tw-bg-surface-10')}
    >
      <td className='tw-p-4'>
        <span className='tw-flex tw-items-center tw-gap-2'>
          <Flag size='24px' url={payrollCountryFlag} />
          <span className='tw-text-sm tw-text-text'>
            {payroll.country?.name || 'N/A'}
          </span>
        </span>
      </td>

      <td className='tw-p-4'>
        <span className='tw-text-sm tw-font-medium tw-text-text'>
          {payroll.entity_name}
        </span>
      </td>

      <td className='tw-p-4'>
        <span className='tw-text-sm tw-font-medium tw-text-text'>
          {payroll.employees}
        </span>
      </td>

      <td className='tw-p-4'>
        <span className='tw-text-sm tw-font-medium tw-text-text'>
          {payroll.cycle && isValid(new Date(payroll.cycle))
            ? format(new Date(payroll.cycle), 'MMMM yyyy')
            : 'N/A'}
        </span>
      </td>

      <td className='tw-p-4'>
        <span className='tw-text-sm tw-font-medium tw-text-text'>
          {payroll.cut_off_date && isValid(new Date(payroll.cut_off_date))
            ? format(new Date(payroll.cut_off_date), DATE_FORMAT.FORMAT_1)
            : 'N/A'}
        </span>
      </td>

      {isPastPayrollReport && (
        <th className='tw-text-sm tw-font-medium tw-text-text'>
          {payroll.total_payroll || 'N/A'}
        </th>
      )}

      <td className='tw-p-4'>
        <BadgeV2 name={payroll?.status} status={getColor()} />
      </td>

      <td className='tw-p-4'>
        <Button
          tag={Link}
          to={null} // @todo: update link/action
          className='tw-rounded-lg !tw-bg-secondary-20 !tw-p-4 hover:!tw-bg-secondary-40'
          icon={<Eye size={20} className='tw-text-secondary-100' />}
          id={'actionBtn' + payroll?.id}
          color='link'
        />
        <UncontrolledTooltip target={'actionBtn' + payroll?.id}>
          See details
        </UncontrolledTooltip>
      </td>
    </tr>
  )
}
