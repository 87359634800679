import { yupResolver } from '@hookform/resolvers/yup'
import {
  Baby,
  Briefcase,
  CalendarBlank,
  CalendarDots,
  CalendarPlus,
  CalendarX,
  CircleHalf,
  CirclesThreePlus,
  ClockClockwise,
  ClockCountdown,
  ClockCounterClockwise,
  DotsThreeCircle,
  Flag,
  HeartBreak,
  HourglassLow,
  HourglassMedium,
  Info,
  ListPlus,
  MinusCircle,
  Paperclip,
  PlusCircle,
  Siren,
  Star,
  Textbox,
  Thermometer,
  TreePalm,
} from '@phosphor-icons/react'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom'
import {
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'
import { v4 as uuid } from 'uuid'
import * as yup from 'yup'

import { Stepper } from 'ui'
import ControlledInput from '../../components/ControlledInput'
import ModalHeader from '../../components/ModalHeader'
import StepContainer from '../../components/Steps/StepContainer'
import Head from '../../components/head'
import { PrimaryAlert } from '../../components/ui/alert'
import Loader from '../../components/ui/loader'
import { useFetch } from '../../helpers/hooks'
import {
  createTimeOffPolicy,
  editTimeOffPolicy,
} from '../../services/api-time-off-policies'
import { track } from '../../utils/analytics'
import { formatDays } from '../../utils/formatters/format-days'
import isNill from '../../utils/is-nill'
import {
  DetailsList,
  TenureRulesList,
} from '../CompanySetting/components/time-off-policies-tab/view-policy'
import { getCarryOverExpiration } from '../Contract/ContractPage/time-off-stats'
import { ConfirmFormField } from '../Contract/CreateContract/components/confirm-field'
import {
  FormSectionHr,
  FormSectionTitle,
} from '../Contract/CreateContract/components/form-section-title'
import ControlledTypeSelect from '../Contract/CreateContract/fields/type-select'
import {
  TimeOffPolicyCarryOverTypes,
  TimeOffPolicyTypes,
} from '../Contract/utils/constants'
import AddTenureRule from './add-tenure-rule'
import ControlledWorkingDays from './controlled-working-days'
import { TIMEOFF_EVENTS } from './events'
import PolicyTimeOffAmount from './policy-time-off-amount'
import SettingsApplication from './settings-application'
import TenureRuleLine from './tenure-rule-line'
import {
  TimeOffFlowContextProvider,
  useTimeOffFlowContext,
} from './time-off-flow-context'
import { getAddedDays } from './utils/get-added-tenure-days'
import {
  CARRY_OVER_DATE_TYPE,
  getCarryOverDateType,
} from './utils/get-carry-over-type'

const BASE_PATH = '/time-off-policies/add'

function getStepFormId(step) {
  return `time-off-form-step-${step.key}`
}
function getPrevStepLink(step, steps) {
  const stepIndex = steps.findIndex((s) => s.key === step.key)
  const prevStepKey = steps[stepIndex - 1]?.key

  if (!prevStepKey) return null

  return `${BASE_PATH}/${prevStepKey}`
}
function getNextStepLink(step, steps) {
  const stepIndex = steps.findIndex((s) => s.key === step.key)
  const nextStepKey = steps[stepIndex + 1]?.key

  if (!nextStepKey) return null

  return `${BASE_PATH}/${nextStepKey}`
}

export function NewTimeOffPolicy() {
  const location = useLocation()
  const { state: policy } = location

  return (
    <TimeOffFlowContextProvider
      defaultData={
        !policy || policy?.fromTimeOffTab
          ? { working_days: [1, 2, 3, 4, 5] }
          : {
              // Step 1
              name: policy.name,
              policy_type: policy.type.id,
              amount: policy.accrual_days ?? policy.max_annual_days,
              working_days:
                typeof policy?.working_days === 'string'
                  ? JSON.parse(policy?.working_days)
                  : policy?.working_days,
              amount_type: policy.max_annual_days ? 'limited' : 'unlimited',
              // Step 2
              earning_days: policy.earning_start_days,
              accrual_frequency: policy.accrual_frequency,
              earning_date: policy.earning_start_days
                ? 'after_days'
                : 'start_date',
              carryover_days: policy.carryover_days,
              carryover_expiration_days: policy.carryover_expiration_days,
              carryover_date_type: policy.carryover_date_type,
              carryover:
                policy.carryover_type ===
                TimeOffPolicyCarryOverTypes.USE_OR_LOSS
                  ? 'use_it_or_lose_it'
                  : policy.carryover_days
                    ? 'limited'
                    : 'unlimited',
              carryover_expiration: policy.carryover_expiration_days
                ? 'expires'
                : 'never_expires',
              // Step 3
              half_day_request: policy.can_request_half_day ? 'yes' : 'no',
              max_sequential_days: policy.max_sequential_days ? 'yes' : 'no',
              max_sequential_days_amount: policy.max_sequential_days,
              retrospective_request: policy.is_retrospective_enabled
                ? 'yes'
                : 'no',
              waiting_time: policy.days_after_hiring ? 'yes' : 'no',
              waiting_time_amount: policy.days_after_hiring,
              negative_balance_enabled: policy.negative_balance_enabled
                ? 'yes'
                : 'no',
              are_reason_and_file_required: policy.are_reason_and_file_required
                ? 'yes'
                : 'no',
              has_tenure_rules: policy.tenure_rules?.length > 0 ? 'yes' : 'no',
              tenure_rules: policy.tenure_rules?.map((rule) => ({
                amount: rule.time_off_amount,
                tenure: rule.min_years,
                id: uuid(),
              })),
            }
      }
    >
      <NewTimeOffPolicyFlow />
    </TimeOffFlowContextProvider>
  )
}

function NewTimeOffPolicyFlow() {
  const history = useHistory()
  const { policyFormData } = useTimeOffFlowContext()
  const { stepKey } = useParams()
  const location = useLocation()
  const { state: policy } = location

  const isVacationType =
    policyFormData?.policy_type === TimeOffPolicyTypes.VACATION_POLICY_TYPE ||
    policy?.type?.id === TimeOffPolicyTypes.VACATION_POLICY_TYPE

  const allPossibleSteps = timeOffSteps(t)

  const filteredSteps = [
    allPossibleSteps[0],
    isVacationType && allPossibleSteps[1],
    allPossibleSteps[2],
    allPossibleSteps[3],
  ].filter(Boolean)

  const updatedSteps = filteredSteps.map((step, index) => {
    const isLastStep = index === filteredSteps.length - 1
    return { ...step, next: isLastStep ? null : filteredSteps[index + 1]?.key }
  })

  const stepperSteps = updatedSteps.map((step) => step.name)
  const stepKeys = updatedSteps.map((step) => step.key)
  const activeIndex = stepKeys.indexOf(stepKey ?? '')
  const activeStep = updatedSteps[activeIndex]
  const isLastStep = activeIndex === filteredSteps.length - 1

  const pageTitleWithStepName = [
    activeStep?.name,
    t('Create a time off policy'),
  ]
    .filter(Boolean)
    .join(' - ')

  function handlePrev(step) {
    const prevStepLink = getPrevStepLink(step, updatedSteps)
    history.push(prevStepLink ?? BASE_PATH, policy)
  }

  const loading = policyFormData?.status === 'pending'
  const disableNext = loading

  function handleNext(step) {
    const nextStepLink = getNextStepLink(step, updatedSteps)
    if (nextStepLink) {
      history.push(nextStepLink, policy)
    }
  }

  return (
    <Container fluid className='px-0'>
      <Head title={pageTitleWithStepName} />

      <ModalHeader
        action={() =>
          history.push(
            policy?.fromTimeOffTab
              ? `/contract/detail?id=${policy.contractRef}&tab=timeOff`
              : '/settings/time-off-policies',
          )
        }
      >
        <Stepper
          steps={stepperSteps}
          activeStep={activeIndex}
          className='tw-flex-grow'
        />
      </ModalHeader>

      <div className='tw-px-4'>
        <Switch>
          {updatedSteps.map((step, index) => {
            const { key, Component, name } = step
            return (
              <Route path={`${BASE_PATH}/${key}`} key={index} exact>
                <StepContainer
                  title={name}
                  index={activeIndex}
                  total={updatedSteps.length}
                  nextType='submit'
                  nextFormId={getStepFormId(step)}
                  noBack={index === 0}
                  disableNext={disableNext}
                  loading={loading}
                  onBack={() => handlePrev(step)}
                  nextText={isLastStep ? t('Save') : t('Continue')}
                >
                  <Component
                    step={step}
                    goToNextStep={() => handleNext(step)}
                  />
                </StepContainer>
              </Route>
            )
          })}
        </Switch>
      </div>
    </Container>
  )
}

const timeOffSteps = (t) => [
  {
    name: t('Policy details'),
    key: '',
    Component: Step1PolicyDetails,
    next: 'accrual',
  },
  {
    name: t('Accrual & carryover'),
    key: 'accrual',
    Component: Step2Accrual,
    next: 'requests',
  },
  {
    name: t('Requests rules'),
    key: 'requests',
    Component: Step3RequestsRules,
    next: 'review',
  },
  { name: t('Review'), key: 'review', Component: Step4Review },
]

function Step1PolicyDetails({ step, goToNextStep }) {
  const { policyFormData, setPolicyFormData } = useTimeOffFlowContext()

  const timeOffTypes = useSelector(
    (state) => state?.Layout?.staticData?.time_off_types ?? [],
  )
  const [addTenureRule, setAddTenureRule] = useState({
    isOpen: false,
    ruleToEdit: null,
  })
  const location = useLocation()
  const { state: policy } = location

  const policyTypeOptions = timeOffTypes.reduce((acc, type) => {
    if (type.id !== TimeOffPolicyTypes.DEFAULT_POLICY_TYPE) {
      acc.push({
        value: type.id,
        label: type.name,
        description:
          type?.is_accrued === 1
            ? t('Accrual time off')
            : t('Non-accrual time off'),
        icon: () => <PolicyTypeIcon typeId={type.id} weight='duotone' />,
        isDisabled: !!policy,
        tip: t('Policy type cannot be changed while editing'),
      })
    }
    return acc
  }, [])

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required(),
        policy_type: yup
          .number()
          .label(t('Policy type'))
          .oneOf(timeOffTypes.map((type) => type.id))
          .required(),
        amount: yup
          .number()
          .when(['policy_type', 'amount_type'], {
            is: (policyType, amountType) =>
              policyType === TimeOffPolicyTypes.VACATION_POLICY_TYPE ||
              (policyType !== TimeOffPolicyTypes.VACATION_POLICY_TYPE &&
                policyType !== TimeOffPolicyTypes.DEFAULT_POLICY_TYPE &&
                amountType === 'limited'),
            then: (schema) =>
              schema
                .required(t('Time off days is required'))
                .max(
                  yup.ref('max_amount'),
                  t('Time off days must be less than the first tenure rule'),
                ),
            otherwise: (schema) => schema.notRequired(),
          })
          .typeError(t('Invalid amount')),
        working_days: yup
          .array()
          .of(yup.number())
          .required(t('Working day is required'))
          .min(1, t('Working day is required')),
        has_tenure_rules: yup.string().when([], {
          is: () =>
            policyFormData.policy_type ===
            TimeOffPolicyTypes.VACATION_POLICY_TYPE,
          then: (schema) =>
            schema.required().when([], {
              is: () => tenureRules.length > 0,
              then: (schema) => schema.oneOf(['yes', 'no']),
              otherwise: (schema) =>
                schema.oneOf(['no'], 'Please add at least one tenure rule'),
            }),
          otherwise: (schema) => schema.notRequired(),
        }),
        amount_type: yup
          .string()
          .label('Time off amount type')
          .oneOf(['limited', 'unlimited'])
          .when('policy_type', {
            is: (type) =>
              type !== TimeOffPolicyTypes.VACATION_POLICY_TYPE &&
              type !== TimeOffPolicyTypes.DEFAULT_POLICY_TYPE,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
      }),
    ),
    defaultValues: { ...policyFormData },
  })

  const {
    policy_type: policyType,
    has_tenure_rules: hasTenureRules,
    amount,
    amount_type: amountType,
  } = useWatch({ control })
  const [tenureRules, setTenureRules] = useState(
    policyFormData?.tenure_rules ?? [],
  )

  const isVacationPolicy =
    policyType === TimeOffPolicyTypes.VACATION_POLICY_TYPE
  const isDefaultPolicy = policyType === TimeOffPolicyTypes.DEFAULT_POLICY_TYPE

  function onSubmit(values) {
    values.tenure_rules = tenureRules
    setPolicyFormData(values)
    goToNextStep()
  }

  useEffect(() => {
    setValue('max_amount', tenureRules[0]?.amount ?? Infinity)
  }, [tenureRules, setValue])

  if (!timeOffTypes || timeOffTypes.length <= 0) {
    return <Loader minHeight='max(50vh, 550px)' />
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} id={getStepFormId(step)}>
        <FormSectionTitle title={t('Insert a title')} />

        <ControlledInput
          control={control}
          name='name'
          id='name-input'
          placeholder={t('Policy name')}
        />

        <FormSectionHr />
        <FormSectionTitle title={t('Choose the type')} />

        <ControlledTypeSelect
          control={control}
          name='policy_type'
          error={errors?.policy_type}
          transform={{
            output: (event) => {
              setPolicyFormData({ policy_type: event.value })
              return event?.value
            },
          }}
          types={policyTypeOptions}
          cols={3}
          horizontal
        />

        <PrimaryAlert className='!tw-mb-2 tw-mt-6'>
          {t(
            'Accrual time off accumulates over time based on the employee’s work days',
          )}
        </PrimaryAlert>

        {!isDefaultPolicy && <FormSectionHr />}

        <PolicyTimeOffAmount
          isAccrued={isVacationPolicy}
          control={control}
          isDefault={isDefaultPolicy}
          isLimited={amountType === 'limited'}
          error={errors.amount_type}
          setValue={setValue}
        />

        {!isVacationPolicy ? null : (
          <>
            <ConfirmFormField
              control={control}
              error={errors.has_tenure_rules}
              name='has_tenure_rules'
              icon={
                <ListPlus
                  weight='duotone'
                  size={32}
                  className='tw-text-text-30'
                />
              }
              title={
                <div className='tw-flex tw-items-center tw-gap-1'>
                  {t('Time off tenure rules')}

                  <UncontrolledTooltip target='tenure-rules-info'>
                    {t(
                      'Offer additional vacation days based on years of employment',
                    )}
                  </UncontrolledTooltip>
                  <Info
                    id='tenure-rules-info'
                    size={16}
                    className='tw-text-text-70'
                  />
                </div>
              }
              description={t(
                'Should time-off days depend on length of service?',
              )}
              fieldOptions={[
                { label: t('Yes'), value: 'yes' },
                { label: t('No'), value: 'no' },
              ]}
              className='tw-mt-4 md:tw-col-span-2'
            />

            {hasTenureRules === 'yes' ? (
              <>
                {tenureRules.length > 0
                  ? tenureRules.map((tenure, index) => (
                      <TenureRuleLine
                        key={tenure.id}
                        tenureRule={tenure}
                        index={index}
                        addedDays={getAddedDays({
                          tenure,
                          tenureRules,
                          index,
                          amount,
                        })}
                        onDelete={() => {
                          setTenureRules((prev) =>
                            prev.filter((rule) => rule.id !== tenure.id),
                          )
                        }}
                        onEdit={() => {
                          setAddTenureRule({ isOpen: true, ruleToEdit: tenure })
                        }}
                      />
                    ))
                  : null}
                <button
                  type='button'
                  className='tw-my-4 tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded tw-border !tw-border-dashed tw-border-primary tw-bg-primary-20 tw-p-4 tw-text-primary'
                  onClick={() => {
                    setAddTenureRule({ isOpen: true, ruleToEdit: null })
                  }}
                >
                  <PlusCircle size={24} /> <span>{t('Add Tenure Rule')}</span>
                </button>
              </>
            ) : null}
          </>
        )}

        <FormSectionHr />
        <div className='tw-flex tw-flex-col tw-gap-6 tw-rounded tw-border tw-border-surface-30 tw-p-6'>
          <span className='tw-flex tw-gap-4'>
            <CalendarDots size={32} className='tw-fill-text-30' />
            <span className='tw-flex tw-flex-col'>
              <span className='tw-text-base tw-font-bold tw-text-black'>
                {t('Choose your working days')}
              </span>
              <span className='tw-text-sm tw-font-normal tw-text-text-60'>
                {t(
                  'Only the working days will be included in time off requests',
                )}
              </span>
            </span>
          </span>

          <ControlledWorkingDays
            control={control}
            name='working_days'
            inActiveClassName='tw-font-semibold tw-bg-white tw-border-surface-30 '
          />
        </div>
      </form>
      {addTenureRule.isOpen ? (
        <AddTenureRule
          tenureRules={tenureRules}
          isOpen={addTenureRule.isOpen}
          timeOffAmount={amount}
          toggle={() => setAddTenureRule({ isOpen: false, ruleToEdit: null })}
          tenureRule={addTenureRule.ruleToEdit}
          onSave={(newTenures) => {
            setTenureRules(newTenures)
            setAddTenureRule({ isOpen: false, ruleToEdit: null })
          }}
        />
      ) : null}
    </>
  )
}

// Custom hook to make sure that we have data from the first step
// Otherwise, redirect back to the first step
function useCheckFirstStepData(formData, history) {
  useEffect(() => {
    // If we don't have the data from the first step, redirect back to the first step
    // Here we are checking with the name field
    if (!formData?.name) {
      history.push(BASE_PATH)
    }
  }, [formData, history])
}

function Step2Accrual({ step, goToNextStep }) {
  const history = useHistory()
  const { policyFormData, setPolicyFormData } = useTimeOffFlowContext()

  useCheckFirstStepData(policyFormData, history)

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        earning_date: yup
          .string()
          .label(t('Earning date'))
          .oneOf(['start_date', 'after_days'])
          .required(),
        earning_days: yup
          .number()
          .label(t('Earning days'))
          .when('earning_date', {
            is: 'after_days',
            then: (schema) => schema.required(),
            otherwise: (schema) =>
              schema
                .notRequired()
                .transform((value) =>
                  isNaN(value) || isNill(value) ? 0 : value,
                ),
          }),
        accrual_frequency: yup
          .string()
          .label(t('Accrual frequency'))
          .oneOf(['weekly', 'bi_weekly', 'monthly', 'yearly'])
          .required(),
        carryover: yup
          .string()
          .label(t('Time off carryover'))
          .oneOf(['use_it_or_lose_it', 'limited', 'unlimited'])
          .required(),
        carryover_days: yup
          .number()
          .label(t('Carryover days'))
          .when('carryover', {
            is: 'limited',
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
        carryover_expiration: yup
          .string()
          .label(t('Carryover expiration'))
          .oneOf(['never_expires', 'expires'])
          .when('carryover', {
            is: 'limited',
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
        carryover_expiration_days: yup
          .number()
          .label(t('Carryover expiration days'))
          .when('carryover_expiration', {
            is: 'expires',
            then: (schema) => schema.required().typeError('Invalid value'),
            otherwise: (schema) =>
              schema
                .notRequired()
                .transform((value) =>
                  isNaN(value) || isNill(value) ? 0 : value,
                ),
          }),
        carryover_date_type: yup
          .string()
          .label(t('Carryover review'))
          .oneOf([
            CARRY_OVER_DATE_TYPE.CALENDAR_YEAR,
            CARRY_OVER_DATE_TYPE.NON_CALENDAR_YEAR,
          ])
          .when('carryover', {
            is: 'limited',
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
      }),
    ),
    defaultValues: { ...policyFormData },
  })

  const {
    earning_date: earningDate,
    carryover,
    carryover_expiration: carryoverExpiration,
  } = useWatch({ control })
  const isEarningAfterDays = earningDate === 'after_days'
  const isLimitedCarryover = carryover === 'limited'
  const isCarryoverExpires = carryoverExpiration === 'expires'

  function onSubmit(values) {
    setPolicyFormData(values)

    goToNextStep()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={getStepFormId(step)}>
      <FormSectionTitle title={t('Accrual')} />

      <ConfirmFormField
        control={control}
        error={errors.earning_date}
        name='earning_date'
        icon={
          <CalendarPlus
            weight='duotone'
            size={32}
            className='tw-text-text-30'
          />
        }
        title={t('Earning date')}
        description={t('When does the worker start earning?')}
        fieldOptions={[
          { label: t('Start date'), value: 'start_date' },
          { label: t('After number of days'), value: 'after_days' },
        ]}
        className='md:tw-col-span-2'
        innerClassName={isEarningAfterDays && 'tw-rounded-b-none'}
        transform={{
          output: (event) => {
            if (event.target.value === 'start_date') {
              setPolicyFormData({ earning_days: 0 })
            }
            return event
          },
        }}
      />
      {!isEarningAfterDays ? null : (
        <div className='tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
          <InputGroup>
            <ControlledInput
              control={control}
              name='earning_days'
              id='earning_days-input'
              placeholder={t('Insert amount')}
              type='number'
              min={1}
              wrapperClassName='tw-flex-grow'
              className='!tw-rounded-e-none'
            />
            <InputGroupAddon addonType='append'>
              <InputGroupText>{t('Calendar days')}</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
      )}

      <ConfirmFormField
        control={control}
        error={errors.accrual_frequency}
        name='accrual_frequency'
        icon={
          <ClockClockwise
            weight='duotone'
            size={32}
            className='tw-text-text-30'
          />
        }
        title={t('Accrual frequency')}
        description={t('How often does the time off balance increase?')}
        fieldOptions={[
          { label: t('Weekly'), value: 'weekly' },
          { label: t('Bi-Weekly'), value: 'bi_weekly' },
          { label: t('Monthly'), value: 'monthly' },
          { label: t('Yearly'), value: 'yearly' },
        ]}
        className='tw-mt-4 md:tw-col-span-2'
      />

      <FormSectionHr />
      <FormSectionTitle title={t('Carryover')} />

      <ConfirmFormField
        control={control}
        error={errors.carryover}
        name='carryover'
        icon={
          <CirclesThreePlus
            weight='duotone'
            size={32}
            className='tw-text-text-30'
          />
        }
        title={t('Time off carryover')}
        description={t(
          'Should the worker be allowed to carry over unused time off to the next year?',
        )}
        fieldOptions={[
          {
            label: t('No, it’s use it or lose it'),
            value: 'use_it_or_lose_it',
          },
          { label: t('Limited carryover'), value: 'limited' },
          { label: t('Unlimited carryover'), value: 'unlimited' },
        ]}
        className='tw-mt-4 md:tw-col-span-2'
        innerClassName={isLimitedCarryover && 'tw-rounded-b-none'}
      />

      {!isLimitedCarryover ? null : (
        <div className='tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
          <InputGroup>
            <ControlledInput
              control={control}
              name='carryover_days'
              id='carryover_days-input'
              placeholder={t('Insert amount')}
              type='number'
              min={1}
              wrapperClassName='tw-flex-grow'
              className='!tw-rounded-e-none'
            />

            <InputGroupAddon addonType='append'>
              <InputGroupText>{t('Working days')}</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
      )}

      {isLimitedCarryover && (
        <ConfirmFormField
          control={control}
          error={errors.carryover_date_type}
          name='carryover_date_type'
          icon={
            <HourglassLow
              weight='duotone'
              size={32}
              className='tw-text-text-30'
            />
          }
          title={t('Carryover review')}
          description={t(
            'When should the review of unused time off take place?',
          )}
          fieldOptions={[
            {
              label: t('At the start of a new calendar year'),
              value: CARRY_OVER_DATE_TYPE.CALENDAR_YEAR,
            },
            {
              label: t('Every 12 months after earning date'),
              value: CARRY_OVER_DATE_TYPE.NON_CALENDAR_YEAR,
            },
          ]}
          className='tw-mt-4 md:tw-col-span-2'
        />
      )}

      {!isLimitedCarryover ? null : (
        <>
          <ConfirmFormField
            control={control}
            error={errors.carryover_expiration}
            name='carryover_expiration'
            icon={
              <Briefcase
                weight='duotone'
                size={32}
                className='tw-text-text-30'
              />
            }
            title={t('Carryover expiration')}
            description={t('Should carried-over time off expire?')}
            fieldOptions={[
              { label: t('No, it never expires'), value: 'never_expires' },
              {
                label: t('Yes, expires after an amount of days'),
                value: 'expires',
              },
            ]}
            className='tw-mt-4 md:tw-col-span-2'
            innerClassName={isCarryoverExpires && 'tw-rounded-b-none'}
          />
          {!isCarryoverExpires ? null : (
            <div className='tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
              <InputGroup>
                <ControlledInput
                  control={control}
                  name='carryover_expiration_days'
                  id='carryover_expiration_days-input'
                  placeholder={t('Insert amount')}
                  type='number'
                  min={1}
                  wrapperClassName='tw-flex-grow'
                  className='!tw-rounded-e-none'
                />

                <InputGroupAddon addonType='append'>
                  <InputGroupText>{t('Calendar days')}</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </div>
          )}
        </>
      )}
    </form>
  )
}

function Step3RequestsRules({ step, goToNextStep }) {
  const history = useHistory()
  const { policyFormData, setPolicyFormData } = useTimeOffFlowContext()

  const isAccrued =
    policyFormData?.policy_type === TimeOffPolicyTypes.VACATION_POLICY_TYPE

  useCheckFirstStepData(policyFormData, history)

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        half_day_request: yup
          .string()
          .oneOf(['yes', 'no'])
          .label(t('Half day request'))
          .required(),
        max_sequential_days: yup
          .string()
          .label(t('Max sequential days'))
          .oneOf(['yes', 'no'])
          .required(),
        max_sequential_days_amount: yup
          .number()
          .label(t('Time off maximum'))
          .when('max_sequential_days', {
            is: 'yes',
            then: (schema) => schema.required(),
            otherwise: (schema) =>
              schema
                .nullable()
                .notRequired()
                .transform((value) =>
                  isNaN(value) || isNill(value) ? 0 : value,
                ),
          }),
        retrospective_request: yup
          .string()
          .label(t('Retrospective request'))
          .oneOf(['yes', 'no'])
          .required(),
        waiting_time: yup
          .string()
          .label(t('Waiting time'))
          .oneOf(['yes', 'no'])
          .required(),
        waiting_time_amount: yup
          .number()
          .label(t('Waiting time amount'))
          .when('waiting_time', {
            is: 'yes',
            then: (schema) => schema.typeError(t('Invalid value')).required(),
            otherwise: (schema) => schema.notRequired(),
          }),
        negative_balance_enabled: yup
          .string()
          .oneOf(['yes', 'no'])
          .label(t('Negative balance'))
          .when([], {
            is: () => isAccrued,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
        are_reason_and_file_required: yup
          .string()
          .oneOf(['yes', 'no'])
          .label(t('Add reason and upload document'))
          .required(),
      }),
    ),
    defaultValues: { ...policyFormData },
  })

  const { max_sequential_days: maxSequentialDays, waiting_time: waitingTime } =
    useWatch({ control })
  const isMaxSequentialDays = maxSequentialDays === 'yes'
  const isWaitingTime = waitingTime === 'yes'

  function onSubmit(values) {
    setPolicyFormData(values)
    goToNextStep()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={getStepFormId(step)}>
      <ConfirmFormField
        control={control}
        error={errors.half_day_request}
        name='half_day_request'
        icon={
          <CircleHalf weight='duotone' size={32} className='tw-text-text-30' />
        }
        title={t('Half day request')}
        description={t('Can a worker request a half day off?')}
        fieldOptions={[
          { label: t('Yes'), value: 'yes' },
          { label: t('No'), value: 'no' },
        ]}
      />

      <ConfirmFormField
        control={control}
        error={errors.max_sequential_days}
        name='max_sequential_days'
        icon={<Siren weight='duotone' size={32} className='tw-text-text-30' />}
        title={t('Time off maximum')}
        description={t(
          'Is there a maximum number of sequential days per time off request?',
        )}
        fieldOptions={[
          { label: t('Yes'), value: 'yes' },
          { label: t('No'), value: 'no' },
        ]}
        className='tw-mt-4'
        innerClassName={isMaxSequentialDays && 'tw-rounded-b-none'}
      />
      {!isMaxSequentialDays ? null : (
        <div className='tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
          <InputGroup>
            <ControlledInput
              control={control}
              name='max_sequential_days_amount'
              id='max_sequential_days_amount-input'
              placeholder={t('Insert amount')}
              type='number'
              min={1}
              wrapperClassName='tw-flex-grow'
              className='!tw-rounded-e-none'
            />

            <InputGroupAddon addonType='append'>
              <InputGroupText>{t('Calendar days')}</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
      )}

      <ConfirmFormField
        control={control}
        error={errors.retrospective_request}
        name='retrospective_request'
        icon={
          <ClockCounterClockwise
            weight='duotone'
            size={32}
            className='tw-text-text-30'
          />
        }
        title={t('Retrospective request')}
        description={t('Can a worker submit a request for a past period?')}
        fieldOptions={[
          { label: t('Yes'), value: 'yes' },
          { label: t('No'), value: 'no' },
        ]}
        className='tw-mt-4'
      />

      <ConfirmFormField
        control={control}
        error={errors.waiting_time}
        name='waiting_time'
        icon={
          <ClockCountdown
            weight='duotone'
            size={32}
            className='tw-text-text-30'
          />
        }
        title={t('Request eligibility')}
        description={t(
          'Is there a waiting time to request time off after date of hire?',
        )}
        fieldOptions={[
          { label: t('Yes'), value: 'yes' },
          { label: t('No'), value: 'no' },
        ]}
        className='tw-mt-4'
        wrapperClassName={isWaitingTime && 'tw-rounded-b-none'}
      />
      {!isWaitingTime ? null : (
        <div className='tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
          <InputGroup>
            <ControlledInput
              control={control}
              name='waiting_time_amount'
              id='waiting_time_amount-input'
              placeholder={t('Insert amount')}
              type='number'
              min={1}
              wrapperClassName='tw-flex-grow'
              className='!tw-rounded-e-none'
            />
            <InputGroupAddon addonType='append'>
              <InputGroupText>{t('Calendar days')}</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
      )}

      {!isAccrued ? null : (
        <ConfirmFormField
          control={control}
          error={errors.negative_balance_enabled}
          name='negative_balance_enabled'
          icon={
            <MinusCircle
              weight='duotone'
              size={32}
              className='tw-text-text-30'
            />
          }
          title={t('Negative Balance')}
          description={t(
            'Can a worker request time off even if they don’t have a balance?',
          )}
          fieldOptions={[
            { label: t('Yes'), value: 'yes' },
            { label: t('No'), value: 'no' },
          ]}
          className='tw-mt-4'
        />
      )}

      <ConfirmFormField
        control={control}
        error={errors.are_reason_and_file_required}
        name='are_reason_and_file_required'
        icon={
          <Paperclip weight='duotone' size={32} className='tw-text-text-30' />
        }
        title={t('Add Reason and Upload document')}
        description={t(
          'Is the worker required to add a reason and upload a document with the request?',
        )}
        fieldOptions={[
          { label: t('Yes'), value: 'yes' },
          { label: t('No'), value: 'no' },
        ]}
        className='tw-mt-4'
      />
    </form>
  )
}

function getTrackingData(policyFormData) {
  return {
    policy_type:
      policyFormData.policy_type === TimeOffPolicyTypes.VACATION_POLICY_TYPE
        ? 'accrued'
        : 'non-accrued',
    accrual_frequency: policyFormData.accrual_frequency,
    carryover_used: policyFormData?.carryover === 'limited',
    is_tenure_used:
      policyFormData.policy_type !== TimeOffPolicyTypes.VACATION_POLICY_TYPE
        ? null
        : policyFormData?.has_tenure_rules === 'yes',
    is_limited:
      policyFormData.policy_type === TimeOffPolicyTypes.VACATION_POLICY_TYPE
        ? null
        : policyFormData?.amount_type === 'limited',
  }
}

function Step4Review({ step }) {
  const history = useHistory()
  const { policyFormData, setPolicyFormData } = useTimeOffFlowContext()
  const location = useLocation()
  const { state: policy } = location
  const carryOverEnabled = policyFormData?.carryover === 'limited'
  useCheckFirstStepData(policyFormData, history)
  const hasTenureRules = policyFormData.has_tenure_rules === 'yes'
  const isVacationPolicy =
    policyFormData?.policy_type === TimeOffPolicyTypes.VACATION_POLICY_TYPE

  const { handleSubmit } = useForm({ defaultValues: { ...policyFormData } })
  const [newData, setNewData] = useState()

  const { startFetch: createPolicy } = useFetch({
    action: createTimeOffPolicy,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(t('Failed to create policy'))
      } else {
        toastr.success(t('Policy created successfully'))
        history.push('/settings/time-off-policies')
        track(TIMEOFF_EVENTS.SAVED_POLICY, getTrackingData(policyFormData))
      }
    },
    onError: (error) => {
      toastr.error(error || t('Something went wrong while creating policy'))
      setPolicyFormData({ status: 'error' })
    },
  })

  const { startFetch: editPolicy, isLoading: isEditing } = useFetch({
    action: editTimeOffPolicy,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(t('Failed to edit policy'))
      } else {
        toastr.success(t('Policy edited successfully'))
        history.push('/settings/time-off-policies')
        track(TIMEOFF_EVENTS.EDITED_POLICY, getTrackingData(policyFormData))
      }
    },
    onError: (error) => {
      toastr.error(error || t('Something went wrong while editing policy'))
      setPolicyFormData({ status: 'error' })
    },
  })

  function onSubmit() {
    let body = {
      name: policyFormData?.name,
      time_off_type_id: policyFormData?.policy_type,
      can_request_half_day: policyFormData?.half_day_request === 'yes',
      max_sequential_days:
        policyFormData?.max_sequential_days === 'yes'
          ? policyFormData?.max_sequential_days_amount
          : null,
      is_retrospective_enabled: policyFormData?.retrospective_request === 'yes',
      days_after_hiring:
        policyFormData?.waiting_time === 'yes'
          ? policyFormData?.waiting_time_amount
          : 0,
      working_days: policyFormData.working_days,
      negative_balance_enabled:
        policyFormData?.negative_balance_enabled === 'yes',
      are_reason_and_file_required:
        policyFormData?.are_reason_and_file_required === 'yes',
      max_annual_days: policyFormData?.amount,
    }

    if (isVacationPolicy) {
      body = {
        ...body,
        accrual_days: policyFormData?.amount,
        accrual_frequency: policyFormData?.accrual_frequency,
        earning_start_days:
          policyFormData?.earning_date === 'start_date'
            ? 0
            : policyFormData.earning_days,
        carryover_days: !carryOverEnabled
          ? '0'
          : String(policyFormData.carryover_days),
        carryover_expiration_days: !carryOverEnabled
          ? 0
          : policyFormData.carryover_expiration === 'never_expires'
            ? 0
            : policyFormData.carryover_expiration_days,
        carryover_type: carryOverEnabled
          ? TimeOffPolicyCarryOverTypes.LIMITED
          : policyFormData?.carryover === 'unlimited'
            ? TimeOffPolicyCarryOverTypes.UNLIMITED
            : TimeOffPolicyCarryOverTypes.USE_OR_LOSS,
        carryover_date_type: policyFormData?.carryover_date_type,
        tenure_rules: hasTenureRules
          ? policyFormData.tenure_rules.map((rule) => ({
              min_years: rule.tenure,
              time_off_amount: rule.amount,
            }))
          : [],
      }
    }

    if (hasAdditionalStep(body)) {
      body.id = policy.id
      setNewData(body)
    } else {
      setPolicyFormData({ status: 'pending' })
      if (policy?.id) {
        body.id = policy.id
        editPolicy(body)
      } else {
        createPolicy(body)
      }
    }
  }

  const hasAdditionalStep = (body) => {
    if (!isVacationPolicy || !policy?.id) {
      return false
    }
    const changeInMaxAnnualDays =
      policy?.max_annual_days !== policyFormData?.amount ||
      policy?.accrual_days !== policyFormData?.amount
    const changeInTenureRules = !isEqual(
      policy?.tenure_rules,
      body.tenure_rules,
    )
    const changeInAccrualRules =
      policy?.accrual_frequency !== body.accrual_frequency ||
      policy?.earning_start_days !== body.earning_start_days
    const changeInCarryoverRules =
      policy?.carryover_days !== policyFormData?.carryover_days ||
      policy?.carryover_expiration_days !==
        policyFormData?.carryover_expiration_days ||
      policy?.carryover_type?.toLowerCase() !== policyFormData?.carryover ||
      policy?.carryover_date_type !== policyFormData?.carryover_date_type

    return (
      changeInMaxAnnualDays ||
      changeInTenureRules ||
      changeInAccrualRules ||
      changeInCarryoverRules
    )
  }

  const timeOffTypes = useSelector(
    (state) => state?.Layout?.staticData?.time_off_types ?? [],
  )
  const formTimeOffType = timeOffTypes.find(
    (type) => type.id === policyFormData?.policy_type,
  )
  const timeOffTypeName = formTimeOffType?.name
  const isAccruedType = formTimeOffType?.is_accrued === 1

  const frequency = policyFormData?.accrual_frequency
  const timeOffAmount = policyFormData?.amount
  const accrualSettings = getAccrualSettings(frequency, timeOffAmount, t)

  return (
    <>
      {!!newData && (
        <SettingsApplication
          toggle={() => setNewData(null)}
          handleEdit={(data) => editPolicy({ ...data, ...newData })}
          isEditing={isEditing}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)} id={getStepFormId(step)}>
        <FormSectionTitle title={t('Here is a recap of your policy:')} />

        <DetailsList
          bodyClassName='tw-rounded tw-border tw-border-surface-30 tw-p-6'
          items={[
            {
              label: t('Name'),
              value: policyFormData?.name,
              icon: <Textbox size={24} />,
            },
            {
              label: t('Type'),
              value: timeOffTypeName,
              icon: <TreePalm size={24} />,
            },
            !!policyFormData?.amount_type && {
              icon: <CalendarBlank size={24} />,
              label: t('Time off amount'),
              value: hasTenureRules
                ? null
                : policyFormData?.amount
                  ? formatDays(policyFormData?.amount) + ' / ' + t('Year')
                  : t('Unlimited'),
              extraDetails: hasTenureRules ? (
                <TenureRulesList policy={policyFormData} />
              ) : null,
            },
            {
              label: t('Accrual settings'),
              value: !isAccruedType ? t('Non-accrual') : accrualSettings,
              icon: <ClockClockwise size={24} />,
            },
            !isAccruedType
              ? null
              : {
                  label: t('Carryover'),
                  value:
                    policyFormData?.carryover === 'use_it_or_lose_it'
                      ? t('Use it or lose it')
                      : carryOverEnabled
                        ? t('Limited to', {
                            days: formatDays(policyFormData?.carryover_days),
                          })
                        : t('Unlimited'),
                  icon: <CirclesThreePlus size={24} />,
                },
            isAccruedType &&
              carryOverEnabled && {
                label: t('Carryover review'),
                value: getCarryOverDateType(
                  policyFormData?.carryover_date_type,
                  t,
                ),
                icon: <HourglassMedium size={24} />,
              },
            !isAccruedType || policyFormData?.carryover !== 'limited'
              ? null
              : {
                  label: t('Carryover expiration'),
                  value: getCarryOverExpiration(
                    policyFormData?.carryover_expiration_days,
                    t,
                  ),
                  icon: <CalendarX size={24} />,
                },
          ]}
        />

        <PrimaryAlert className='tw-mt-6'>
          {t(
            'You can always edit these information later and assign workers to this policy.',
          )}
        </PrimaryAlert>
      </form>
    </>
  )
}

export function PolicyTypeIcon({ typeId, weight, size = 32, className }) {
  switch (typeId) {
    // Public holiday
    case 1: {
      return <Flag weight={weight} size={size} className={className} />
    }
    // Vacation
    case 2: {
      return <TreePalm weight={weight} size={size} className={className} />
    }
    // Sick leave
    case 3: {
      return <Thermometer weight={weight} size={size} className={className} />
    }
    // Parental leave
    case 4: {
      return <Baby weight={weight} size={size} className={className} />
    }
    // Religious
    case 5: {
      return <Star weight={weight} size={size} className={className} />
    }
    // Bereavement
    case 6: {
      return <HeartBreak weight={weight} size={size} className={className} />
    }
    // Default Time Off
    case 8: {
      return <HourglassLow weight={weight} size={size} className={className} />
    }
    // Other & unknown
    case 7:
    default: {
      return (
        <DotsThreeCircle weight={weight} size={size} className={className} />
      )
    }
  }
}

export function policyTypeColor({ typeId }) {
  switch (typeId) {
    case 1: {
      return 'secondary'
    }
    case 2: {
      return 'systemGold'
    }
    case 3: {
      return 'systemRed'
    }
    case 4: {
      return 'green'
    }
    case 5: {
      return 'systemBlue'
    }
    case 6: {
      return 'cyan'
    }
    case 7:
    default: {
      return 'surface'
    }
  }
}

export function policyTypeIconColors({ typeId }) {
  const colors = {
    secondary: 'tw-bg-secondary-10 tw-text-secondary-100',
    systemGold: 'tw-bg-systemGold-20 tw-text-systemGold-110',
    systemRed: 'tw-bg-systemRed-10 tw-text-systemRed-100',
    green: 'tw-bg-green-10 tw-text-green-100',
    systemBlue: 'tw-bg-systemBlue-10 tw-text-systemBlue-100',
    cyan: 'tw-bg-cyan-20 tw-text-cyan-100',
    surface: 'tw-bg-surface-10 tw-text-surface-100',
  }

  return colors[policyTypeColor({ typeId })]
}

function floorDays(days) {
  return Number(days).toFixed(2)
}
function getAccrualSettings(frequency, yearlyAmount, t) {
  switch (frequency) {
    case 'weekly': {
      return `${floorDays(yearlyAmount / 52)} ${t('days')} / ${t('Weekly')}`
    }
    case 'bi_weekly': {
      return `${floorDays(yearlyAmount / 26)} ${t('days')} / ${t('Bi-Weekly')}`
    }
    case 'monthly': {
      return `${floorDays(yearlyAmount / 12)} ${t('days')} / ${t('Monthly')}`
    }
    case 'yearly': {
      return `${floorDays(yearlyAmount)} ${t('days')} / ${t('Yearly')}`
    }
    default: {
      return t('Non-accrual')
    }
  }
}
