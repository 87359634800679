import { t } from 'i18next'
import React from 'react'
import { ListBullets, ArrowRight } from '@phosphor-icons/react'
import { ModalFooter } from 'reactstrap'
import { useSteps } from './step-context'
import Button from '../../../../../components/ui/button'

export function Intro() {
  const { nextStep } = useSteps()
  return (
    <div className='tw-flex tw-flex-1 tw-flex-col'>
      <div className='tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-center'>
        <ListBullets size={150} weight='duotone' className='tw-text-primary' />
        <span className='tw-text-xl tw-text-black'>
          {t('Check your eligiblity')}
        </span>
        <span className='tw-text-60 tw-mt-5'>
          {
            // prettier-ignore
            t(
            'Three steps to know if the contract is eligible for this program'
          )
          }
        </span>
      </div>
      <ModalFooter>
        <Button onClick={nextStep} iconRight={<ArrowRight />}>
          {t('Check')}
        </Button>
      </ModalFooter>
    </div>
  )
}
