import React from 'react'
import DataTable from '../../../components/ui/data-table'
import { ProfileCard } from 'ui'
import { getFullName } from '../../../utils/get-full-name'
import { DownloadSimple, Eye } from '@phosphor-icons/react'
import { UncontrolledTooltip } from 'reactstrap'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
function PayrollDetailsTable({ data, onViewPayrollsDetails, currency }) {
  const { format } = getCurrencyFormatter(currency)
  const columns = [
    {
      Header: `Employees (${data.length})`,
      headerClassName: 'sticky-col first-col',
      className: 'sticky-col first-col !tw-w-[240px] !tw-min-w-[240px]',
      accessor: 'contractor',
      truncated: true,
      Cell: ({ rowData }) => {
        return (
          <ProfileCard
            title={getFullName(rowData.contractor)}
            name={getFullName(rowData.contractor)}
            photo={rowData.contractor.avatar}
            description={rowData.contractor.contract_id}
          />
        )
      },
    },
    {
      Header: 'Base Pay',
      accessor: 'details.base_salary',
      Cell: ({ cellData }) => format(cellData),
    },
    {
      Header: 'Allowances',
      accessor: 'details.allowances',
      Cell: ({ cellData }) => format(cellData),
    },
    {
      Header: 'Expenses',
      accessor: 'details.expenses',
      Cell: ({ cellData }) => format(cellData),
    },
    {
      Header: 'Additions',
      accessor: 'details.additions',
      Cell: ({ cellData }) => format(cellData),
    },
    {
      Header: 'Deductions',
      accessor: 'details.deductions',
      Cell: ({ cellData }) => format(cellData),
    },
    {
      Header: 'ER Contributions',
      accessor: 'details.employer_contributions',
      Cell: ({ cellData }) => format(cellData),
    },
    {
      Header: 'Net Pay',
      accessor: 'details.net_pay',
      Cell: ({ cellData }) => format(cellData),
    },
    {
      Header: 'Total Cost',
      accessor: 'details.total_cost',
      Cell: ({ cellData }) => format(cellData),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      className: 'sticky-col !tw-right-0',
      headerClassName: 'sticky-col !tw-right-0',
      Cell: ({ rowData }) => {
        return (
          <div className='tw-flex tw-items-center tw-gap-2'>
            <button
              id='view-payslips-details'
              className='tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded tw-bg-secondary-20 tw-text-text-100 tw-transition-colors hover:tw-bg-secondary-30'
              onClick={() => onViewPayrollsDetails?.(rowData)}
            >
              <Eye size={16} />
            </button>
            <UncontrolledTooltip
              placement='bottom'
              target='view-payslips-details'
            >
              See details
            </UncontrolledTooltip>
            <button
              id='download-payslips-details'
              className='tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded tw-bg-secondary-20 tw-text-text-100 tw-transition-colors hover:tw-bg-secondary-30'
            >
              <DownloadSimple size={16} />
            </button>
            <UncontrolledTooltip
              placement='bottom'
              target='download-payslips-details'
            >
              Download Payslip
            </UncontrolledTooltip>
          </div>
        )
      },
    },
  ]
  return (
    <div className='tw-rounded tw-bg-white tw-p-6'>
      <DataTable data={data} columns={columns} responsive />
    </div>
  )
}

export default PayrollDetailsTable
