import React from 'react'
import { Buildings, Handshake, UserCircle } from '@phosphor-icons/react'

export const STEP_TYPE = {
  INTRO: 'INTRO',
  QUESTION: 'QUESTION',
  RESULT: 'RESULT',
}

export const steps = [
  {
    type: STEP_TYPE.INTRO,
  },
  {
    type: STEP_TYPE.QUESTION,
    title: 'Contractor Assessment',
    id: 'contractor_assessment',
    icon: Handshake,
    answers: [
      {
        value: 'yes',
        label:
          'Yes – The individual operates independently and is not treated as an employee.',
      },
      {
        value: 'no',
        label:
          'No – The individual functions like an employee (e.g., fixed hours, direct supervision, core to the business).',
      },
    ],
    description: (
      <span>
        Does your working relationship with this individual qualify as an
        independent contractor arrangement under the laws of their country and
        as reflected in the{' '}
        <a
          target='_blank'
          rel='noreferrer'
          href='https://drive.google.com/file/d/1NYA9N5HUCTdcFgj9kxePGsf2Zlz_F7jF/view?usp=drive_link'
        >
          <span className='tw-text-primary'>contractor agreement</span>
        </a>
        ?
      </span>
    ),
    caption:
      'If confirmed, a contractor agreement will be signed between RemotePass and the contractor, under the terms of the Master Service Agreement.',
  },
  {
    type: STEP_TYPE.QUESTION,
    title: 'Worker’s role',
    id: 'workers_roles',
    icon: UserCircle,
    answers: [
      { value: 'yes', label: 'Yes – A license or certification is required.' },
      { value: 'no', label: 'No – No license or certification is needed.' },
    ],

    description:
      'Does the contractor need a professional license, certification, or regulatory approval to legally perform these services in their country? (e.g., law, finance, healthcare, investment advice)',
  },
  {
    type: STEP_TYPE.QUESTION,
    title: 'Legal Entity',
    id: 'legal_entity',
    icon: Buildings,
    answers: [
      { value: 'yes', label: 'Yes – We do have a legal entity there.' },
      { value: 'no', label: 'No – We do not have a legal entity there.' },
    ],

    description:
      'Do you have a registered legal entity in the contractor’s country of work?',
  },
  { type: STEP_TYPE.RESULT },
]
