import { t } from 'i18next'
import cx from 'classnames'
import React, { Suspense, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { Label, Popover } from 'reactstrap'

import { getInputErrorMessage } from '../../../../components/Forms/get-input-error-message'
import Button from '../../../../components/ui/button'
import InputFeedback from '../../../../components/ui/input-feedback'
import { useOnClickOutside } from '../../../../helpers/hooks/use-outside-click'
import { AllTitles } from '../../components/Forms/InfoForm'
import styles from './scope-of-work.module.scss'
import Loader from '../../../../components/ui/loader'

const ReactQuill = React.lazy(() => import('react-quill'))

export default function ScopeOfWorkField({ name, control, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { ref, ...restField } = field
        return <ScopeOfWorkEditor {...restField} forwardedRef={ref} {...rest} />
      }}
    />
  )
}

function ScopeOfWorkEditor({
  onChange,
  onBlur,
  placeholder,
  forwardedRef,
  label,
  error,
  value,
}) {
  const [scope, setScope] = useState(value)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const wrapperRef = useRef()
  const buttonRef = useRef()

  const toggle = () => setTooltipOpen((open) => !open)

  function handleScopeChange(content) {
    setScope(content)
    onChange(content)
  }

  useOnClickOutside(wrapperRef, (event) => {
    if (buttonRef.current?.contains(event.target)) return

    setTooltipOpen(false)
  })

  return (
    <>
      {!label ? null : <Label>{label}</Label>}

      <Suspense fallback={<Loader />}>
        <ReactQuill
          formats={[
            'font',
            'size',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'indent',
          ]}
          ref={forwardedRef}
          modules={{ toolbar: null }}
          value={scope}
          onChange={handleScopeChange}
          onBlur={onBlur}
          placeholder={placeholder}
          className={cx(
            'p-0 ql-contract-info border rounded',
            styles.scopeField,
            { 'border-danger': error, 'border-gray-b': !error },
          )}
          // @todo: figure out if `preserveWhitespace` and `whiteSpace: 'pre-line'` are doing the same thing
          // style={{ whiteSpace: 'pre-line' }}
          preserveWhitespace
        />
      </Suspense>

      {!error ? null : (
        <InputFeedback className='tw-mt-1'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}

      <div className='tw-mt-2'>
        <Button
          type='button'
          className='-tw-ms-2 !tw-px-2 !tw-py-1 hover:tw-bg-primary-10'
          id='toggle-predefined-descriptions'
          color='link'
          onClick={toggle}
          ref={buttonRef}
        >
          {t('Predefined descriptions')}
        </Button>

        <Popover
          popperClassName={cx('rounded', styles.scopePopover)}
          placement='top'
          target='toggle-predefined-descriptions'
          isOpen={tooltipOpen}
          toggle={toggle}
        >
          <AllTitles
            click={(text) => {
              setScope(text)
              onChange(text)
              toggle()
            }}
            wrapperRef={wrapperRef}
          />
        </Popover>
      </div>
    </>
  )
}
