import { t } from 'i18next'
import React from 'react'
import { Info } from '@phosphor-icons/react'
import { RadioButtonsV2 } from '../../../../../components/ui/radio-button/radio-buttons-v2'
import { ModalCloseButton } from '../../../../../components/Common/modal-close-button'
import { ModalFooter, ModalHeader } from 'reactstrap'
import Button from '../../../../../components/ui/button'
import { useSteps } from './step-context'
import { cn } from 'ui'
import first from 'lodash/first'
import last from 'lodash/last'

export function Question() {
  const {
    step: {
      title,
      description,
      caption,
      answers: stepAnswers = [],
      icon: Icon,
      id,
    },
    previousStep,
    toggle,
    answers,
    setAnswers,
    nextStep,
    questions,
  } = useSteps()

  return (
    <>
      <ModalHeader
        className='tw-items-center'
        toggle={toggle}
        close={<ModalCloseButton toggle={toggle} />}
      >
        <Icon size={24} />
      </ModalHeader>
      <div className='tw-flex tw-flex-1 tw-flex-col tw-p-5'>
        <span className='tw-text-xl tw-text-black'>{title}</span>
        <span className='tw-mb-4 tw-mt-1'>{description}</span>
        <RadioButtonsV2
          value={answers[id]}
          onChange={(e) =>
            setAnswers((prev) => ({ ...prev, [id]: e.target.value }))
          }
          options={stepAnswers}
        />

        {caption ? (
          <div className='tw-mt-4 tw-flex tw-flex-row tw-items-center tw-gap-2 tw-rounded tw-bg-primary-20 tw-p-4'>
            <Info className='tw-text-primary' size={20} />
            <span className='tw-text-80 tw-flex-1'>{caption}</span>
          </div>
        ) : null}
      </div>
      <ModalFooter className='tw-flex tw-flex-row tw-items-center !tw-justify-between'>
        <Button
          className={cn(
            '!tw-border-surface-30 !tw-bg-white',
            first(questions).id === id && 'tw-opacity-0',
          )}
          textClassName='!tw-text-black'
          onClick={previousStep}
          outline
        >
          {t('Back')}
        </Button>

        <div className='tw-flex tw-flex-row tw-gap-1'>
          {questions?.map((i) => (
            <div
              key={`indicator-${i.id}`}
              className={cn(
                'tw-h-[8px] tw-w-[8px] tw-rounded-full tw-bg-[#D9D9D9]',
                id === i.id && 'tw-bg-[#424652]',
              )}
            />
          ))}
        </div>
        <Button color='primary' disabled={!answers[id]} onClick={nextStep}>
          {last(questions).id === id ? t('Check') : t('Next')}
        </Button>
      </ModalFooter>
    </>
  )
}
