import { t } from 'i18next'
import { CaretDown, CaretUp, ClockCountdown } from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'

import CustomDateRange from '../../components/Forms/custom-date-range'
import SearchBar from '../../components/SearchBar'
import BadgeX from '../../components/Table/BadgeX'
import StyledTd from '../../components/Table/StyledTd'
import StyledTh from '../../components/Table/StyledTh'
import TableComp from '../../components/Table/TableComp'
import Loader from '../../components/ui/loader'
import Shimmer from '../../components/ui/shimmer'
import { useFetch, useResize } from '../../helpers/hooks'
import { getCompanyHistory } from '../../services/api'
import { useFilters } from '../AdminPanel/pages/cards/use-filters'
import TabCardHeader from '../Contract/components/tab/tab-card-header'
import TabEmpty from '../Contract/components/tab/tab-empty'
import { CheckItem } from '../../components/ui/check-item'
import capitalizeFirstLetter from '../../utils/capitalize-first-letter'

const categoryFilters = [
  { label: 'Company Info', value: 'Company' },
  { label: 'Payment Method', value: 'Payment Method' },
  { label: 'Refund Method', value: 'Refund Method' },
  { label: 'Users', value: 'Users' },
  { label: 'Roles', value: 'Roles' },
  { label: 'Approval Flow', value: 'Approval Flow' },
  { label: 'Custom Field', value: 'Custom Field' },
  { label: 'Integrations', value: 'Integrations' },
  { label: 'Time Off Policy', value: 'Time Off Policy' },
  { label: 'Bill', value: 'Bill' },
  { label: 'Vendor', value: 'Vendor' },
]

const descriptionFilters = [
  { label: 'Created', value: 'CREATED' },
  { label: 'Updated', value: 'UPDATED' },
  { label: 'Deleted', value: 'DELETED' },
  { label: 'Added', value: 'ADDED' },
]

const userTypeFilters = [
  { label: 'Client', value: 'Client' },
  { label: 'System', value: 'System' },
  { label: 'Admin', value: 'Admin' },
]

const extractTranslatedDate = (date, t) => {
  const month = date?.slice(0, 3)
  const rest = date?.slice(3, date.length)
  return `${t(month)} ${rest}`
}

const CompanyHistory = ({ companyId, companyInfoLoading }) => {
  const [filters, handleFiltersChange] = useFilters({
    fromDate: null,
    toDate: null,
    userType: null,
    searchTerm: '',
    limit: 20,
  })
  const [showFilters, setShowFilters] = useState(false)
  const [historyList, setHistoryList] = useState([])
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const isMobile = useResize()
  const autoFetchHistory = !!companyId
  const {
    isLoading: isLoadingHistory,
    completed: completedLoadingHistory,
    data: contractHistory,
  } = useFetch(
    {
      action: getCompanyHistory,
      body: { ...filters, id: companyId },
      autoFetch: autoFetchHistory,
      onComplete: (data) => {
        if (data?.items) {
          if (isLoadingMore) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            setHistoryList([...historyList, ...data?.items])
            setIsLoadingMore(false)
          } else {
            setHistoryList(data?.items)
          }
        }
      },
    },
    [companyId, filters],
  )
  const loadingHistory = autoFetchHistory
    ? !completedLoadingHistory
    : isLoadingHistory

  const filterByCategory = (checked, value) => {
    if (filters?.cursor) handleFiltersChange('cursor', null)
    if (checked) {
      handleFiltersChange('itemType', value)
    } else {
      handleFiltersChange('itemType', null)
    }
  }
  const filterByUserType = (checked, value) => {
    if (filters?.cursor) handleFiltersChange('cursor', null)
    if (checked) {
      handleFiltersChange('userType', value)
    } else {
      handleFiltersChange('userType', null)
    }
  }
  const filterByDescription = (checked, value) => {
    if (filters?.cursor) handleFiltersChange('cursor', null)
    if (checked) {
      handleFiltersChange('action', value) // action
    } else {
      handleFiltersChange('action', null)
    }
  }
  return (loadingHistory && historyList.length < 10) || companyInfoLoading ? (
    <Loader minHeight='30rem' />
  ) : (
    <>
      <Card>
        <TabCardHeader title={t('History')} />
        <Container fluid className='tw-p-4 md:tw-px-6'>
          <div className='tw-flex tw-flex-col tw-gap-2 md:tw-flex-row'>
            <>
              {loadingHistory ? (
                <Shimmer width={160} height='42px' className='tw-mr-1' />
              ) : (
                <FilterByDropdown
                  style={{ minWidth: 160 }}
                  className='tw-rounded'
                  isOpen={showFilters}
                  toggle={() => setShowFilters((t) => !t)}
                  filterByCategory={filterByCategory}
                  filterByUserType={filterByUserType}
                  filterByDescription={filterByDescription}
                  clearFilters={() => {
                    setShowFilters(false)
                    if (filters?.cursor) handleFiltersChange('cursor', null)
                    if (filters?.itemType) handleFiltersChange('itemType', null)
                    if (filters?.userType) handleFiltersChange('userType', null)
                    if (filters?.action) handleFiltersChange('action', null)
                    // onUpdate([], [], 'clearF')
                  }}
                  categoryFilters={categoryFilters}
                  userTypeFilters={userTypeFilters}
                  descriptionFilters={descriptionFilters}
                  filters={filters}
                />
              )}
            </>
            <CustomDateRange
              wrapperStyles={{
                maxWidth: isMobile ? '100%' : 240,
                minWidth: 220,
              }}
              wrapperClassName='tw-w-full'
              name='dateRange'
              value={[filters.fromDate, filters.toDate]}
              placeholder={t('Select date range')}
              clearable
              handleClear={() => {
                if (filters?.cursor) handleFiltersChange('cursor', null)
                handleFiltersChange('fromDate', null, { action: 'clear' })
                handleFiltersChange('toDate', null, { action: 'clear' })
              }}
              onChange={(newDates) => {
                const start = newDates?.[0]
                const end = newDates?.[1]
                if (filters?.cursor) handleFiltersChange('cursor', null)
                handleFiltersChange('fromDate', start, {
                  action: 'clear',
                })
                handleFiltersChange('toDate', end, {
                  action: 'clear',
                })
              }}
            />

            <SearchBar
              className='tw-flex-grow tw-rounded'
              placeholder={t('Search by Name, Email, Category or IP Address')}
              query={filters?.searchTerm}
              onQueryChanged={(e) => {
                if (filters?.cursor) handleFiltersChange('cursor', null)
                handleFiltersChange('searchTerm', e)
              }}
            />
          </div>
        </Container>

        {historyList?.length === 0 ? (
          <TabEmpty
            title={t('No history')}
            subtitle={t('Nothing to show here yet')}
            icon={
              <ClockCountdown
                size={250}
                weight='duotone'
                color='var(--primary)'
              />
            }
          />
        ) : (
          <CardBody className='tw-px-0'>
            <div className='table-responsive'>
              {isMobile ? (
                <div className='tw-p-4' style={{ minHeight: '70vh' }}>
                  {React.Children.toArray(
                    historyList?.map((order, key) => (
                      <Line key={key} item={order} />
                    )),
                  )}
                </div>
              ) : (
                <TableComp className='table-centered'>
                  <thead className='thead-light'>
                    <tr>
                      <StyledTh>{t('Date')}</StyledTh>
                      <StyledTh>{t('Category')}</StyledTh>
                      <StyledTh className='tw-text-center'>
                        {t('Actor')}
                      </StyledTh>
                      <StyledTh>{t('Description')}</StyledTh>
                      <StyledTh>{t('User Type')}</StyledTh>
                      <StyledTh>{t('IP Address')}</StyledTh>
                      <StyledTh>{t('Action')}</StyledTh>
                    </tr>
                  </thead>
                  <tbody>
                    {React.Children.toArray(
                      historyList?.map((order, key) => (
                        <Line key={key} item={order} />
                      )),
                    )}
                  </tbody>
                </TableComp>
              )}
            </div>

            {loadingHistory ? (
              <Loader minHeight='3rem' />
            ) : contractHistory.pagination?.hasMore ? (
              <div className='mt-md-0 tw-mt-2 tw-flex tw-justify-center tw-p-8'>
                <button
                  className='tw-mt-1 tw-border-none tw-p-0 tw-text-left tw-text-xs tw-text-primary'
                  onClick={() => {
                    setIsLoadingMore(true)
                    handleFiltersChange(
                      'cursor',
                      contractHistory.pagination?.nextCursor,
                    )
                  }}
                >
                  {t('Load more')}
                </button>
              </div>
            ) : null}
          </CardBody>
        )}
      </Card>
    </>
  )
}

export default CompanyHistory

const customToTitleCase = (str) => {
  return typeof str === 'object'
    ? t('N/A')
    : typeof str === 'string' && str
      ? str?.replace(/_/g, ' ')?.replace?.(/\b\w/g, (s) => s?.toUpperCase())
      : str
}

const getStatusColor = (status) => {
  switch (status) {
    case 'UPDATED':
      return 'warning'
    case 'DELETED':
      return 'danger'
    default:
      return 'primary'
  }
}

const removeHtmlTags = (str) => {
  return typeof str === 'object'
    ? t('N/A')
    : typeof str === 'string' && str
      ? t(customToTitleCase(str?.replace(/<[^>]*>/g, '')))
      : t(str)
}

const Line = ({ item }) => {
  const [show, setShow] = useState(false)

  const isMobile = useResize()

  const info = [
    { label: 'User name', value: item?.userName ?? '-' },
    { label: 'User Type', value: item?.userType },
    { label: 'IP Address', value: item?.ipAddress ?? '-' },
  ]

  const getChangesSummaryText = (item) => {
    if (Array.isArray(item?.details?.changes)) {
      const TextSummaryElement = ({ text }) => {
        return (
          <span
            className='tw-mb-0 tw-ms-1 tw-mt-1 tw-text-xs tw-text-text-80'
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: '180px',
              whiteSpace: 'nowrap',
            }}
          >
            {text}
          </span>
        )
      }
      const changesLength = item?.details?.changes.length

      const firstValue = item?.details?.changes?.[0]
      const nameValue = item?.details?.changes?.find(
        (change) => change.name === 'name',
      )
      const descriptionValue = item?.details?.changes?.find(
        (change) => change.name === 'description',
      )
      const statusValue = item?.details?.changes?.find(
        (change) => change.name === 'status',
      )

      let appendText =
        changesLength > 1
          ? `, +${t('moreItems', { count: changesLength - 1 })}`
          : ''

      const AppendTextSummaryElement = ({ appendText }) => {
        return appendText ? (
          <button
            className='tw-mt-1 tw-border-none tw-p-0 tw-text-start tw-text-xs tw-text-primary'
            onClick={() => {
              setShow(true)
            }}
          >
            {appendText}
          </button>
        ) : null
      }

      let textSummary = removeHtmlTags(
        `${customToTitleCase(firstValue?.name)}: ${
          firstValue?.newValue ?? firstValue?.oldValue
        }`,
      )

      switch (item.action) {
        case 'CREATED':
          if (['payment', 'allowance'].includes(item.itemType.toLowerCase())) {
            return (
              <>
                <TextSummaryElement text={textSummary} />
                <AppendTextSummaryElement appendText={appendText} />
              </>
            )
          }
          if (item.itemType.toLowerCase() === 'work') {
            textSummary = `${customToTitleCase(statusValue?.name)}: ${
              statusValue?.newValue
            }`

            return (
              <>
                <TextSummaryElement text={textSummary} />
                <AppendTextSummaryElement appendText={appendText} />
              </>
            )
          }
          return (
            <>
              <TextSummaryElement text={firstValue?.newValue} />
              <AppendTextSummaryElement appendText={appendText} />
            </>
          )

        case 'UPDATED':
          textSummary = t('historyValueUpdated', { count: changesLength })

          return <TextSummaryElement text={textSummary} />
        case 'ADDED':
          if (item.itemType.toLowerCase() === 'equipment') {
            return (
              <>
                <TextSummaryElement text={descriptionValue?.newValue} />
                <AppendTextSummaryElement appendText={appendText} />
              </>
            )
          } else if (item.itemType.toLowerCase() === 'time off') {
            const fromDateValue = item?.details?.changes?.find(
              (change) => change.name === 'from',
            )
            const toDateValue = item?.details?.changes?.find(
              (change) => change.name === 'to',
            )
            const dateFormat = 'MMM dd, yyyy'
            textSummary = `${extractTranslatedDate(
              format(new Date(fromDateValue?.newValue), dateFormat),
              t,
            )} to ${extractTranslatedDate(format(new Date(toDateValue?.newValue), dateFormat), t)}`
            appendText = `, +${t('moreItems', { count: changesLength - 2 })}`
            return (
              <>
                <TextSummaryElement text={textSummary} />
                <AppendTextSummaryElement appendText={appendText} />
              </>
            )
          } else {
            appendText = `, +${t('historyValueAdded', { count: changesLength - 1 })} `

            return (
              <>
                <TextSummaryElement text={nameValue?.newValue} />
                <AppendTextSummaryElement appendText={appendText} />
              </>
            )
          }
        case 'DELETED':
          textSummary = `${customToTitleCase(firstValue?.name)}: ${
            firstValue?.oldValue
          }`

          return (
            <>
              <TextSummaryElement text={textSummary} />
              <AppendTextSummaryElement appendText={appendText} />
            </>
          )

        default:
          return (
            <>
              <TextSummaryElement text={textSummary} />
              <AppendTextSummaryElement appendText={appendText} />
            </>
          )
      }
    } else {
      return item?.userType
    }
  }

  return (
    <>
      <DetailsModal
        isOpen={show}
        title={`${item?.details?.message} by ${
          ['System', 'Admin'].includes(item?.userType)
            ? item?.userType
            : item?.userName
        }`}
        toggle={() => setShow(false)}
        changes={item?.details?.changes}
        time={item?.timestamp}
      />

      {isMobile ? (
        <div className='tw-relative tw-mb-4 tw-rounded tw-border tw-border-surface-30 tw-bg-white tw-py-0 tw-shadow-sm'>
          <Row className='tw-m-0 tw-border-b tw-border-b-surface-30 tw-p-4'>
            <Col xs={8} className='tw-m-0 tw-p-0'>
              <p className='tw-mb-0 tw-text-sm tw-font-bold'>
                {extractTranslatedDate(
                  format(new Date(item?.timestamp), 'MMM dd, yyyy'),
                  t,
                )}
              </p>
              <p className='tw-mb-0 tw-text-xs tw-text-text-80'>
                {format(new Date(item?.timestamp), 'H:mm:ss')}
              </p>
            </Col>
            <Col
              xs={4}
              className='tw-m-0 tw-flex tw-items-center tw-justify-end tw-p-0'
            >
              <BadgeX
                status={getStatusColor(item?.action)}
                textStatus={getStatusColor(item?.action)}
                name={customToTitleCase(item?.action)}
              />
            </Col>
          </Row>

          <div className='tw-flex tw-flex-col tw-gap-3 tw-p-4'>
            {info.map(({ label, value }, key) => {
              return (
                <div
                  key={key}
                  className='tw-flex tw-items-center tw-justify-between'
                >
                  <h6 className='tw-mb-0 tw-text-sm tw-font-normal tw-text-text-80'>
                    {t(label)}
                  </h6>
                  <h6
                    className={`text-right tw-mb-0 tw-w-3/4 tw-text-sm tw-font-normal tw-text-text-80 ${
                      value === '-' ? 'tw-mr-4' : 'tw-mr-0'
                    }`}
                  >
                    {value}
                  </h6>
                </div>
              )
            })}
          </div>
          <div className='tw-flex tw-items-center tw-justify-center tw-p-2'>
            <button
              className='tw-mt-1 tw-border-none tw-p-0 tw-text-left tw-text-sm tw-font-medium tw-text-primary'
              onClick={() => {
                setShow(true)
              }}
            >
              {t('Details')}
            </button>
          </div>
        </div>
      ) : (
        <>
          <tr>
            <StyledTd>
              <p className='tw-mb-1 tw-text-sm tw-font-medium tw-text-text-80'>
                {extractTranslatedDate(
                  format(new Date(item?.timestamp), 'MMM dd, yyyy'),
                  t,
                )}
              </p>
              <p className='tw-mb-0 tw-text-xs tw-text-text-60'>
                {format(new Date(item?.timestamp), 'H:mm:ss')}
              </p>
            </StyledTd>
            <StyledTd className='tw-text-sm tw-font-medium tw-text-text-80'>
              {t(item?.itemType)}
            </StyledTd>
            <StyledTd>
              <p className='tw-mb-1 tw-text-center tw-text-sm tw-font-medium tw-text-text-80'>
                {item?.userName ?? '-'}
              </p>
              <p className='tw-mb-0 tw-text-xs tw-text-text-80'>
                {item?.userEmail}
              </p>
            </StyledTd>
            <StyledTd>
              <BadgeX
                status={getStatusColor(item?.action)}
                textStatus={getStatusColor(item?.action)}
                name={t(capitalizeFirstLetter(item?.action.toLowerCase()))}
              />

              <p className='tw-mb-0 tw-ml-1 tw-mt-1 tw-flex tw-text-xs tw-text-text-80'>
                {getChangesSummaryText(item)}
              </p>
            </StyledTd>
            <StyledTd className='tw-text-sm tw-font-medium tw-text-text-80'>
              {t(item?.userType)}
            </StyledTd>
            <StyledTd className='tw-text-sm tw-font-medium tw-text-text-80'>
              {item?.ipAddress ?? '-'}
            </StyledTd>
            <StyledTd className='tw-text-sm tw-font-medium tw-text-text-80'>
              <button
                className='tw-mt-1 tw-border-none tw-p-0 tw-text-left tw-text-sm tw-font-medium tw-text-primary'
                onClick={() => {
                  setShow(true)
                }}
              >
                {t('Details')}
              </button>
            </StyledTd>
          </tr>
        </>
      )}
    </>
  )
}

function FilterByDropdown({
  isOpen,
  toggle,
  filterByCategory,
  filterByDescription,
  filterByUserType,
  clearFilters,
  categoryFilters,
  descriptionFilters,
  userTypeFilters,
  filters,
  ...props
}) {
  return (
    <Dropdown isOpen={isOpen} toggle={toggle} {...props}>
      <DropdownToggle tag='div'>
        <div
          className='tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-rounded tw-border tw-border-surface-40 tw-bg-white tw-px-2 rtl:!tw-start-0'
          style={{ height: 'calc(1.5em + 1.3rem + 2px)' }}
        >
          <span className='tw-text-sm tw-text-text-80'>{t('Filters')}</span>

          {isOpen ? <CaretUp size={12} /> : <CaretDown size={12} />}
        </div>
      </DropdownToggle>
      <DropdownMenu
        className='tw-w-full tw-rounded tw-border tw-border-surface-40 tw-bg-white tw-py-0 md:tw-w-[548px] rtl:!tw-start-0'
        flip={true}
        end={true}
      >
        <div
          className='tw-flex tw-w-full tw-items-center tw-justify-between tw-border-b tw-border-b-surface-30 tw-px-4'
          style={{ height: 'calc(1.5em + 1.3rem + 2px)' }}
        >
          <span className='tw-text-sm tw-text-text-80'>{t('Filter by')}</span>
          <button
            className='tw-border-none tw-text-xs tw-text-text-60'
            onClick={() => clearFilters()}
          >
            {t('Reset All')}
          </button>
        </div>

        <Container fluid>
          <Row className='!tw-text-start'>
            <Col
              className='tw-h-full tw-border-r tw-border-r-surface-30 tw-py-4'
              md={4}
            >
              <div className='tw-mb-2 tw-text-xs tw-text-text-80'>
                {t('Category')}
              </div>
              {categoryFilters?.map((e, i) => {
                return (
                  <Label
                    key={`drop-${i}`}
                    className='tw-flex tw-cursor-pointer tw-justify-between tw-text-sm tw-text-text-80'
                  >
                    <CheckItem
                      key={`drop-${i}`}
                      label={t(e.label)}
                      labelClassName='!tw-text-sm !tw-text-secondary-100'
                      inputClassName='!tw-w-[13px] !tw-h-[13px] !tw-border-secondary-100'
                      className='tw-flex tw-items-center'
                      checked={filters?.itemType === e.value}
                      onChange={(t) => {
                        toggle()
                        filterByCategory(t.target.checked, e.value)
                      }}
                    />
                  </Label>
                )
              })}
            </Col>
            <Col md={4} className='tw-border-r tw-border-r-surface-30 tw-py-4'>
              <div className='tw-mb-2 tw-text-xs tw-text-text-80'>
                {t('User type')}
              </div>
              {userTypeFilters?.map((e, i) => {
                return (
                  <Label
                    key={`drop-${i}`}
                    className='tw-block tw-cursor-pointer tw-text-sm tw-text-text-80'
                  >
                    <CheckItem
                      key={`drop-${i}`}
                      label={t(e.label)}
                      labelClassName='!tw-text-sm !tw-text-secondary-100'
                      inputClassName='!tw-w-[13px] !tw-h-[13px] !tw-border-secondary-100'
                      className='tw-flex tw-items-center'
                      checked={filters?.itemType === e.value}
                      onChange={(t) => {
                        toggle()
                        filterByUserType(t.target.checked, e.value)
                      }}
                    />
                  </Label>
                )
              })}
            </Col>
            <Col md={4} className='tw-py-4'>
              <div className='tw-mb-2 tw-text-xs tw-text-text-80'>
                {t('Description status')}
              </div>
              {descriptionFilters?.map((e, i) => {
                return (
                  <Label
                    key={`drop-${i}`}
                    className='tw-block tw-cursor-pointer tw-text-sm tw-text-text-80'
                  >
                    <CheckItem
                      key={`drop-${i}`}
                      label={t(e.label)}
                      labelClassName='!tw-text-sm !tw-text-secondary-100 tw-uppercase'
                      inputClassName='!tw-w-[13px] !tw-h-[13px] !tw-border-secondary-100'
                      className='tw-flex tw-items-center'
                      checked={filters?.itemType === e.value}
                      onChange={(t) => {
                        toggle()
                        filterByDescription(t.target.checked, e.value)
                      }}
                    />
                  </Label>
                )
              })}
            </Col>
          </Row>
        </Container>
      </DropdownMenu>
    </Dropdown>
  )
}

function DetailsModal({
  isOpen,
  toggle,
  onClosed = () => {},
  title,
  centered = true,
  changes,
  time,
}) {
  const _changes = []
  const keeper = {}
  for (let i = 0; i < changes.length; i++) {
    const change = changes[i]
    if (keeper[change.name] === undefined) {
      keeper[change.name] = i
      _changes.push({
        name: change.name,
        oldValue: change.oldValue ? [change.oldValue] : [],
        newValue: change.newValue ? [change.newValue] : [],
      })
    } else {
      if (change?.oldValue) {
        _changes[keeper[change.name]]?.oldValue?.push(change.oldValue)
      }
      if (change?.newValue) {
        _changes[keeper[change.name]]?.newValue?.push(change?.newValue)
      }
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      centered={centered}
      toggle={toggle}
      onClosed={onClosed}
    >
      <ModalHeader toggle={toggle}>
        <div>
          <p className='tw-mb-0 tw-text-xl tw-font-semibold'>{title}</p>
          <p className='text-secondary-80 tw-mb-1 tw-text-sm'>
            {extractTranslatedDate(
              format(new Date(time), 'MMM dd, yyyy H:mm:ss'),
              t,
            )}
          </p>
        </div>
      </ModalHeader>
      <ModalBody>
        {_changes.map((item, index) => {
          return (
            <div
              key={index}
              className={`tw-flex tw-border-b tw-border-b-surface-30 tw-py-2 ${
                item?.oldValue?.length
                  ? 'tw-flex-col'
                  : 'tw-flex-row tw-items-center'
              }`}
            >
              <p
                className={`${
                  item?.oldValue?.length
                    ? 'tw-font-semibold tw-text-black'
                    : 'text-text-100'
                } tw-mb-0 tw-flex-1 tw-text-sm`}
              >
                {customToTitleCase(item?.name)}
              </p>
              {item?.oldValue?.length ? (
                <div className='tw-flex tw-gap-x-4'>
                  <div className='tw-flex-1'>
                    <p className='text-text-100 tw-mb-2 tw-text-sm'>
                      {t('Old value')}
                      {item.oldValue.length > 1 ? 's' : ''}
                    </p>
                    {item.oldValue.map((_item, i) => {
                      return (
                        <p
                          key={item.name + '_old_value_' + i}
                          className='tw-mb-0 tw-text-xs tw-font-semibold tw-text-black'
                        >
                          {removeHtmlTags(_item)}
                        </p>
                      )
                    })}
                  </div>
                  <div className='tw-flex-1'>
                    <p className='text-text-100 tw-mb-2 tw-text-sm'>
                      {t('New value')}
                      {item.newValue.length > 1 ? 's' : ''}
                    </p>
                    {item.newValue.map((_item, i) => {
                      return (
                        <p
                          key={item.name + '_new_value_' + i}
                          className='tw-mb-0 tw-text-xs tw-font-semibold tw-text-black'
                        >
                          {removeHtmlTags(_item)}
                        </p>
                      )
                    })}
                  </div>
                </div>
              ) : (
                <div className='tw-flex-1'>
                  {item.newValue.map((_item, i) => {
                    return (
                      <p
                        key={'value' + i}
                        className='tw-mb-0 tw-flex-1 tw-text-sm tw-font-semibold tw-text-black'
                      >
                        {removeHtmlTags(_item)}
                      </p>
                    )
                  })}
                </div>
              )}
            </div>
          )
        })}
      </ModalBody>
    </Modal>
  )
}
