import { t } from 'i18next'
import { Bank, IdentificationBadge, Paperclip } from '@phosphor-icons/react'
import cx from 'classnames'
import React from 'react'
import { Controller } from 'react-hook-form'

import CardButtons from '../../../../components/ui/card-button/card-buttons'
import InputFeedback from '../../../../components/ui/input-feedback'
import {
  CONTRACT_CATEGORY,
  CONTRACT_DOC_TYPE,
  contractTypes,
  DE_PAYMENT_PROVIDER,
  EMPLOYEE_CONTRACT_TYPE,
} from '../../../../helpers/enum'
import {
  Fixed,
  Milestone,
  PayAsYouGo,
  RpIcon,
  RpTemplate,
  YourTemplate,
} from '../../../../helpers/SvgIcons'
import styles from './type-select.module.css'
import { getInputErrorMessage } from '../../../../components/Forms/get-input-error-message'

export function contractTypeCards({
  isFixedDisabled,
  isPayAsYouGoDisabled,
  isMilestoneDisabled,
  t,
}) {
  return [
    {
      label: t('Fixed'),
      description: t('Pay a fixed rate monthly, bi-weekly or weekly'),
      icon: (active) => <Fixed isActive={active} />,
      value: contractTypes.FIXED,
      id: 'contract-fixed',
      isDisabled: isFixedDisabled,
    },
    {
      label: t('Pay as you go'),
      description: t('Pay per day or per hour based on timesheets'),
      icon: (active) => <PayAsYouGo isActive={active} />,
      value: contractTypes.PAYG,
      id: 'contract-pay-as-you-go',
      isDisabled: isPayAsYouGoDisabled,
    },
    {
      label: t('Milestones'),
      description: t('Pay as milestones are completed'),
      icon: (active) => <Milestone isActive={active} />,
      value: contractTypes.MILESTONES,
      id: 'contract-milestones',
      isDisabled: isMilestoneDisabled,
    },
  ]
}

export const contractCategories = (t) => {
  return [
    {
      label: t('A Contractor'),
      // prettier-ignore
      description:
        t(
        'Onboard, manage and pay an individual or a company on a fixed, pay as you go or milestone contract.'
      ),
      icon: (active) => <PayAsYouGo isActive={active} />,
      value: CONTRACT_CATEGORY.CONTRACTOR,
    },
    {
      label: t('An Employee'),
      // prettier-ignore
      description: t(
        'Onboard, manage, offer benefits and pay a full-time or a part-time employee in 150+ countries.'
      ),
      icon: (active) => <Fixed isActive={active} />,
      value: CONTRACT_CATEGORY.EMPLOYEE,
    },
  ]
}

export const defaultContractTemplatesTypes = (t) => {
  return [
    {
      label: t('Use RemotePass’s Template'),
      description: t('Use RemotePass’s legally compliant contract template'),
      icon: (active) => <RpTemplate isActive={active} />,
      value: CONTRACT_DOC_TYPE.RP_TEMPLATE,
    },
    {
      label: t('Upload My Contract'),
      description: t('Click here to upload your signed contract *PDF only'),
      icon: (active) => <YourTemplate isActive={active} />,
      value: CONTRACT_DOC_TYPE.CUSTOM,
    },
  ]
}

export const customContractTemplatesType = (t) => {
  return {
    label: t('Use your templates'),
    description: t('Click here to select one of your existing templates'),
    icon: (active) => <YourTemplate isActive={active} />,
    value: CONTRACT_DOC_TYPE.CUSTOM_TEMPLATE,
  }
}

export function employeeContractOptions({
  isEorDisabled,
  isDeDisabled,
  t,
} = {}) {
  return [
    {
      label: t('EOR'),
      description: t(
        'RemotePass to hire an employee on your behalf in their country of residence and pay local taxes',
      ),
      icon: (active) => <RpIcon isActive={active} />,
      value: EMPLOYEE_CONTRACT_TYPE.EOR,
      id: 'eor',
      isDisabled: isEorDisabled,
    },
    {
      label: t('Direct Employee'),
      description: t(
        'Onboard an employee under your entity and manage them through RemotePass',
      ),
      icon: (active) => (
        <IdentificationBadge
          weight='duotone'
          width='100%'
          height='100%'
          color={active ? 'var(--primary)' : '#BDBDBD'}
        />
      ),

      value: EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE,
      id: 'direct-employee-option-id',
      isDisabled: isDeDisabled,
      isNew: true,
    },
  ]
}

export const deExternalPaymentOptions = (t) => {
  return [
    {
      label: t('Use RemotePass as payroll provider'),
      description: t('Manage & Pay employee on RemotePass'),
      value: DE_PAYMENT_PROVIDER.RP_PROVIDER,
      icon: (active) => <RpIcon isActive={active} />,
    },
    {
      label: t('Use external payroll provider'),
      description: // prettier-ignore
       t(
        'Manage employee on RemotePass, generate payroll report and process using your bank'
      ),
      value: DE_PAYMENT_PROVIDER.EXTERNAL_PROVIDER,
      icon: (active) => (
        <Bank
          weight='duotone'
          width='100%'
          height='100%'
          color={active ? 'var(--primary)' : '#BDBDBD'}
        />
      ),
    },
  ]
}

export const deContractOptions = (t) => {
  return [
    {
      label: t('Upload My Contract'),
      description: t('Click here to upload your signed contract (*PDF only)'),
      icon: (active) => (
        <Paperclip
          weight='duotone'
          width='100%'
          height='100%'
          color={active ? 'var(--primary)' : '#BDBDBD'}
        />
      ),

      value: CONTRACT_DOC_TYPE.CUSTOM,
    },
  ]
}

export default function ControlledTypeSelect({
  control,
  name,
  error,
  required,
  types = [],
  cols = 3,
  horizontal,
  transform,
  buttonClassName,
  spaced = true,
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => {
        const { value, onChange } = field

        return (
          <>
            <CardButtons
              value={transform?.input ? transform.input(value) : value}
              onChange={(e) =>
                typeof transform?.output === 'function'
                  ? onChange(transform.output(e))
                  : onChange(e?.value)
              }
              options={types}
              className={cx('d-flex', styles.cardButtons)}
              style={{
                '--cols': isNaN(Number(cols)) ? 2 : +cols,
                '--gap': spaced ? 'var(--size-16)' : '3px',
              }}
              horizontal={horizontal}
              buttonClassName={buttonClassName}
              spaced={spaced}
            />

            {!error ? null : (
              <InputFeedback className='tw-mt-1'>
                {getInputErrorMessage(error)}
              </InputFeedback>
            )}
          </>
        )
      }}
    />
  )
}
