import { MinusCircle } from '@phosphor-icons/react'
import React from 'react'
import { cn } from 'ui'

export function InactiveUser({ className, avatarClassName }) {
  return (
    <div
      className={cn(
        'tw-flex tw-flex-nowrap tw-items-center tw-gap-3 tw-p-3',
        className,
      )}
    >
      <MinusCircle
        className={cn(
          'tw-size-8 tw-rounded-full tw-bg-text-10 tw-p-2 tw-text-text-60',
          avatarClassName,
        )}
      />

      <div translate='no' className='tw-min-w-0 tw-leading-none'>
        <p className='tw-mb-0 tw-truncate tw-text-xs/5 tw-font-semibold'>
          Role Not Assigned
        </p>
        <div className='tw-overflow-hidden tw-truncate tw-text-ellipsis !tw-whitespace-nowrap tw-text-wrap tw-text-xs tw-font-medium tw-text-text-80'>
          This position is vacant
        </div>
      </div>
    </div>
  )
}
