import React from 'react'
import { SideMenu } from '../../../components/ui/side-menu'
import { Button, currencyFormatter, ProfileCard } from 'ui'
import { getFullName } from '../../../utils/get-full-name'
import ContractRef from '../../AdminPanel/components/ContractRef'
import { DetailsInfoList } from '../../review-center/review-layout-details-components'
import {
  CurrencyCircleDollar,
  Envelope,
  Flag,
  GlobeHemisphereWest,
  Info,
  Money,
  Phone,
  TreeStructure,
  UserRectangle,
} from '@phosphor-icons/react'
import Alert from '../../../components/ui/alert'
import BadgeV2 from '../../../components/ui/badge-v2'
import { t } from 'i18next'

function PayrollDetailsSidePanel({ isOpen, data, toggle, currency }) {
  const { format } = currencyFormatter(currency)

  const getStatusBadge = (status) => {
    switch (status) {
      case 'Ongoing':
        return (
          <BadgeV2 noPadding={true} status='success'>
            {t('Ongoing')}
          </BadgeV2>
        )
      case 'Terminated':
        return (
          <BadgeV2 noPadding={true} status='danger'>
            {t('Terminated')}
          </BadgeV2>
        )
      default:
        return (
          <BadgeV2 noPadding={true} status='warning'>
            {t(status)}
          </BadgeV2>
        )
    }
  }

  return (
    <SideMenu isOpen={isOpen} data={data} onClose={toggle}>
      <SideMenu.Header toggle={toggle}>
        <ProfileCard
          photo={data.avatar}
          name={getFullName(data.contractor)}
          title={getFullName(data.contractor)}
          description={<ContractRef withHash contractId={data.contract?.ref} />}
          suffix={<span>{getStatusBadge(data.contract?.status)}</span>}
        />
      </SideMenu.Header>
      <SideMenu.Body className='!tw-p-0'>
        <DetailsInfoList
          title={
            <div className='tw-flex tw-items-center tw-gap-2'>
              <Info className='tw-text-primary' size={20} />
              <span className='tw-text-base tw-font-bold'>
                General Information
              </span>
            </div>
          }
          items={[
            {
              icon: <UserRectangle size={24} />,
              label: 'Full Name',
              value: getFullName(data.contractor),
            },
            {
              icon: <Envelope size={24} />,
              label: 'Email',
              value: data.contractor.email,
            },
            {
              icon: <Phone size={24} />,
              label: 'Phone',
              value: data.contractor.phone,
            },
            {
              icon: <Flag size={24} />,
              label: 'Nationality',
              value: data.contractor.nationality.name,
            },
            {
              icon: <GlobeHemisphereWest size={24} />,
              label: 'Country of Residence',
              value: data.contractor.country_of_residence.name,
            },
          ]}
        />
        <hr />
        <DetailsInfoList
          title={
            <div className='tw-flex tw-items-center tw-justify-between tw-gap-2'>
              <div className='tw-flex tw-gap-2'>
                <CurrencyCircleDollar className='tw-text-primary' size={20} />
                <span className='tw-text-base tw-font-bold'>
                  Payroll details
                </span>
              </div>
              <button
                onClick={() => {
                  // @todo: add edit functionality
                }}
                className='tw-text-base tw-font-bold tw-text-primary-100'
              >
                Edit
              </button>
            </div>
          }
          items={[
            {
              label: 'Base salary',
              value: format(data.details.base_salary),
            },
            {
              label: 'Allowance',
              value: format(data.details.allowances),
            },
            {
              label: 'Total expenses',
              value: format(data.details.expenses),
            },
            {
              label: 'Total additions',
              value: format(data.details.additions),
            },
            {
              label: 'Total deductions',
              value: format(data.details.deductions),
            },
          ]}
        />
        <Alert color='info' className='tw-m-4'>
          You can view and edit expense details under the Expenses tab, and
          additions and deductions under the Payments tab of the Employee
          contract.
        </Alert>
      </SideMenu.Body>
      <SideMenu.Footer className='tw-flex tw-justify-center tw-gap-4 tw-p-4'>
        <Button
          color='secondary'
          outline
          icon={<Money size={20} />}
          lightOutline
          onPress={() => {
            window.open(
              `/contract/detail?id=${data.contract.ref}&tab=payments`,
              '_blank',
            )
          }}
        >
          View payments
        </Button>
        <Button
          color='secondary'
          outline
          icon={<TreeStructure size={20} />}
          lightOutline
          onPress={() => {
            window.open('/org-chart', '_blank')
          }}
        >
          View orgChart
        </Button>
      </SideMenu.Footer>
    </SideMenu>
  )
}

export default PayrollDetailsSidePanel
