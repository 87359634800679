import { t } from 'i18next'
import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const TermsModal = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size='xl'>
      <ModalHeader toggle={toggle}>{t('Terms and Conditions')}</ModalHeader>
      <ModalBody className='px-0 pb-0'>
        <div className='p-2 px-3'>
          <div className='pb-4'>{t('RP_Referral_TERMS_AND_CONDITIONS')}</div>
          <h4>{t('How the Program works')}</h4>
          <div className='px-3'>
            <ol>
              <li>{t('You Refer us to your network')}</li>
              <li>
                {t(
                  'The company signs with RemotePass and processes the first month’s payroll',
                )}
              </li>
              <li>
                {t(
                  'You earn a $30 Gift Card per contractor if your referral adds less than 5 contractors',
                )}
              </li>
              <li>
                {t(
                  'You earn an additional $250 Gift Card if your referral adds more than 5 contractors or 1 Full Time Employee',
                )}
              </li>
              <li>
                {t(
                  'After using your referral link to Sign up, your referral gets their first-month subscription for free!',
                )}
              </li>
            </ol>
          </div>
          <h4>
            {t(
              'Here are a few scenarios where you would not earn your gift card:',
            )}
          </h4>
          <div className='px-3'>
            <ol>
              <li>{t('The referral already has an account')}</li>
              <li>{t('The referral has not processed payment')}</li>
              <li>
                {t(
                  'The referral has previously accepted another invitation to onboard',
                )}
              </li>
            </ol>
          </div>
          <h5>{t('Eligibility.')}</h5>
          <div className='pb-2'>{t('RemotePass Eligibility Message')}</div>
          <h5>{t('Conduct.')}</h5>
          <div className='pb-2'>{t('RemotePass Conduct Message')}</div>
          <h4>{t('Liability')}</h4>
          <h5>{t('Binding Effects.')}</h5>
          <div className='pb-2'>{t('RemotePass Binding Effects Message')}</div>
          <h5>{t('Release.')}</h5>
          <div className='pb-2'>{t('RemotePass Release Message')}</div>
          <h5>{t('Indemnification.')}</h5>
          <div className='pb-2'>{t('RemotePass Indemnification Message')}</div>
          <h4>{t('Disclaimer')}</h4>
          <h5>{t('Reserved Right.')}</h5>
          <div className='pb-2'>
            {t('RemotePass Program Message Reserved Right')}
          </div>
          <h4>{t('Other Terms')}</h4>
          <h5>{t('General Terms.')}</h5>
          <div className='pb-2'>{t('RemotePass General Terms Message')}</div>
          <h5>{t('Term.')}</h5>
          <div className='pb-2'>{t('RemotePass Term Message')}</div>
        </div>
      </ModalBody>
    </Modal>
  )
}
export default TermsModal
