import { api, getConfig } from './api'

export const getPayrollDetails = (token, data, extra) => {
  return api.get(
    `/direct_employees/payroll_cycle/${data?.id}`,
    getConfig(token, extra),
  )
}
export const approvePayrollCycle = (token, data, extra) => {
  return api.put(
    `/direct_employees/payroll_cycle/${data?.id}/approve`,
    data,
    getConfig(token, extra),
  )
}
export const modifyPayrollCycle = (token, data, extra) => {
  return api.put(
    `/direct_employees/payroll_cycle/${data?.id}/modify`,
    data,
    getConfig(token, extra),
  )
}
export const markPayrollCycleAsPaid = (token, data, extra) => {
  return api.put(
    `/direct_employees/payroll_cycle/${data?.id}/mark_paid`,
    data,
    getConfig(token, extra),
  )
}

export const downloadPayrollCycleFiles = (token, data, extra) => {
  return api.get(
    `/direct_employees/payroll_cycle/${data?.id}/file/download?file_type=${data?.file_type}`,
    getConfig(token, extra),
  )
}
