export const documentStatuses = [
  {
    label: 'Requested',
    value: 'requested',
  },
  {
    label: 'Uploaded',
    value: 'completed',
  },
  {
    label: 'Generated',
    value: 'generated',
  },
]

export const workerStatuses = [
  {
    label: 'Ongoing',
    value: '4',
  },
  {
    label: 'Amended',
    value: '8',
  },
]

export const workerTypes = [
  {
    label: 'Contractor',
    value: 'contractor',
  },
  {
    label: 'Direct Employee',
    value: 'direct_employee',
  },
  {
    label: 'EOR Employee',
    value: 'eor',
  },
]
