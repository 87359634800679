import {
  CheckCircle,
  FilePlus,
  Files,
  PlusCircle,
  Spinner,
  Trash,
  TrashSimple,
  UploadSimple,
  Warning,
  X,
  XCircle,
} from '@phosphor-icons/react'
import cx from 'classnames'
import {
  addDays,
  differenceInDays,
  endOfMonth,
  format,
  getDaysInMonth,
  isPast,
  isSameMonth,
  startOfMonth,
} from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Controller,
  FormProvider,
  useController,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  TabPane,
} from 'reactstrap'
import toastr from 'toastr'
import { useDebounceValue } from 'usehooks-ts'
import * as yup from 'yup'

import { cn } from 'ui'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import DropzoneInput from '../../../components/Common/dropzone-input'
import { ModalCloseButton } from '../../../components/Common/modal-close-button'
import ControlledCurrencyInput from '../../../components/ControlledCurrencyInput'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import Toggle from '../../../components/Forms/Toggle/Toggle'
import DynamicForm, {
  buildYupSchema,
  formatValues,
  removeFormFields,
} from '../../../components/Forms/dynamic-form'
import { getInputErrorMessage } from '../../../components/Forms/get-input-error-message'
import ModalHeader from '../../../components/ModalHeader'
import Steps from '../../../components/Steps'
import StepContainer from '../../../components/Steps/StepContainer'
import ControlledCheckbox from '../../../components/controlled-checkbox'
import ControlledRadioList from '../../../components/controlled-radio-list'
import Head from '../../../components/head'
import RelatedFieldContent from '../../../components/related-field-content'
import Alert from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import Flag, { getFlagUrlFromIso2 } from '../../../components/ui/flag'
import { FlagIcon } from '../../../components/ui/flag-icon'
import InputFeedback from '../../../components/ui/input-feedback'
import Loader from '../../../components/ui/loader'
import Shimmer from '../../../components/ui/shimmer'
import TabContent from '../../../components/ui/tabs'
import FEATURE_FLAGS from '../../../config/feature-flags'
import {
  CONTRACT_CATEGORY,
  CONTRACT_DOC_TYPE,
  DE_PAYMENT_PROVIDER,
  EMPLOYEE_CONTRACT_TYPE,
  contractTypes,
  userTypes,
} from '../../../helpers/enum'
import { formatEOSPlanData } from '../../../helpers/eos-plans-helpers'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import {
  definiteValue,
  definiteValueDe,
  employmentTermOptions,
  employmentTermOptionsDe,
  employmentTypeOptions,
  employmentTypeOptionsDe,
  partTimeValue,
  qualificationOptions,
} from '../../../helpers/lists'
import permissions from '../../../helpers/permissions'
import {
  createContract,
  createDirectEmploymentContract,
  createFulltimeContract,
  deValidateEmployeeIdentifier,
  getAttributes,
  getCalculatorResult,
  getCurrencyExchangeRate,
  getCycleText,
  getEORRegionConfig,
  getLocalCurrencies,
  getOccurrences,
  getProrataData,
  getTemplateList,
  updateProrata,
  uploadContractFileV2,
} from '../../../services/api'
import { getEntities } from '../../../services/api-direct-employee'
import { track } from '../../../utils/analytics'
import { getCookie, setCookie } from '../../../utils/cookies'
import { DE_ONBOARDING_BANNER_COOKIE } from '../../../utils/cookies/cookie-names'
import { datePickerDateFormat } from '../../../utils/formatters/date-picker-date-format'
import { getErrorMessage } from '../../../utils/get-errors'
import {
  getOptionFromList,
  mapCountryToOption,
  mapCurrencyToOption,
} from '../../../utils/map-to-option'
import { CustomRadio } from '../../remotepass-cards/components/request-new-card'
import { CONTRACT_TYPE_MAP } from '../ContractPage/settings/custom-fields-section'
import {
  BULK_CONTRACT_CREATION_EVENTS,
  useBulkContractActionEvents,
} from '../bulk-creation/events'
import { getCurrencyFromTaxCountry } from '../components/Forms/PaymentForm'
import BenefitsForm from '../components/Forms/benefits-form'
import {
  customOption,
  customSingleValue,
} from '../components/Forms/select-components'
import QuoteView from '../components/Quote/QuoteView'
import { getCustomFieldSchema } from '../components/add-milestone-modal'
import { FILE_SIZE_LIMITS_IN_BYTES } from '../utils/constants'
import ContributionPlansAlert from './components/contribution-plans-alert'
import { FormCol } from './components/form-col'
import {
  FormSectionHr,
  FormSectionTitle,
} from './components/form-section-title'
import LabelContent from './components/label-content'
import NoticePeriodField from './components/notice-period-field'
import OnboardToDeModal from './components/onboard-to-de-modal'
import { ControlledPremiumRecruitment } from './components/premium'
import ProbationPeriodField from './components/probation-period-field'
import {
  PRORATA_CALCULATION_TYPE,
  PRORATA_TYPE,
  WORKING_DAYS,
} from './components/prorata/constants'
import { ProrataStateProvider } from './components/prorata/prorata-context'
import { ProrataSettings } from './components/prorata/prorata-settings'
import { useProrataEdit } from './components/prorata/use-prorata'
import { YearlySalaryField } from './components/yearly-salary-field'
import { CreationCustomFields } from './creation-custom-fields'
import ScopeOfWorkField from './fields/scope-of-work'
import ControlledTypeSelect, {
  contractCategories,
  contractTypeCards,
  customContractTemplatesType,
  deExternalPaymentOptions,
  defaultContractTemplatesTypes,
  employeeContractOptions,
} from './fields/type-select'
import { getDefaultDuplicateData } from './utils/default-contract-duplicate-data'
import { formatDataBody } from './utils/format-data-body'
import { mapFrequencyToOption } from './utils/map-frequency-to-option'
import { USD_ID } from './utils/usd-id'
import {
  FormDataProvider,
  dataKeys,
  useFormData,
} from './utils/use-form-data-context'
import useTriggerField from './utils/use-trigger-field'
import validateSchema from './utils/validate-schema'
import { t } from 'i18next'

const countriesWithStates = ['US', 'CA', 'AU', 'CH', 'DE']
const fileTempName = 'file-temp-name'

function calculateMinProRataAmount(monthlySalary, start, paymentDate) {
  const daysInMonth = getDaysInMonth(paymentDate)

  let startOfPeriod = startOfMonth(paymentDate)
  let endOfPeriod = endOfMonth(paymentDate)

  if (isSameMonth(start, paymentDate)) {
    startOfPeriod = start
    endOfPeriod = paymentDate
  }

  const workedDays = differenceInDays(endOfPeriod, startOfPeriod) + 1

  const proratedSalary = (monthlySalary * workedDays) / daysInMonth
  // Maintain two decimal points
  return Math.ceil(proratedSalary * 100) / 100
}

function constructWhen(attr, getCondition) {
  return (getSchema, getNotContractorSchema) => {
    return [
      attr,
      {
        is: (...data) => getCondition(...data),
        then: getSchema,
        otherwise: getNotContractorSchema,
      },
    ]
  }
}

function preventEnterKeySubmission(e) {
  const target = e.target
  if (e.key === 'Enter' && !['TEXTAREA'].includes(target.tagName)) {
    e.preventDefault()
  }
}

function isContractor(contractType) {
  return contractType === CONTRACT_CATEGORY.CONTRACTOR
}
const whenContractor = constructWhen('contract_type', isContractor)

function isNotMilestone(contractType, type) {
  return (
    contractType === CONTRACT_CATEGORY.CONTRACTOR &&
    type !== contractTypes.MILESTONES
  )
}
const whenNotMilestone = constructWhen(
  ['contract_type', 'type'],
  isNotMilestone,
)

function isMilestone(type) {
  return type === contractTypes.MILESTONES
}
const whenMilestone = constructWhen('type', isMilestone)

const whenPayG = constructWhen('type', (type) => {
  return type === contractTypes.PAYG
})
const whenEmployee = constructWhen('contract_type', (contractType) => {
  return contractType === CONTRACT_CATEGORY.EMPLOYEE
})
const whenEor = constructWhen('employee_type', (employeeType) => {
  return employeeType === EMPLOYEE_CONTRACT_TYPE.EOR
})
const isDe = (employeeType) => {
  return employeeType === EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE
}
const whenDe = constructWhen('employee_type', isDe)

const whenContractorOrDe = constructWhen(
  ['contract_type', 'employee_type'],
  (contractType, employeeType) => {
    const isDeCondition = isDe(employeeType)
    const isContractorCondition = isContractor(contractType)

    return isDeCondition || isContractorCondition
  },
)

export function isDefinite(term) {
  return [definiteValue, definiteValueDe].includes(term)
}
export function isPartTime(term) {
  return term === partTimeValue
}
export function isBonusDetails(term) {
  return term === true
}
const whenDeEndDate = constructWhen('employment_term', isDefinite)
const whenPartTime = constructWhen('employment_type', isPartTime)
const whenBonusDetails = constructWhen('variable_comp', isBonusDetails)

export const fieldKeys = {
  type: 'type',
  startDate: 'start_date',
  endDate: 'end_date',
  currencyId: 'currency_id',
  frequencyId: 'frequency_id',
  occurrenceId: 'occurrence_id',
  firstPaymentDate: 'first_payment_date',
  proRata: 'first_payment_prorata',
  proRataAmount: 'prorata_amount',
  prorataType: 'prorata_type',
  amount: 'amount',
  milestones: 'milestones',
}
const allowancesKey = 'allowances'

function getSteps(contractType, employeeType, steps) {
  if (
    contractType === CONTRACT_CATEGORY.EMPLOYEE &&
    employeeType === EMPLOYEE_CONTRACT_TYPE.EOR
  ) {
    return [steps.contractType, steps.info, steps.details, steps.benefits]
  } else {
    return [steps.contractType, steps.info, steps.payment, steps.compliance]
  }
}

function getIndex(steps, id) {
  return steps.findIndex((s) => s.id === id)
}

function getNext(steps, current) {
  const index = getIndex(steps, current)
  return steps[index + 1]?.id
}

function getPrevious(steps, current) {
  const index = getIndex(steps, current)
  return steps[index - 1]?.id
}
export default function CreateContractWrapper() {
  return (
    <FormDataProvider>
      <CreateContractV3 />
    </FormDataProvider>
  )
}
// @todo bug: if you're in a step (e.g. payment step) - leave an error in an input, navigate back to a step with all valid inputs, you can't navigate forward.
function CreateContractV3() {
  const { contractor_types: contractorTypes } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )
  const user = useSelector((state) => state.Account?.user)
  const { trackEvent } = useBulkContractActionEvents()

  const history = useHistory()
  const contractToDuplicate = useSelector((state) => state.Contract.toDuplicate)
  const isContractor = user?.type === userTypes.CONTRACTOR
  const { formData } = useFormData()
  const regionConfig = formData?.regionConfig
  const isDuplicating = history.location.state?.duplicating
  const defaultValues = isDuplicating
    ? getDefaultDuplicateData({ contract: contractToDuplicate })
    : {
        start_date: datePickerDateFormat(addDays(new Date(), 3)),
        contractor_label_id: 1,
        first_payment_prorata: false,
        milestones: [],

        custom_field: {},

        // EOR default values
        insurance: regionConfig?.is_healthcare_required,
        work_visa: 0,
        trial_period: 'No probation',
        contract_doc_type: CONTRACT_DOC_TYPE.RP_TEMPLATE,

        // DE default values
        is_bonus_clause_enabled: false,
        is_annual_plane_ticket_enabled: false,
        is_overtime_enabled: false,
        is_external_payroll_provider: DE_PAYMENT_PROVIDER.RP_PROVIDER,
        de_contract_doc_type: CONTRACT_DOC_TYPE.CUSTOM,

        contract_type: isContractor ? CONTRACT_CATEGORY.CONTRACTOR : undefined,
      }
  const formMethods = useForm({ defaultValues })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    clearErrors,
    setFocus,
    watch,
    trigger,
  } = formMethods

  const countries = useSelector((state) => state.Layout?.staticData?.countries)
  const disallowedJobTitles = useSelector(
    (state) => state.Layout?.staticData?.disallowed_job_titles,
  )
  const {
    contract_type: formContractType,
    employee_type: formEmployeeType,
    type: formContractorType,
  } = useWatch({ control })

  const contractTypeKey =
    formContractType === CONTRACT_CATEGORY.EMPLOYEE
      ? formEmployeeType
      : formContractorType

  const { data: fields, isLoading: isFieldsLoading } = useFetch(
    {
      action: getAttributes,
      autoFetch: !!formContractorType || !!formEmployeeType,
      initResult: [],
      body: {
        area: 'contract',
        contract_type: CONTRACT_TYPE_MAP[contractTypeKey],
        active: 1,
      },
      onComplete: () => {
        setValue('custom_field', {})
      },
    },
    [formContractorType, formEmployeeType],
  )

  function includeForbiddenWords(string) {
    const prohibited = disallowedJobTitles?.map((p) => p?.title)
    return !prohibited.includes(string)
  }
  const steps = useCallback(
    (regionConfig, jurisdiction = null) => {
      const isFte =
        formContractType === CONTRACT_CATEGORY.EMPLOYEE &&
        formEmployeeType === EMPLOYEE_CONTRACT_TYPE.EOR

      const isDE =
        formEmployeeType === EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE &&
        formContractType === CONTRACT_CATEGORY.EMPLOYEE

      return {
        contractType: {
          id: 0,
          label: t('Contract type'),
          stepTitle: t('I would like to onboard'),
          schema: yup.object().shape({
            contract_type: yup
              .string()
              .required(t('Please select a contract type')),
          }),
        },
        info: {
          id: 1,
          label: isFte ? t('Simulation') : t('Contract Info'),
          isDE,
          stepTitle: t('ContractType', {
            type:
              formContractType === CONTRACT_CATEGORY.CONTRACTOR
                ? t('Contract')
                : t('Employment'),
          }),
          schema: yup.object().shape({
            type: yup.string().when(
              ...whenContractor(
                (schema) =>
                  schema
                    .typeError(t('Please select a type'))
                    .required(t('Please select a type')),
                (schema) => schema.nullable(),
              ),
            ),

            employee_type: yup.string().when(
              ...whenEmployee(
                (schema) =>
                  schema
                    .typeError(t('Please select a type'))
                    .required(t('Please select a type')),
                (schema) => schema.nullable(),
              ),
            ),
            currency_id: yup
              .string()
              .when(
                ...whenEor((schema) =>
                  schema.required(t('Currency is required')),
                ),
              ),

            // Contractor
            tax_residence_id: yup
              .string()
              .when(
                ...whenContractor((schema) =>
                  schema.required(t('Contractor Tax Country is required')),
                ),
              ),

            custom_field: yup
              .object()
              .when(
                ...whenContractorOrDe((schema) =>
                  getCustomFieldSchema(schema, fields),
                ),
              ),

            scope: yup
              .string()
              .when(
                ...whenContractor((schema) =>
                  schema.required(t('Scope of work is required')),
                ),
              ),
            name: yup
              .string()
              .when(
                ...whenContractor((schema) =>
                  schema.required(t('Role is required')),
                ),
              )
              .when(
                ...whenDe((schema) => schema.required(t('Role is required'))),
              ),

            // Employee | DE & EOR
            employee_country_id: yup
              .string()
              .when(
                ...whenEor((schema) =>
                  schema.required(t('Country is required')),
                ),
              ),
            country_id: yup.string(),
            working_from_state_id: yup.string().when('employee_country_id', {
              is: (country) => {
                const countryFound = countries.find(
                  (c) => c.id?.toString() === country,
                )
                return (
                  countryFound?.states?.length &&
                  countriesWithStates.includes(countryFound?.iso2)
                )
              },
              then: (schema) => schema.required(t('State is required')),
              otherwise: (schema) => schema.nullable(),
            }),

            // Employee | EOR
            amount: yup
              .number()
              .transform((value) => (isNaN(value) ? null : value))
              .nullable()
              .typeError(t('Please enter a valid number for the amount'))
              .label(t('Amount'))
              .when(
                ...whenEor(
                  regionConfig
                    ? (schema) =>
                        schema
                          .label(t('Amount'))
                          .required(t('Please enter a valid amount'))
                          .min(
                            regionConfig?.is_enabled
                              ? regionConfig?.rate
                                ? regionConfig?.min_annual_salary *
                                  regionConfig?.rate
                                : regionConfig?.min_annual_salary
                              : null,
                            t('MinSalaryRegion', {
                              region: regionConfig?.region?.name,
                              rate: regionConfig?.rate
                                ? regionConfig?.min_annual_salary *
                                  regionConfig?.rate
                                : regionConfig?.min_annual_salary,
                            }),
                          )
                          .max(999999999999.99)
                    : (schema) =>
                        schema
                          .label('Amount')
                          .min(0, t('Amount must be a positive number'))
                          .max(999999999999.99)
                          .nullable(),
                  (schema) =>
                    schema
                      .label(t('Amount'))
                      .min(0, t('Amount must be a positive number'))
                      .max(999999999999.99)
                      .nullable(),
                ),
              ),

            // Employee | DE
            employment_term: yup
              .string()
              .when(
                ...whenDe((schema) =>
                  schema.required(t('Employment term is required')),
                ),
              ),
            employment_type: yup
              .string()
              .when(
                ...whenDe((schema) =>
                  schema.required(t('Employment type is required')),
                ),
              ),
            seniority: yup
              .string()
              .when(
                ...whenDe((schema) =>
                  schema.required(t('Seniority level is required')),
                ),
              ),
            employee_identifier: yup.string().when(
              ...whenDe((schema) =>
                schema.when([], {
                  is: () => !!jurisdiction?.is_employee_identifier_required,
                  then: (schema) =>
                    schema.required(t('Employee ID is required')),
                  otherwise: (schema) => schema.notRequired(),
                }),
              ),
            ),
            sub_entity_id: yup
              .number()
              .when(
                ...whenDe((schema) => schema.required(t('Entity is required'))),
              ),
            job_description: yup.string(),

            start_date: yup.date().when(
              ...whenMilestone(
                (schema) => schema.nullable(),
                (schema) =>
                  schema
                    .typeError(t('Start date is required'))
                    .required(t('Start date is required')),
              ),
            ),
            end_date: yup
              .date()
              .nullable()
              .when(
                ...whenDeEndDate((schema) =>
                  schema
                    .typeError(t('End date is required'))
                    .required(t('End date is required')),
                ),
              ),
          }),
        },
        details: {
          id: 3,
          label: t('Employee and job info'),
          stepTitle: t('Employee and job info'),
          schema: yup.object().shape({
            employee_first_name: yup
              .string()
              .required(t('First name is required')),
            employee_last_name: yup
              .string()
              .required(t('Last name is required')),
            employee_email: yup
              .string()
              .email(t('Please enter a valid email'))
              .required(t('Email is required')),
            employee_nationality_country_id: yup
              .string()
              .required(t('Nationality is required')),
            custom_field: getCustomFieldSchema(yup.object(), fields),

            working_from_country_id: yup
              .string()
              .required(t('Country of employment is required')),
            work_visa:
              regionConfig?.is_enabled && regionConfig?.is_immigration_supported
                ? yup.boolean()
                : yup
                    .boolean()
                    .oneOf(
                      [0, false],
                      t('Immigration is not supported in this region'),
                    ),
            qualification: yup
              .string()
              .required(t('Qualification is required')),
            employment_type: yup
              .string()
              .required(t('Employment type is required')),
            employment_term: yup
              .string()
              .required(t('Employment term is required')),
            job_title: yup
              .string()
              .test(
                'job_title',
                'This title is not allowed, instead use Head of or VP of',
                (value) => {
                  return includeForbiddenWords(value)
                },
              )
              .required(t('Job title is required')),
            job_description: yup
              .string()
              .required(t('Job description is required')),
            start_date: yup
              .date()
              .typeError(t('Start date is required'))
              .required(t('Start date is required')),
            end_date: yup
              .date()
              .nullable()
              .when(
                ...whenDeEndDate((schema) =>
                  schema
                    .typeError(t('End date is required'))
                    .required(t('End date is required')),
                ),
              ),
            working_hours_per_week: yup
              .string()
              .max(168)
              .nullable()
              .when(
                ...whenPartTime((schema) =>
                  schema
                    .typeError(t('Working hours is required'))
                    .required(t('Working hours is required')),
                ),
              ),

            ...buildYupSchema({
              form: regionConfig?.work_visa_questionnaire,
              validateAllWhenField: 'work_visa',
            }),
          }),
        },
        payment: {
          id: 4,
          label: t('Payment'),
          stepTitle: t('Payment'),
          schema: yup.object().shape({
            start_date: yup.date().when(
              ...whenNotMilestone(
                (schema) =>
                  schema
                    .typeError(t('Start date is required'))
                    .required(t('Start date is required')),
                (schema) => schema.nullable(),
              ),
            ),
            amount: yup
              .number()
              .label(t('Amount'))
              .typeError(t('Amount must be number'))
              .min(0, t('Amount must be a positive number'))
              .max(999999999999.99)
              .when(['contract_type', 'type'], {
                is: isNotMilestone,
                then: (schema) => schema.required(t('An amount is required')),
                otherwise: (schema) => schema.nullable(),
              })
              .when(
                ...whenDe((schema) =>
                  schema.required(t('An amount is required')),
                ),
              ),

            milestones: yup.array().when('type', {
              is: isMilestone,
              then: (schema) =>
                schema
                  .of(
                    yup.object().shape({
                      name: yup
                        .string()
                        .typeError(t('Milestone name is required'))
                        .required(t("Milestone name can't be empty")),
                      amount: yup
                        .number()
                        .typeError(t('Milestone amount is required'))
                        .moreThan(
                          0,
                          t('Milestone amount must be greater than 0'),
                        )
                        .required(t('Milestone amount is required')),
                    }),
                  )
                  .required(t('Milestones are required')),
            }),

            currency_id: yup
              .string()
              .when(
                ...whenContractor((schema) =>
                  schema.required(t('Currency is required')),
                ),
              )
              .when(
                ...whenDe((schema) =>
                  schema.required(t('Currency is required')),
                ),
              ),

            rate_id: yup
              .string()
              .when(
                ...whenPayG((schema) =>
                  schema.required(t('Unit (of work) is required')),
                ),
              ),

            ...buildPaymentFieldsSchema(t),

            is_external_payroll_provider: yup
              .string()
              .when(
                ...whenDe((schema) =>
                  schema
                    .oneOf(Object.values(DE_PAYMENT_PROVIDER))
                    .required(t('Please select a payroll provider')),
                ),
              ),

            [allowancesKey]: yup.array().when(
              ...whenDe((schema) =>
                schema
                  .min(0)
                  .of(
                    yup.object().shape({
                      name: yup
                        .string()
                        .required(t('Allowance name is required')),
                      amount: yup
                        .number()
                        .required(t('Allowance amount is required')),
                    }),
                  )
                  .required(t('Allowances are required')),
              ),
            ),
          }),
        },
        compliance: {
          id: 5,
          label: t('Compliance'),
          stepTitle: t('Compliance'),
          schema: yup.object().shape({
            contract_doc_type: yup
              .string()
              .when(
                ...whenContractor((schema) =>
                  schema.required(t('Contract document type is required')),
                ),
              )
              .when(
                ...whenDe((schema) =>
                  schema.required(t('Contract document type is required')),
                ),
              ),
            file: yup.mixed().when(
              ...whenDe(
                (schema) => schema.nullable(),
                (schema) =>
                  schema.when(['contract_doc_type'], {
                    is: (contractDocType) =>
                      contractDocType === CONTRACT_DOC_TYPE.CUSTOM,
                    then: (schema) => schema.required(t('File is required')),
                    otherwise: (schema) => schema.nullable(),
                  }),
              ),
            ),
            de_contract_doc_type: yup
              .string()
              .when(...whenDe((schema) => schema.required())),
            probation_period: yup
              .number()
              .min(0, t('Probation period must be a positive number'))
              .typeError(t('Probation period is required'))
              .when(
                ...whenDe((schema) =>
                  schema.required(t('Probation period is required')),
                ),
              ),
            notice_period: yup
              .number()
              .typeError(t('Notice period must be number'))
              .when(
                ...whenDe((schema) =>
                  schema.required(t('Notice period is required')),
                ),
              ),
          }),
        },
        benefits: {
          id: 6,
          label: t('Compensation and benefits'),
          stepTitle: t('Compensation and benefits'),
          schema: yup.object().shape({
            trial_period:
              regionConfig && regionConfig?.is_enabled
                ? yup
                    .number()
                    .transform((value) => (isNaN(value) ? null : value))
                    .min(0, t('Probation days must be a positive number'))
                    .max(
                      regionConfig?.max_probation_months * 30,
                      `${t('Maximum days are')} ${
                        regionConfig?.max_probation_months * 30
                      }`,
                    )
                    .required(t('Probation period is required'))
                : yup
                    .number()
                    .transform((value) => (isNaN(value) ? null : value))
                    .min(0, t('Probation days must be a positive number'))
                    .required(t('Probation period is required')),
            annual_leave_days:
              regionConfig?.is_enabled && regionConfig?.min_annual_leave_days
                ? yup
                    .number()
                    .transform((value) => (isNaN(value) ? null : value))
                    .min(
                      regionConfig?.min_annual_leave_days,
                      `${t('Minimum days are')} ${regionConfig?.min_annual_leave_days}`,
                    )
                    .required(t('Vacation days is required'))
                : yup
                    .number()
                    .transform((value) => (isNaN(value) ? null : value))
                    .min(0, t('Vacation days must be a positive number'))
                    .required(t('Vacation days is required')),
            // insurance_provider_id: yup.string().required(),
            [allowancesKey]: yup
              .array()
              .min(0)
              .of(
                yup.object().shape({
                  name: yup
                    .string()
                    .required(t('Allowance name is required'))
                    .min(2, t('Name must be at least 2 characters')),
                  amount: yup
                    .number()
                    .required(t('Allowance amount is required')),
                }),
              ),
            bonus_details: yup
              .string()
              .nullable()
              .when(
                ...whenBonusDetails((schema) =>
                  schema
                    .typeError(t('Description is required'))
                    .required(t('Description is required')),
                ),
              ),
          }),
        },
      }
    },
    [countries, fields, formContractType, formEmployeeType],
  )

  const [activeTab, setActiveTab] = useState(
    isContractor
      ? steps(regionConfig).info.id
      : steps(regionConfig).contractType.id,
  )

  function handleReturn() {
    history.push(history.location.state?.backRoute ?? '/activity')
  }

  const stepsData = useMemo(() => {
    return getSteps(
      formContractType,
      formEmployeeType,
      steps(regionConfig, watch(dataKeys.jurisdiction)),
    )
  }, [
    formContractType,
    formEmployeeType,
    regionConfig,
    steps,
    watch(dataKeys.jurisdiction),
  ])

  const employeeIdentifier = watch('employee_identifier')

  function validateStep(schema, goNext) {
    if (!schema) {
      goNext?.()
    } else {
      validateSchema(schema, getValues(), {
        setFocus,
        setError,
        clearErrors,
        onValidSchema: goNext,
      })
    }
  }

  useTriggerField({ watch, trigger })

  const { startFetch: validateEmpID, isLoading: isValidatingEmpId } = useFetch({
    action: deValidateEmployeeIdentifier,
    body: { employee_identifier: employeeIdentifier },
    onComplete: () => {
      clearErrors('employee_identifier')
      handleNext(activeTab, true)
    },
    onError: (data) => {
      setError('employee_identifier', {
        type: 'manual',
        message: data,
      })
    },
  })

  function handleNext(id, deIdVerified) {
    function goNext() {
      setActiveTab(getNext(stepsData, id))
      window.scrollTo(0, 0)
    }

    const currentStep = stepsData.find((s) => s.id === id)
    if (currentStep?.isDE && id === 1 && employeeIdentifier && !deIdVerified) {
      validateEmpID()
    } else {
      validateStep(currentStep.schema, goNext)
    }
  }

  function handleBack(id) {
    window.scrollTo(0, 0)
    clearErrors()
    setActiveTab(getPrevious(stepsData, id))
  }

  function handleContractCreationError(data) {
    const additionalErrors =
      typeof data === 'object'
        ? Object.values(data?.data ?? {})
            .map((errorArray) => errorArray?.join(', '))
            .join('<br />')
        : ''

    toastr.error(
      additionalErrors,
      typeof data === 'string'
        ? data
        : data?.message || data?.error || t('Error creating contract'),
      { allowHtml: true },
    )
  }

  function handleContractCreated(data, body) {
    if (data?.success === false) {
      handleContractCreationError(data)
    } else {
      track('Contract_created', {
        contract_id: data?.ref,
        contract_name: body?.name || body?.job_title,
        contractor_type: body?.directEmployee
          ? 'direct_employee'
          : body?.employment_type
            ? 'employee'
            : 'contractor',
        contractor_label: body?.contractor_name,
        contract_type: body?.type || body?.employment_type,
        type: user?.type,
        isBulk: 0,
      })

      toastr.success(t('Contract created'))

      if (regionConfig?.is_quotation_automation_enabled) {
        toastr.success(t('Quotation was automatically generated'))
      }

      history.push(
        data?.ref ? `/contract/detail?id=${data?.ref}` : '/contracts',
      )
    }
  }

  const { startFetch: _createContract, isLoading: creatingContract } = useFetch(
    {
      action: createContract,
      onComplete: (data, body) => handleContractCreated(data, body),
      onError: handleContractCreationError,
    },
  )

  const { startFetch: createEorContract, isLoading: creatingEorContract } =
    useFetch({
      action: createFulltimeContract,
      onComplete: (data, body) => handleContractCreated(data, body),
      onError: handleContractCreationError,
    })

  const {
    startFetch: createDirectEmployeeContract,
    isLoading: creatingDirectEmployeeContract,
  } = useFetch({
    action: createDirectEmploymentContract,
    onComplete: (data, body) =>
      handleContractCreated(data, { ...body, directEmployee: true }),
    onError: handleContractCreationError,
  })

  const loading =
    creatingDirectEmployeeContract || creatingContract || creatingEorContract

  function handleCreateContract(data) {
    const formattedBody = {
      ...removeFormFields(
        formatDataBody(formatEOSPlanData(data), { contractorTypes }),
      ),
      work_visa_questionnaire_answers: formatValues(data)?.form_inputs,
    }
    const currentStep = stepsData.find((s) => s.id === activeTab)
    if (formattedBody?.jurisdiction_eos_plan_inputs?.length < 1) {
      delete formattedBody?.jurisdiction_eos_plan_inputs
    }
    if (formattedBody?.[fileTempName]) {
      delete formattedBody[fileTempName]
    }
    // Remove data that is not needed for the API
    delete formattedBody?.jurisdiction
    delete formattedBody?.jurisdictionId

    // format start date (sending full date causes a timezone issue)
    formattedBody.start_date = formattedBody?.start_date
      ? format(new Date(formattedBody?.start_date), 'yyyy-MM-dd')
      : null

    validateStep(currentStep?.schema, () => {
      if (data.contract_type === CONTRACT_CATEGORY.EMPLOYEE) {
        if (data.employee_type === EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE) {
          delete formattedBody?.[fieldKeys.proRata]
          if (formattedBody?.visa_number === '')
            delete formattedBody?.visa_number
          createDirectEmployeeContract(formattedBody)
        } else if (data.employee_type === EMPLOYEE_CONTRACT_TYPE.EOR) {
          if (!data.work_visa) {
            delete formattedBody?.work_visa_questionnaire_answers
          }
          if (data.insurance_provider_id === 'later') {
            delete formattedBody?.insurance_provider_id
          }
          createEorContract(formattedBody)
        }
      } else {
        if (formattedBody?.type) {
          _createContract(formattedBody)
        }
      }
    })
  }
  function handleError() {
    toastr.error(t('There was a validation error!'))
  }

  const contractCategoryTypes = contractCategories(t)
    .map((category) => {
      if (category.value === CONTRACT_CATEGORY.CONTRACTOR || !isContractor) {
        return category
      }

      return null
    })
    .filter(Boolean)

  return (
    <Container fluid className='px-0'>
      <Head title={t('Create a contract')} />

      <ModalHeader action={handleReturn}>
        <Steps
          activeTab={getIndex(stepsData, activeTab)}
          data={stepsData.map((s) => s.label)}
          className='d-none d-md-flex'
        />
      </ModalHeader>

      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(handleCreateContract, handleError)}
          onKeyDown={preventEnterKeySubmission}
          noValidate
        >
          <TabContent activeTab={activeTab} className='tab-content-sm-margin'>
            <TabPane tabId={steps(regionConfig).contractType.id}>
              <StepContainer
                title={steps(regionConfig).contractType.stepTitle}
                index={getIndex(stepsData, steps(regionConfig).contractType.id)}
                total={stepsData.length}
                onNext={() => handleNext(steps(regionConfig).contractType.id)}
                onBack={() => handleBack(steps(regionConfig).contractType.id)}
              >
                <ControlledTypeSelect
                  control={control}
                  name='contract_type'
                  error={errors?.contract_type}
                  types={contractCategoryTypes}
                  required
                  cols={2}
                  horizontal
                  transform={{
                    output: (newValue) => {
                      setValue('employee_type', null)
                      setValue('type', null)
                      return newValue?.value
                    },
                  }}
                />

                <div className='tw-mt-4 tw-text-center'>
                  <Button
                    icon={<UploadSimple size={20} />}
                    color='link'
                    className='!tw-px-0'
                    tag={Link}
                    to='/contract/bulk-creation'
                    onClick={() =>
                      trackEvent(
                        BULK_CONTRACT_CREATION_EVENTS.CLICKED_BULK_CREATION,
                      )
                    }
                  >
                    {t('Upload contracts in bulk')}
                  </Button>
                </div>
              </StepContainer>
            </TabPane>

            <TabPane tabId={steps(regionConfig).info.id}>
              <StepContainer
                title={steps(regionConfig).info.stepTitle}
                index={getIndex(stepsData, steps(regionConfig).info.id)}
                total={stepsData.length}
                loading={isValidatingEmpId}
                onNext={() => handleNext(steps(regionConfig).info.id)}
                onBack={() => handleBack(steps(regionConfig).info.id)}
                noBack={isContractor}
                minHeight='540px'
              >
                <ContractTypeDetailsStep
                  control={control}
                  watch={watch}
                  fields={fields}
                  isFieldsLoading={isFieldsLoading}
                />

                <SimulationView control={control} />
              </StepContainer>
            </TabPane>
            <TabPane tabId={steps(regionConfig).payment.id}>
              <StepContainer
                title={steps(regionConfig).payment.stepTitle}
                index={getIndex(stepsData, steps(regionConfig).payment.id)}
                total={stepsData.length}
                onNext={() => handleNext(steps(regionConfig).payment.id)}
                onBack={() => handleBack(steps(regionConfig).payment.id)}
                minHeight='445px'
              >
                <PaymentStep control={control} errors={errors} />
              </StepContainer>
            </TabPane>

            <TabPane tabId={steps(regionConfig).details.id}>
              <StepContainer
                title={steps(regionConfig).details.stepTitle}
                index={getIndex(stepsData, steps(regionConfig).details.id)}
                total={stepsData.length}
                onNext={() => handleNext(steps(regionConfig).details.id)}
                onBack={() => handleBack(steps(regionConfig).details.id)}
                loading={loading}
              >
                <EORContractDetails
                  control={control}
                  watch={watch}
                  fields={fields}
                  isFieldsLoading={isFieldsLoading}
                />
              </StepContainer>
            </TabPane>
            <TabPane tabId={steps(regionConfig).benefits.id}>
              <StepContainer
                title={steps(regionConfig).benefits.stepTitle}
                index={getIndex(stepsData, steps(regionConfig).benefits.id)}
                total={stepsData.length}
                onBack={() => handleBack(steps(regionConfig).benefits.id)}
                onNext={() => handleNext(steps(regionConfig).details.id)}
                loading={loading}
                minHeight='742px'
                nextText={t('Create')}
                nextType='submit'
              >
                <BenefitsForm control={control} watch={watch} />
              </StepContainer>
            </TabPane>
            <TabPane tabId={steps(regionConfig).compliance.id}>
              <StepContainer
                title={steps(regionConfig).compliance.stepTitle}
                index={getIndex(stepsData, steps(regionConfig).compliance.id)}
                total={stepsData.length}
                onBack={() => handleBack(steps(regionConfig).compliance.id)}
                loading={loading}
                nextText={t('Create')}
                nextType='submit'
              >
                <ComplianceStep control={control} t={t} />
              </StepContainer>
            </TabPane>
          </TabContent>
        </form>
      </FormProvider>
    </Container>
  )
}

function ContractTypeDetailsStep({ control, watch, fields, isFieldsLoading }) {
  const [showDeOnboardingInfo, setShowDeOnboardingInfo] = useState(false)

  const {
    countries,
    currencies,
    job_titles: titles,
    cor_countries: corCountries = [],
  } = useSelector((state) => state.Layout.staticData ?? {})

  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const company = userProfile?.company
  const isContractor = userProfile?.type === userTypes.CONTRACTOR

  const {
    name: watchingJobTitle,
    contract_type: contractType,
    employee_type: employeeType,
    type,
    tax_residence_id: taxResidenceId,
  } = useWatch({ control })

  const isCompanyCountryCorEnabled = corCountries.some(
    (country) => country.id === taxResidenceId,
  )
  const isCompanyCorEnabled = company?.is_cor_enabled === 1

  const disabledCorCountry = countries?.find(
    (country) => country.id === taxResidenceId,
  )

  const {
    setValue,
    formState: { errors },
  } = useFormContext()
  const { hasAccess } = usePermissions()

  if (contractType === CONTRACT_CATEGORY.EMPLOYEE) {
    const isEorEnabled = company?.eor === 1
    const isDeEnabled = company?.is_direct_employee_enabled

    const hasEorAddPermission = hasAccess(permissions.createEorContracts)
    const hasDeAddPermission = hasAccess(permissions.createDeContracts)

    const types = employeeContractOptions({
      isEorDisabled: !hasEorAddPermission
        ? {
            title: 'You don’t have access to create EOR contracts',
            description: 'Contact your administrator to get access',
          }
        : isEorEnabled
          ? null
          : {
              title: 'Unlock access to full time contracts',
              description:
                'Full-time contract is a premium service where RemotePass acts as the legal employer on your behalf',
              chatMessage:
                'Hi, I want to unlock access to full time contracts on my account',
            },
      isDeDisabled: !hasDeAddPermission
        ? {
            title: 'You don’t have access to create direct employee contracts',
            description: 'Contact your administrator to get access',
          }
        : isDeEnabled
          ? null
          : {
              title: 'Unlock access to direct employee contracts',
              chatMessage:
                'Hi, I want to unlock access to direct employee contracts on my account',
            },
      t,
    })

    return (
      <>
        <ControlledTypeSelect
          control={control}
          name='employee_type'
          error={errors?.employee_type}
          types={types}
          transform={{
            output: (newValue) => {
              const deOnboardInfoAlreadySeen = getCookie(
                DE_ONBOARDING_BANNER_COOKIE,
              )

              if (
                newValue?.value === EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE &&
                !deOnboardInfoAlreadySeen
              ) {
                setShowDeOnboardingInfo(true)
              }

              return newValue?.value
            },
          }}
          required
          cols={types?.length}
        />

        <div className='mt-3'>
          {employeeType === EMPLOYEE_CONTRACT_TYPE.EOR ? (
            <EORForm control={control} watch={watch} setValue={setValue} />
          ) : employeeType === EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE ? (
            <>
              <DEForm
                control={control}
                fields={fields}
                isFieldsLoading={isFieldsLoading}
              />

              <OnboardToDeModal
                isOpen={showDeOnboardingInfo}
                toggle={() => {
                  setShowDeOnboardingInfo(false)
                  setCookie(DE_ONBOARDING_BANNER_COOKIE, true)
                }}
              />
            </>
          ) : null}
        </div>
      </>
    )
  } else if (contractType === CONTRACT_CATEGORY.CONTRACTOR) {
    const hasFixedAddPermission = hasAccess(permissions.createFixedContracts)
    const hasPayAsYouGoAddPermission = hasAccess(
      permissions.createPAYGContracts,
    )
    const hasMilestoneAddPermission = hasAccess(
      permissions.createMilestoneContracts,
    )

    const types = contractTypeCards({
      isFixedDisabled: hasFixedAddPermission
        ? null
        : {
            title: 'You don’t have access to create fixed contracts',
            description: 'Contact your administrator to get access',
          },
      isPayAsYouGoDisabled: hasPayAsYouGoAddPermission
        ? null
        : {
            title: 'You don’t have access to create pay as you go contracts',
            description: 'Contact your administrator to get access',
          },
      isMilestoneDisabled: hasMilestoneAddPermission
        ? null
        : {
            title: 'You don’t have access to create milestone contracts',
            description: 'Contact your administrator to get access',
          },
      t,
    })

    return (
      <>
        <ControlledTypeSelect
          control={control}
          name='type'
          error={errors?.type}
          types={types}
          required
        />

        <div className='mt-3'>
          <ControlledSelect
            wrapperClassName='mb-3'
            control={control}
            label={
              <LabelContent required>
                {t('Contractor Tax Country:')}
              </LabelContent>
            }
            name='tax_residence_id'
            error={errors?.tax_residence_id}
            options={countries?.map((c) => mapCountryToOption(c))}
            transform={{
              output: (value) => {
                const newCurrency = getCurrencyFromTaxCountry(
                  currencies,
                  value?.currency,
                )

                setValue('currency_id', newCurrency?.id ?? USD_ID)

                return value
              },
            }}
          />

          {!type ||
          !taxResidenceId ||
          !isCompanyCorEnabled ||
          isContractor ? null : (
            <ControlledPremiumRecruitment
              name='is_cor'
              control={control}
              disabled={isCompanyCorEnabled && !isCompanyCountryCorEnabled}
              companyCountry={disabledCorCountry?.name}
            />
          )}

          <CreationCustomFields
            control={control}
            errors={errors}
            fields={fields}
            isFieldsLoading={isFieldsLoading}
          />

          <div className='mb-3'>
            <ControlledInput
              control={control}
              label={<LabelContent required>{t('Role')}:</LabelContent>}
              name='name'
              error={errors?.name}
              placeholder={t('i.e. Website Design, Content generation')}
              autoComplete={titles}
              watchState={watchingJobTitle}
            />
          </div>

          <div>
            <ScopeOfWorkField
              label={
                <LabelContent className='!tw-capitalize' required>
                  {t('Scope of Work')}:
                </LabelContent>
              }
              placeholder={t('Describe the deliverables')}
              name='scope'
              error={errors?.scope}
              control={control}
            />
          </div>
        </div>
      </>
    )
  }

  return null
}

function EORForm({ control, watch }) {
  const [country, setCountry] = useState(null)
  const showState =
    country?.states?.length && countriesWithStates.includes(country?.iso2)
  const { eor_countries: eorCountries } = useSelector(
    (state) => state.Layout.staticData ?? {},
  )
  const {
    setValue,
    formState: { errors },
  } = useFormContext()

  const { employee_country_id: countryId, working_from_state_id: state } =
    useWatch({
      control,
    })

  const { setItem, formData } = useFormData()
  const [config, setConfig] = useState(null)

  useFetch(
    {
      action: getEORRegionConfig,
      autoFetch: countryId || state,
      body: {
        region_id: state ?? countryId,
        region_type: state ? 'state' : 'country',
      },
      onComplete: (data) => {
        setConfig(data)
      },
      onError: () => {
        setItem(dataKeys.regionConfig, null)
      },
    },
    [countryId, state],
  )

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'employee_country_id') {
        setCountry(
          eorCountries.find((c) => c.id === value?.employee_country_id) ?? null,
        )
        setValue('working_from_state_id', null)
        setValue('work_visa', 0)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, eorCountries, setValue])

  const { data: localCurrencies } = useFetch({
    action: getLocalCurrencies,
    autoFetch: true,
    initResult: [],
  })
  const { startFetch: getExchangeRate } = useFetch({
    action: getCurrencyExchangeRate,
    onComplete: (data) => {
      setItem(dataKeys.exchangeRate, data?.rate)
      setConfig((c) => ({ ...c, rate: data?.rate }))
    },
  })
  const currencyOptions = localCurrencies?.map((c) =>
    mapCurrencyToOption(c, 'id'),
  )
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (
        name === 'currency_id' &&
        watch('currency_id') &&
        watch('employee_country_id') &&
        watch('currency_id') !==
          localCurrencies?.find(
            (p) =>
              p?.code ===
              eorCountries?.find((c) => c?.id === watch('employee_country_id'))
                ?.currency,
          )?.id
      ) {
        getExchangeRate({
          source_currency: localCurrencies?.find(
            (p) =>
              p?.code ===
              eorCountries?.find((c) => c?.id === watch('employee_country_id'))
                ?.currency,
          )?.code,
          target_currency: localCurrencies?.find(
            (p) => p?.id === watch('currency_id'),
          )?.code,
        })
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, eorCountries, setValue, localCurrencies])

  useEffect(() => {
    setItem(dataKeys.regionConfig, config)
  }, [config])
  return (
    <Row className='mx-n2'>
      <FormCol>
        <ControlledSelect
          label={<LabelContent required>{t('Country')}:</LabelContent>}
          control={control}
          name='employee_country_id'
          error={errors.employee_country_id}
          options={(eorCountries ?? []).map((c) => mapCountryToOption(c))}
          transform={{
            output: (value) => {
              const newCurrency = getOptionFromList(
                localCurrencies,
                value?.currency,
                'code',
              )
              setValue('employee_country_id', value?.value)
              setValue('working_from_country_id', value?.value)
              setValue('currency_id', newCurrency?.id ?? USD_ID)

              return value
            },
          }}
        />
      </FormCol>

      <FormCol>
        <YearlySalaryField
          control={control}
          currencyOptions={currencyOptions}
          errors={errors}
          hideError={false}
          label={
            <LabelContent required>{t('Yearly Gross Salary:')}</LabelContent>
          }
          onBlur={(event) => {
            const value = Number(event?.target?.value?.replaceAll(',', ''))

            const exchangeRate = formData?.exchangeRate ?? 1
            const effectiveSalary = value
            const minSalary =
              formData?.regionConfig?.min_annual_salary * exchangeRate

            if (
              effectiveSalary < minSalary &&
              formData?.regionConfig?.is_enabled
            ) {
              toastr.error(
                // prettier-ignore
                t('MinimumSalaryInRegion', {
                  region: formData?.regionConfig?.region?.name,
                  minSalary,
                  currency: localCurrencies?.find(
                    (p) => p?.id === watch('currency_id'),
                  )?.code,
                }),

                t('Error'),
              )
            }
          }}
        />
      </FormCol>
      {showState ? (
        <FormCol>
          <ControlledSelect
            label={<LabelContent required>{t('State:')}</LabelContent>}
            control={control}
            name='working_from_state_id'
            options={
              country?.states
                ? country?.states?.map((state) => mapCountryToOption(state))
                : []
            }
          />
        </FormCol>
      ) : null}
    </Row>
  )
}

function EORContractDetails({
  control,
  watch,
  fields: customFields,
  isFieldsLoading,
}) {
  const WORK_PERMIT_NEEDED = [
    { label: t('No'), value: 0 },
    { label: t('Yes'), value: 1 },
  ]
  const {
    countries,
    other_countries: otherCountries,
    eor_countries: eorCountries,
    job_titles: titles,
  } = useSelector((state) => state.Layout.staticData ?? {})

  const { job_title: watchingJobTitle } = useWatch({
    control,
  })

  const {
    clearErrors,
    setValue,
    formState: { errors },
  } = useFormContext()

  const { formData } = useFormData()
  const regionConfig = formData?.regionConfig
  const countryOptions = eorCountries?.map((c) => mapCountryToOption(c, 'id'))
  const nationalities = [...(countries ?? []), ...(otherCountries ?? [])].map(
    (c) => mapCountryToOption(c, 'id'),
  )
  const questions = regionConfig?.work_visa_questionnaire?.form_fields

  const shouldShowVisaBlock =
    watch('employee_nationality_country_id') &&
    watch('working_from_country_id') &&
    watch('employee_nationality_country_id') !==
      watch('working_from_country_id')

  return (
    <>
      <FormSectionTitle
        title={t('Employee Info')}
        subTitle={t('Add your employee’s info')}
      />

      <Row className='px-2'>
        <FormCol>
          <ControlledInput
            control={control}
            label={<LabelContent required>{t('First Name')}:</LabelContent>}
            name='employee_first_name'
            error={errors?.employee_first_name}
            placeholder={t('First Name')}
          />
        </FormCol>

        <FormCol>
          <ControlledInput
            control={control}
            label={<LabelContent required>{t('Last Name')}:</LabelContent>}
            name='employee_last_name'
            error={errors?.employee_last_name}
            placeholder={t('Last Name')}
          />
        </FormCol>

        <FormCol>
          <ControlledInput
            control={control}
            label={<LabelContent>{t('Middle Name')}:</LabelContent>}
            name='employee_middle_name'
            error={errors?.employee_middle_name}
            placeholder={t('Middle Name')}
          />
        </FormCol>

        <FormCol>
          <ControlledInput
            control={control}
            label={<LabelContent required>{t('Personal Email')}:</LabelContent>}
            name='employee_email'
            error={errors?.employee_email}
            placeholder={t('Personal Email')}
            type='email'
          />
        </FormCol>

        <FormCol>
          <ControlledSelect
            control={control}
            name='employee_nationality_country_id'
            error={errors?.employee_nationality_country_id}
            label={<LabelContent required>{t('Nationality')}:</LabelContent>}
            options={nationalities}
          />
        </FormCol>

        <FormCol>
          <ControlledSelect
            control={control}
            name='working_from_country_id'
            error={errors?.working_from_country_id}
            label={
              <LabelContent required>
                {t('Country Of Employment')}:
              </LabelContent>
            }
            options={countryOptions}
            disabled
          />
        </FormCol>
      </Row>

      {shouldShowVisaBlock ? (
        <Row className='px-2 mt-3'>
          <FormCol noMargin md={12}>
            <div
              className={cx(
                'tw-flex tw-justify-between tw-rounded tw-border tw-border-surface-30 tw-p-4',
                {
                  '!tw-border-red': errors?.work_visa,
                  'tw-rounded-b-none': watch('work_visa') === 1,
                },
              )}
            >
              <div className='d-flex gap-16 align-items-center'>
                <Files size={32} className='tw-text-text-30' weight='duotone' />

                <div>
                  <p className='mb-0 rp-font-bold font-size-16 text-text-black'>
                    {t('Work Visa / Permit')}
                  </p>
                  <p className='font-size-14 text-text-60 mb-0'>
                    {t('Does the employee require Work Visa or permit in')}{' '}
                    {regionConfig?.region && regionConfig?.is_enabled
                      ? regionConfig?.region?.name
                      : eorCountries?.find(
                          (p) => p?.id === watch('working_from_country_id'),
                        )?.name}
                    {t('?')}
                  </p>
                </div>
              </div>

              <ControlledOption
                control={control}
                error={errors?.working_from_country_id}
                name='work_visa'
                disabled={false}
                options={WORK_PERMIT_NEEDED}
              />
            </div>
          </FormCol>

          {watch('work_visa') === 1 ? (
            <Col className='px-3' md={12}>
              <Col
                className={cx(
                  'tw-rounded-b tw-border-x tw-border-b tw-border-surface-30 tw-bg-surface-10 tw-p-4',
                )}
                md={12}
              >
                <Row className='px-2'>
                  {regionConfig?.is_immigration_supported &&
                  regionConfig?.is_enabled ? (
                    <DynamicForm
                      control={control}
                      clearErrors={clearErrors}
                      setValue={setValue}
                      conditions={
                        regionConfig?.work_visa_questionnaire?.conditions
                      }
                      fields={questions}
                      errors={errors}
                      watch={watch}
                      newUI={false}
                    />
                  ) : (
                    <Alert
                      color='transparent'
                      customIcon={<XCircle size={16} color='black' />}
                    >
                      {
                        // prettier-ignore
                        t('VisaPermitSupport', {
                        region:
                          regionConfig?.region && regionConfig?.is_enabled
                            ? regionConfig?.region?.name
                            : eorCountries?.find(
                                (p) =>
                                  p?.id === watch('working_from_country_id'),
                              )?.name
                      })
                      }
                    </Alert>
                  )}
                </Row>
              </Col>

              {errors?.work_visa && (
                <span className='tw-mt-2 tw-block tw-text-red'>
                  {errors?.work_visa?.message}
                </span>
              )}
            </Col>
          ) : null}
        </Row>
      ) : null}

      <CreationCustomFields
        control={control}
        errors={errors}
        rules={{ top: true, bottom: false }}
        fields={customFields}
        isFieldsLoading={isFieldsLoading}
      />

      <FormSectionHr />

      <FormSectionTitle
        title={t('Job Info')}
        subTitle={t('Add this job’s info')}
      />

      <Row className='px-2'>
        <FormCol>
          <LabelContent className='mb-2' required>
            {t('Employment Term')}:
          </LabelContent>
          <ControlledOption
            control={control}
            error={errors?.employment_term}
            name='employment_term'
            disabled={false}
            options={employmentTermOptions(t)}
          />

          {!errors?.employment_term ? null : (
            <InputFeedback className='tw-mt-1'>
              {getInputErrorMessage(errors?.employment_term)}
            </InputFeedback>
          )}
        </FormCol>

        <FormCol>
          <LabelContent className='mb-2' required>
            {t('Employment Type')}:
          </LabelContent>
          <ControlledOption
            control={control}
            error={errors?.employment_type}
            name='employment_type'
            disabled={false}
            options={employmentTypeOptions(t)}
          />

          {!errors?.employment_type ? null : (
            <InputFeedback className='tw-mt-1'>
              {getInputErrorMessage(errors?.employment_type)}
            </InputFeedback>
          )}
        </FormCol>
        <FormCol>
          <ControlledDatePicker
            control={control}
            name='start_date'
            minDate={addDays(new Date(), 3)}
            error={errors?.start_date}
            label={<LabelContent>{t('Start date')}:</LabelContent>}
          />
        </FormCol>
        {watch('employment_term') === definiteValue ? (
          <FormCol>
            <ControlledDatePicker
              control={control}
              name='end_date'
              error={errors?.end_date}
              label={<LabelContent>{t('End Date')}:</LabelContent>}
            />
          </FormCol>
        ) : null}
        {watch('employment_type') === partTimeValue ? (
          <FormCol>
            <ControlledInput
              control={control}
              label={
                <LabelContent required>{t('Hours per week:')}</LabelContent>
              }
              name='working_hours_per_week'
              error={errors?.working_hours_per_week}
            />
          </FormCol>
        ) : null}
        <FormCol>
          <ControlledSelect
            control={control}
            name='qualification'
            error={errors?.qualification}
            label={<LabelContent required>{t('Qualification')}:</LabelContent>}
            options={qualificationOptions(t)}
          />
        </FormCol>

        <FormCol md={12}>
          <ControlledInput
            control={control}
            label={<LabelContent required>{t('Job title')}:</LabelContent>}
            name='job_title'
            error={errors?.job_title}
            placeholder={t('i.e. Website Design, Content generation')}
            autoComplete={titles}
            watchState={watchingJobTitle}
          />
        </FormCol>

        <FormCol md={12}>
          <ScopeOfWorkField
            label={
              <LabelContent required>{t('Job description:')}</LabelContent>
            }
            placeholder={t('Job description')}
            name='job_description'
            error={errors?.job_description}
            control={control}
          />
        </FormCol>
      </Row>
    </>
  )
}

function SimulationView({ control }) {
  const { eor_countries: eorCountries } = useSelector(
    (state) => state?.Layout?.staticData ?? {},
  )
  const { formData } = useFormData()

  const { data: localCurrencies } = useFetch({
    action: getLocalCurrencies,
    autoFetch: true,
    initResult: [],
  })

  const {
    currency_id: currencyId,
    employee_country_id: countryId,
    amount,
    working_from_country_id: workingCountry,
  } = useWatch({ control })

  const countryCode =
    eorCountries?.length > 0
      ? getOptionFromList(eorCountries, countryId, 'id')?.iso2
      : null
  const customerCurrencyCode =
    localCurrencies?.length > 0
      ? getOptionFromList(localCurrencies, currencyId, 'id')?.code
      : null

  const [debouncedAmount] = useDebounceValue(amount, 500)

  const {
    data: eorQuote,
    isLoading: gettingQuote,
    error,
  } = useFetch(
    {
      action: getCalculatorResult,
      autoFetch: currencyId && countryId && amount && customerCurrencyCode,
      body: {
        country_code: countryCode,
        annual_salary: Number(amount),
        currency: customerCurrencyCode,
      },
    },
    [currencyId, countryId, debouncedAmount, customerCurrencyCode],
  )

  return currencyId && countryId && debouncedAmount ? (
    <>
      {/* <EORForm control={control} /> */}

      <div style={{ height: '0.5rem' }} />

      {(error || eorQuote?.original?.data?.error) && !gettingQuote ? (
        <Alert color='primary'>
          {t('NoBreakDownCalculation', {
            country:
              formData?.regionConfig?.region &&
              formData?.regionConfig?.is_enabled
                ? formData?.regionConfig?.region?.name
                : eorCountries?.find((p) => p?.id === workingCountry)?.name,
          })}
        </Alert>
      ) : gettingQuote || !customerCurrencyCode ? (
        <Loader minHeight='577px' />
      ) : (
        <QuoteView
          quote={eorQuote}
          customerCurrencyCode={customerCurrencyCode}
        />
      )}
    </>
  ) : null
}

function DEForm({ control, fields, isFieldsLoading }) {
  const { job_titles: titles = [], seniorities = [] } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )

  const [isContributionConfirmModalOpen, setIsContributionConfirmModalOpen] =
    useState(false)

  const {
    name: watchingJobTitle,
    employment_term: employmentTerm,
    start_date: startDate,
    is_contributions_enabled: isContributionsEnabled,
  } = useWatch({ control })

  const { data: { entities } = {}, isLoading: fetchingEntities } = useFetch({
    action: getEntities,
    autoFetch: true,
  })

  const {
    formState: { errors },
    watch,
    setValue,
  } = useFormContext()
  const { sub_entity_id: entityID } = useWatch({ control })

  const isDeDefinite = isDefinite(employmentTerm)
  const entity = useMemo(() => {
    const entity = entities?.find((e) => e?.id === entityID)
    setValue(dataKeys.jurisdiction, entity?.jurisdiction)
    setValue(dataKeys.jurisdictionId, entity?.jurisdiction?.id)
    return entity
  }, [entities, entityID, setValue])
  const jurisdictionPlan = entity?.jurisdiction ?? watch(dataKeys.jurisdiction)
  const hasDEWSPlan = entity?.jurisdiction?.contribution_plans?.some(
    (plan) => plan.code === 'dews',
  )
  const isKsaEntity = entity?.country?.iso3 === 'SAU'

  useEffect(() => {
    if (hasDEWSPlan || isKsaEntity) {
      setValue('is_contributions_enabled', true)
    } else {
      setValue('is_contributions_enabled', false)
    }
  }, [entity])

  const allowedEntities = entities?.filter((ent) =>
    ['ARE', 'SAU'].includes(ent.country?.iso3),
  )

  return (
    <>
      {FEATURE_FLAGS.NEW_DIRECT_EMPLOYEE && (
        <>
          <FormSectionHr />
          <FormSectionTitle title={t('Entity')} />
          {fetchingEntities ? (
            <Spinner className='tw-animate-spin tw-transition-transform tw-duration-300' />
          ) : allowedEntities?.length > 0 ? (
            <>
              <ControlledSelect
                control={control}
                name='sub_entity_id'
                label={t('Select entity')}
                isLoading={fetchingEntities}
                options={allowedEntities?.map((entity) => ({
                  value: entity.id,
                  label: entity.name,
                  icon: (
                    <Flag
                      url={getFlagUrlFromIso2(entity.country.iso2)}
                      className='tw-me-2'
                    />
                  ),
                }))}
                required
                error={errors?.sub_entity_id}
              />

              {hasDEWSPlan && (
                <div className='tw-mt-6 tw-flex tw-items-center tw-justify-between tw-gap-4 tw-rounded tw-border tw-border-surface-30 tw-p-4'>
                  <div>
                    <div className='tw-font-semibold tw-text-black'>
                      {t('Contribution')}
                    </div>
                    <span className='tw-text-xs tw-text-text-60'>
                      {
                        // prettier-ignore
                        t(
                        'If enabled, DEWS contributions will be automatically calculated when applicable and added to your monthly payroll.'
                      )
                      }
                    </span>
                  </div>

                  <Toggle
                    marginRight=''
                    check={isContributionsEnabled}
                    change={() => {
                      if (isContributionsEnabled) {
                        setIsContributionConfirmModalOpen(true)
                      } else {
                        setValue('is_contributions_enabled', 1)
                      }
                    }}
                  />
                </div>
              )}
              {isContributionConfirmModalOpen && (
                <ConfirmationModal
                  isOpen={isContributionConfirmModalOpen}
                  toggle={() => setIsContributionConfirmModalOpen((o) => !o)}
                  content={
                    <>
                      <div className='tw-mb-2 tw-flex tw-items-center tw-justify-between'>
                        <Warning size={24} className='tw-fill-red-100' />
                        <ModalCloseButton
                          toggle={() =>
                            setIsContributionConfirmModalOpen((o) => !o)
                          }
                        />
                      </div>
                      <p className='tw-mb-2 tw-text-xl tw-text-secondary-120'>
                        {t('Disabled contributions')}
                      </p>
                      <p className='tw-text-sm tw-text-text-80'>
                        {t(
                          "Are you sure that contributions aren't required for this contract?",
                        )}
                      </p>
                    </>
                  }
                  negativeCaption='Cancel'
                  caption='Yes, Disable'
                  buttonColor='danger'
                  onConfirm={() => {
                    setValue('is_contributions_enabled', 0)
                    setIsContributionConfirmModalOpen(false)
                  }}
                />
              )}
              {entity && !entity.jurisdiction ? (
                <Alert color='danger' className='tw-mt-4'>
                  {t(
                    'To continue, set the jurisdiction for this entity by navigating to Company Settings > Company Info > Click "View Details" for',
                  )}

                  {entity.name}
                  {t('entity > Select Jurisdiction')}
                </Alert>
              ) : null}
              {entity?.jurisdiction?.contribution_plans?.length ? (
                <ContributionPlansAlert jurisdiction={entity?.jurisdiction} />
              ) : null}
            </>
          ) : (
            <div className='tw-flex tw-items-center tw-p-4'>
              <span className='tw-me-2 tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-systemGold-20'>
                <Warning size={24} className='tw-text-systemGold-110' />
              </span>

              <span className='tw-me-4 tw-flex-1'>
                <div className='tw-text-sm tw-font-semibold tw-text-black'>
                  {t(
                    'You need to add a UAE company entity to hire direct employees',
                  )}
                </div>
                <span className='tw-text-xs tw-text-text-60'>
                  {
                    // prettier-ignore
                    t(
                    'Simplify your hiring process and comply with local laws with our expert assistance.'
                  )
                  }
                </span>
              </span>

              <Link
                className='tw-flex tw-text-primary'
                to={{
                  pathname: '/settings',
                  state: { fromContractCreation: true },
                }}
              >
                <PlusCircle size={20} />
                <span className='tw-ms-2 tw-text-sm tw-font-bold'>
                  {t('Add Entity')}
                </span>
              </Link>
            </div>
          )}
        </>
      )}

      <FormSectionHr />

      <FormSectionTitle
        title={t('Contract')}
        subTitle={t('Add contract details')}
      />

      <Row className='mx-n3'>
        <FormCol>
          <ControlledRadioList
            control={control}
            name='employment_term'
            error={errors?.employment_term}
            horizontal
            label={
              <LabelContent required>{t('Employment Term')}:</LabelContent>
            }
            options={employmentTermOptionsDe(t)}
            isFancy
          />
        </FormCol>

        <FormCol>
          <ControlledRadioList
            control={control}
            name='employment_type'
            error={errors?.employment_type}
            horizontal
            label={<LabelContent required>{t('Contract Type')}:</LabelContent>}
            options={employmentTypeOptionsDe(t)}
            isFancy
          />
        </FormCol>
        <FormCol>
          <ControlledDatePicker
            control={control}
            name='start_date'
            transform={{
              output: (value) => {
                setValue('end_date', null)
                return format(value, 'yyyy-MM-dd')
              },
            }}
            error={errors?.start_date}
            label={<LabelContent required>{t('Start date')}:</LabelContent>}
          />
        </FormCol>

        <FormCol>
          {employmentTerm === 'indefinite' ? null : (
            <ControlledDatePicker
              control={control}
              name='end_date'
              minDate={!startDate ? null : addDays(new Date(startDate), 1)}
              error={errors?.end_date}
              placeholder={isDeDefinite ? t('End date') : `(${t('Optional')})`}
              label={
                <LabelContent required={isDeDefinite}>
                  {t('End Date')}:
                </LabelContent>
              }
            />
          )}
        </FormCol>
      </Row>

      <FormSectionHr />

      <FormSectionTitle
        title={t('Employee')}
        subTitle={t('Add your employee’s information')}
      />

      <Row className='mx-n3'>
        <FormCol>
          <ControlledSelect
            control={control}
            name='seniority'
            error={errors?.seniority}
            label={
              <LabelContent required>{t('Seniority level:')}</LabelContent>
            }
            options={seniorities?.map((s) => ({
              label: typeof s === 'string' ? s : s?.name,
              value: typeof s === 'string' ? s : s?.id,
            }))}
          />
        </FormCol>

        <FormCol>
          <ControlledInput
            label={<LabelContent required>{t('Job Title')}:</LabelContent>}
            control={control}
            name='name'
            error={errors?.name}
            placeholder={t('i.e. Sales Manager, Software Engineer')}
            autoComplete={titles}
            watchState={watchingJobTitle}
          />
        </FormCol>

        <FormCol>
          <ControlledInput
            label={
              <LabelContent
                for='employee_identifier'
                required={entity?.jurisdiction?.is_employee_identifier_required}
              >
                {t('Employee ID:')}
              </LabelContent>
            }
            control={control}
            id='employee_identifier'
            name='employee_identifier'
          />
        </FormCol>
        <FormCol>
          <ControlledInput
            label={
              <LabelContent for='visa_number'>
                {t('Visa Number (Optional):')}
              </LabelContent>
            }
            control={control}
            placeholder={`(${t('Optional')})`}
            id='visa_number'
            name='visa_number'
          />
        </FormCol>
        {jurisdictionPlan?.contribution_plans?.length
          ? jurisdictionPlan?.contribution_plans?.map((plan) => (
              <DynamicForm
                key={plan.id}
                control={control}
                fields={plan.configs?.contract_fields?.map((field) => ({
                  ...field,
                  id: `${field.name}-${jurisdictionPlan?.id}-${plan?.id}`,
                }))}
                newUI={false}
                colClassName='!tw-mb-3 !tw-pt-0 !tw-px-4'
                conditions={[]}
                setValue={setValue}
                watch={watch}
                optionalPlaceholder='(Optional)'
              />
            ))
          : null}
      </Row>

      <CreationCustomFields
        control={control}
        errors={errors}
        rules={{ top: true, bottom: false }}
        fields={fields}
        isFieldsLoading={isFieldsLoading}
      />
    </>
  )
}

function PaymentStep({ control, errors, showRecurringExpensesMessage }) {
  const { [fieldKeys.type]: type, employee_type: employeeType } = useWatch({
    control,
  })

  if (type === contractTypes.MILESTONES) {
    return <MilestoneForm control={control} errors={errors} />
  }

  if (employeeType === EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE) {
    return <DePaymentStep control={control} />
  }

  return (
    <DefaultContractForm
      control={control}
      showRecurringExpensesMessage={showRecurringExpensesMessage}
    />
  )
}

function useSelectedCurrency(control) {
  const { currencies } = useSelector((state) => state.Layout?.staticData ?? {})

  const { currency_id: currencyId } = useWatch({ control })

  const currencyOptions = currencies?.map((c) => mapCurrencyToOption(c, 'id'))
  const selectedCurrency = getOptionFromList(currencyOptions, currencyId, 'id')

  return selectedCurrency
}

const deDefaultFrequencyId = 4
function DePaymentStep({ control }) {
  const selectedCurrency = useSelectedCurrency(control)

  const {
    start_date: startDate,
    occurrence_id: occurrenceId,
    [fieldKeys.firstPaymentDate]: firstPaymentDate,
    jurisdiction,
    [fieldKeys.endDate]: endDate,
    [fieldKeys.amount]: amount,
    [allowancesKey]: allowances,
  } = useWatch({ control })

  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  useProRataUpdate({
    amount,
    endDate,
    firstPaymentDate,
    frequencyId: deDefaultFrequencyId,
    occurrenceId,
    proRata: true,
    startDate,
    onComplete: (data) => {
      setValue(fieldKeys.proRataAmount, data?.amount)
    },
    isDirectEmployee: true,
  })

  const totalAllowances = allowances?.reduce(
    (acc, curr) => acc + Number(curr?.amount),
    0,
  )
  const calculatedAmount = Number(amount) + Number(totalAllowances)
  const totalPayment = isNaN(calculatedAmount) ? 0 : calculatedAmount
  const jurisdictionPlan = watch('jurisdiction')

  const filteredDePaymentOptions = deExternalPaymentOptions(t).map(
    (option) => ({
      ...option,
      isDisabled:
        option.value === DE_PAYMENT_PROVIDER.RP_PROVIDER
          ? !jurisdictionPlan?.is_internal_payroll_enabled
          : option.value === DE_PAYMENT_PROVIDER.EXTERNAL_PROVIDER
            ? !jurisdictionPlan?.is_external_payroll_enabled
            : true,
    }),
  )

  useEffect(() => {
    // update (once) the default selected payroll provider value using supported jurisdiction.
    setValue(
      'is_external_payroll_provider',
      jurisdictionPlan?.is_external_payroll_enabled
        ? DE_PAYMENT_PROVIDER.EXTERNAL_PROVIDER
        : DE_PAYMENT_PROVIDER.RP_PROVIDER,
    )
  }, [jurisdictionPlan, setValue])

  // @todo if the next 26th day is before the start date, what should happen? Right now, it throws an error - first payment date can't be before start date.
  useEffect(() => {
    if (firstPaymentDate) return
    // select the next 26th day in the month of the start date, if it's a weekend select the day before
    // if startDate is before today then start date is today
    const startDate = isPast(new Date(watch('start_date')))
      ? new Date()
      : new Date(watch('start_date'))

    let currentMonth = startDate.getMonth()
    // if today is the 26th or later, set the next 26th day to the next month
    if (startDate.getDate() >= 15) {
      currentMonth += 1
    }
    const next26thDay = new Date(startDate.getFullYear(), currentMonth, 26)

    // Check if the 26th day is a weekend
    if (next26thDay.getDay() === 0) {
      // Sunday
      next26thDay.setDate(next26thDay.getDate() - 2) // Set to the previous Friday
    } else if (next26thDay.getDay() === 6) {
      // Saturday
      next26thDay.setDate(next26thDay.getDate() - 1) // Set to the previous Friday
    }

    setValue(fieldKeys.firstPaymentDate, format(next26thDay, 'yyyy-MM-dd'))
  }, [startDate])

  return (
    <>
      <Row className='mx-n3'>
        <FormCol md={12}>
          <ControlledTypeSelect
            control={control}
            name='is_external_payroll_provider'
            error={errors?.is_external_payroll_provider}
            types={filteredDePaymentOptions}
            cols={filteredDePaymentOptions.length}
            horizontal
          />
        </FormCol>
        {jurisdictionPlan?.is_wps_enabled ? (
          <div className='tw-w-full tw-grow tw-px-3'>
            <Alert color='info'>
              {
                // prettier-ignore
                t(
                'The Employee salary will be paid through the UAE WPS (Wage Protection System)'
              )
              }
            </Alert>
          </div>
        ) : null}
      </Row>

      <FormSectionHr />

      <FormSectionTitle title={t('Monthly compensation')} showRequired />

      <Row className='mx-n3'>
        <FormCol md={12}>
          <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-4 sm:tw-flex-row'>
            <div className='tw-w-[370px]'>
              <Input readOnly value={t('Base salary')} />
            </div>

            <div className='tw-grow tw-basis-0'>
              <YearlySalaryField
                control={control}
                currencyOptions={jurisdiction?.currencies?.map((c) =>
                  mapCurrencyToOption(c, 'id'),
                )}
                hideError={false}
                errors={errors}
              />
            </div>
            <div
              className='d-flex align-items-center flex-shrink-0'
              style={{ minWidth: 30 }}
            >
              <button
                type='button'
                className='flex-shrink-0 d-flex border p-2 text-red-60 cursor-not-allowed'
                aria-label='Delete'
                disabled
              >
                <TrashSimple size='20' />
              </button>
            </div>
          </div>

          <DeAllowancesField control={control} />

          <div className='tw-mt-4 tw-flex tw-justify-between tw-border-b tw-border-b-surface-30 tw-bg-surface-10 tw-p-6'>
            <div>{t('Total monthly compensation')}</div>
            <div className='tw-text-base tw-font-bold'>
              {selectedCurrency?.symbol ?? ''} {totalPayment.toLocaleString()}
            </div>
          </div>
        </FormCol>
      </Row>

      <FormSectionHr />

      <Row className='mx-n3'>
        <FormCol>
          <ControlledDatePicker
            label={
              <LabelContent required>{t('First payroll date:')}</LabelContent>
            }
            control={control}
            name={fieldKeys.firstPaymentDate}
            error={errors?.[fieldKeys.firstPaymentDate]}
            placeholder={t('First payroll date')}
            minDate={new Date(startDate)}
            className='rounded-bottom-0'
            showError={false}
          />

          <RelatedFieldContent>
            <DeCycleStartEndText starts={t('the first')} ends={t('last day')} />
          </RelatedFieldContent>

          {!errors?.[fieldKeys.firstPaymentDate] ? null : (
            <InputFeedback className='tw-mt-1'>
              {getInputErrorMessage(errors[fieldKeys.firstPaymentDate])}
            </InputFeedback>
          )}
        </FormCol>

        <FormCol>
          <label htmlFor={fieldKeys.proRataAmount}>
            <LabelContent>{t('First base salary payment:')}</LabelContent>
          </label>

          <InputGroup style={{ flex: 1 }}>
            <ControlledCurrencyInput
              control={control}
              name={fieldKeys.proRataAmount}
              error={errors?.[fieldKeys.proRataAmount]}
              id={fieldKeys.proRataAmount}
              placeholder={t('Amount')}
              hideError={true}
              className='!tw-rounded-e-0 !tw-rounded-b-0'
            />

            <InputCurrencyAddon
              code={selectedCurrency?.code}
              addonType='append'
              textClassName='!tw-rounded-b-0'
            />
          </InputGroup>

          <RelatedFieldContent>
            {t('Specify the first base salary payment.')}
          </RelatedFieldContent>

          {!errors?.[fieldKeys.proRataAmount] ? null : (
            <InputFeedback className='tw-mt-1'>
              {getErrorMessage(errors?.[fieldKeys.proRataAmount])}
            </InputFeedback>
          )}
        </FormCol>
      </Row>
    </>
  )
}

export function DeAllowancesField({
  control,
  allowAddition = true,
  disabled,
  showFlag = true,
}) {
  const selectedCurrency = useSelectedCurrency(control)

  const { fields, append, remove } = useFieldArray({
    control,
    name: allowancesKey,
  })

  const {
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <div className='d-flex flex-column gap-24'>
        {fields.map((item, index) => (
          <div key={item.id} className='d-flex align-items-start gap-16'>
            <div className='tw-w-[370px]'>
              <ControlledInput
                control={control}
                name={`${allowancesKey}.${index}.name`}
                error={errors?.[allowancesKey]?.[index]?.name}
                placeholder={t('e.g. Housing, Transportation')}
                disabled={disabled ?? item?.disabled}
              />
            </div>

            <div className='d-flex flex-column tw-grow'>
              <div className='d-flex gap-16' style={{ flex: 1 }}>
                <InputGroup style={{ flex: 1 }} className='flex-nowrap'>
                  <ControlledCurrencyInput
                    control={control}
                    name={`${allowancesKey}.${index}.amount`}
                    error={errors?.[allowancesKey]?.[index]?.amount}
                    isDisabled={disabled ?? item?.disabled}
                    className='rounded-right-0'
                  />

                  <InputCurrencyAddon
                    code={selectedCurrency?.code}
                    addonType='append'
                    showFlag={showFlag}
                  />
                </InputGroup>

                <div
                  className='d-flex align-items-center flex-shrink-0'
                  style={{ minWidth: 30 }}
                >
                  <button
                    type='button'
                    onClick={() => remove(index)}
                    className={cx(
                      'flex-shrink-0 d-flex border p-2',
                      item?.disableDelete
                        ? 'text-red-60 cursor-not-allowed'
                        : 'hover:bg-red-20 text-red-100',
                    )}
                    aria-label='Delete'
                    disabled={item?.disableDelete}
                  >
                    <TrashSimple size='20' />
                  </button>
                </div>
              </div>
              {!errors?.[allowancesKey]?.[index]?.amount ? null : (
                <InputFeedback className='tw-mt-1'>
                  {getErrorMessage(errors?.[allowancesKey]?.[index]?.amount)}
                </InputFeedback>
              )}
            </div>
          </div>
        ))}
      </div>

      {!allowAddition ? null : (
        <>
          {fields.length <= 0 ? null : (
            <div style={{ height: 'var(--size-24)' }} />
          )}

          <Button
            onClick={() => append({ name: '', amount: 0 })}
            outline
            icon={<PlusCircle size='24' />}
            block
            className='tw-h-[56px] !tw-border-dashed !tw-bg-primary-20 tw-p-4 !tw-text-primary-100 hover:!tw-bg-primary-30'
            alignLeft
          >
            {t('Add Allowance')}
          </Button>
        </>
      )}
    </>
  )
}

function MilestoneForm({ control, errors }) {
  const { currencies } = useSelector((state) => state.Layout?.staticData ?? {})

  const { [fieldKeys.currencyId]: currencyId } = useWatch({
    control,
  })

  const currencyOptions = currencies?.map((c) => mapCurrencyToOption(c, 'id'))
  const selectedCurrency = getOptionFromList(currencyOptions, currencyId, 'id')

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldKeys.milestones,
  })

  return (
    <div className='d-flex flex-column align-items-start gap-24'>
      <div style={{ maxWidth: 320 }} className='w-100'>
        <ControlledSelect
          name='currency_id'
          control={control}
          error={errors?.currency_id}
          options={currencyOptions}
        />
      </div>

      <div className='w-100 d-flex flex-column gap-14'>
        {fields.map((item, index) => {
          return (
            <div key={item.id} className='d-flex gap-24'>
              <div className='flex-grow-1'>
                <ControlledInput
                  control={control}
                  name={`${fieldKeys.milestones}.${index}.name`}
                  placeholder={t('Milestone name')}
                />
              </div>

              <ControlledCurrencyInput
                control={control}
                name={`${fieldKeys.milestones}.${index}.amount`}
                prefix={
                  selectedCurrency?.symbol ? `${selectedCurrency?.symbol} ` : ''
                }
                className='w-50'
              />

              <div style={{ minWidth: 30 }}>
                <button
                  type='button'
                  onClick={() => remove(index)}
                  className='rp-btn-nostyle text-danger bg-slate-50 d-flex p-1.5'
                  aria-label='Delete'
                >
                  <Trash size={20} weight='bold' />
                </button>
              </div>
            </div>
          )
        })}
      </div>

      <Button onClick={() => append({ name: '', amount: 0 })} size='sm' outline>
        {t('Add milestone')}
      </Button>
    </div>
  )
}

export function useProRataUpdate({
  amount,
  endDate,
  firstPaymentDate,
  frequencyId,
  occurrenceId,
  proRata,
  startDate,
  onComplete,
  daysType,
  workWeek,
  isDirectEmployee,
}) {
  const {
    startFetch: getDefaultProrata,
    isLoading,
    data,
  } = useFetch({
    action: isDirectEmployee ? updateProrata : getProrataData,
    onComplete,
    onError: toastr.error,
  })

  // Recalculate prorata amount when data changes => amount, start date, frequency, occurrence, end date or First payroll date
  const [debouncedAmount] = useDebounceValue(amount, 500)
  useEffect(() => {
    const haveDaysData =
      daysType === PRORATA_CALCULATION_TYPE.CALENDAR_DAYS || !!workWeek

    if (
      proRata &&
      debouncedAmount &&
      !!firstPaymentDate &&
      (isDirectEmployee || haveDaysData)
    ) {
      getDefaultProrata({
        amount: debouncedAmount,
        [fieldKeys.firstPaymentDate]: firstPaymentDate,
        frequency_id: frequencyId,
        start_date: startDate,
        occurrence_id: occurrenceId,
        end_date: endDate ?? null,
        days_type: daysType,
        work_week_type: workWeek,
      })
    }
  }, [
    debouncedAmount,
    endDate,
    firstPaymentDate,
    frequencyId,
    occurrenceId,
    proRata,
    startDate,
    workWeek,
    daysType,
  ])

  return { isLoading, data }
}

function DefaultContractForm({ control, showRecurringExpensesMessage }) {
  const { currencies, rates, frequencies } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )

  const {
    setValue,
    formState: { errors },
  } = useFormContext()

  const {
    [fieldKeys.type]: type,
    [fieldKeys.startDate]: startDate,
    [fieldKeys.currencyId]: currencyId,
    [fieldKeys.frequencyId]: frequencyId,
    [fieldKeys.occurrenceId]: occurrenceId,
    is_cor: isCor,
  } = useWatch({ control })

  const currencyOptions = currencies?.map((c) => mapCurrencyToOption(c, 'id'))
  const selectedCurrency = getOptionFromList(currencyOptions, currencyId, 'id')

  const { data: occurrencesData } = useFetch(
    {
      action: getOccurrences,
      autoFetch: !!startDate,
      body: { start_date: startDate },
    },
    [startDate],
  )

  const {
    startFetch: fetchCycleText,
    isLoading: fetchingCycleText,
    data: cycleText,
  } = useFetch({ action: getCycleText })

  const amountCurrencyError = [
    errors?.amount?.message,
    errors?.currency_id?.message,
  ]
    .filter(Boolean)
    .join(', ')

  return (
    <Row className='mx-0 py-2'>
      {!showRecurringExpensesMessage ? null : (
        <FormCol md={12} noMargin>
          <Alert color='info'>
            {
              // prettier-ignore
              t(
              'If the currency is changed, this contract’s active recurring expenses will be deactivated.'
            )
            }
          </Alert>
        </FormCol>
      )}

      <FormCol>
        <ControlledDatePicker
          control={control}
          name='start_date'
          error={errors?.start_date}
          placeholder={t('Start date')}
          label={<LabelContent required>{t('Start date')}:</LabelContent>}
          minDate={isCor ? new Date() : null}
        />
      </FormCol>

      <FormCol>
        <ControlledDatePicker
          control={control}
          name='end_date'
          error={errors?.end_date}
          placeholder={t('(Optional)')}
          label={<LabelContent>{t('End Date:')}</LabelContent>}
          minDate={!startDate ? null : addDays(new Date(startDate), 1)}
        />
      </FormCol>

      <FormCol>
        <label htmlFor='amount'>
          <LabelContent required>{t('Amount')}:</LabelContent>
        </label>

        <InputGroup>
          <ControlledCurrencyInput
            control={control}
            name='amount'
            id='amount'
            placeholder={t('Amount')}
            className='rounded-right-0 border-right-0'
            hideError
          />

          <ControlledSelect
            className='input-group-text p-0 border-0 rounded-left-0'
            leftRounded
            placeholder={t('Currency')}
            name='currency_id'
            control={control}
            error={errors.currency_id}
            classNamePrefix='RS-Addon'
            options={currencyOptions}
            customComponents={{
              Option: customOption,
              Value: customSingleValue,
              SingleValue: customSingleValue,
            }}
            showError={false}
          />
        </InputGroup>

        {!amountCurrencyError ? null : (
          <InputFeedback className='tw-mt-1'>
            {amountCurrencyError}
          </InputFeedback>
        )}
      </FormCol>

      {type !== contractTypes.PAYG ? null : (
        <FormCol>
          <ControlledSelect
            label={<LabelContent required>{t('Unit (of work):')}</LabelContent>}
            name='rate_id'
            error={errors?.rate_id}
            control={control}
            isSearchable={false}
            options={rates?.map((e) => ({ value: e.id, label: e.name }))}
          />
        </FormCol>
      )}

      <FormCol>
        <ControlledSelect
          label={
            <LabelContent required>{t('Frequency of payments')}:</LabelContent>
          }
          control={control}
          name='frequency_id'
          error={errors?.frequency_id}
          isSearchable={false}
          options={frequencies?.map(mapFrequencyToOption)}
          transform={{
            output: (value) => {
              setValue(fieldKeys.occurrenceId, null)
              setValue(fieldKeys.firstPaymentDate, null)
              return value
            },
          }}
        />
      </FormCol>

      {!frequencyId ? null : (
        <FormCol>
          <ControlledSelect
            label={
              <LabelContent required>
                {t('When do you want to process payments?')}
              </LabelContent>
            }
            control={control}
            name='occurrence_id'
            error={errors?.occurrence_id}
            isSearchable={false}
            options={occurrencesData
              ?.filter((o) => o.frequency_id === frequencyId)
              ?.map((e) => ({ value: e.id, label: e.name }))}
            transform={{
              output: (value) => {
                setValue(fieldKeys.firstPaymentDate, null)
                return value
              },
            }}
          />
        </FormCol>
      )}

      {!occurrenceId ? null : (
        <FormCol>
          <ControlledSelect
            label={
              <LabelContent required>
                {t('Date for the first payment')}:
              </LabelContent>
            }
            control={control}
            name={fieldKeys.firstPaymentDate}
            error={errors?.[fieldKeys.firstPaymentDate]}
            isSearchable={false}
            options={occurrencesData?.find((o) => o.id === occurrenceId)?.dates}
            transform={{
              output: (value) => {
                fetchCycleText({ [fieldKeys.firstPaymentDate]: value.value })
                return value
              },
            }}
          />
        </FormCol>
      )}

      <PaymentCycle
        cycleText={cycleText}
        fetchingCycleText={fetchingCycleText}
      />

      <ProrataFields selectedCurrency={selectedCurrency} control={control} />
    </Row>
  )
}

export function ProrataFields({ selectedCurrency }) {
  const {
    [fieldKeys.type]: type,
    [fieldKeys.startDate]: startDate,
    [fieldKeys.endDate]: endDate,
    [fieldKeys.frequencyId]: frequencyId,
    [fieldKeys.occurrenceId]: occurrenceId,
    [fieldKeys.firstPaymentDate]: firstPaymentDate,
    [fieldKeys.proRata]: proRata,
    [fieldKeys.amount]: amount,
  } = useWatch()

  const hideProRata =
    !firstPaymentDate ||
    ![contractTypes.FIXED, contractTypes.FULL_TIME].includes(type)

  const { setValue, getValues, register } = useFormContext()

  const prorataValues = useProrataEdit()
  const {
    prorataDays,
    setProrataDays,
    workDays,
    setWorkDays,
    showProrataEdit,
    setShowProrataEdit,
    setProrataTab,
  } = prorataValues

  const { isLoading: fetchingProrata, data: prorataData } = useProRataUpdate({
    amount,
    endDate,
    firstPaymentDate,
    frequencyId,
    occurrenceId,
    proRata,
    startDate,
    daysType: prorataDays,
    workWeek: workDays,
    onComplete: (data) => {
      if (showProrataEdit) {
        // If we have the edit open, we don't want to update the prorata amount
        return
      }
      // If the prorata amount is greater than the amount, set the default amount to the amount
      const defaultAmount = Math.min(amount, data?.pro_rata_amount)

      if (Number(data?.pro_rata_amount) > Number(amount)) {
        setProrataTab(PRORATA_TYPE.FULL_AMOUNT)
      } else {
        setProrataTab(PRORATA_TYPE.CALCULATED)
      }

      setValue(fieldKeys.proRataAmount, defaultAmount)
    },
  })

  if (hideProRata) {
    return null
  }

  return (
    <FormCol>
      <ProrataStateProvider
        value={{
          ...prorataValues,
          gettingProrataData: fetchingProrata,
          prorataData,

          amount: getValues(fieldKeys.amount),
          [fieldKeys.proRataAmount]: getValues(fieldKeys.proRataAmount),

          firstPaymentProrata: getValues(fieldKeys.proRata),
          setFirstPaymentProrata: (event) =>
            setValue(fieldKeys.proRata, event.target.checked),
        }}
      >
        {/* We need to send the value of the prorataType field to the backend
            we rely on the hidden field to register and set a default value
            We update the value when the user changes the selection on BottomSection component */}
        <input
          type='hidden'
          {...register(fieldKeys.prorataType, {
            value: WORKING_DAYS.MONDAY_FRIDAY,
          })}
        />

        <ProrataSettings
          currency={selectedCurrency}
          dates={{ startDate, firstPaymentDate }}
          calculationType={{
            value: prorataDays,
            onChange: (val) => {
              setProrataDays(val)
              if (val === PRORATA_CALCULATION_TYPE.CALENDAR_DAYS) {
                setWorkDays(null)
                setValue(fieldKeys.prorataType, val)
              } else {
                setWorkDays(WORKING_DAYS.MONDAY_FRIDAY)
                setValue(fieldKeys.prorataType, null)
              }
            },
          }}
          workDays={{
            value: workDays,
            onChange: (val) => {
              setWorkDays(val)
              setValue(fieldKeys.prorataType, val)
            },
          }}
          onSave={(amount) => {
            setValue(fieldKeys.proRataAmount, amount)
            setShowProrataEdit(false)
          }}
          onEditClick={() => setShowProrataEdit(true)}
          onEditClose={() => setShowProrataEdit(false)}
          showEdit={showProrataEdit}
        />
      </ProrataStateProvider>
    </FormCol>
  )
}

export function PaymentCycle({ fetchingCycleText, cycleText }) {
  const {
    [fieldKeys.type]: type,
    [fieldKeys.frequencyId]: frequencyId,
    [fieldKeys.firstPaymentDate]: firstPaymentDate,
    is_monthly_cycle: isMonthlyCycle,
  } = useWatch()

  const hideProRata =
    !firstPaymentDate ||
    ![contractTypes.FIXED, contractTypes.FULL_TIME].includes(type)

  const isFrequencyMonthly = frequencyId === 4

  if (hideProRata || !isFrequencyMonthly) {
    return null
  }

  return (
    <FormCol>
      <RelatedFieldContent className='tw-rounded-t tw-border-t tw-bg-transparent tw-p-0'>
        <div className='tw-p-4'>
          <h5 className='tw-text-sm tw-font-bold'>{t('Payment cycle')}</h5>

          <div className='tw-min-h-[32px] tw-text-xs'>
            {isMonthlyCycle ? (
              <CycleStartEndText starts='the first' ends='last day' />
            ) : fetchingCycleText ? (
              <Shimmer
                width='100%'
                height='32px'
                backgroundColor='var(--slate-100)'
              />
            ) : (
              <p
                className='tw-mb-0'
                dangerouslySetInnerHTML={{
                  __html: cycleText?.default_period,
                }}
              />
            )}
          </div>
        </div>

        <ControlledCheckbox
          id='is_monthly_cycle'
          name='is_monthly_cycle'
          leftLabel={
            <LabelContent>
              {t('Reset to 1st to the end of every month')}
            </LabelContent>
          }
          containerClassName='tw-p-4 tw-border-t tw-border-surface-30'
        />
      </RelatedFieldContent>
    </FormCol>
  )
}

function ComplianceStep({ control, t }) {
  const { contractor_types: contractorTypes } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )

  const { data: templates } = useFetch({
    action: getTemplateList,
    autoFetch: true,
  })

  const {
    contract_doc_type: contractDocType,
    employee_type: employeeType,
    is_cor: isCor,
  } = useWatch({ control })

  const {
    setValue,
    formState: { errors },
  } = useFormContext()

  const types = useMemo(() => {
    const types = [
      ...defaultContractTemplatesTypes(t),
      templates?.length > 0 ? customContractTemplatesType(t) : null,
    ]
      .filter(Boolean)
      .map((type) => {
        const isDisabled =
          isCor &&
          [
            CONTRACT_DOC_TYPE.CUSTOM,
            CONTRACT_DOC_TYPE.CUSTOM_TEMPLATE,
          ].includes(type?.value)

        return { ...type, isDisabled }
      })

    return types
  }, [isCor, templates?.length])

  if (employeeType === EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE) {
    return <DeComplianceStep control={control} t={t} />
  }

  return (
    <Row className='mx-n2'>
      <FormCol md={12}>
        <ControlledTypeSelect
          control={control}
          name='contract_doc_type'
          error={errors?.contract_doc_type}
          types={types}
          cols={types.length}
          horizontal
        />
      </FormCol>

      {!contractDocType ||
      contractDocType === CONTRACT_DOC_TYPE.RP_TEMPLATE ? null : (
        <FormCol md={12}>
          {contractDocType === CONTRACT_DOC_TYPE.CUSTOM ? (
            <UploadCustomFile
              control={control}
              name='file'
              error={errors?.file}
              onFileSet={() => {
                // template_id & file are mutually exclusive
                setValue('template_id', null)
              }}
            />
          ) : contractDocType === CONTRACT_DOC_TYPE.CUSTOM_TEMPLATE ? (
            <ControlledSelect
              label={t('Select a template:')}
              control={control}
              name='template_id'
              options={templates?.map((t) => ({
                value: t?.id,
                label: t?.name,
              }))}
              transform={{
                output: (value) => {
                  // template_id & file are mutually exclusive
                  setValue('file', null)
                  return value
                },
              }}
            />
          ) : null}
        </FormCol>
      )}

      <FormCol>
        <label htmlFor='notice_period'>
          <LabelContent required>
            {t('Notice period (days) to terminate the contract')}:
          </LabelContent>
        </label>
        <InputGroup>
          <ControlledInput
            control={control}
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault()
              }
            }}
            wrapperClassName='flex-grow-1'
            className='rounded-right-0'
            name='notice_period'
            id='notice_period'
          />

          <InputGroupAddon addonType='append'>
            <InputGroupText className='bg-transparent text-muted !tw-capitalize'>
              {t('days')}
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </FormCol>

      <FormCol>
        <ControlledSelect
          control={control}
          name='contractor_label_id'
          label={<ContractorLabel />}
          options={contractorTypes?.map((e) => ({
            value: e.id,
            label: e.name,
          }))}
        />
      </FormCol>
    </Row>
  )
}

function DeComplianceStep({ control, t }) {
  const {
    formState: { errors },
  } = useFormContext()
  return (
    <>
      <UploadCustomFile control={control} name='file' error={errors?.file} />
      <Alert color='info' className='tw-mt-4'>
        {t(
          'You can upload the contract later in the contract page after it’s created.',
        )}
      </Alert>
      <FormSectionHr />

      <FormSectionTitle title={t('Terms')} />

      <Row className='mx-n3'>
        <FormCol>
          <ProbationPeriodField control={control} errors={errors} />
        </FormCol>

        <FormCol>
          <NoticePeriodField control={control} errors={errors} />
        </FormCol>
      </Row>
    </>
  )
}

export function ContractorLabel() {
  return (
    <LabelContent
      tooltipId='contractorLabel'
      tooltipText={
        // prettier-ignore
        t(
        'By default this label is set to ‘Contractor’. You can change it to; Consultant, Freelancer, Expert, Service provider…etc. If you change this label, the change will be reflected on the Agreement as well as the Contract details. *Avoid using Employee as a label, if you are using our contract template.'
      )
      }
    >
      {t('Contractor Label')}
    </LabelContent>
  )
}

function UploadCustomFile({ control, name, onFileSet, error }) {
  const [contractFile, setContractFile] = useState(null)

  const {
    field: { value },
  } = useController({ control, name })
  const { setValue } = useFormContext()

  const { startFetch: uploadContractDoc, isLoading: uploadingContractDoc } =
    useFetch({
      action: uploadContractFileV2,
      onComplete: (data) => {
        setValue(name, data.path)
      },
      onError: (error) => {
        setContractFile(null)
        toastr.error(
          typeof error === 'string'
            ? error
            : typeof error?.message === 'string'
              ? error.message
              : t('Something went wrong while uploading the contract document'),
        )
      },
    })

  const fileName = useWatch({ control, name: fileTempName })

  function handleUploadCustomContract(files) {
    const file = files[0]
    setContractFile(file)
    setValue(fileTempName, file.name)
    onFileSet?.()
    uploadContractDoc({ file })
  }

  function handleRemoveFile(e) {
    e.preventDefault()
    e.stopPropagation()
    setContractFile(null)
    setValue(name, null)
    setValue(fileTempName, null)
  }

  return (
    <div>
      <DropzoneInput
        name={name}
        onDropAccepted={handleUploadCustomContract}
        className={cn(
          'tw-flex tw-w-full tw-cursor-pointer tw-justify-between tw-p-6',
          {
            'tw-items-center tw-rounded tw-border tw-border-surface-30 tw-bg-systemGreen-10':
              contractFile || value,
            'tw-rounded-lg tw-bg-primary-20 tw-outline-dashed tw-outline-primary':
              !contractFile && !value,
          },
        )}
        maxSize={FILE_SIZE_LIMITS_IN_BYTES.FIVE_MB}
        accept={{ 'application/pdf': ['.pdf'] }}
        error={error}
        unstyled
      >
        {contractFile || value ? (
          <div className='tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-3'>
            {uploadingContractDoc ? (
              <Loader type='border' minHeight={null} />
            ) : (
              <span className='tw-flex tw-flex-col'>
                <div className='tw-text-base tw-font-bold tw-text-black'>
                  {fileName}
                </div>
                <span className='tw-flex tw-items-center tw-text-systemGreen'>
                  <CheckCircle size={16} />
                  {t('Uploaded successfully')}!
                </span>
              </span>
            )}

            {!uploadingContractDoc && (
              <button type='button' onClick={handleRemoveFile}>
                <X size={20} className='tw-fill-black' />
              </button>
            )}
          </div>
        ) : (
          <>
            <span>
              <div className='tw-mb-1 tw-text-base tw-font-bold tw-text-black'>
                {t('Contract')}
              </div>
              <span className='tw-text-sm tw-font-normal tw-text-text-80'>
                {t('Upload a valid contract')}
              </span>
            </span>

            <span className='tw-flex tw-flex-col tw-items-end'>
              <span className='tw-mb-1 tw-flex tw-text-primary'>
                <FilePlus className='tw-me-1' size='20px' />
                <span className='tw-text-sm tw-font-bold tw-capitalize'>
                  {t('Drop files here or click to upload')}
                </span>
              </span>
              <span className='tw-text-secondary-80'>
                {t('*PDF only. Max file size 5MB')}
              </span>
            </span>
          </>
        )}
      </DropzoneInput>
    </div>
  )
}

function InputCurrencyAddon({
  code,
  textClassName,
  addonType = 'prepend',
  showFlag = true,
}) {
  return (
    <InputGroupAddon addonType={addonType}>
      <InputGroupText
        style={{ minWidth: showFlag ? 80 : 52 }}
        className={textClassName}
      >
        {!showFlag ? null : <FlagIcon className='!tw-me-2' code={code} />}
        {code}
      </InputGroupText>
    </InputGroupAddon>
  )
}

export function CycleStartEndText({ starts, ends }) {
  return (
    <p className='tw-mb-0'>
      {!starts || !ends ? (
        <>
          {t(
            'The payment cycle depends on the first payroll date. Please select a valid date.',
          )}
        </>
      ) : (
        <>
          {t('Starts on')}{' '}
          <span className='text-primary rp-font-bold'>{starts}</span>{' '}
          {t('of every month and completes on the')}{' '}
          <span className='text-primary rp-font-bold'>{ends}</span>
          {t('on the following month.')}
        </>
      )}
    </p>
  )
}

function DeCycleStartEndText({ starts, ends }) {
  return (
    <p className='mb-0'>
      {!starts || !ends ? (
        <>
          {
            // prettier-ignore
            t(
            'The payment cycle depends on the first payroll date. Please select a valid date.'
          )
          }
        </>
      ) : (
        <>
          {t('Payslips will cover the cycle from')}{' '}
          <span className='text-primary rp-font-bold'>{starts}</span>{' '}
          {t('to the')}{' '}
          <span className='text-primary rp-font-bold'>{ends}</span>{' '}
          {t('of the same month.')}
        </>
      )}
    </p>
  )
}

function PermitNeeded({
  options,
  name,
  onChange,
  value: globalValue,
  isDisabled,
  error,
}) {
  return (
    <CustomRadio
      isDisabled={isDisabled}
      name={name}
      onChange={onChange}
      options={options}
      value={globalValue}
      className='tw-items-center tw-justify-center'
      hasError={getInputErrorMessage(error) && error !== undefined}
    >
      {({ label }) => <p className={cn('tw-mb-0 tw-font-semibold')}>{label}</p>}
    </CustomRadio>
  )
}

function ControlledOption({
  control,
  defaultValue,
  name,
  error,
  disabled,
  options,
  rules,
  ...otherProps
}) {
  return (
    <Controller
      control={control}
      error={error}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value, name } }) => {
        return (
          <PermitNeeded
            options={options}
            value={value}
            error={error}
            onChange={onChange}
            isDisabled={disabled}
            name={name}
            {...otherProps}
          />
        )
      }}
    />
  )
}

export function buildPaymentFieldsSchema(t) {
  return {
    frequency_id: yup.string().when(['contract_type', 'type'], {
      is: isNotMilestone,
      then: (schema) => schema.required(t('Frequency is required')),
      otherwise: (schema) => schema.nullable(),
    }),

    occurrence_id: yup.string().when(['contract_type', 'frequency_id'], {
      is: (contractType, frequencyId) => {
        return contractType === CONTRACT_CATEGORY.CONTRACTOR && !!frequencyId
      },
      then: (schema) =>
        schema
          .typeError(t('Occurrence is required'))
          .required(t('Occurrence is required')),
      otherwise: (schema) => schema.nullable(),
    }),

    [fieldKeys.firstPaymentDate]: yup
      .date()
      .when(['contract_type', 'occurrence_id', 'employee_type'], {
        is: (contractType, occurrenceId, employeeType) => {
          return (
            isDe(employeeType) ||
            (contractType === CONTRACT_CATEGORY.CONTRACTOR && !!occurrenceId)
          )
        },
        then: (schema) =>
          schema
            .typeError(t('First payroll date is required'))
            .required(t('First payroll date is required')),
        otherwise: (schema) => schema.nullable(),
      }),

    [fieldKeys.proRata]: yup
      .boolean()
      .when(
        ['contract_type', 'type', 'employee_type', fieldKeys.firstPaymentDate],
        {
          is: (contractType, type, employeeType, firstPaymentDate) => {
            if (isDe(employeeType)) {
              return !!firstPaymentDate
            }
            return (
              contractType === CONTRACT_CATEGORY.CONTRACTOR &&
              [contractTypes.FIXED, contractTypes.FULL_TIME].includes(type)
            )
          },
          then: (schema) =>
            schema
              .typeError(t('Pro rata is required'))
              .required(t('Pro rata is required')),
          otherwise: (schema) => schema.nullable(),
        },
      ),

    [fieldKeys.proRataAmount]: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(0, t('Amount must be a positive number'))
      .max(999999999999.99)
      .label(t('Amount'))
      .when(
        [
          'contract_type',
          'type',
          fieldKeys.firstPaymentDate,
          fieldKeys.proRata,
          'employee_type',
        ],

        {
          is: (contractType, type, firstPaymentDate, proRata, employeeType) => {
            if (isDe(employeeType)) {
              return !!firstPaymentDate && proRata === true
            }
            return (
              contractType === CONTRACT_CATEGORY.CONTRACTOR &&
              [contractTypes.FIXED, contractTypes.FULL_TIME].includes(type) &&
              !!firstPaymentDate &&
              proRata === true
            )
          },
          then: (schema) =>
            schema
              .typeError(t('Amount is required'))
              .required(t('Amount is required')),
          otherwise: (schema) => schema.notRequired(),
        },
      )
      .when(
        ...whenDe((schema) =>
          schema.test('is-more-than-prorata', (amount, context) => {
            const {
              [fieldKeys.startDate]: startDate,
              [fieldKeys.firstPaymentDate]: firstPaymentDate,
              [fieldKeys.amount]: monthlySalary,
            } = context.parent

            const minProratedAmount = calculateMinProRataAmount(
              parseFloat(monthlySalary),
              startDate,
              firstPaymentDate,
            )

            return (
              amount >= minProratedAmount ||
              context.createError({
                message: `${t('First payment should be greater than the employee’s monthly prorated salary')}: ${minProratedAmount.toFixed(2)}`,
              })
            )
          }),
        ),
      ),
  }
}
