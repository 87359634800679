import { CONTRACT_TYPES } from '../../../../core/config/contract-types'
import {
  CONTRACT_CATEGORY,
  EMPLOYEE_CONTRACT_TYPE,
  contractTypes,
} from '../../../../helpers/enum'

const EMPLOYEE_MAP = {
  [CONTRACT_TYPES.DIRECT_EMPLOYEE]: EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE,
  [CONTRACT_TYPES.FULL_TIME]: EMPLOYEE_CONTRACT_TYPE.EOR,
}
const CONTRACTOR_MAP = {
  [CONTRACT_TYPES.FIXED]: contractTypes.FIXED,
  [CONTRACT_TYPES.MILESTONES]: contractTypes.MILESTONES,
  [CONTRACT_TYPES.PAY_AS_YOU_GO]: contractTypes.PAYG,
}

export function getContractTypeFields(contractType) {
  const employeeTypes = [
    CONTRACT_TYPES.DIRECT_EMPLOYEE,
    CONTRACT_TYPES.FULL_TIME,
  ]

  const contractorTypes = [
    CONTRACT_TYPES.FIXED,
    CONTRACT_TYPES.MILESTONES,
    CONTRACT_TYPES.PAY_AS_YOU_GO,
  ]

  if (employeeTypes.includes(contractType)) {
    return {
      contract_type: CONTRACT_CATEGORY.EMPLOYEE,
      employee_type: EMPLOYEE_MAP[contractType],
      type: null,
    }
  } else if (contractorTypes.includes(contractType)) {
    return {
      contract_type: CONTRACT_CATEGORY.CONTRACTOR,
      employee_type: null,
      type: CONTRACTOR_MAP[contractType],
    }
  }

  return {}
}
