import { t } from 'i18next'
import React from 'react'
import { useWatch } from 'react-hook-form'

import Shimmer from '../../../components/ui/shimmer'
import { CONTRACT_CATEGORY } from '../../../helpers/enum'
import { LoadingItems } from '../ContractPage/payment-approvals'
import { AttributeFields } from '../components/submit-work-modal'
import {
  FormSectionHr,
  FormSectionTitle,
} from './components/form-section-title'

export function CreationCustomFields({
  control,
  errors,
  rules = { top: true, bottom: true },
  fields,
  isFieldsLoading,
}) {
  const formContractorType = useWatch({ control, name: 'type' })
  const formEmployeeType = useWatch({ control, name: 'employee_type' })
  const formContractType = useWatch({ control, name: 'contract_type' })

  const contractTypeKey =
    formContractType === CONTRACT_CATEGORY.EMPLOYEE
      ? formEmployeeType
      : formContractorType

  if (!contractTypeKey || fields.length <= 0 || isFieldsLoading) {
    return null
  }

  return (
    <>
      {!rules?.top ? null : <FormSectionHr />}

      <FormSectionTitle title={t('Custom Fields')} />

      <div className='tw-grid tw-gap-6 md:tw-grid-cols-2'>
        {isFieldsLoading ? (
          <LoadingItems
            length={2}
            noParent
            item={() => {
              return (
                <div className='tw-flex tw-flex-col tw-gap-2'>
                  <Shimmer className='!tw-h-[18px] tw-rounded' />
                  <Shimmer className='!tw-h-[42px] !tw-w-full tw-rounded' />
                </div>
              )
            }}
          />
        ) : fields.length <= 0 ? (
          <div className='tw-h-[68px] tw-text-sm tw-text-text-80'>
            {t('No fields for this contract type.')}
          </div>
        ) : (
          <AttributeFields fields={fields} control={control} errors={errors} />
        )}
      </div>

      {!rules?.bottom ? null : <FormSectionHr />}
    </>
  )
}
