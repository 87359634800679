import React, { useState } from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import cx from 'classnames'

export function CustomDropDown({
  title,
  options,
  containerClassName = '',
  triggerClassName = '',
  isDisabled = false,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const toggle = () => setIsDropdownOpen(!isDropdownOpen)

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      disabled={isDisabled}
      toggle={toggle}
      className={cx(containerClassName, isDisabled ? 'disabled' : '')}
    >
      <DropdownToggle
        color='link'
        disabled={isDisabled}
        className={cx('p-2', triggerClassName)}
      >
        {title}
      </DropdownToggle>

      <DropdownMenu right>
        {options.map((e, i) => {
          return (
            <DropdownItem key={`drop-${i}`} onClick={e.onClick}>
              {e.label}
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}
