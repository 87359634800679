import { t } from 'i18next'
import { format } from 'date-fns'
import React, { useMemo } from 'react'
import { Badge, Card } from 'reactstrap'

import { StyledP } from '../../../components/Typo'
import DataTable from '../../../components/ui/data-table'
import Shimmer from '../../../components/ui/shimmer'
import { useResize } from '../../../helpers/hooks'
import { SectionHeading } from '../../remotepass-cards/components/section-heading'
import EmailInvites from './../../../assets/images/email_invites.svg'

const getStatusColor = (status) => {
  switch (status) {
    case 'Awarded':
      return 'success'
    case 'Expired':
      return 'danger'
    case 'Pending':
    case 'Invited':
      return 'warning'
    default:
      return 'primary'
  }
}
const Refers = ({ referrals }) => {
  const isMobile = useResize()

  const columns = useMemo(() => {
    return [
      { Header: t('Your referral'), accessor: 'email' },
      {
        Header: t('Date'),
        accessor: 'date',
        Cell: ({ cellData: date }) => {
          return (
            <p className='mb-0'>{format(new Date(date), 'MM/dd/yyyy HH:mm')}</p>
          )
        },
      },
      {
        Header: t('Status'),
        accessor: 'status',
        Cell: ({ cellData: status }) => {
          return (
            <Badge
              className={`py-1 font-size-12 rounded bg-soft-${getStatusColor(
                status,
              )} my-2`}
              pill
            >
              <p className={`mb-0 font-size-14 text-${getStatusColor(status)}`}>
                {t(status)}
              </p>
            </Badge>
          )
        },
      },
    ]
  }, [])
  return (
    <Card className='mb-0 p-4 p-md-0 rp-shadow-2'>
      <div
        className='p-md-3 pb-3 d-flex justify-content-between align-items-center flex-wrap'
        style={{ gap: '0.75rem' }}
      >
        <SectionHeading className='mb-0'>{t('Invites')}</SectionHeading>
      </div>
      {!referrals?.data ||
      !Array.isArray(referrals?.data) ||
      referrals?.data?.length <= 0 ? (
        <div
          style={{ minHeight: 'max(30vh, 340px)' }}
          className='align-items-center d-flex flex-column justify-content-center p-4 text-center'
        >
          {referrals.isLoading ? (
            <div className='p-3' style={{ '--s-height': '64px' }}>
              <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
              <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
              <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
              <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
            </div>
          ) : (
            <>
              <img src={EmailInvites} alt='' className='pb-4' />
              <h4 className='font-size-16 text-gray-600'>
                {t('No one has been invited yet')}
              </h4>
            </>
          )}
        </div>
      ) : isMobile ? (
        referrals?.data?.map((order, index) => (
          <RefersCard order={order} key={index} />
        ))
      ) : (
        <DataTable responsive columns={columns} data={referrals?.data} />
      )}
    </Card>
  )
}
const RefersCard = ({ order }) => {
  return (
    <div
      className='py-0 mb-3'
      style={{
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 0px #DFE1E6',
        borderRadius: 4,
        border: '1px solid #E7E8F2',
        position: 'relative',
      }}
    >
      <div className='p-3 m-0 border-bottom'>
        <p
          style={{ fontWeight: 'bold' }}
          className='text-dark font-size-14 mb-0'
        >
          {t('Your referral')}
        </p>
        <p style={{ fontWeight: '400' }} className='font-size-14 mb-0'>
          {order?.email}
        </p>
      </div>
      <div className='p-3'>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {t('Date')}
          </h6>
          <h6 className='font-weight-normal mb-0 text-dark font-size-14'>
            {order?.date}
          </h6>
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {t('Status')}
          </h6>
          <Badge
            className={`font-size-14 rounded bg-soft-${getStatusColor(
              order?.status,
            )}`}
            color='white'
            pill
          >
            <StyledP
              style={{ padding: 4 }}
              min='14px'
              max='14px'
              className={`mb-0 text-${getStatusColor(order?.status)}`}
            >
              {t(order?.status)}
            </StyledP>
          </Badge>
        </div>
      </div>
    </div>
  )
}
export default Refers
