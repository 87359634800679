import React, { useCallback, useMemo, useState } from 'react'
import {
  MagnifyingGlass,
  Paperclip,
  PaperPlaneTilt,
} from '@phosphor-icons/react'
import { Avatar, cn } from 'ui'
import Head from '../../../../components/head'
import PageHeading from '../../../../components/ui/page-heading'
import { Card, UncontrolledTooltip } from 'reactstrap'
import Button from '../../../../components/ui/button'
import SearchBar from '../../../../components/SearchBar'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import DataTable from '../../../../components/ui/data-table'
import Pagination from '../../../../components/ui/pagination'
import { assetsStorage } from '../../../../helpers/config'
import { ExpandedDocumentRow } from './expanded-document-row'
import { RequestDocumentModal } from '../request-document-modal'
import { useFetch } from '../../../../helpers/hooks'
import { getClientDocs } from '../../../../services/api'
import Loader from '../../../../components/ui/loader'
import TabEmpty from '../../../Contract/components/tab/tab-empty'
import { t } from 'i18next'
import { documentStatuses, workerStatuses, workerTypes } from './consts'
import { BE_CONTRACT_CATEGORY, CONTRACT_STATUS } from '../../../../helpers/enum'
import toastr from 'toastr'
import { BackgroundCheckRow } from './background-check-row'
import { Link } from 'react-router-dom'
import { getFullName } from '../../../../utils/get-full-name'
import { KycStatusRow } from './kyc-status-row'
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import upperCase from 'lodash/upperCase'

export function ClientGlobalDocuments() {
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState({})
  const isFiltersEmpty = useMemo(
    () => isEmpty(omitBy(filters, isNil)),
    [filters],
  )
  const [requestDocumentModal, setRequestDocumentModal] = useState(false)
  const {
    isLoading,
    data: documents,
    startFetch: getDocuments,
    paginator,
  } = useFetch(
    {
      action: getClientDocs,
      body: {
        params: {
          search_key: filters?.name,
          document_status: filters?.documentStatus?.value,
          worker_type: filters?.workerType?.value,
          worker_status: filters?.workerStatus?.value,
          page,
        },
      },
      autoFetch: true,
      onError: (err) => toastr.error(err),
    },
    [page, filters],
  )
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        Cell: ({ rowData: { contractor, ref } }) => {
          const fullName = getFullName(contractor)
          return (
            <div className='tw-flex tw-items-center tw-gap-2'>
              <Avatar size='lg' photo={contractor?.photo} name={fullName} />
              <div className='tw-flex tw-flex-col'>
                <Link to={`/contract/detail?id=${ref}`}>
                  <span
                    id='full-name-text'
                    className='tw-font-bold tw-text-text-100'
                  >
                    {fullName}
                  </span>
                  <UncontrolledTooltip target='full-name-text'>
                    Go to contract details
                  </UncontrolledTooltip>
                </Link>

                <span className='tw-text-text-80'>{ref}</span>
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Country',
        Cell: ({ rowData: { contractor } }) => (
          <div className='tw-flex tw-items-center tw-gap-1'>
            <img
              alt='flag'
              className='tw-h-[25px] tw-w-[25px] tw-rounded-full tw-object-cover'
              src={`${assetsStorage}/${contractor?.flag}`}
            />
            <span className='tw-text-sm tw-font-medium tw-text-text'>
              {contractor?.country?.name}
            </span>
          </div>
        ),
      },
      {
        Header: 'Worker type',
        Cell: ({
          rowData: {
            contractor: { contractor_type: contractorType },
            type,
          },
        }) => (
          <div className='tw-capitalize'>
            <span className='tw-text-sm tw-font-medium tw-text-black'>
              {contractorType === BE_CONTRACT_CATEGORY.EMPLOYEE
                ? 'EOR Employee'
                : contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE
                  ? 'Direct Employee'
                  : 'Contractor'}
            </span>
            <br />
            <span className='tw-text-xs tw-font-medium tw-text-text-80'>
              {type}
            </span>
          </div>
        ),
      },
      {
        Header: 'Status',
        Cell: ({ rowData }) => {
          const isActive = rowData?.status?.id === CONTRACT_STATUS.ONGOING.value
          return (
            <span
              className={cn(
                'tw-rounded-full tw-bg-systemGreen-10 tw-px-2.5 tw-py-1 tw-font-bold tw-text-systemGreen',
                !isActive && 'tw-bg-systemGold-10 tw-text-systemGold',
              )}
            >
              {upperCase(rowData?.status?.name)}
            </span>
          )
        },
      },
      {
        Header: 'ID verification',
        Cell: ({ rowData }) => (
          <KycStatusRow
            status={rowData?.kyc_status}
            verifiedAt={rowData?.kyc_verified_at}
            id={rowData?.id}
          />
        ),
      },
      {
        Header: 'Background check',
        Cell: ({ rowData }) => (
          <BackgroundCheckRow
            reference={rowData?.ref}
            background={rowData?.contractor?.bg_check}
            reftech={getDocuments}
          />
        ),
      },
      {
        Header: 'Documents',
        Cell: ({ rowData: { total_documents: documents = 1 } }) => {
          // Contract Agreement is always available for contracts
          const count = documents
          return (
            <span className='tw-rounded-full tw-bg-secondary-30 tw-px-2.5 tw-py-1 tw-font-bold tw-text-secondary'>
              {count} FILE{count > 1 ? 'S' : ''}
            </span>
          )
        },
      },
    ],
    [],
  )

  const renderExpand = useCallback((doc) => {
    return (
      <ExpandedDocumentRow
        refetch={getDocuments}
        onDelete={() => {}}
        onCancel={() => {}}
        id={doc?.id}
      />
    )
  }, [])

  return (
    <div className='page-content'>
      <Head title='Documents' />
      <PageHeading>
        <PageHeading.Title subtitle='Manage your workers documents and track expiration dates'>
          Documents
        </PageHeading.Title>
        <Button
          onClick={() => setRequestDocumentModal(true)}
          icon={<PaperPlaneTilt size={16} />}
          className='tw-h-min'
        >
          Request Document
        </Button>
      </PageHeading>
      <Card className='tw-p-6'>
        <div className='tw-grid tw-grid-cols-1 tw-gap-2 tw-px-0.5 sm:tw-grid-cols-2 lg:tw-grid-cols-4'>
          <SearchBar
            inputClassName='!tw-h-full'
            onQueryChanged={(query) =>
              setFilters((prev) => ({ ...prev, name: query || null }))
            }
            query={filters?.name ?? ''}
          />
          <CustomSelect
            placeholder='Worker Type'
            options={workerTypes}
            value={filters?.workerType}
            onChange={(workerType) =>
              setFilters((prev) => ({ ...prev, workerType }))
            }
            isClearable
          />
          <CustomSelect
            placeholder='Worker Status'
            isClearable
            options={workerStatuses}
            value={filters?.workerStatus}
            onChange={(workerStatus) =>
              setFilters((prev) => ({ ...prev, workerStatus }))
            }
          />
          <CustomSelect
            placeholder='Document status'
            options={documentStatuses}
            isClearable
            value={filters?.documentStatus}
            onChange={(documentStatus) =>
              setFilters((prev) => ({ ...prev, documentStatus }))
            }
          />
        </div>
        <div className='tw-mt-6'>
          {isLoading ? (
            <Loader minHeight='28rem' />
          ) : documents?.length ? (
            <>
              <DataTable
                expandable={{
                  expandedRowRender: renderExpand,
                  rowExpandable: () => true,
                }}
                striped
                columns={columns}
                data={documents}
                responsive
              />
              <div className='tw-w-100 tw-my-3 tw-flex tw-justify-end'>
                <Pagination
                  activePage={page}
                  itemsCountPerPage={paginator?.per_page}
                  totalItemsCount={paginator?.total}
                  onChange={(page) => setPage(page)}
                />
              </div>
            </>
          ) : (
            <TabEmpty
              icon={
                isFiltersEmpty ? (
                  <Paperclip
                    size={250}
                    className='tw-text-primary'
                    weight='duotone'
                  />
                ) : (
                  <MagnifyingGlass
                    size={250}
                    weight='duotone'
                    className='tw-text-primary'
                  />
                )
              }
              title={t(
                isFiltersEmpty ? 'No documents added' : 'No documents found',
              )}
              subtitle={t(
                isFiltersEmpty
                  ? 'Submitted compliance documents will be shown here'
                  : 'We couldn’t find any result with the entered details',
              )}
            />
          )}
        </div>
      </Card>
      <RequestDocumentModal
        onSuccess={getDocuments}
        isOpen={requestDocumentModal}
        toggle={() => setRequestDocumentModal((prev) => !prev)}
      />
    </div>
  )
}
