import { CalendarBlank, MoneyWavy, PlusCircle } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { t } from 'i18next'
import { cn } from 'ui'
import { ModalCloseButton } from '../../../../../components/Common/modal-close-button'
import CustomRadio from '../../../../../components/custom-radio'
import Button from '../../../../../components/ui/button'
import { getCurrencyFormatter } from '../../../../../utils/formatters/currency'
import CalculatedProrataBody from './calculated-prorata-body'
import { PRORATA_CALCULATION_TYPE, PRORATA_TYPE } from './constants'
import CustomProrataBody from './custom-prorata-body'
import FullAmountBody from './full-amount-body'
import { useProrataState } from './prorata-context'

const options = [
  {
    icon: <CalendarBlank size={24} />,
    label: 'Pro-rated amount',
    value: PRORATA_TYPE.CALCULATED,
  },
  {
    icon: <MoneyWavy size={24} />,
    label: 'Full amount',
    value: PRORATA_TYPE.FULL_AMOUNT,
  },
  {
    icon: <PlusCircle size={24} />,
    label: 'Custom amount',
    value: PRORATA_TYPE.CUSTOM,
  },
]

export default function ProrataEdit({
  onClose,
  currency,
  dates,
  onSave,
  calculationType,
  workDays,
  customAmount: _customAmount,
}) {
  const [customAmount, setCustomAmount] = useState(_customAmount)
  const formatter = getCurrencyFormatter(currency.code)

  const { prorataData, gettingProrataData, prorataTab, setProrataTab, amount } =
    useProrataState()

  return (
    <Modal centered isOpen toggle={onClose} size='lg'>
      <ModalHeader close={<ModalCloseButton toggle={onClose} />} tag='div'>
        <h5 className='tw-text-xl tw-font-semibold'>{t('Pro-rata payment')}</h5>
        <div className='tw-text-sm tw-font-normal tw-text-text'>
          {t('Calculate or set a custom amount for the first payment')}
        </div>
      </ModalHeader>
      <ModalBody
        className={cn(
          '!tw-p-6',
          prorataTab === PRORATA_TYPE.CALCULATED
            ? 'tw-min-h-[572px]'
            : 'tw-min-h-[640px]',
        )}
      >
        <div className='tw-mb-2 tw-flex tw-gap-3 *:tw-flex-1'>
          <CustomRadio
            items={options}
            renderItem={(item) => (
              <>
                <span className='group-[:not(:has(:checked))]:tw-text-text-30'>
                  {item.icon}
                </span>
                {t(item.label)}
              </>
            )}
            name='prorataType'
            className='tw-group tw-mb-0 tw-flex tw-items-center tw-gap-2 tw-py-4 tw-capitalize *:tw-shrink-0'
            onSelect={(e) => {
              if (e.target.checked) {
                setProrataTab(e.target.value)
              }
            }}
            checkedItem={prorataTab}
          />
        </div>

        <hr className='-tw-mx-6' />
        {prorataTab === PRORATA_TYPE.CALCULATED ? (
          <CalculatedProrataBody
            prorataType={prorataTab}
            calculationType={calculationType}
            workDays={workDays}
            dates={dates}
            formatter={formatter}
          />
        ) : prorataTab === PRORATA_TYPE.CUSTOM ? (
          <CustomProrataBody
            currencyCode={currency.code}
            onAmountChange={(val) => setCustomAmount(val)}
            amount={customAmount}
          />
        ) : prorataTab === PRORATA_TYPE.FULL_AMOUNT ? (
          <FullAmountBody currencyCode={currency.code} />
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button
          className='!tw-border-surface-30 !tw-bg-white !tw-text-black'
          onClick={onClose}
          disabled={gettingProrataData}
        >
          {t('Cancel')}
        </Button>
        <Button
          onClick={() => {
            onSave({
              amount:
                prorataTab === PRORATA_TYPE.CUSTOM
                  ? customAmount
                  : prorataTab === PRORATA_TYPE.FULL_AMOUNT
                    ? amount
                    : prorataData?.pro_rata_amount,
            })
            if (prorataTab === PRORATA_TYPE.CALCULATED) {
              setCustomAmount(undefined)
            }
          }}
          disabled={
            gettingProrataData ||
            (!workDays.value &&
              calculationType.value === PRORATA_CALCULATION_TYPE.WORKING_DAYS &&
              prorataTab === PRORATA_TYPE.CALCULATED) ||
            (prorataTab === PRORATA_TYPE.CUSTOM && !customAmount)
          }
          loading={gettingProrataData}
        >
          {t('Save Changes')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
