import { t } from 'i18next'
import React from 'react'
import { cn } from 'ui'

export function FormSectionTitle({
  title,
  className,
  noBorder = true,
  showRequired,
  subTitle,
  Icon,
  rightItem,
}) {
  return (
    <div
      className={cn(
        'tw-mb-6',
        { 'tw-border-t tw-border-surface-30': !noBorder },
        className,
      )}
    >
      {!title ? null : (
        <h5 className='tw-mb-0 tw-flex tw-items-center tw-gap-1 tw-text-base tw-font-bold'>
          {!Icon ? null : (
            <Icon className='tw-me-1 tw-size-5 tw-text-primary-100' />
          )}

          {title}

          {!rightItem ? null : rightItem}

          {!showRequired ? null : (
            <span className='tw-text-systemRed-100'>*</span>
          )}
        </h5>
      )}

      {!subTitle ? null : (
        <div className='tw-mt-1 tw-text-sm tw-text-text-80'>{subTitle}</div>
      )}
    </div>
  )
}

export function FormSectionHr({ className }) {
  return <hr className={cn('-tw-mx-6 tw-my-8', className)} />
}
