import React, { useState } from 'react'
import {
  IntegrationAppProvider,
  useIntegrationApp,
  useIntegrations,
} from '@integration-app/react'
import { useHistory } from 'react-router-dom'

import wafeqLogo from '../../../assets/images/brands/wafeq.svg'
import Head from '../../../components/head'
import Loader from '../../../components/ui/loader'
import { useFetch } from '../../../helpers/hooks'
import { authenticateIntegration } from '../../../services/api-integrations'
import {
  ConnectCustomIntegrationModal,
  ConnectIntegrationModal,
} from './connect-integration-modal'
import { IntegrationsListItem } from './integration-item'

export const integrationLoaderElement = <Loader minHeight='max(20vh, 214px)' />

export function RpIntegrationProvider({ children }) {
  const { data: integrationClientToken, isLoading } = useFetch({
    action: authenticateIntegration,
    autoFetch: true,
  })

  if (isLoading) {
    return integrationLoaderElement
  }

  return (
    <IntegrationAppProvider token={integrationClientToken?.accessToken}>
      {children}
    </IntegrationAppProvider>
  )
}

export function IntegrationsV2() {
  return (
    <RpIntegrationProvider>
      <IntegrationsList />
    </RpIntegrationProvider>
  )
}

export function integrationDataMap(integration) {
  return {
    ...integration,
    key: integration.code,
    logoUri: getIntegrationLogo({ code: integration.code }),
    connection: integration.connected
      ? {
          lastActiveAt: integration.lastSyncDate,
          name: integration.name,
          disconnected: false,
        }
      : null,
  }
}

function IntegrationsList() {
  const [disabling, setDisabling] = useState(null)
  const [selectedIntegration, setSelectedIntegration] = useState(null)
  const [selectedCustomIntegration, setSelectedCustomIntegration] =
    useState(null)

  const history = useHistory()

  const integrationApp = useIntegrationApp()

  const {
    integrations,
    loading: loadingIntegrations,
    refresh,
  } = useIntegrations({ limit: 25 })

  if (loadingIntegrations) {
    return integrationLoaderElement
  }

  function onConnect(integration) {
    setSelectedIntegration(integration.key)
  }

  async function onDisable(integration) {
    setDisabling(integration.key)
    await integrationApp.connection(integration.key).archive()
    refresh?.()
    setDisabling(null)
  }

  function onConnectionSuccess() {
    refresh()
    setSelectedIntegration(null)
  }

  return (
    <div className='tw-grid tw-gap-2 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4'>
      <Head>
        <link rel='preconnect' href='https://static.integration.app' />
      </Head>

      {[...integrations].map((item) => (
        <IntegrationsListItem
          integration={item}
          key={item.id}
          onConnect={() => onConnect(item)}
          onDisable={() => onDisable(item)}
          onReview={() => {
            history.push(`/settings/integrations/${item.key}/accounts`)
          }}
          disabling={disabling === item.key}
        />
      ))}

      {!selectedIntegration ? null : (
        <ConnectIntegrationModal
          isOpen={!!selectedIntegration}
          toggle={() => setSelectedIntegration(null)}
          integrationKey={selectedIntegration}
          onConnectionSuccess={onConnectionSuccess}
        />
      )}

      {!selectedCustomIntegration ? null : (
        <ConnectCustomIntegrationModal
          isOpen={!!selectedCustomIntegration}
          toggle={() => setSelectedCustomIntegration(null)}
          integration={selectedCustomIntegration}
          onConnectionSuccess={onConnectionSuccess}
        />
      )}
    </div>
  )
}

function getIntegrationLogo({ code }) {
  switch (code) {
    case 'wafeq': {
      return wafeqLogo
    }
    default: {
      return null
    }
  }
}
