import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Alert,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'

import { ActionsDropdown, cn } from 'ui'
import { useSelectedCard } from '..'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import { cardSecurityCodes, getCardEnumerations } from '../../../services/api'
import { getErrorMessage } from '../../../utils/get-errors'
import { BxIcon } from '../../AdminPanel/pages/Transactions'
import { CARD_TYPE_ENUM, isCardActive } from '../utils/card-types-status'
import { CARD_STATUS_ACTIONS } from './cc-component/cc-component'
import { CreditCard } from './credit-card'
import { Warning } from '@phosphor-icons/react'

let cancelCardDetailsShow = false

export default function ActiveCreditCardSection({
  cardData,
  setAllCardData,
  handleToggleFreezeCard,
}) {
  const { selectedCard } = useSelectedCard()

  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const { startFetch: fetchCardSecurityCodes, isLoading: loadingUnmaskedData } =
    useFetch({
      action: cardSecurityCodes,
      onComplete: handleCardDetailsComplete,
      onError: (err) => {
        toastr.error(getErrorMessage(err))
      },
    })

  function handleCardDetailsComplete(data) {
    if (cancelCardDetailsShow) {
      return
    }

    const newCardData = {
      unmaskedCardNumber: data?.number,
      cvv: data?.cvv,
      validUntil: data?.date?.expiry,
      showDetails: true,
    }

    const cardType = data?.card_type?.type

    setAllCardData((prev) => {
      const newData = { ...prev }
      newData[cardType] = { ...prev[cardType], ...newCardData }

      return newData
    })
  }

  function handleShowData() {
    cancelCardDetailsShow = false
    if (cardData?.unmaskedCardNumber) {
      setAllCardData((prev) => ({
        ...prev,
        [selectedCard]: { ...prev[selectedCard], showDetails: true },
      }))
    } else {
      fetchCardSecurityCodes({
        id: cardData?.id,
        rp_user_id: userProfile?.id,
        masked_number: cardData?.number,
      })
    }
  }

  function handleHideData() {
    cancelCardDetailsShow = true

    setAllCardData((prev) => ({
      ...prev,
      [selectedCard]: { ...prev[selectedCard], showDetails: false },
    }))
  }

  function toggleCardDetails() {
    const showDetails = cardData?.showDetails
    if (showDetails) {
      handleHideData()
    } else {
      handleShowData()
    }
  }

  return (
    <>
      <CreditCard
        {...cardData}
        loading={loadingUnmaskedData}
        handleToggleFreezeCard={handleToggleFreezeCard}
        onMouseEnter={
          selectedCard === CARD_TYPE_ENUM.VIRTUAL ? handleShowData : null
        }
        onMouseLeave={
          selectedCard === CARD_TYPE_ENUM.VIRTUAL ? handleHideData : null
        }
        onToggleCardDetails={toggleCardDetails}
      />
    </>
  )
}

export function ActiveCardActions({
  cardData,
  cardDetails,
  allCardsTerminated,
  updateCard,
  hideConfirmationModal,
  setConfirmationModalProps,
}) {
  const [blockReason, setBlockReason] = useState('stolen')
  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const history = useHistory()
  const { selectedCard } = useSelectedCard()
  const isActiveCardPhysical = selectedCard === CARD_TYPE_ENUM.PHYSICAL

  function handleToggleFreezeCard() {
    updateCard({
      id: cardData?.id,
      status: isCardActive(cardData?.cardStatus)
        ? CARD_STATUS_ACTIONS.LOCK
        : CARD_STATUS_ACTIONS.UNLOCK,
      rp_user_id: userProfile?.id,
    })
  }

  function handleTerminateCard() {
    updateCard({
      id: cardData?.id,
      status: CARD_STATUS_ACTIONS.TERMINATE,
      rp_user_id: userProfile?.id,
      block_reason: blockReason,
    })
  }

  const cardActionOptions = [
    {
      onClick: () => {
        const actionText = isCardActive(cardData?.cardStatus)
          ? 'freeze'
          : 'unfreeze'

        const lastFourDigits = cardData.maskedCardNumber.slice(-4)

        setConfirmationModalProps({
          isOpen: true,
          toggle: hideConfirmationModal,
          title: `${actionText} card`,
          content: (
            <p
              className='font-size-16 text-slate-600'
              style={{ maxWidth: '32ch' }}
            >
              Are you sure you want to <b>{actionText}</b> this card (**** ****
              **** {lastFourDigits})?
            </p>
          ),
          onConfirm: handleToggleFreezeCard,
          confirmText: `Yes, ${actionText} this card`,
          confirmColor: 'danger',
          cancelColor: 'light',
        })
      },
      icon: <BxIcon name='bx bxs-lock' className='font-size-16' />,
      label: isCardActive(cardData?.cardStatus) ? 'Freeze' : 'Unfreeze',
    },
    {
      onClick: () => {
        const lastFourDigits = cardData.maskedCardNumber.slice(-4)

        setConfirmationModalProps({
          isOpen: true,
          toggle: hideConfirmationModal,
          title: 'Terminate card',
          Component: () => (
            <TerminateModalContent
              lastFourDigits={lastFourDigits}
              setBlockReason={setBlockReason}
            />
          ),
          onConfirm: handleTerminateCard,
          confirmText: `Yes, terminate this card`,
          confirmColor: 'danger',
          cancelColor: 'light',
        })
      },
      icon: <BxIcon name='bx bxs-x-circle' className='font-size-16' />,
      label: 'Terminate',
    },
    isActiveCardPhysical &&
      isCardActive(cardData?.cardStatus) && {
        onClick: () => {
          history.push('/cards/reset-pin/' + cardData?.id)
          window.scroll(0, 0)
        },
        icon: pinLockIcon,
        label: 'Reset PIN',
      },
  ].filter(Boolean)

  return (
    <>
      <div className='tw-mt-6 tw-flex tw-w-full tw-items-center tw-gap-4'>
        <ActionsDropdown
          data={cardActionOptions}
          className='tw-size-[44px] tw-border tw-border-surface-30 tw-bg-transparent tw-p-2.5 [&>svg]:tw-size-[34px]'
          position='bottom start'
        />

        <div className='d-flex align-items-center justify-content-end gap-8 w-100'>
          <Button
            tag={Link}
            to={{ pathname: '/cards/offload', state: { card: cardDetails } }}
            style={{ height: 44 }}
            color='light'
            outline
            disabled={!cardDetails}
            loading={!cardDetails}
          >
            Offload
          </Button>
          <Button
            style={{ height: 44 }}
            tag={Link}
            to={
              allCardsTerminated
                ? null
                : { pathname: '/cards/topup', state: { card: cardDetails } }
            }
            disabled={allCardsTerminated || !cardDetails}
            loading={!cardDetails}
          >
            Top Up
          </Button>
        </div>
      </div>
    </>
  )
}

/* @todo: seems we have two different (but similar) ConfirmationModal component, we should only have and use one. */
export function ConfirmationModal({
  isOpen,
  toggle,
  title,
  content,
  confirmText,
  confirmColor = 'primary',
  onConfirm,
  cancelText,
  cancelColor = 'primary',
  onCancel,
  loading,
  centered = true,
  Component,
  withReason,
  showX = false,
  icon,
  titleClassName,
  contentClassName,
  disableConfirm,
}) {
  const body = Component ? <Component /> : content
  const [reason, setReason] = useState(withReason?.value ?? '')
  const [error, setError] = useState('')
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered={centered}
      className='native-dialog-sm'
    >
      {!title ? null : (
        <ModalHeader
          toggle={showX ? toggle : null}
          className='border-0'
          cssModule={{
            'modal-title': cn(
              'd-flex flex-column modal-title w-100 gap-8',
              showX ? '!tw-mr-[-20px]' : '',
              titleClassName,
            ),
          }}
        >
          {icon ?? (
            <Warning
              size={24}
              className='tw-text-systemRed-100'
              weight='duotone'
            />
          )}
          <span className='text-muted rp-capitalize'>{title}</span>
        </ModalHeader>
      )}
      {!body && !withReason ? null : (
        <ModalBody className={contentClassName}>
          {body}
          {withReason && (
            <Input
              type={withReason.type ?? 'textarea'}
              placeholder={withReason.placeholder}
              className='mt-3 mb-2'
              value={reason}
              onChange={(ev) => setReason(ev.target.value)}
            />
          )}
          {error && (
            <div className='text-danger ml-1'>
              {withReason.name ?? 'Reason'} is required
            </div>
          )}
        </ModalBody>
      )}
      <ModalFooter className='border-0'>
        <Button
          loading={loading}
          disabled={loading}
          type='button'
          color={cancelColor}
          outline
          onClick={onCancel ?? toggle}
        >
          {cancelText ?? 'Cancel'}
        </Button>
        <Button
          loading={loading}
          disabled={loading || disableConfirm}
          type='button'
          color={confirmColor}
          onClick={(ev) => {
            if (withReason) {
              if (withReason.isRequired && !reason) {
                setError(true)
              } else {
                setError(false)
                onConfirm(ev, reason)
              }
            } else {
              onConfirm(ev)
            }
          }}
        >
          {confirmText ?? 'Cancel'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function TerminateModalContent({ lastFourDigits, setBlockReason }) {
  const { data: enumerations, isLoading } = useFetch({
    action: getCardEnumerations,
    autoFetch: true,
  })

  const options = isLoading
    ? []
    : enumerations?.card_termination_reasons?.map((p) => {
        return { label: p.toUpperCase(), value: p }
      }) || []

  return (
    <div>
      <Alert color='orange' className='font-size-14'>
        <p className='mb-0' style={{ maxWidth: '34ch' }}>
          Are you sure you want to <b>terminate</b> this card (**** **** ****{' '}
          {lastFourDigits})?
          <br />
          This action <b>cannot be reversed</b>.
        </p>
      </Alert>
      {isLoading || !enumerations ? (
        <div>
          <label>Reason</label>
          <div style={{ height: 42.55 }} className='bg-light rounded' />
        </div>
      ) : (
        <ReasonDropDown onChange={setBlockReason} options={options} />
      )}
    </div>
  )
}

function ReasonDropDown({ onChange, options }) {
  const [reason, setReason] = useState(options?.[0])

  return (
    <CustomSelect
      label='Reason'
      value={reason}
      onChange={(value) => {
        setReason(value)
        onChange(value?.value)
      }}
      options={options}
    />
  )
}

const pinLockIcon = (
  <svg
    width='16'
    height='14'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 6H10V4C10 3.46957 9.78929 2.96086 9.41421 2.58579C9.03914 2.21071 8.53043 2 8 2C7.46957 2 6.96086 2.21071 6.58579 2.58579C6.21071 2.96086 6 3.46957 6 4V6H4V4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4V6Z'
      fill='currentColor'
    />
    <path
      d='M14 7H2C1.73478 7 1.48043 7.10536 1.29289 7.29289C1.10536 7.48043 1 7.73478 1 8V15C1 15.2652 1.10536 15.5196 1.29289 15.7071C1.48043 15.8946 1.73478 16 2 16H14C14.2652 16 14.5196 15.8946 14.7071 15.7071C14.8946 15.5196 15 15.2652 15 15V8C15 7.73478 14.8946 7.48043 14.7071 7.29289C14.5196 7.10536 14.2652 7 14 7Z'
      fill='currentColor'
    />
    <path
      d='M4.10232 12.8571L4.14928 11.8105L3.2694 12.3785L3 11.9072L3.93426 11.4286L3 10.9499L3.2694 10.4787L4.14928 11.0466L4.10232 10H4.63866L4.5917 11.0466L5.47158 10.4787L5.74098 10.9499L4.80672 11.4286L5.74098 11.9072L5.47158 12.3785L4.5917 11.8105L4.63866 12.8571H4.10232Z'
      fill='white'
    />
    <path
      d='M7.73183 12.8571L7.77879 11.8105L6.89891 12.3785L6.62951 11.9072L7.56377 11.4286L6.62951 10.9499L6.89891 10.4787L7.77879 11.0466L7.73183 10H8.26817L8.22121 11.0466L9.10109 10.4787L9.37049 10.9499L8.43623 11.4286L9.37049 11.9072L9.10109 12.3785L8.22121 11.8105L8.26817 12.8571H7.73183Z'
      fill='white'
    />
    <path
      d='M11.3613 12.8571L11.4083 11.8105L10.5284 12.3785L10.259 11.9072L11.1933 11.4286L10.259 10.9499L10.5284 10.4787L11.4083 11.0466L11.3613 10H11.8977L11.8507 11.0466L12.7306 10.4787L13 10.9499L12.0657 11.4286L13 11.9072L12.7306 12.3785L11.8507 11.8105L11.8977 12.8571H11.3613Z'
      fill='white'
    />
  </svg>
)
