import React from 'react'
function PayrollInfoCard({ icon, title, description, rightContent }) {
  return (
    <div className='tw-flex tw-items-center tw-justify-between tw-gap-2 tw-bg-white tw-p-4'>
      <div className='tw-flex tw-items-center tw-gap-2'>
        {icon && (
          <div className='tw-flex tw-h-9 tw-w-9 tw-items-center tw-justify-center tw-rounded-full tw-bg-secondary-20'>
            {icon}
          </div>
        )}
        <div className='tw-flex tw-flex-col'>
          <div className='tw-text-xs tw-text-text-60'>{title}</div>
          <div className='tw-text-text-100'>{description}</div>
        </div>
      </div>
      {rightContent && <div className='tw-ml-auto'>{rightContent}</div>}
    </div>
  )
}

export default PayrollInfoCard
