import React, { useState } from 'react'
import {
  ArrowLeft,
  CalendarCheck,
  CalendarDots,
  Check,
  Coins,
  DotsThreeOutlineVertical,
  DownloadSimple,
  Info,
  Money,
  PencilSimple,
} from '@phosphor-icons/react'
import { InfoTooltip } from '../CompanySetting/manage-role'
import BadgeV2 from '../../components/ui/badge-v2'
import Button from '../../components/ui/button'
import PayrollInfoCard from './components/payroll-info-card'
import Flag from '../../components/ui/flag'
import { currencyFormatter } from 'ui'
import { CustomDropDown } from '../../components/Dropdowns/custom-drop-down'
import PayrollDetailsTable from './components/payroll-details-table'
import PayrollDetailsSidePanel from './components/payroll-details-sidepanel'
import { useFetch } from '../../helpers/hooks'
import {
  approvePayrollCycle,
  downloadPayrollCycleFiles,
  getPayrollDetails,
  markPayrollCycleAsPaid,
  modifyPayrollCycle,
} from '../../services/api-payroll-cycles'
import { format } from 'date-fns'
import { useParams, Link } from 'react-router-dom'
import Loader from '../../components/ui/loader'
import { ConfirmationModal } from '../remotepass-cards/components/active-credit-card-section'
import CustomDatePicker from '../../components/Forms/CustomDatePicker/CustomDatePicker'
import { t } from 'i18next'
import { DATE_FORMAT } from '../../utils/formatters/date-picker-date-format'
import toastr from 'toastr'

function PayrollDetails() {
  const isAdmin = location.pathname.startsWith('/admin')

  const [confirmApprove, setConfirmApprove] = useState(false)
  const [confirmModify, setConfirmModify] = useState(false)
  const [confirmMarkAsPaid, setConfirmMarkAsPaid] = useState(false)
  const [paymentDate, setPaymentDate] = useState()
  const { id } = useParams()
  const [openPayrollDetails, setOpenPayrollDetails] = useState({
    open: false,
    data: {},
  })

  const handleViewPayroll = (data) => {
    setOpenPayrollDetails({ open: true, data })
  }

  const {
    data,
    isLoading,
    startFetch: refresh,
  } = useFetch({
    action: getPayrollDetails,
    autoFetch: true,
    body: {
      id,
    },
  })
  const { startFetch: approve, isLoading: isApproving } = useFetch({
    action: approvePayrollCycle,
    body: {
      id,
    },
    onComplete: () => {
      setConfirmApprove(false)
      toastr.success(
        'Any changes will be implemented in the next month cycle.',
        'Payroll Approved!',
      )
      refresh()
    },
  })

  const { startFetch: modify, isLoading: isModifying } = useFetch({
    action: modifyPayrollCycle,
    body: {
      id,
    },
    onComplete: () => {
      setConfirmModify(false)
      refresh()
    },
  })

  const { startFetch: markAsPaid, isLoading: isMarkingAsPaid } = useFetch({
    action: markPayrollCycleAsPaid,
    onComplete: () => {
      setConfirmMarkAsPaid(false)
      refresh()
      toastr.success('All payments have been completed.', 'Payroll paid!')
    },
  })

  const { startFetch: downloadFile } = useFetch({
    action: downloadPayrollCycleFiles,
    onError: (err) => toastr.error(err),
  })

  const getStatusBadge = (status) => {
    switch (status) {
      case 'pending':
        return <BadgeV2 status='warning'>{t('PENDING CLIENT REVIEW')}</BadgeV2>
      case 'approved':
        return <BadgeV2 status='success'>{t('Approved')}</BadgeV2>
      case 'paid':
        return <BadgeV2 status='primary'>{t('Paid')}</BadgeV2>
      default:
        return null
    }
  }

  return isLoading ? (
    <Loader className='tw-h-svh tw-w-svw' />
  ) : (
    data && (
      <div className='page-content tw-flex tw-flex-col tw-gap-4'>
        <div className='tw-mb-2 tw-flex tw-items-center tw-justify-between'>
          <div>
            <Button
              className='tw-rounded-lg !tw-border-transparent !tw-bg-transparent !tw-p-2 !tw-pl-0 hover:!tw-bg-secondary-40'
              tag={Link}
              to='/payroll'
            >
              <ArrowLeft size={24} className='tw-text-secondary-100' />
            </Button>
            <div className='tw-text-2xl'>{t('Payroll Details')}</div>
            {/* Todo: To be added later once supported from BE */}
            {/* <div className='tw-flex tw-items-center tw-gap-1'>
              <div className='tw-text-text-80'>Last update 22/01/2025</div>
              <InfoTooltip id='payroll-details-info'>
                Click on Modify to update the payment amounts
              </InfoTooltip>
            </div> */}
          </div>
          <div className='tw-flex tw-flex-col tw-items-end tw-gap-4'>
            <div className='tw-flex tw-items-center tw-gap-4'>
              <div>{getStatusBadge(data.status)}</div>
              <div className='tw-text-3xl tw-font-bold'>
                {currencyFormatter(data.entity.country.currency).format(
                  data.gross_pay,
                )}
              </div>
            </div>
            {!isAdmin && (
              <div className='tw-flex tw-items-center tw-gap-2'>
                <CustomDropDown
                  title={
                    <DotsThreeOutlineVertical
                      weight='fill'
                      size={16}
                      className='tw-text-black'
                    />
                  }
                  triggerClassName='!tw-flex tw-h-10 tw-w-10 !tw-items-center !tw-justify-center tw-rounded tw-border !tw-border-surface-30 !tw-bg-white'
                  options={[
                    {
                      label: t('WPS Report'),
                      onClick: () => {
                        downloadFile({
                          id,
                          file_type: 'wps',
                        })
                      },
                    },
                    {
                      label: t('GOSI report'),
                      onClick: () => {
                        downloadFile({
                          id,
                          file_type: 'gosi',
                        })
                      },
                    },
                    {
                      label: t('Variance report'),
                      onClick: () => {
                        downloadFile({
                          id,
                          file_type: 'variance',
                        })
                      },
                    },
                  ]}
                />
                {data.status === 'pending' ? (
                  <Button
                    className='tw-h-10'
                    textClassName='tw-text-xs'
                    icon={<Check size={18} />}
                    color='success'
                    onClick={() => setConfirmApprove(true)}
                  >
                    {t('Approve')}
                  </Button>
                ) : data.status === 'approved' ? (
                  <div className='tw-flex tw-gap-2'>
                    <Button
                      className='tw-h-10 tw-border !tw-border-surface-30 !tw-bg-white !tw-text-black'
                      onClick={() => setConfirmModify(true)}
                      icon={<PencilSimple />}
                    >
                      {t('Modify')}
                    </Button>
                    <Button
                      className='tw-h-10'
                      color='primary'
                      onClick={() => setConfirmMarkAsPaid(true)}
                    >
                      {t('Mark As Paid')}
                    </Button>
                  </div>
                ) : (
                  <Button
                    className='tw-h-10 tw-border !tw-border-surface-30 !tw-bg-white !tw-text-black'
                    onClick={() => {
                      // @todo: api to download all reports in zip?
                    }}
                    icon={<DownloadSimple size={18} />}
                  >
                    {t('Download All Reports')}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
        <div className='tw-grid tw-grid-cols-3 tw-gap-4'>
          <PayrollInfoCard
            icon={<CalendarDots size={20} />}
            title={t('Cycle')}
            description={`${format(new Date(data.start_date), DATE_FORMAT.FORMAT_1)} - ${format(new Date(data.end_date), DATE_FORMAT.FORMAT_1)}`}
          />
          <PayrollInfoCard
            icon={<Flag url='flags/ma.svg' />}
            title={t('Country')}
            description={data.entity.country.name}
          />
          <PayrollInfoCard
            icon={<CalendarDots size={20} />}
            title={t('Entity')}
            description={data.entity.name}
          />
          <PayrollInfoCard
            icon={<CalendarCheck size={20} />}
            title={t('Cut off date')}
            description={format(
              new Date(data.cut_off_date),
              DATE_FORMAT.FORMAT_1,
            )}
          />
          <PayrollInfoCard
            icon={<Money size={20} />}
            title={t('Gross pay')}
            description={currencyFormatter(data.entity.country.currency).format(
              data.gross_pay,
            )}
            // rightContent={<ValueChangeIndicator changeType='none' value='2.4%' />}
          />
          <PayrollInfoCard
            icon={<Coins size={20} />}
            title={t('Employer contributions')}
            description={currencyFormatter(data.entity.country.currency).format(
              data.total_employer_contribution,
            )}
            // rightContent={
            //   <ValueChangeIndicator changeType='decrease' value='2.4%' />
            // }
          />
        </div>
        <PayrollDetailsTable
          data={data?.current_cycle_payrolls}
          onViewPayrollsDetails={handleViewPayroll}
          currency={data.entity.country.currency}
        />
        {openPayrollDetails.open && openPayrollDetails.data && (
          <PayrollDetailsSidePanel
            isOpen={openPayrollDetails.open}
            currency={data.entity.country.currency}
            data={openPayrollDetails.data}
            toggle={() => setOpenPayrollDetails({ open: false, data: {} })}
          />
        )}
        <ConfirmationModal
          isOpen={confirmApprove}
          toggle={() => {
            setConfirmApprove(false)
          }}
          title={
            <div className='tw-text-xl tw-text-black'>Ready to approve?</div>
          }
          content={
            <div>
              {t(
                'Any payroll changes made afterward will be reflected in the next month cycle.',
              )}
            </div>
          }
          icon={<Info className='tw-text-systemBlue-100' size={24} />}
          loading={isApproving}
          contentClassName='!tw-py-0'
          confirmText={t('Yes, Confirm')}
          cancelText={t('No, close')}
          cancelColor='secondary'
          onConfirm={() => {
            approve()
          }}
        />
        <ConfirmationModal
          isOpen={confirmModify}
          loading={isModifying}
          toggle={() => {
            setConfirmModify(false)
          }}
          title={
            <div className='tw-text-xl tw-text-black'>
              {t('Are you sure you want make changes?')}
            </div>
          }
          content={
            <div>
              {t(
                "This will move the payroll back to Pending Review so that payment updates are included in the current month's cycle",
              )}
            </div>
          }
          icon={<Info className='tw-text-systemBlue-100' size={24} />}
          contentClassName='!tw-py-0'
          confirmText={t('Yes, Confirm')}
          cancelText={t('No, close')}
          cancelColor='secondary'
          onConfirm={() => {
            modify()
          }}
        />
        <ConfirmationModal
          isOpen={confirmMarkAsPaid}
          loading={isMarkingAsPaid}
          disableConfirm={!paymentDate}
          toggle={() => {
            setConfirmMarkAsPaid(false)
          }}
          title={
            <div className='tw-text-xl tw-text-black'>
              {t('Please confirm the date of the payment')}
            </div>
          }
          content={
            <div>
              <CustomDatePicker
                label={t('Payment Date')}
                value={paymentDate}
                handleOnChange={setPaymentDate}
                minDate={new Date(data.start_date)}
                placeholder={t('Please select payment date')}
                openToDate={new Date(data.start_date)}
              />
            </div>
          }
          icon={<></>}
          contentClassName='!tw-py-0'
          confirmText={t('Yes, Confirm')}
          cancelText={t('No, close')}
          cancelColor='secondary'
          onConfirm={() => {
            markAsPaid({
              id,
              marked_paid_at: format(paymentDate, 'yyyy-MM-dd'),
            })
          }}
        />
      </div>
    )
  )
}

export default PayrollDetails
