import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import toastr from 'toastr'

import noTransactions from '../../assets/images/no-transactions.svg'
import Head from '../../components/head'
import NoContent from '../../components/NoContent'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import PageHeading from '../../components/ui/page-heading'
import { getUpcomingPaymentList } from '../../services/api'
import { updateToPayList } from '../../store/payment/actions'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import PaymentList from '../due-payments-page/paymentList'
import { getPaymentIds } from '../payInvoices'

const UpcomingPayment = ({ history, match }) => {
  const user = useSelector((state) => state.Account?.user)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const profile = useSelector((state) => state.userProfile.userProfile)
  const currency = profile?.company?.currency || profile?.currency
  const [totalToPay, setTotalToPay] = useState(0)

  const updateInvoiceList = () => {
    getUpcomingPaymentList(user?.token)
      .then((r) => {
        if (r.data.success) {
          if (match.params?.id) {
            setData(
              r.data.data.map((e) => ({
                ...e,
                selected: e.id === match.params?.id,
              })),
            )
          } else {
            setData(r.data.data.map((e) => ({ ...e, selected: false })))
          }
        } else {
          toastr.error(r.data.message)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const updateInvoiceSelections = (i, works = null) => {
    setData(
      data.map((inv, idx) => {
        const sameIndex = i === idx
        let selectedSubPayments = inv?.selected_payment_works ?? []
        let isSelected = inv.selected
        if (sameIndex) {
          const allSubPaymentWorkIds = inv.payments
            .filter((p) => p.is_processable && !p.is_external_payroll)
            .flatMap((p) => p.works?.map((w) => w.payment_item_id))
          if (works) {
            const paymentItemIds = works?.map((w) => w?.payment_item_id)
            if (
              selectedSubPayments.some((paymentId) =>
                paymentItemIds.includes(paymentId),
              )
            ) {
              selectedSubPayments = selectedSubPayments.filter(
                (id) => !paymentItemIds.includes(id),
              )
            } else {
              selectedSubPayments = [...selectedSubPayments, ...paymentItemIds]
            }

            // if all sub-payments's works are selected, isSelected = true, else false
            isSelected =
              selectedSubPayments.length === allSubPaymentWorkIds.length
          } else {
            isSelected = !inv.selected
            // select or deselect all sub payments
            if (isSelected) {
              selectedSubPayments = allSubPaymentWorkIds
            } else {
              selectedSubPayments = []
            }
          }
        }

        return {
          ...inv,
          selected: isSelected,
          selected_payment_works: selectedSubPayments,
        }
      }),
    )
  }

  const dispatch = useDispatch()

  useEffect(() => {
    updateInvoiceList()
  }, [])

  useEffect(() => {
    const _totalToPay = data?.reduce((prev, current) => {
      if (current.selected) {
        return prev + current?.total_trans_amount
      } else {
        let total = 0
        for (const payment of current.payments) {
          if (
            // if a payment work payment_item_id is in the selected_payment_works
            current?.selected_payment_works?.some((id) =>
              payment.works?.map((w) => w?.payment_item_id).includes(id),
            )
          ) {
            total += payment?.trans_amount
          }
        }
        return prev + total
      }
    }, 0)

    setTotalToPay(_totalToPay)
    const ids = data?.flatMap((d) => d.selected_payment_works)
    dispatch(updateToPayList(ids))
  }, [data])

  const formatter = getCurrencyFormatter(currency?.code)

  const url = new URL(window.location.href)
  const path = url.pathname + url.search
  const payLink = `/pay-invoices?from=${encodeURIComponent(path)}`

  const isTotalZero = totalToPay === 0

  return (
    <div className='page-content'>
      <Head title={t('Upcoming Payments')} />

      {loading ? (
        <Loader minHeight='30rem' />
      ) : (
        <>
          <PageHeading>
            <PageHeading.Title>{t('Upcoming Payments')}</PageHeading.Title>

            <Button
              tag={Link}
              to={payLink}
              disabled={isTotalZero}
              color={isTotalZero ? 'secondary' : 'primary'}
            >
              {t('Pay')}
              {isTotalZero ? '' : formatter.format(totalToPay)}
            </Button>
          </PageHeading>

          <Card>
            <CardBody className='p-0'>
              {data?.length === 0 ? (
                <NoContent
                  headline=''
                  subtitle={t("You don't have any upcoming payments")}
                  image={noTransactions}
                />
              ) : (
                <PaymentList
                  data={data}
                  currency={currency}
                  onInvoiceSelected={updateInvoiceSelections}
                  toggleSelectAll={() => {
                    const isAllSelected = data.every((item) => item?.selected)
                    setData((data) => {
                      return data.map((item) => ({
                        ...item,
                        selected:
                          !item?.is_external_payroll &&
                          !isAllSelected &&
                          !item.selected,
                        selected_payment_works: !isAllSelected
                          ? item.payments
                              .filter(
                                (p) =>
                                  p.is_processable &&
                                  !item?.is_external_payroll,
                              )
                              .flatMap((p) =>
                                p.works?.map((w) => w.payment_item_id),
                              )
                          : [],
                      }))
                    })
                  }}
                  onAdvanceSet={(e) => {
                    const ids = getPaymentIds([e])
                    dispatch(updateToPayList(ids))
                    history.push('/pay-invoices/' + e.advance)
                  }}
                />
              )}
            </CardBody>
          </Card>
        </>
      )}
    </div>
  )
}

export default UpcomingPayment
