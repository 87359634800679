import { t } from 'i18next'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'reactstrap'
import toastr from 'toastr'

import { ReactComponent as RefersHeaderIcon } from '../../assets/images/refersHeaderIcon.svg'
import { useFetch, useResize } from '../../helpers/hooks'
import { getReferrals, sendReferralInvites } from '../../services/api'
import InstructionSection from './components/instructionSection'
import PreviewModal from './components/previewModal'
import Refers from './components/refers'
import SharingSection from './components/sharing-section'
import TermsModal from './components/TermsModal'

const USER_FULL_NAME_PLACEHOLDER = '__USER_FULL_NAME__'
const defaultEmail = `Hi there,

I've been using an HR Platform called RemotePass for a while now and I think you'll find it really useful for your remote onboarding and payroll needs. Here is what else you can do with RemotePass.

- Smart Document Collection
- Get a head start with locally compliant templates.
- Global Health Insurance Administration
- Pay your remote team in 90+ currencies

Sign Up today and when you run payroll you'll get your first month off!

Sincerely,
${USER_FULL_NAME_PLACEHOLDER}`

export default function RemotePassReferrals() {
  const isMobile = useResize()
  const user = useSelector((state) => state.Account?.user)
  const [showPreviewEmail, setShowPreviewEmail] = useState(false)
  const [showTermsModal, setShowTermsModal] = useState(false)

  const [emails, setEmails] = useState([])
  const [emailText, setEmailText] = useState(
    defaultEmail.replace(
      USER_FULL_NAME_PLACEHOLDER,
      `${user.first_name} ${user.last_name}`,
    ),
  )
  const { startFetch: inviteUsers, isLoading: sendingInvitation } = useFetch({
    action: sendReferralInvites,
    onComplete: () => {
      setShowPreviewEmail(false)
      toastr.success(t('Invitations sent successfully'))
      setEmails([])
      referrals.startFetch()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  function sendInvitations() {
    inviteUsers({
      emailAddresses: emails,
      subject: 'subject',
      content: emailText,
      id: user?.id,
    })
  }
  const referrals = useFetch({
    action: getReferrals,
    autoFetch: true,
    body: { id: user?.id },
    onError: (err) => {
      toastr.error(err)
    },
  })
  return (
    <div className='page-content'>
      <Container
        className='p-0 d-flex flex-column'
        style={{ gap: '1.5rem' }}
        fluid
      >
        <RefersHeader isMobile={isMobile} />
        <div
          className={`d-flex ${
            isMobile ? 'flex-column' : ''
          } justify-content-between flex-wrap mb-0`}
        >
          <SharingSection
            showPreview={setShowPreviewEmail}
            emails={emails}
            setEmails={setEmails}
            loading={sendingInvitation}
            sendInvitations={sendInvitations}
          />

          <InstructionSection />
        </div>
        <Refers referrals={referrals} />
        <PreviewModal
          isOpen={showPreviewEmail}
          toggle={() => setShowPreviewEmail(false)}
          emailText={emailText}
          setEmailText={setEmailText}
          onConfirm={sendInvitations}
          loading={sendingInvitation}
        />

        <TermsModal
          isOpen={showTermsModal}
          toggle={() => setShowTermsModal(false)}
        />
      </Container>
    </div>
  )
}

function RefersHeader({ isMobile }) {
  return (
    <div
      style={{ backgroundColor: 'rgba(227, 235, 255, 1)', borderRadius: 4 }}
      className={`tw-flex ${isMobile ? 'tw-flex-col-reverse' : ''} ${
        isMobile ? 'tw-items-center' : 'tw-items-start'
      } tw-bg-blue-100 tw-w-full tw-justify-between tw-rounded tw-p-3`}
    >
      <div className={`${isMobile ? 'flex-col tw-flex tw-items-center' : ''}`}>
        <p className='tw-m-0 tw-text-2xl tw-font-semibold'>
          {t('Refer & Earn $400')}
        </p>
        <div className='tw-text-gray-500 tw-py-3 tw-text-lg'>
          {
            // prettier-ignore
            t('Refer a company to RemotePass and get up to $400 when they process payroll') + '!'
          }
        </div>
        <a
          className='tw-pt-3 tw-text-primary'
          target='_blank'
          rel='noreferrer'
          href='https://www.remotepass.com/blog/refer-and-earn-with-remotepass'
        >
          {t('Learn more')}
        </a>
      </div>
      <div className='pr-4'>
        <RefersHeaderIcon />
      </div>
    </div>
  )
}
