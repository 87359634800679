import axios from 'axios'

import { history } from '../App'
import { contractTypes, userTypes } from '../helpers/enum'
import isNill from '../utils/is-nill'
import { searchParamsFromObject } from '../utils/search-params-from-object'

const host = process.env.REACT_APP_API_HOST
const path = '/api'
const UNAUTHENTICATED = 401

export const api = axios.create({
  baseURL: `${host}${path}`,
  headers: { 'Content-Type': 'application/json' },
})

api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    const isAdminRequest = error?.request?.responseURL?.includes('/api/admin')
    const isAdmin = window.location.pathname.startsWith('/admin')
    const isLoginPage = window.location.pathname.startsWith('/login')

    if (error?.response?.status === UNAUTHENTICATED && !isLoginPage) {
      if (isAdmin && isAdminRequest) {
        history.push('/admin/login')
        window.location.reload()
      } else if (!isAdmin && !isAdminRequest) {
        history.push('/logout')
        window.location.reload()
      }
    }

    return Promise.reject(error)
  },
)

export function getConfig(token, extra = {}, extraHeader = {}) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      ...extraHeader,
    },
    ...extra,
  }
}

const hostV2 = process.env.REACT_APP_API_HOST_V1
export const apiV2 = axios.create({
  baseURL: hostV2,
  headers: { 'Content-Type': 'application/json' },
})

export function getConfigV2(token, extraHeader, extra) {
  // TODO to be removed as it's now typical to v1 (access control headers are causing issues and removed)
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      ...extraHeader,
    },
    ...extra,
  }
}
export const signup = (data, type) =>
  api.post(
    type === userTypes.CONTRACTOR ? 'contractor/signup' : 'client/signup',
    { ...data },
  )

export const signupEmployee = (token, data) => {
  const formData = getFormData(data)
  return api.post('contractor/update', formData, getConfig(token))
}

export const login = (data) => {
  return api.post('/login', data)
}
export const userLogin = (token, data) => {
  return api.post('/login', data, getConfig(token))
}

export const getOktaCompanies = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return apiV2.get(
    `/auth-service/api/v1/users/companies${sp}`,
    getConfigV2(token, {}, extra),
  )
}
export const logoutUser = (token) => api.get('/logout', getConfig(token))

export const resetPassword = (_, data) => api.post('/password/reset', data)

export const updatePassword = (_, data) => api.post('/password/update', data)

export const verifyOTP = (otp, email, type) =>
  api.post(
    type === userTypes.CONTRACTOR ? 'contractor/verify' : 'client/verify',
    { email, otp },
  )
export const checkRegisterToken = (token) =>
  api.get('auth/token/check', getConfig(token))
export const verifyClientOTP = (data) => api.post('client/verify', data)
export const verifyContractorOTP = (data) => api.post('contractor/verify', data)

export const verifyReCaptchaV3 = (token) => {
  return api.post('recaptcha/verify', { response: token })
}

export const completeRegistration = (type, allData, token) => {
  const { nextStep, ...data } = allData

  if (data.token && nextStep !== 'update-profile') {
    return api.post(
      type === userTypes.CONTRACTOR
        ? 'contractor/google/signup'
        : 'client/google/signup',
      data,
      getConfig(token),
    )
  } else {
    const formData = getFormData(data)
    return api.post(
      type === userTypes.CONTRACTOR ? 'contractor/update' : 'client/update',
      formData,
      getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
    )
  }
}

export const googleSignUp = (_, data) => {
  const { userType, token, sourcePath, sourceData } = data
  return api.post(
    userType === userTypes.COMPANY
      ? 'client/google/signup/verify'
      : 'contractor/google/signup/verify',
    {
      token,
      source_path: sourcePath,
      source_data: sourceData,
    },
  )
}

export const getStaticData = (token, _, extra) => {
  return api.post(
    'static/list',
    {
      tables: [
        'cor_countries',
        'job_titles',
        'countries',
        'frequencies',
        'job_descriptions',
        'rates',
        'contract_statuses',
        'contract_types',
        'company_types',
        'currencies',
        'company_currencies',
        'roles',
        'occurrences',
        'document_types',
        'adjustment_types',
        'template_tags',
        'attribute_types',
        'expense_categories',
        'disallowed_job_titles',
        'payment_statuses',
        'document_request_types',
        'equipment_types',
        'contractor_types',
        'other_countries',
        'eor_countries',
        'payment_classifications',
        'payment_providers',
        'time_off_types',
        'seniorities',
        'all_countries',
        'termination_reasons',
        'de_termination_reasons',
        'departments',
        'religious_faiths',
        'religions',
        'passport_types',
        'education_levels',
        'languages',
        'marital_statuses',
        'bill_categories',
      ],
    },
    getConfig(token, extra),
  )
}

export const getStaticDataByTable = (token, data) => {
  const table = data?.table
  const tables = Array.isArray(table) ? table : [table]

  return api.post('static/list', { tables }, getConfig(token))
}

export const impersonateUser = (token, data) => {
  return api.post('admin/impersonate/enter', data, getConfig(token))
}
export const leaveImpersonation = (token, data) => {
  return api.post('admin/impersonate/leave', data, getConfig(token))
}
export const getStates = (data) => api.post('static/states', data)

export const getCities = (data) => api.post('static/cities', data)

export const genericDownload = (token, data) => {
  return api.get(data?.link, getConfig(token, { responseType: 'blob' }))
}

export const getCompanyInfo = (token, extra) => {
  return api.get('company/info', getConfig(token, extra))
}

export const getClientInfo = (token, _, extra) => {
  return api.get('client/info', getConfig(token, extra))
}

export const getContractorInfo = (token, _, extra) => {
  return api.get('contractor/info?settings', getConfig(token, extra))
}

export const getEmployeesListByPartner = (token) => {
  return api.get('contract/fulltime/partner/employees', getConfig(token))
}

export const getEmployeeDataCollection = (token) => {
  return api.get(
    'contract/fulltime/employee_data_collections',
    getConfig(token),
  )
}
export const getEmployeeDataCollectionAP = (token, data) => {
  return api.get(
    `admin/contract/fulltime/${data?.contract_id}/employee_data_collections`,
    getConfig(token),
  )
}

export const submitEmployeeDataCollection = (token, data) => {
  return api.put(
    'contract/fulltime/employee_data_collections',
    data,
    getConfig(token),
  )
}

export const declineDataCollection = (token, data) => {
  return api.post(
    `admin/contract/fulltime/${data?.contract_id}/employee_data_collections/decline`,
    data,
    getConfig(token),
  )
}

export const exportAdminForm = (token, data) => {
  return api.get(
    `admin/contract/fulltime/${data?.contract_id}/employee_data_collections/export?export_format=${data?.export_format}`,
    getConfig(token),
  )
}

export const approveDataCollection = (token, data) => {
  return api.post(
    `admin/contract/fulltime/${data?.contract_id}/employee_data_collections/approve`,
    data,
    getConfig(token),
  )
}
export const assignContractToRegionForm = (token, data) => {
  return api.post(
    `admin/contract/fulltime/${data?.contract_id}/employee_data_collections`,
    data,
    getConfig(token),
  )
}

export const addDocumentRequestEmployee = (token, data) => {
  return api.post('contract/document/request/add', data, getConfig(token))
}
export const addDocumentRequestClient = (token, data) => {
  return api.post(
    'contract/document/client/request/create',
    data,
    getConfig(token),
  )
}

export function getFormData(data) {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (!isNill(data[k])) {
      if (Array.isArray(data[k])) {
        for (let i = 0; i < data[k].length; i++) {
          formData.append(`${k}[]`, data[k][i])
        }
      } else {
        formData.append(k, data[k])
      }
    }
  })
  return formData
}

export const uploadDocumentRequestClient = (token, data) => {
  const formData = getFormData(data)

  return api.post(
    'contract/document/client/request/upload',
    formData,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}
export const getDocumentRequests = (token) => {
  return api.get('contract/document/request/list', getConfig(token))
}
export const getClientDocumentRequests = (token, data) => {
  return api.get(
    `contract/document/client/request/${data?.contract_id}/list`,
    getConfig(token),
  )
}
export const generateClientDocumentRequest = (token, data) => {
  return api.post(
    'contract/document/client/request/generate',
    data,
    getConfig(token),
  )
}
export const getDocumentRequestTypes = (token, data) => {
  return api.get(
    `contract/document/request/types?contract_id=${data?.contract_id}`,
    getConfig(token),
  )
}

export const getClientRequestTemplate = (token, data) => {
  return api.get(
    `contract/document/client/request/${data?.request_id}/template`,
    getConfig(token),
  )
}
export const downloadDocumentRequest = (token, data) => {
  return api.get(
    `contract/document/request/${data?.request_id}/download`,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const adminAddDocumentRequest = (token, data) => {
  return api.post(
    'admin/contract/fulltime/document/request/add',
    data,
    getConfig(token),
  )
}
export const getEmployeeDocuments = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('contract/document/request/list' + sp, getConfig(token))
}
export const getEmployeeDocumentsAdmin = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/contract/fulltime/requests' + sp, getConfig(token))
}

export const uploadEmployeeDocument = (token, data) => {
  const formData = getFormData(data)

  return api.post(
    'admin/contract/fulltime/document/upload',
    formData,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const downloadEmployeeDocument = (token, data) => {
  const { request_id: requestId } = data
  return api.get(
    `contract/document/request/${requestId}/download`,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const downloadEmployeeDocumentDe = (token, data) => {
  const { request_id: requestId } = data
  return api.post(
    'contract/fulltime/document/download',
    { request_id: requestId },
    getConfig(token, { responseType: 'blob' }),
  )
}
export const downloadEmployeeDocumentAdmin = (token, data) => {
  const { request_id: requestId } = data
  return api.post(
    'admin/contract/fulltime/document/download',
    { request_id: requestId },
    getConfig(token, { responseType: 'blob' }),
  )
}
export const deleteEmployeeDocumentAdmin = (token, data) => {
  const { request_id: requestId } = data
  return api.delete(
    `/admin/contract/fulltime/document/request/${requestId}/file`,
    getConfig(token),
  )
}
export const deleteEmployeeDocumentRequestAdmin = (token, data) => {
  const { request_id: requestId } = data
  return api.delete(
    `/admin/contract/fulltime/document/request/${requestId}`,
    getConfig(token),
  )
}
export const updateCompanyInfo = (token, data) => {
  const formData = getFormData(data)
  return api.post(
    'company/update',
    formData,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const updateClientInfo = (token, data) => {
  const formData = getFormData(data)
  return api.post(
    'client/update',
    formData,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const updateContractorProfile = (token, data) => {
  return api.post(
    'contractor/update',
    data,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const getUsers = (token, data, extra) => {
  return api.post('company/users', data, getConfig(token, extra))
}
export const getCompanyApprovers = (token, _, extra) => {
  return api.get('company/approvers', getConfig(token, extra))
}
export const getBanners = (token) => {
  return api.get('banners', getConfig(token))
}
export const requestKYC = (token, data) => {
  const sp = searchParamsFromObject(data)

  return api.get('veriff/create' + sp, getConfig(token))
}

export const approveKYB = (token, data) => {
  return api.post('admin/company/kyb/approve', data, getConfig(token))
}
export const rejectKYB = (token, data) => {
  return api.post('admin/company/kyb/reject', data, getConfig(token))
}

export const sendUserInvitation = (token, data) => {
  return api.post('client/invite/send', data, getConfig(token))
}

export const verifyInvitationToken = (token) => {
  return api.post('client/invite/verify', {}, getConfig(token))
}

export const confirmInvitation = (data) =>
  api.post('client/invite/confirm', data)

export const createContract = (token, data) => {
  switch (data.type) {
    case contractTypes.FIXED:
      return api.post('contract/fixed/create', data, getConfig(token))
    case contractTypes.PAYG:
      return api.post('contract/payg/create', data, getConfig(token))
    case contractTypes.MILESTONES:
      return api.post('contract/milestone/create', data, getConfig(token))
    default:
      return () => {
        // do nothing
      }
  }
}

export const createDirectEmploymentContract = (token, data) => {
  return api.post('direct_employees/contracts', data, getConfig(token))
}

export const uploadAnnexe = (token, data) => {
  const formData = getFormData(data)
  return api.post('contract/annex/upload', formData, getConfig(token))
}
export const removeAnnexe = (token, data) => {
  return api.post('contract/annex/delete', data, getConfig(token))
}
export const createFulltimeContract = (token, data) => {
  return api.post('contract/fulltime', data, getConfig(token))
}

export const updateAdminFulltimeQuotation = (token, data) => {
  return api.post(
    '/admin/contract/fulltime/quote/update',
    data,
    getConfig(token),
  )
}
export const inviteEmployeeToFtContract = (token, data) => {
  return api.post(
    `admin/contract/fulltime/${data?.contract_id}/employee/invite`,
    data,
    getConfig(token),
  )
}
export const generateAdminFulltimeQuotation = (token, data) => {
  return api.post(
    '/admin/contract/fulltime/quote/generate',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const generateFulltimeQuotation = (token, data) => {
  return api.post(
    'contract/fulltime/quote/generate',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const uploadContractFileV2 = (token, data, extra) => {
  const formData = getFormData(data)
  return api.post(
    'contract/upload',
    formData,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }, extra),
  )
}

function getContractsSearchQuery(data) {
  const { status, tag, page, query, type } = data ?? {}

  const qp1 = searchParamsFromObjectArray(
    { country_ids: data?.country_ids },
    false,
  )

  const qp2 = [
    Array.isArray(status) && status.length > 0 ? `statuses=${status}` : null,
    Array.isArray(tag) && tag.length ? `tags=${tag}` : null,
  ]
    .filter(Boolean)
    .join('&')

  const cf = searchParamsFromObjectArray(
    { selected_custom_fields: data?.selected_custom_fields },
    false,
  )

  const sp = [
    cf,
    qp1,
    qp2,
    page ? `page=${page}` : '',
    !query ? null : `search_key=${query ?? ''}`,
    type ? `contract_type=${type}` : '',
  ]
    .filter(Boolean)
    .join('&')

  return sp
}

export const getContractList = (token, data, extra) => {
  const sp = getContractsSearchQuery(data)

  return api.get(`contract/list${sp ? `?${sp}` : ''}`, getConfig(token, extra))
}

export const getArchivedContracts = (token, data) => {
  const sp = getContractsSearchQuery(data)

  return api.get(
    `contract/list/archived${sp ? `?${sp}` : ''}`,
    getConfig(token),
  )
}
/**
 *
 * @param {*} data - { page: number, pending_invites: 1 }
 */
export function getContractsPendingInvite(token, data, extra) {
  const sp = searchParamsFromObject(data)
  return api.get(`contract/list${sp}`, getConfig(token, extra))
}

export function getCustomFields(token, data, extra) {
  const sp = searchParamsFromObject(data)
  return api.get(`contract/list${sp}`, getConfig(token, extra))
}

export const addContractTag = (token, data) => {
  return api.post('contract/tag/add', data, getConfig(token))
}
export const deleteContractTag = (token, data) => {
  return api.post('contract/tag/delete', data, getConfig(token))
}

export const getContractDetail = (token, { id }, extra) => {
  return api.get(`contract/${id}/details`, getConfig(token, extra))
}

export const getContractSubmittedWork = (token, { id, ...data }, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get(`contract/${id}/works${sp}`, getConfig(token, extra))
}

export const getTagsList = (token) => {
  return api.get('contract/tag/list', getConfig(token))
}
export const inviteContractPart = (token, data) => {
  return api.post('contract/invite', data, getConfig(token))
}
export const inviteFullTimeEmployee = (token, data) => {
  return api.post('contract/fulltime/employee/invite', data, getConfig(token))
}
export const signContract = (token, data) => {
  return api.post('contract/signature', data, getConfig(token))
}

export const getNGBanks = (token) => {
  return api.get('accounts/bank/NG/banks', getConfig(token))
}

export function getBankFields(token, data, extra) {
  return api.post('accounts/bank/fields', data, getConfig(token, extra))
}

export const updateBankAccount = (token, data) => {
  return api.put('accounts/bank/update', data, getConfig(token))
}

export const addPayPalAccount = (token, data) => {
  return api.post('contractor/paypal/add', data, getConfig(token))
}

export const updatePaypalAccount = (token, data) => {
  return api.put(
    `contractor/paypal/${data.id}`,
    { is_active: data.is_active },
    getConfig(token),
  )
}

export const addPayoneerAccount = (token, data) => {
  return api.post('contractor/payoneer/add', data, getConfig(token))
}

export const deletePayoneerAccount = (token, data) => {
  return api.get(`contractor/payoneer/${data?.id}/delete`, getConfig(token))
}

export const deleteCoinbaseAccount = (token, data) => {
  return api.get(`contractor/coinbase/${data?.id}/delete`, getConfig(token))
}

export const getInvoicesList = (token, data, extra) => {
  const sp = searchParamsFromObject(data)

  return api.post('invoice/list' + sp, data, getConfig(token, extra))
}
export const getAdminInvoicesList = (token, data, extra) => {
  return api.post('admin/contract/invoice/list', data, getConfig(token, extra))
}
export const getUnpaidInvoicesList = (token) => {
  return api.get('invoice/list/unpaid', getConfig(token))
}
export const getInvoiceDetail = (token, id) => {
  return api.get(`invoice/${id}/details`, getConfig(token))
}
export const getOngoingContract = (token) => {
  return api.get('cron/contracts/ongoing', getConfig(token))
}
export const newPaymentMethod = (token) => {
  return api.get('payment/method/new', getConfig(token))
}
export const generateInvoice = (token, data) => {
  return api.post('cron/contracts/invoices', data, getConfig(token))
}
export const getContractInvoices = (token, data, extra) => {
  return api.get(
    `contract/${data?.id}/invoices?month=${data?.month}&search_key=${data?.search_key}`,
    getConfig(token, extra),
  )
}
export const addPaymentMethod = (token, data) => {
  return api.post('payment/method/add', data, getConfig(token))
}
export const addContractorPaymentMethod = (token, data) => {
  return api.post('contractor/payment/method/add', data, getConfig(token))
}
export const getPaymentMethods = (token, _, extra) => {
  return api.get('payment/method/list', getConfig(token, extra))
}

export const getContractorPaymentMethods = (token) => {
  return api.get('contractor/payment/method/list', getConfig(token))
}

export const revertAdminEorClientInvoice = (token, data) => {
  return api.post('admin/eor/invoice/client/revert', data, getConfig(token))
}

export const revertAdminEorClientApprovedInvoice = (token, data) => {
  const { client_invoice_id: clientInvoiceId } = data
  return api.put(
    `admin/eor/invoice/${clientInvoiceId}/client-approval/revert`,
    {},
    getConfig(token),
  )
}
export const getContractsInvoicesList = (token, data, extra) => {
  return api.post('admin/eor/invoice/list', data, getConfig(token, extra))
}
export const getGeneratedInvoicesList = (token, data, extra) => {
  return api.post(
    'admin/eor/invoice/generate/list',
    data,
    getConfig(token, extra),
  )
}
export const addAdminEorPartnerInvoice = (token, data) => {
  return api.post(
    'admin/eor/invoice/partner/add',
    data,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}
export const updateAdminEorPartnerInvoice = (token, data) => {
  return api.post(
    'admin/eor/invoice/partner/update',
    data,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const getPartnersInvoicesList = (token, data, extra) => {
  return api.post(
    'admin/eor/invoice/partner/list',
    data,
    getConfig(token, extra),
  )
}
export const uploadPartnerInvoiceBreakdown = (token, data) => {
  return api.post(
    'admin/eor/invoice/breakdown/upload',
    data,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}
export const getPartnerInvoiceEmployees = (token, data) => {
  return api.post('admin/eor/invoice/partner/employees', data, getConfig(token))
}

export const downloadEorInvoice = (token, data) => {
  return api.get(
    `admin/eor/invoice/download/${data?.id}`,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const downloadGeneratedInvoice = (token, data) => {
  return api.post(
    'admin/eor/invoice/generate/download',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const payPartnerInvoice = (token, data) => {
  return api.post(
    'admin/eor/invoice/partner/payment/confirm',
    data,
    getConfig(token),
  )
}
export const approvePartnerInvoice = (token, data) => {
  return api.post('admin/eor/invoice/partner/approve', data, getConfig(token))
}
export const revertPartnerInvoice = (token, data) => {
  return api.post('admin/eor/invoice/partner/revert', data, getConfig(token))
}
export const revertPartnerInvoiceBreakdown = (token, data) => {
  return api.post(
    'admin/eor/invoice/partner/breakdown/revert',
    data,
    getConfig(token),
  )
}

export const previewContractInvoice = (token, data) => {
  return api.post(
    'admin/eor/invoice/preview' + `?page=${data?.page || 1}`,
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const submitContractInvoice = (token, data) => {
  return api.post(
    'admin/eor/invoice/submit',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const submitGeneratedInvoice = (token, data) => {
  return api.post(
    'admin/eor/invoice/generate',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const getPartnerInvoiceDetails = (token, data) => {
  return api.post('admin/eor/invoice/partner/details', data, getConfig(token))
}
export const getEORBenefitDocuments = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get(
    '/admin/contract/fulltime/benefit_documents' + sp,
    getConfig(token),
  )
}
export const getEORImmigrationDocuments = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get(
    '/admin/contract/fulltime/immigration_documents' + sp,
    getConfig(token),
  )
}
export const getEOREmploymentContracts = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get(
    '/admin/contract/fulltime/employment_contracts' + sp,
    getConfig(token),
  )
}
export const getEORContractsAmendments = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('/admin/contract/fulltime/amendments' + sp, getConfig(token))
}

export const approveAmendment = (token, data, extra) => {
  return api.post(
    `/admin/contract/fulltime/amendments/${data?.id}/approve`,
    data,
    getConfig(token, extra),
  )
}
export const declineAmendment = (token, data, extra) => {
  return api.post(
    `/admin/contract/fulltime/amendments/${data?.id}/decline`,
    data,
    getConfig(token, extra),
  )
}

export const getEORContractsAdjustments = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/contract/fulltime/adjustments' + sp, getConfig(token))
}
export const getEORContractsAdjustmentsMonths = (token) => {
  return api.get('admin/contract/fulltime/adjustments/months', getConfig(token))
}

export const getEORPartnersPayrollOutputs = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('/admin/eor/payroll/partners/outputs' + sp, getConfig(token))
}

export const getEORContractsPayslips = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('/admin/eor/payroll/payslips' + sp, getConfig(token))
}

export const deleteEORPayslip = (token, data, extra) => {
  return api.post(
    '/admin/eor/payroll/payslips/delete',
    data,
    getConfig(token, extra),
  )
}
export const setEORCutoffDay = (token, data, extra) => {
  return api.post(
    '/admin/contract/fulltime/cutoff_day_of_month/set',
    data,
    getConfig(token, extra),
  )
}

export const unsetEORCutoffDay = (token, data, extra) => {
  return api.post(
    '/admin/contract/fulltime/cutoff_day_of_month/unset',
    data,
    getConfig(token, extra),
  )
}

export const generateEORPartnerOutputFile = (token, data, extra) => {
  return api.post(
    `/admin/eor/payroll/partners/${data?.id}/outputs`,
    data,
    getConfig(token, extra),
  )
}
export const downloadEORPartnerMasterOutputFile = (token, data) => {
  return api.post(
    '/admin/eor/payroll/partners/outputs/master/generate',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const getOutputPartners = (token) => {
  return api.get('/admin/contract/fulltime/partner/list', getConfig(token))
}

export const getEORAdminTodos = (token) => {
  return api.get('/admin/widgets/eor/todo', getConfig(token))
}

export const reGenerateEORPartnerOutputFile = (token, data, extra) => {
  return api.post(
    `/admin/eor/payroll/partners/${data?.partnerId}/outputs/${data?.outputId}/regenerate`,
    data,
    getConfig(token, extra),
  )
}

export const sendEORPartnerOutputFile = (token, data, extra) => {
  return api.post(
    `/admin/eor/payroll/partners/${data?.partnerId}/outputs/${data?.outputId}/send`,
    data,
    getConfig(token, extra),
  )
}

export const approveAdjustment = (token, data, extra) => {
  return api.post(
    `/admin/contract/fulltime/adjustments/${data?.id}/approve`,
    data,
    getConfig(token, extra),
  )
}

export const declineAdjustment = (token, data, extra) => {
  return api.post(
    `/admin/contract/fulltime/adjustments/${data?.id}/decline`,
    data,
    getConfig(token, extra),
  )
}

export const getTransactionList = (token, data, extra) => {
  return api.post(
    'admin/transaction/list' + `?page=${data?.page || 1}`,
    data,
    getConfig(token, extra),
  )
}

export const getAllPaymentList = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/payment/list' + sp, getConfig(token, extra))
}

export const getAdminUpcomingPaymentList = (token, data, extra) => {
  return api.post(
    'admin/payment/company/list/upcoming' + `?page=${data?.page || 1}`,
    data,
    getConfig(token, extra),
  )
}

export const getUnpaidPaymentList = (token, data, extra) => {
  return api.post(
    'admin/payment/company/list/unpaid' + `?page=${data?.page || 1}`,
    data,
    getConfig(token, extra),
  )
}

export const getPaymentListByCompany = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/payment/company/list' + sp, getConfig(token, extra))
}

export const getPaymentHistory = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.post('transaction/list' + sp, {}, getConfig(token, { ...extra }))
}

export const getUnpaidList = (token, _, extra) => {
  return api.get('payment/unpaid?grouped=1', getConfig(token, extra))
}

export const getPaymentsForReview = (token) => {
  return api.get('payment/approval/pending?grouped=1', getConfig(token))
}

export const approvePayments = (token, data) => {
  return api.post('payment/approve', data, getConfig(token))
}
export const declinePayments = (token, data) => {
  return api.post('payment/decline', data, getConfig(token))
}

export const getUpcomingPaymentList = (token) => {
  return api.get('payment/upcoming?grouped=1', getConfig(token))
}

export const payWithCC = (token, data) => {
  return api.post('payment/create/cc', data, getConfig(token))
}

export const payWithTransfer = (token, data) => {
  return api.post('payment/create/transfer', data, getConfig(token))
}

export const downloadInvoice = (ref, token, invoiceToken) => {
  return api.get(
    `invoice/download/${ref}/${invoiceToken}`,
    getConfig(token, { responseType: 'blob' }),
  )
}
export function downloadInvoiceV2(token, data) {
  const { ref, invoiceToken } = data

  return api.get(
    `invoice/download/${ref}/${invoiceToken}`,
    getConfig(token, { responseType: 'blob' }),
  )
}
export function downloadAdminInvoice(token, data) {
  const { ref, invoiceToken } = data

  return api.get(
    `admin/contract/invoice/download/${ref}/${invoiceToken}`,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const exportInsurancePayments = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/reports/insurance_payments' + sp, getConfig(token))
}

export const downloadReceipt = (ref, token, invoiceToken) => {
  return api.get(
    `transaction/statement/${ref}/${invoiceToken}`,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const downloadTrxReceipt = (token, data) => {
  const { ref, invoiceToken, ...rest } = data

  const sp = searchParamsFromObject(rest)

  return api.get(
    `transaction/statement/${ref}/${invoiceToken}` + sp,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const downloadAdminReceipt = (token, data) => {
  const { ref, invoiceToken, ...rest } = data

  const sp = searchParamsFromObject(rest)
  return api.get(
    `admin/transaction/statement/${ref}/${invoiceToken}` + sp,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const downloadWithdrawalReceipt = (ref, token, invoiceToken) => {
  return api.get(
    `transaction/withdrawal/receipt/${ref}/${invoiceToken}`,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const downloadProviderReceipt = (token, data) => {
  return api.post(
    `admin/transfer/receipt`,
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const userDownloadContract = (token, data) => {
  return api.get(
    `contract/download/${data?.ref}/${data?.file}`,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const adminDownloadContract = (token, data) => {
  return api.get(
    `admin/contract/download/${data?.ref}/${data?.file}`,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const downloadContract = (ref, token, contractToken, isAdmin) => {
  return api.get(
    isAdmin
      ? `admin/contract/download/${ref}/${contractToken}`
      : `contract/download/${ref}/${contractToken}`,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const downloadAdminQuote = (token, data) => {
  return api.post(
    'admin/contract/fulltime/quote/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const downloadQuote = (token, data) => {
  return api.post(
    'contract/fulltime/quote/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const downloadContractPdf = (token, data) => {
  return api.get(
    `admin/contract/download/${data?.ref}/${data?.contractToken}`,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const downloadEmploymentContractPdf = (token, data) => {
  return api.post(
    'admin/contract/fulltime/employment/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const removeProcessingTransactionReceipt = (token, data) => {
  return api.post(
    `transaction/${data?.transaction_id}/receipt/remove`,
    data,
    getConfig(token),
  )
}

export const downloadProcessingTransactionReceipt = (token, data) => {
  return api.get(
    `transaction/${data?.transaction_id}/receipt/download`,
    getConfig(token),
  )
}

export const adminDownloadProcessingTransactionReceipt = (token, data) => {
  return api.get(
    `admin/transaction/${data?.transaction_id}/receipt/download`,
    getConfig(token),
  )
}

export const getDocs = (token) => {
  return api.get('contractor/documents', getConfig(token))
}
export const getDocumentsUS = (token) =>
  api.get('contractor/document/residence/form', getConfig(token))
export const getTaxConditions = (token) =>
  api.get('contractor/document/residence/conditions', getConfig(token))
export const submitWForm = (token, data) =>
  api.post('contractor/document/residence/generate', data, getConfig(token))

export const addMilestone = (token, data) => {
  return api.post('contract/milestone/add', data, getConfig(token))
}

export const submitMilestone = (token, data) => {
  return api.post('contract/milestone/submit', data, getConfig(token))
}

export const submitPaygWork = (token, data) => {
  return api.post('contract/payg/submit', data, getConfig(token))
}

export const addWork = (token, data) => {
  return api.post('contract/payg/add', data, getConfig(token))
}

export const approveMilestone = (token, data) => {
  return api.post('contract/milestone/approve', data, getConfig(token))
}

export const declineMilestone = (token, data) => {
  return api.post('contract/milestone/decline', data, getConfig(token))
}

export const approveWork = (token, data) => {
  return api.post('contract/payg/approve', data, getConfig(token))
}

export const declineWork = (token, data) => {
  return api.post('contract/payg/decline', data, getConfig(token))
}

export const createTransactionCC = (token, data) => {
  return api.post('transaction/cc/create', data, getConfig(token))
}
export const createTransactionSepa = (token, data) => {
  return api.post('transaction/sepa/create', data, getConfig(token))
}

export const createTransactionTransfer = (token, data) => {
  return api.post('transaction/transfer/create', data, getConfig(token))
}

export const createTransactionACH = (token, data) => {
  return api.post('/transaction/ach/create', data, getConfig(token))
}

export const getContractPayment = (token, data, extra) => {
  const { id, ...rest } = data

  const sq = searchParamsFromObject(rest)

  return api.get(`contract/${id}/payments${sq}`, getConfig(token, extra))
}
export const getAdminContractPayment = (token, { id }, extra) => {
  return api.post(
    'admin/contract/payment/list',
    { contract_id: id },
    getConfig(token, extra),
  )
}
export const getContractAdjustments = (token, data) => {
  return api.get(
    `contract/fulltime/${data?.contract_id}/adjustments`,
    getConfig(token),
  )
}
export const getAdminEORContractsAdjustments = (token, data) => {
  return api.get(
    `/admin/contract/fulltime/${data?.contract_id}/adjustments`,
    getConfig(token),
  )
}
export const getOverview = (token) => {
  return api.get('contract/overview', getConfig(token))
}

export const getEarnings = (token) => {
  return api.get('contractor/earnings', getConfig(token))
}

export const getRecentContracts = (token, extra) => {
  return api.get('widgets/recent_contracts', getConfig(token, extra))
}
export const getUpcomingPayments = (token, extra) => {
  return api.get('widgets/upcoming_payments', getConfig(token, extra))
}
export const getOutstandingPayments = (token, extra) => {
  return api.get('widgets/outstanding_payments', getConfig(token, extra))
}
export const getCurrentMonthPayments = (token, extra) => {
  return api.get('widgets/current_month_payments', getConfig(token, extra))
}
export const getPendingApprovalPayments = (token, extra) => {
  return api.get('widgets/pending_approval_payments', getConfig(token, extra))
}
export const getTransactionRef = (token, data) => {
  return api.post('transaction/quotes?grouped=1', data, getConfig(token))
}

export function getScheduledTaskStatus(token, data) {
  return api.get('scheduled_tasks/' + data.id, getConfig(token))
}

export const confirmTransaction = (token, data) => {
  return api.post('admin/transaction/confirm', data, getConfig(token))
}
export const revertTransaction = (token, data) => {
  return api.post('admin/transaction/withdraw/revert', data, getConfig(token))
}
export const manualProcessTransaction = (token, data) => {
  return api.post('admin/transfer/process/manual', data, getConfig(token))
}
export const revertPaymentTransaction = (token, data) => {
  return api.post('admin/transaction/payment/revert', data, getConfig(token))
}
export const reviewTransaction = (token, data) => {
  return api.post('admin/transaction/compliance/status', data, getConfig(token))
}
export const archiveTransaction = (token, data) => {
  return api.post('admin/transaction/archive', data, getConfig(token))
}
export const createZendeskTicket = (token, data) => {
  return api.post(
    'admin/transaction/withdraw/ticket/send',
    data,
    getConfig(token),
  )
}
export const setPreferredProvider = (token, data) => {
  return api.post('admin/transaction/processor/update', data, getConfig(token))
}
export const unProcessWithdraw = (token, data) => {
  return api.post(
    'admin/transaction/withdraw/unprocess',
    data,
    getConfig(token),
  )
}
export const updateReceivedStatus = (token, data) => {
  return api.post(
    'admin/transaction/update_received_status',
    data,
    getConfig(token),
  )
}

export const prepareTransaction = (token, data) => {
  return api.post('transaction/transfer/prepare', data, getConfig(token))
}
export const transferTransaction = (token, data) => {
  return api.post('admin/transfer/confirm', data, getConfig(token))
}

export const getTransferList = (token, data, extra) => {
  return api.post(
    'admin/transfer/list' + `?page=${data?.page || 1}`,
    data,
    getConfig(token, extra),
  )
}

export const getNotificationList = (token, _, extra) => {
  return api.get('notifications', getConfig(token, extra))
}

export const getContractorWallet = (token) => {
  return api.get('contractor/wallet', getConfig(token))
}

export const prepareWithdraw = (token, data) => {
  return api.post('contractor/withdraw/prepare', data, getConfig(token))
}

export const getPayoneerLink = (token, data) => {
  return api.post('contractor/payoneer/link', data, getConfig(token))
}

export const savePayoneerAccount = (token, data) => {
  return api.post('contractor/payoneer/save', data, getConfig(token))
}

export const deletePayoneerMethod = (token, data) => {
  if (!data?.id) {
    return () => {
      // do nothing
    }
  }

  return api.get('contractor/payoneer/deactivate/' + data?.id, getConfig(token))
}

export const activatePayoneerAccount = (token, data) => {
  return api.post('contractor/payoneer/activate', data, getConfig(token))
}

export const createWithdraw = (token, data) => {
  return api.post('contractor/withdraw/create', data, getConfig(token))
}

export const getContractors = (token, data) => {
  return api.get('company/contractors', getConfig(token, data))
}

export const deleteCC = (token, data) => {
  return api.get(`payment/method/${data?.id}/delete`, getConfig(token))
}

export const deleteContractorCC = (token, data) => {
  return api.get(
    `contractor/payment/method/${data?.id}/delete`,
    getConfig(token),
  )
}

export const getMethods = (token, data) => {
  return api.post('transaction/methods', data, getConfig(token))
}

export const markNotificationAsRead = (token, data) => {
  return api.post('notification/global/read', data, getConfig(token))
}

export const markAllNotificationAsRead = (token, data) => {
  return api.post('notifications/read', data, getConfig(token))
}

export const cancelInvitation = (token, data) => {
  return api.post('contract/cancel/invitation', data, getConfig(token))
}

export const downloadDoc = (token, data) => {
  return api.get(
    `company/document/${data?.id}/image`,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const getContractDocs = (token, data) => {
  return api.get(`contract/${data?.id}/documents`, getConfig(token))
}
export const getClientDocs = (token, data) => {
  return api.get(`/contract/document/list`, getConfig(token, data))
}

export const downloadContractDoc = (token, data) => {
  return api.get(
    `contract/${data?.id}/document/${data?.token}`,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const getContractorDocs = (token, data) => {
  return api.get(`company/documents/${data?.token}`, getConfig(token))
}

export const changeUserStatus = (token, data) => {
  return api.post('client/status', data, getConfig(token))
}
export const terminateContract = (token, data) => {
  const { contract_id: contractId, ...body } = data
  return api.post(`/contract/${contractId}/terminate`, body, getConfig(token))
}
export const reactivateContract = (token, data) => {
  return api.put(
    `/contract/${data?.contract_id}/reactivate`,
    data,
    getConfig(token),
  )
}
export const changePassword = (token, data) => {
  return api.post('password/change', data, getConfig(token))
}
export const confirmStripe = (token, data) => {
  return api.post('/transaction/cc/verify', data, getConfig(token))
}

export const confirmStripeForInsurance = (token, data) => {
  return api.post('/insurance/transaction/cc/verify', data, getConfig(token))
}

export const getProrataData = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('contract/fixed/prorata' + sp, getConfig(token, extra))
}

export const updateProrata = (token, data) => {
  return api.post('contract/fixed/prorata', data, getConfig(token))
}

export const getTerminationProrata = (token, { contractId, ...data }) => {
  const sp = searchParamsFromObject(data)
  return api.get(
    `contract/${contractId}/termination/prorata` + sp,
    getConfig(token),
  )
}

export const getCycleText = (token, data) => {
  return api.post('contract/payment/default_cycle', data, getConfig(token))
}
export const getUsersListAdmin = (token, data, extra) => {
  return api.post('admin/users/list', data, getConfig(token, extra))
}
export const getBalancesAdmin = (token) => {
  return api.get('admin/company/balance/list', getConfig(token))
}
export const getAdminCompanies = (token) => {
  return api.get('admin/company/clients', getConfig(token))
}
export const deleteBalanceAdmin = (token, data) => {
  return api.post('admin/company/balance/delete', data, getConfig(token))
}
export const createBalanceAdmin = (token, data) => {
  return api.post('admin/company/balance/add', data, getConfig(token))
}
export const getCompaniesListAdmin = (token, data, extra) => {
  return api.post(
    'admin/company/list' + `?page=${data?.page}`,
    data,
    getConfig(token, extra),
  )
}

export const updateCompanyAdmin = (token, data, extra) => {
  const formData = getFormData(data)
  return api.post(
    'admin/company/update',
    formData,
    getConfigV2(token, { ...extra, 'Content-Type': 'multipart/form-data' }),
  )
}

export const updateCompanyAdminJson = (token, data, extra) => {
  return api.post('admin/company/update', data, getConfigV2(token, extra))
}

export const updateManagementFee = (token, data) => {
  return api.put(
    `admin/company/regional_configs/${data.company_id}`,
    data,
    getConfigV2(token),
  )
}

export const updateCompanyPerks = (token, data) => {
  const { id, ...rest } = data
  return api.post(`admin/company/${id}/perks`, rest, getConfig(token))
}

export const getContractsListAdmin = (token, data, extra) => {
  return api.post(
    'admin/contract/adminlist' + `?page=${data?.page || 1}`,
    data,
    getConfig(token, extra),
  )
}

export const getContractDetailAdmin = (token, data, extra) => {
  return api.get(`admin/contract/${data.id}/details`, getConfig(token, extra))
}

export const getContractAmendmentsAdmin = (token, data, extra) => {
  return api.get(
    `admin/contract/fulltime/${data?.contract_id}/amendments`,
    getConfig(token, extra),
  )
}

export const deleteContractorDocument = (token, data) => {
  return api.get(
    `contractor/document/residence/delete/${data?.token}`,
    getConfig(token),
  )
}

export const connectPaypal = (token, data) => {
  return api.post('contractor/paypal/connect', data, getConfig(token))
}

export const verifyPaypal = (token, data) => {
  return api.post('contractor/paypal/verify', data, getConfig(token))
}
export const connectCoinbase = (token, data) => {
  return api.post('contractor/coinbase/connect', data, getConfig(token))
}

export const verifyCoinbase = (token, data) => {
  return api.post('contractor/coinbase/verify', data, getConfig(token))
}

export const whitelistEmail = (token, data) => {
  return api.post('admin/contractor/email/whitelist', data, getConfig(token))
}

export const uploadContractorDoc = (token, data) => {
  const formData = getFormData(data)
  return api.post(
    'contractor/document/residence/upload',
    formData,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const uploadClientTransactionReceiptFile = (token, data) => {
  const { transaction_id: transactionId, ...body } = data
  return api.post(
    `transaction/${transactionId}/receipt/upload`,
    body,
    getConfig(token),
  )
}

/**
 * @param {*} data - { file: File, type: 'external_invoices' | 'external_invoices' | 'mol_id_cards' | 'offer_letters' | 'works' | 'immigration_documents' | 'employment_contracts' | 'benefit_documents' | 'form_uploads' |
 * 'time_off_attachments' | 'transaction_receipts' }
 */
export const uploadTempFile = (token, data) => {
  return api.post(
    'storage/temp_files/upload',
    { ...data, field: null, name: null, id: null },
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}
export const uploadTempFileAdmin = (token, data) => {
  return api.post(
    'admin/storage/temp_files/upload',
    { ...data, field: null, name: null },
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}
export const getDocsList = (token) => {
  return api.get('contractor/document/residence/list', getConfig(token))
}

export const downloadContractorDoc = (token, data) => {
  return api.get(
    `contractor/document/residence/download/${data?.token}`,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const changeUserRole = (token, data) => {
  return api.post('client/role', data, getConfig(token))
}

export const updateContract = (token, data) => {
  return api.post('contract/update', data, getConfig(token))
}
export const updateDEContract = (token, data) => {
  const { contract_id: id, ...rest } = data
  return api.patch(`direct_employees/contracts/${id}`, rest, getConfig(token))
}

export const updateOrgChartContract = (token, data) => {
  return api.post(
    `/organization_charts/${data?.contract_id}/update`,
    data,
    getConfig(token),
  )
}

export const updateFullTimeContract = (token, data) => {
  return api.patch(
    `contract/fulltime/${data?.contract_id}`,
    data,
    getConfig(token),
  )
}
export const updateFTContract = (token, data) => {
  return api.post('admin/contract/fulltime/update', data, getConfig(token))
}
export const assignInsuranceProviderToFTContract = (token, data) => {
  return api.post(
    `/admin/contract/fulltime/${data?.contract_id}/assign_insurance_provider`,
    data,
    getConfig(token),
  )
}
export const AddBenefitDocumentToFTContract = (token, data) => {
  return api.post(
    `/admin/contract/fulltime/${data?.contract_id}/benefit_documents`,
    data,
    getConfig(token),
  )
}
export const terminateEORContract = (token, data) => {
  return api.post('admin/contract/fulltime/terminate', data, getConfig(token))
}

export const addImmigrationDocument = (token, data) => {
  return api.post(
    `admin/contract/fulltime/${data?.fulltime_contract_id}/immigration_documents`,
    data,
    getConfig(token),
  )
}

export const addEmploymentContract = (token, data) => {
  return api.post(
    `admin/contract/fulltime/${data?.contract_id}/employment_contracts`,
    data,
    getConfig(token),
  )
}

export const editEmploymentContract = (token, data) => {
  return api.put(
    `admin/contract/fulltime/employment_contracts/${data?.employment_contract_contract_id}`,
    data,
    getConfig(token),
  )
}

export const editEorContractStartDate = (token, data) => {
  return api.post(
    `/admin/contract/fulltime/${data?.contract_id}/start_date/update`,
    data,
    getConfig(token),
  )
}

export const uploadFTContract = (token, data) => {
  const formData = getFormData(data)
  return api.post(
    'admin/contract/fulltime/update',
    formData,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const amendContract = (token, data) => {
  return api.post('contract/amendment/add', data, getConfig(token))
}
export const requestContractTermination = (token, data) => {
  return api.post(
    `contract/fulltime/${data?.contract_id}/termination_requests`,
    data,
    getConfig(token),
  )
}
export const requestContractTerminationDetails = (token, data) => {
  return api.get(
    `contract/fulltime/${data?.contract_id}/termination_requests/${data?.request_id}`,
    getConfig(token),
  )
}
export const requestContractTerminationDetailsAdmin = (token, data) => {
  return api.get(
    `admin/contract/fulltime/${data?.contract_id}/termination_requests/${data?.request_id}`,
    getConfig(token),
  )
}
export const cancelContractTermination = (token, data) => {
  return api.post(
    `contract/fulltime/${data?.contract_id}/termination_requests/cancel`,
    {},
    getConfig(token),
  )
}
export const getCountriesRegionConfig = (token, data) => {
  return api.get(
    `eor/regional_configs/${data?.region_type}/${data?.region_id}`,
    getConfig(token),
  )
}
export const getCurrencyExchangeRate = (token, data) => {
  return api.get(
    `exchange_rates/${data?.source_currency}/${data?.target_currency}`,
    getConfig(token),
  )
}
export const getCurrencyExchangeRateAdmin = (token, data) => {
  return api.get(
    `admin/exchange_rates/${data?.source_currency}/${data?.target_currency}`,
    getConfig(token),
  )
}
export const getOccurrences = (token, data, extra) => {
  return api.post('static/occurrences', data, getConfig(token, extra))
}
export const connectQuickbooks = (token, data) => {
  return data
    ? api.get(
        `integration/qb/connect?code=${data?.code}&realmId=${data?.realmId}`,
        getConfig(token),
      )
    : api.get('integration/qb/connect', getConfig(token))
}
export const getQuickbooksConnection = (token) => {
  return api.get('integration/qb/complete', getConfig(token))
}
export const getFreshbooksConnection = (token) => {
  return api.get('integration/fresh/complete', getConfig(token))
}
export const getZohoConnection = (token, data) => {
  return api.post('integration/zoho/complete', data, getConfig(token))
}
export const disconnectQuickbooks = (token) => {
  return api.post('integrations/qb/disconnect', {}, getConfig(token))
}

export const getQuickbooksToken = (token, data) => {
  return api.post('integration/qb/connect', data, getConfig(token))
}

export const getQuickbooksAccounts = (token, data) => {
  return api.post('integrations/qb/accounts', data, getConfig(token))
}

export const selectQuickbooksAccount = (token, data) => {
  return api.post('integrations/qb/accounts/select', data, getConfig(token))
}
export const updateUserPermissions = (token, data) => {
  return api.post('client/permission', data, getConfig(token))
}
export const getSignatoryList = (token, _, extra) => {
  return api.get('contract/signatory/list', getConfig(token, extra))
}

export const assignContractToSignatory = (token, data) => {
  return api.post('contract/signatory/assign', data, getConfig(token))
}
export const signAsProvider = (token, data) => {
  return api.post(
    `admin/contract/fulltime/${data?.contract_id}/sign_as_provider`,
    data,
    getConfig(token),
  )
}
export const getContractPDF = (token, data, extra) => {
  return api.get(
    `/contract/show/${data?.ref}/${data?.file}`,
    getConfig(token, { responseType: 'blob', ...extra }),
  )
}

export const getAdminContractPDF = (token, data) => {
  return api.get(
    `admin/contract/show/${data?.ref}/${data?.file}`,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const cancelSignatoryInvitation = (token, data) => {
  return api.post('contract/signatory/cancel', data, getConfig(token))
}

export const archiveContract = (token, data) => {
  return api.post('/contract/archive', data, getConfig(token))
}
export const archiveAdminContract = (token, data) => {
  return api.post('admin/contract/archive', data, getConfig(token))
}

export const deleteBankAccount = (token, data) => {
  return api.get(`contractor/bank/${data?.id}/delete`, getConfig(token))
}

export const deletePaysendCardAccount = (token, data) => {
  return api.post(
    `cards/paysend/card_accounts/${data?.id}/delete`,
    {},
    getConfig(token),
  )
}

export const checkTokenUpdatePass = (token, data) => {
  return api.post('client/invite/verify', data, getConfig(token))
}

export const getTransactionDetails = (token, data) => {
  return api.get(`transaction/${data?.id}/details`, getConfig(token))
}

export const checkTransferStatus = (token, data) => {
  return api.get(`transaction/${data.quoteId}/token`, getConfig(token))
}

export const autoWithdraw = (token, data) => {
  return api.post('admin/withdraw/action', data, getConfig(token))
}
export const multipleAutoWithdraw = (token, data) => {
  return api.post('admin/withdraw/action/bulk', data, getConfig(token))
}
export const archiveUser = (token, data) => {
  return api.post('admin/users/archive', data, getConfig(token))
}
export const deactivateUser = (token, data) => {
  return api.post('admin/users/deactivate', data, getConfig(token))
}
export const flagUser = (token, data) => {
  return api.post('admin/users/flag', data, getConfig(token))
}
export const unflagUser = (token, data) => {
  return api.post('admin/users/unflag', data, getConfig(token))
}
export const disableAdmin2fa = (token, data) => {
  return api.post('admin/users/2fa/disable', data, getConfig(token))
}
export const adminChangeEmail = (token, data) => {
  return api.post('admin/users/email/change', data, getConfig(token))
}
export const getAdminUserDetails = (token, data) => {
  return api.get(`admin/user/info/${data.id}`, getConfig(token))
}
export const getAdminCompanyDetails = (token, data) => {
  return api.get(`admin/company/details/${data.id}`, getConfig(token))
}
export const verifyIdwiseJourney = (token, data) => {
  return api.post('contractor/idwise/verify', data, getConfig(token))
}
export const createIdwiseSession = (token, data) => {
  return api.post('contractor/idwise/create', data, getConfig(token))
}

export const getScreeningDetails = (token, data) => {
  return api.get(`admin/screening/user?user_id=${data.id}`, getConfig(token))
}

export const submitKYCVerification = (token, data) => {
  return api.post(
    'contractor/kyc/submit',
    data,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}
export const getKYCSubmissions = (token, data) => {
  return api.post('admin/contractor/kyc/list', data, getConfig(token))
}
export const getBankAccounts = (token, data) => {
  const sp = searchParamsFromObject({ page: data?.page })

  return api.post('admin/bankaccounts/list' + sp, data, getConfig(token))
}
export const getCardAccounts = (token, data) => {
  const sp = searchParamsFromObject(data)

  return api.get('admin/cards/paysend/card_accounts' + sp, getConfig(token))
}

export const getPayeeKycRequirements = (token, data, extra) => {
  return api.get(
    'admin/bank_account/kyc_requirements' + searchParamsFromObject(data),
    getConfig(token, extra),
  )
}

export const markPayeeKycRequirementsAsSubmitted = (token, id) => {
  return api.put(
    `admin/bank_account/${id}/kyc_requirements/mark_submitted`,
    {},
    getConfig(token),
  )
}

export const bankNameVerification = (token, data) => {
  return api.post('admin/bankaccounts/namematches', data, getConfig(token))
}
export const approveCardAfterNameVerif = (token, data) => {
  return api.post(
    `admin/cards/paysend/card_accounts/${data?.cardId}/approve`,
    data,
    getConfig(token),
  )
}
export const rejectCardAfterNameVerif = (token, data) => {
  return api.post(
    `admin/cards/paysend/card_accounts/${data?.cardId}/reject`,
    data,
    getConfig(token),
  )
}
export const updateCardConfig = (token, data) => {
  return api.post(
    `admin/cards/paysend/card_accounts/${data?.cardId}/update`,
    data,
    getConfig(token),
  )
}
export const screenBankAccount = (token, data) => {
  return api.post('admin/bankaccounts/screening', data, getConfig(token))
}
export const screenCompany = (token, data) => {
  return api.post('admin/company/client/screening', data, getConfig(token))
}
export const screenContractorEntity = (token, data) => {
  return api.post('admin/company/contractor/screening', data, getConfig(token))
}
export const archiveBankAccount = (token, data) => {
  return api.post('admin/bankaccounts/archive', data, getConfig(token))
}
export const getUserKYCSubmission = (token, data) => {
  return api.post('admin/contractor/kyc/details', data, getConfig(token))
}
export const approveKYCSubmission = (token, data) => {
  return api.post('admin/contractor/kyc/approve', data, getConfig(token))
}
export const rejectKYCSubmission = (token, data) => {
  return api.post('admin/contractor/kyc/reject', data, getConfig(token))
}
export const screenUserKYCSubmission = (token, data) => {
  return api.post('admin/contractor/kyc/screening', data, getConfig(token))
}

export const resendInvite = (token, data) => {
  return api.post('client/invite/resend', data, getConfig(token))
}

export const getWithdrawList = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/withdraw/list' + sp, getConfig(token))
}
export const getOCR = (token, data) => {
  return api.post(
    'https://vision.googleapis.com/v1/images:annotate?key=AIzaSyCJxpoDnrnRUiX12trB-mDkNtNnk0ZQ0iE',
    data,
  )
}

export const cancelContract = (token, data) => {
  return api.post('contract/cancel', data, getConfig(token))
}
export const adjustPayment = (token, data) => {
  return api.post('payment/adjustment/add', data, getConfig(token))
}
export const fullTimeAddition = (token, data) => {
  return api.post(
    `contract/fulltime/${data?.contract_id}/adjustments/additions`,
    data,
    getConfig(token),
  )
}
export const adminFullTimeAddition = (token, data) => {
  return api.post(
    `admin/contract/fulltime/${data?.contract_id}/adjustments/additions`,
    data,
    getConfig(token),
  )
}
export const fullTimeDeduction = (token, data) => {
  return api.post(
    `contract/fulltime/${data?.contract_id}/adjustments/deductions`,
    data,
    getConfig(token),
  )
}
export const adminFullTimeDeduction = (token, data) => {
  return api.post(
    `admin/contract/fulltime/${data?.contract_id}/adjustments/deductions`,
    data,
    getConfig(token),
  )
}
export const deleteWork = (token, data) => {
  return api.post('contract/work/delete', data, getConfig(token))
}
export const contractorWorkDelete = (token, { work_id: id }) => {
  return api.delete(`contract/${id}/work`, getConfig(token))
}
export const switchApi = (token) => {
  return api.post('/profile/switch', {}, getConfig(token))
}
export const cancelAmendContract = (token, data) => {
  return api.post('contract/amendment/cancel', data, getConfig(token))
}
export const getTrxDetails = (token, data) => {
  return api.get(`contractor/withdraw/details/${data?.id}`, getConfig(token))
}
export const exportTransactions = (token, data) => {
  return api.post(
    'transaction/export',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const clientCancelTransactions = (token, data) => {
  return api.post('transaction/revert', data, getConfig(token))
}

export const updateCountryTaxData = (token, data) => {
  return api.put(
    `admin/eor/regional_configs/${data?.region_type}/${data?.region_id}/taxes`,
    data,
    getConfig(token),
  )
}

export const exportQuote = (token, data) => {
  return api.post(
    'eor/quote/export',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const downloadEORQuote = (token, data) => {
  return api.post(
    'eor/quote/download',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const adminDownloadEORQuote = (token, data) => {
  return api.post(
    'admin/eor/quote/download',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const adminExportQuote = (token, data) => {
  return api.post(
    'admin/eor/quote/export',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const exportStatement = (token, data) => {
  return api.post(
    'transaction/statement',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const googleLogin = (token, data) => {
  return api.post('google/login', data, getConfig(token))
}
export const oktaLogin = (token) => {
  return api.get('/authenticated/info', getConfig(token))
}
export const withdrawCurrencies = (token, data, extra) => {
  return api.get(
    `withdraw/currencies/${data.currency || 'USD'}`,
    getConfig(token, extra),
  )
}
export const getAlfardanBanks = (token, data) => {
  return api.get(`withdraw/${data?.country}/banks`, getConfig(token))
}

export const createWithdrawAccount = (token, data) => {
  return api.post('accounts/bank/create', data, getConfig(token))
}
export const exportAlfardanTrx = (token) => {
  return api.get(
    'admin/alfardan/export',
    getConfig(token, { responseType: 'blob' }),
  )
}
export const exportRMPTrx = (token) => {
  return api.get(
    'admin/remotepass/export',
    getConfig(token, { responseType: 'blob' }),
  )
}
export const exportTrx = (token) => {
  return api.get(
    'admin/transactions/export',
    getConfig(token, { responseType: 'blob' }),
  )
}
export const exportTWTrx = (token) => {
  return api.get(
    'admin/transferwise/export',
    getConfig(token, { responseType: 'blob' }),
  )
}
export const getInvoicesMonths = (token) => {
  return api.get('/invoice/months', getConfig(token))
}
export const getContractInvoicesMonths = (token, data, extra) => {
  return api.get(
    `contract/${data?.id}/invoices/months`,
    getConfig(token, extra),
  )
}
export const getInvoicesYears = (token, _, extra) => {
  return api.get('invoice/years', getConfig(token, extra))
}

export const getTransactionsMonths = (token) => {
  return api.get('/transaction/months', getConfig(token))
}
export const getAdminTemplate = (token, data) => {
  return api.post('admin/template/edit', data, getConfig(token))
}
export const getAdminTemplateList = (token) => {
  return api.get('admin/template/list', getConfig(token))
}
export const getContractTemplate = (token, data) => {
  return api.post('template/edit', data, getConfig(token))
}
export const getAdminContractTemplate = (token, data) => {
  const { contract_id: contractId } = data
  return api.get(
    'admin/contract_templates/contract/edit/' + contractId,
    getConfig(token),
  )
}
export const getTemplateList = (token) => {
  return api.get('template/list', getConfig(token))
}
export const updateContractTemplateAdmin = (token, data) => {
  return api.post(
    'admin/contract_templates/contract/update',
    data,
    getConfig(token),
  )
}
export const updateTemplateAdmin = (token, data) => {
  return api.post('admin/template/update', data, getConfig(token))
}
export const deleteTemplateAdmin = (token, data) => {
  return api.post('admin/template/delete', data, getConfig(token))
}
export const updateTemplateAdminStatus = (token, data) => {
  return api.post('admin/template/update/status', data, getConfig(token))
}
export const createTemplateAdmin = (token, data) => {
  return api.post('admin/template/create', data, getConfig(token))
}
export const updateContractTemplate = (token, data) => {
  return api.post('template/update', data, getConfig(token))
}
export const getCorSowSubmit = (token, data) => {
  return api.post('admin/cor/sow', data, getConfig(token))
}
export const getCorSowDefaultTemplate = (token) => {
  return api.get('admin/cor/sow/template', getConfig(token))
}
export const rpSignCorSow = (token, data) => {
  return api.post('admin/cor/sow/sign', data, getConfig(token))
}
export const adminSignCoRContract = (token, data) => {
  return api.post('admin/cor/contract/sign', data, getConfig(token))
}
export const getCorSupportedCountries = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/cor/supported_countries' + sp, getConfig(token, extra))
}
export const updateCorSupportedCountry = (token, data) => {
  const { id, ...rest } = data
  return api.post(
    `admin/cor/supported_countries/${id}/update`,
    rest,
    getConfig(token),
  )
}

export const viewCorSow = (token, data) => {
  return api.get(
    `contract/cor/sow/view/${data?.contractRef}/${data?.token}`,
    getConfig(token),
  )
}
export const downloadCorSow = (token, data) => {
  return api.get(
    `contract/cor/sow/download/${data?.contractRef}/${data?.token}`,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const clientCorSignSow = (token, data) => {
  return api.post('contract/cor/sow/sign', data, getConfig(token))
}

export const segmentIdentify = (data) => {
  return api.post('https://api.segment.io/v1/identify', data, {
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_SEGMENT_WRITE_KEY}`,
    },
  })
}
export const segmentTrack = (data) => {
  return api.post('https://api.segment.io/v1/track', data, {
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_SEGMENT_WRITE_KEY}`,
    },
  })
}
export const segmentBatch = (data) => {
  return api.post('https://api.segment.io/v1/batch', data, {
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_SEGMENT_WRITE_KEY}`,
    },
  })
}

export const getAttributes = (token, data, extra) => {
  const sp = searchParamsFromObject(data)

  return api.get('/attributes' + sp, getConfig(token, extra))
}
export const postCustomField = (token, data) => {
  return api.post('attributes', data, getConfig(token))
}
export const updateCustomField = (token, data) => {
  return api.put(`attributes/${data?.id}`, data, getConfig(token))
}
export const deleteCustomField = (token, data) => {
  return api.delete(`attributes/${data?.id}`, getConfig(token))
}
export const getContractCustomFields = (
  token,
  { contractId, ...data },
  extra,
) => {
  const sp = searchParamsFromObject(data)

  return api.get(
    `attributes/contract/${contractId}${sp}`,
    getConfig(token, extra),
  )
}

export const updateContractCustomFields = (token, data) => {
  return api.put(
    `attributes/contract/${data?.contract_id}`,
    data,
    getConfig(token),
  )
}

export const getSingleTimeOff = (token, data, extra) => {
  return api.get('contract/timeoff/' + data.timeOffId, getConfig(token, extra))
}

export const getAllTimeOffs = (token, data, extra) => {
  const sp = searchParamsFromObject(data)

  return api.get('contract/timeoff/list' + sp, getConfig(token, extra))
}

export const getClientTimeOffs = (token, data, extra) => {
  const { contract_id: contractId, ...body } = data
  const sp = searchParamsFromObject(body)

  return api.get(
    `contract/timeoff/list/${contractId}${sp}`,
    getConfig(token, extra),
  )
}

export const getTimesOff = (token, data, extra) => {
  const sp = searchParamsFromObject(data)

  return api.get(`contract/timeoff/list${sp}`, getConfig(token, extra))
}
export const getAdminTimesOff = (token, data, extra) => {
  const { contract_id: contractId, ...body } = data
  const sp = searchParamsFromObject(body)

  return api.get(
    `admin/contract/${contractId}/time_off/list` + sp,
    getConfig(token, extra),
  )
}
export const getCycles = (token, data, extra) => {
  return api.post('contract/timeoff/cycles', data, getConfig(token, extra))
}
export const getTimeOffDays = (token, data) => {
  const sp = searchParamsFromObject(data)

  return api.get(`contract/timeoff/days${sp}`, getConfig(token))
}
export const addTimeOff = (token, data) => {
  return api.post('contract/timeoff/add', data, getConfig(token))
}
export const approveTimeOff = (token, data) => {
  return api.post('contract/timeoff/approve', data, getConfig(token))
}
export const approveTimeOffBulk = (token, data) => {
  return api.put('review_items/timeoffs/approve_bulk', data, getConfig(token))
}
export const rejectTimeOff = (token, data) => {
  return api.post('contract/timeoff/reject', data, getConfig(token))
}
export const declineTimeOffBulk = (token, data) => {
  return api.put('review_items/timeoffs/decline_bulk', data, getConfig(token))
}
export const deleteTimeOff = (token, data) => {
  return api.post('contract/timeoff/delete', data, getConfig(token))
}
export const getExpenses = (token, data, extra) => {
  return api.post('contract/expense/list', data, getConfig(token, extra))
}
export const getAllExpenses = (token, data, extra) => {
  const queryString = searchParamsFromObject(data)

  return api.get(
    `contract/expense/list/contractor${queryString}`,
    getConfig(token, extra),
  )
}
export const getAdminExpenses = (token, data) => {
  return api.post('admin/contract/expense/list', data, getConfig(token))
}
export const addExpense = (token, data) => {
  return api.post('contract/expense/add', data, getConfig(token))
}
export const uploadExpense = (token, data) => {
  const formData = new FormData()
  formData.append('photo', data?.photo)
  return api.post(
    'contract/expense/upload',
    formData,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}
export const revertExpense = (token, data) => {
  return api.post('contract/expense/revert', data, getConfig(token))
}
export const approveExpense = (token, data) => {
  return api.post('contract/expense/approve', data, getConfig(token))
}
export const rejectExpense = (token, data) => {
  return api.post('contract/expense/reject', data, getConfig(token))
}
export const deleteExpense = (token, data) => {
  return api.post('contract/expense/delete', data, getConfig(token))
}
export const createEntity = (token, data) => {
  return api.post('entity/create', data, getConfig(token))
}
export const switchEntity = (token, data) => {
  return api.post('entity/switch', data, getConfig(token))
}
export const getExpensesMonths = (token, data) => {
  return api.post('contract/expense/months', data, getConfig(token))
}
export const getAdminExpensesMonths = (token, data) => {
  return api.post('admin/contract/expense/months', data, getConfig(token))
}
export const updateContractorType = (token, data) => {
  return api.post('contractor/type', data, getConfig(token))
}
export const request2fa = (token, data) => {
  return api.post('2fa/request', data, getConfig(token))
}
export const enable2fa = (token, data) => {
  return api.post('2fa/enable', data, getConfig(token))
}
export const disable2fa = (token, data) => {
  return api.post('2fa/disable', data, getConfig(token))
}
export const check2fa = (token, data) => {
  return api.post('checkpoint', data, getConfig(token))
}
export const sendTfaEmailCode = (token, data) => {
  return api.post('2fa/email/send', data, getConfig(token))
}
export const verifyTfaEmail = (token, data) => {
  return api.post('2fa/email/verify', data, getConfig(token))
}

export const downloadAllInvoices = (token, data) => {
  return api.post('invoice/download/multi', data, getConfig(token))
}
export const downloadContractAllInvoices = (token, data) => {
  return api.get(
    `contract/${data?.id}/invoices/download?month=${data?.month}&search_key=${data?.search_key}`,
    getConfig(token),
  )
}
export const exportWithdraw = (token, data) => {
  return api.post(
    'admin/withdraws/export',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
// export Transactions
export const adminExportTransactions = (token, data) => {
  return api.post(
    'admin/export/transactions',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
// export Contractors balances
export const adminExportContractorBalances = (token, data) => {
  return api.post(
    'admin/export/contractor/wallets',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
// export Balances per contractor
export const adminExportContractorBalance = (token, data) => {
  return api.post(
    'admin/export/contractor/wallets/activities',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
// export Client payments balances
export const adminExportClientPaymentBalances = (token, data) => {
  return api.post(
    'admin/export/client/payments/balances',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
// export Client payments breakdown
export const adminExportClientPaymentBreakdowns = (token, data) => {
  return api.post(
    'admin/export/client/payments/breakdown',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
// export EOR - Pending AR
export const adminExportEORPendingAR = (token) => {
  return api.get(
    'admin/export/eor/pending-approval',
    getConfig(token, { responseType: 'blob' }),
  )
}
// export EOR - Outstanding Invoices
export const adminExportEOROutstandingInvoices = (token) => {
  return api.get(
    'admin/export/eor/outstanding-invoices',
    getConfig(token, { responseType: 'blob' }),
  )
}
// export EOR - Outstanding Payments
export const adminExportEOROutstandingPayments = (token) => {
  return api.get(
    'admin/export/eor/outstanding-payments',
    getConfig(token, { responseType: 'blob' }),
  )
}

export const adminExportProviderFees = (token, data) => {
  return api.get(
    `admin/reports/provider_fees?month=${data.month}`,
    getConfig(token),
  )
}

export const adminExportWithdrawMarkups = (token, data) => {
  return api.get(
    `admin/reports/withdraw_markups?month=${data.month}`,
    getConfig(token),
  )
}

export const adminExportWalletProviders = (token, data) => {
  return api.get(
    `admin/reports/wallet_providers?month=${data.month}`,
    getConfig(token),
  )
}

export const adminExportCardMarkups = (token, data) => {
  return api.get(
    `admin/reports/card_markups?month=${data.month}`,
    getConfig(token),
  )
}

export const checkAdminReportStatus = (token, data) => {
  return api.get('admin/reports/status/' + data.id, getConfig(token))
}

export const archiveCompany = (token, data) => {
  return api.post('admin/company/archive', data, getConfig(token))
}

export const approveCompany = (token, data) => {
  return api.post('admin/company/approve', data, getConfig(token))
}
export const unArchiveCompany = (token, data) => {
  return api.post('admin/company/unarchive', data, getConfig(token))
}
export const cancelPayment = (token, data) => {
  return api.post('payment/cancel', data, getConfig(token))
}
export const revertCancelledPayment = (token, data) => {
  return api.post('payment/revert', data, getConfig(token))
}

export const parseInvoiceV2 = (token, data) => {
  return apiV2.post('parserai/api/parse/invoice', data, getConfigV2(token))
}

export const parseExpenseV2 = (token, data) => {
  return apiV2.post('parserai/api/parse/expense', data, getConfigV2(token))
}

export const getTokenInvoice = (token) => {
  return api.get('invoiceparser/token/generate', getConfig(token))
}
export const getAdminTokenInvoice = (token) => {
  return api.get('admin/invoiceparser/token/generate', getConfig(token))
}
export const resendContractInvite = (token, data) => {
  return api.post('admin/contract/invite/resend', data, getConfig(token))
}
export const uploadYourInvoice = (token, data) => {
  const formData = getFormData(data)
  return api.post(
    'invoice/upload',
    formData,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const getToDoList = (token, _, extra) => {
  return api.get('todo', getConfig(token, extra))
}
export const getClientToDoList = (token, _, extra) => {
  return api.get('widgets/todo/client', getConfig(token, extra))
}
export const getTimeOffsReviewItems = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('review_items/timeoffs' + sp, getConfig(token, extra))
}
export const getExpensesReviewItems = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('review_items/expenses' + sp, getConfig(token, extra))
}
export const approveExpenseBulk = (token, data) => {
  return api.put('review_items/expenses/approve_bulk', data, getConfig(token))
}
export const declineExpenseBulk = (token, data) => {
  return api.put('review_items/expenses/decline_bulk', data, getConfig(token))
}
export const getContractsReviewItems = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('review_items/contracts' + sp, getConfig(token, extra))
}
export const getWorksReviewItems = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('review_items/works' + sp, getConfig(token, extra))
}
export const approveWorkBulk = (token, data) => {
  return api.put('review_items/works/approve_bulk', data, getConfig(token))
}
export const declineWorkBulk = (token, data) => {
  return api.put('review_items/works/decline_bulk', data, getConfig(token))
}

export const getDocumentsReviewItems = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('review_items/documents' + sp, getConfig(token, extra))
}
export const getBillsReviewItems = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('review_items/bills' + sp, getConfig(token, extra))
}

export const getPaymentsReviewItems = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('review_items/payments' + sp, getConfig(token, extra))
}

export const getInvoicesReviewItems = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('review_items/invoices' + sp, getConfig(token, extra))
}
export const approveInvoiceBulk = (token, data) => {
  return api.put('review_items/invoices/approve_bulk', data, getConfig(token))
}
export const declineInvoiceBulk = (token, data) => {
  return api.put('review_items/invoices/decline_bulk', data, getConfig(token))
}

export function approveBillBulk(token, data) {
  return api.put('review_items/bills/approve_bulk', data, getConfig(token))
}
export function declineBillBulk(token, data) {
  return api.put('review_items/bills/decline_bulk', data, getConfig(token))
}

export const cancelFullTimeInvitation = (token, data) => {
  return api.post(
    'contract/fulltime/employee/invitation/cancel',
    data,
    getConfig(token),
  )
}

export const submitFullTimeExpense = (token, data) => {
  return api.post('/contract/fulltime/expense/submit', data, getConfig(token))
}

export const getFullTimeExpenses = (token, data) => {
  return api.post('contract/fulltime/expense/list', data, getConfig(token))
}

export const getFullTimeExpensesMoths = (token) => {
  return api.get('contract/fulltime/expense/months', getConfig(token))
}

export const getFullTimeTimeOffs = (token) => {
  return api.get('contract/fulltime/timeoff/list', getConfig(token))
}

export const getFullTimeContractDetails = (token) => {
  return api.get('contract/fulltime/employee/details', getConfig(token))
}

export const submitFullTimeTimeOff = (token, data) => {
  return api.post('contract/fulltime/timeoff/submit', data, getConfig(token))
}

export const uploadPayslip = (token, data) => {
  const formData = getFormData(data)
  return api.post(
    'contract/payslips/upload',
    formData,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const uploadAdminPayslip = (token, data) => {
  const formData = getFormData(data)
  return api.post(
    '/admin/eor/payroll/payslips/upload',
    formData,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const addPayslip = (token, data) => {
  return api.post('contract/payslips/add', data, getConfig(token))
}

export const addAdminPayslip = (token, data) => {
  return api.post('/admin/eor/payroll/payslips/add', data, getConfig(token))
}

export const getPayslipsList = (token, data) => {
  return api.post('contract/payslips/list', data, getConfig(token))
}
export const getAdminPayslipsList = (token, data) => {
  return api.post('/admin/eor/payroll/payslips/list', data, getConfig(token))
}
export const getDePayslipsList = (token, data) => {
  const sp = searchParamsFromObject({ page: data.page, perPage: data.perPage })
  return api.get(
    `direct_employees/contracts/${data?.contract_id}/payslips${sp}`,
    getConfig(token),
  )
}
export const getEORAdminPayslips = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get(
    `/admin/eor/payroll/${data?.contract_id}/payslips${sp}`,
    getConfig(token),
  )
}
export const downloadDePayslip = (token, data) => {
  return api.get(
    `direct_employees/contracts/payslip/${data?.payslip_id}/download`,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const downloadPayslip = (token, data) => {
  return api.post(
    'contract/payslips/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const downloadAdminPayslip = (token, data) => {
  return api.post(
    '/admin/eor/payroll/payslips/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const getAdminDePayroll = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get(
    '/admin/direct_employees/payroll_cycle/list' + sp,
    getConfig(token),
  )
}

export const getClientDePayroll = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('/direct_employees/payroll_cycle/list' + sp, getConfig(token))
}

export const regenerateDePayslip = (token, data) => {
  return api.get(
    `direct_employees/contracts/payslip/${data?.payslip_id}/regenerate`,
    getConfig(token),
  )
}

export const cancelEORRequest = (token, data) => {
  return api.post('admin/contract/eor/cancel', data, getConfig(token))
}
export const getFullTimeReqList = (token, data, extra) => {
  return api.post('admin/contract/eor/list', data, getConfig(token, extra))
}

export const getEORCountriesConfig = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('/admin/eor/regional_configs' + sp, getConfig(token))
}

export const getEORRegionConfig = (token, data) => {
  return api.get(
    `/eor/regional_configs/${data.region_type}/${data.region_id}`,
    getConfig(token),
  )
}

export const getAdminEORRegionConfig = (token, data) => {
  return api.get(
    `admin/eor/regional_configs/${data.region_type}/${data.region_id}`,
    getConfig(token),
  )
}

export const getInsuranceProviders = (token) => {
  return api.get('/static/insurance_providers', getConfig(token))
}
export const getQuestionnaires = (token) => {
  return api.get('/static/questionnaires', getConfig(token))
}

export const updateEORCountryConfig = (token, data, extra) => {
  return api.patch(
    `/admin/eor/regional_configs/country/${data.countryId}`,
    data,
    getConfig(token, extra),
  )
}
export const updateEORStateConfig = (token, data, extra) => {
  return api.patch(
    `/admin/eor/regional_configs/state/${data.stateId}`,
    data,
    getConfig(token, extra),
  )
}
export const createEORCountryConfig = (token, data, extra) => {
  return api.post(
    `/admin/eor/regional_configs/country/${data.countryId}`,
    data,
    getConfig(token, extra),
  )
}
export const createEORStateConfig = (token, data, extra) => {
  return api.post(
    `/admin/eor/regional_configs/state/${data.stateId}`,
    data,
    getConfig(token, extra),
  )
}

export const cancelEORTerminationRequest = (token, data, extra) => {
  return api.post(
    `/admin/contract/fulltime/${data?.contractId}/termination_requests/${data?.requestId}/decline`,
    data,
    getConfig(token, extra),
  )
}
export const approveEORTerminationRequest = (token, data, extra) => {
  return api.post(
    `/admin/contract/fulltime/${data?.contractId}/termination_requests/${data?.requestId}/approve`,
    data,
    getConfig(token, extra),
  )
}

export const inviteClientFulltime = (token, data) => {
  return api.post(
    'admin/contract/fulltime/client/invite',
    data,
    getConfig(token),
  )
}

export const CancelFulltimeRequest = (token, data) => {
  return api.post(
    'admin/contract/fulltime/request/archive',
    data,
    getConfig(token),
  )
}

export const CreateNewPartner = (token, data) => {
  return api.post(
    'admin/contract/fulltime/partner/create',
    data,
    getConfig(token),
  )
}
export const AssignPartnerToFT = (token, data) => {
  return api.post(
    'admin/contract/fulltime/partner/assign',
    data,
    getConfig(token),
  )
}
export const AssignSpecialistToFT = (token, data) => {
  return api.post(
    `admin/contract/fulltime/${data?.contract_id}/onboarding_specialist/assign`,
    data,
    getConfig(token),
  )
}
export const getPartners = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/contract/fulltime/partner/list' + sp, getConfig(token))
}
export const getSpecialists = (token) => {
  return api.get(
    '/admin/contract/fulltime/onboarding_specialists',
    getConfig(token),
  )
}
export const setupSepa = (token) => {
  return api.get('payment/method/sepa/setup', getConfig(token))
}
export const getPlaidToken = (token, _, extra) => {
  return api.get('payment/method/ach/generate', getConfig(token, extra))
}
export const setupAch = (token, data) => {
  return api.post('payment/method/ach/setup', data, getConfig(token))
}
export const getEnabledMethods = (token) => {
  return api.get('payment/method/list/enabled', getConfig(token))
}
export const addEquipment = (token, data) => {
  return api.post('contract/equipment/add', data, getConfig(token))
}
export const signEquipment = (token, data) => {
  return api.post('contract/equipment/signature', data, getConfig(token))
}

export const updateEquipment = (token, data) => {
  return api.post('contract/equipment/update', data, getConfig(token))
}
export const editAgreement = (token, data) => {
  return api.post('contract/equipment/agreement/edit', data, getConfig(token))
}
export const equipmentList = (token, data) => {
  return api.post('contract/equipment/list', data, getConfig(token))
}

export const returnEquipment = (token, data) => {
  return api.post('contract/equipment/return', data, getConfig(token))
}

export const deleteEquipment = (token, data) => {
  return api.post('contract/equipment/delete', data, getConfig(token))
}

export const getAgreement = (token, data) => {
  return api.post(
    'contract/equipment/agreement',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const getContractorAgreement = (token, data) => {
  return api.get(
    `contract/equipment/show/${data?.id}`,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const downloadAgreement = (token, data) => {
  return api.get(
    `contract/equipment/download/${data?.id}`,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const downloadOfferLetter = (token, data) => {
  return api.post(
    'admin/offerletter/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const downloadFullTimeOfferLetter = (token, data) => {
  return api.post(
    'contract/fulltime/offerletter/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const downloadFullTimeOfferLetterAdmin = (token, data) => {
  return api.post(
    'admin/contract/fulltime/offerletter/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const uploadEquipmentFile = (token, data) => {
  const formData = new FormData()
  formData.append('photo', data?.photo)
  return api.post('contract/equipment/upload', formData, getConfig(token))
}
export const requestDoc = (token, data) => {
  return api.post(
    'contract/document/client/request/submit',
    data,
    getConfig(token),
  )
}

export const removeRequestDoc = (token, data) => {
  return api.post(
    'contract/document/client/request/delete',
    data,
    getConfig(token),
  )
}

export const downloadFile = (token, data) => {
  const { link } = data
  return api.get(
    link,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const getLocalCurrencies = (token, _, extra) => {
  return api.get('static/currencies', getConfig(token, extra))
}
export const checkContractInvitation = (token, data) => {
  return api.get(
    `contract/invitation?token=${data?.token}&contract_id=${data?.contract_id}`,
    getConfig(token),
  )
}
export const customizeCompany = (token, data) => {
  const formData = getFormData(data)
  return api.post('company/personalization/update', formData, getConfig(token))
}
export const checkDomain = (token, data) => {
  return api.post('domain', data, getConfig(token))
}

export const processWithdraw = (token, data) => {
  return api.post('admin/transfer/process', data, getConfig(token))
}
export const multipleProcessWithdraw = (token, data) => {
  return api.post('admin/transfer/process/bulk', data, getConfig(token))
}
export const invitationHistory = (token, data) => {
  return api.post('admin/notifications/history', data, getConfig(token))
}
export const getCalculatorResult = (token, data) => {
  return api.post('eor/quote', data, getConfig(token))
}

export const getAdminCalculatorResult = (token, data) => {
  return api.post('admin/eor/quote', data, getConfig(token))
}

export const getMondayDotComTemplates = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/monday_templates' + sp, getConfig(token))
}

export const editMondayDotComTemplate = (token, data) => {
  return api.put(`admin/monday_templates/${data?.id}`, data, getConfig(token))
}

export const addMondayDotComTemplate = (token, data) => {
  return api.post('admin/monday_templates', data, getConfig(token))
}

export const submitMondayDotComTemplateData = (token, data) => {
  return api.post(
    `admin/contract/fulltime/${data?.contract_id}/submit_monday_template_data`,
    data,
    getConfig(token),
  )
}
export const submitOffBoardingData = (token, data) => {
  return api.post(
    `admin/contract/fulltime/${data?.contract_id}/submit_off_boarding-monday_template_data`,
    data,
    getConfig(token),
  )
}

const CURRENCY_CONVERTER_API_KEY = 'dd44ba8883984d0f9593e4d5a25e6295'

const CACHED_RESULTS = new Map()

export const getCurrencyConversionFactor = async (fromCurrency, toCurrency) => {
  try {
    if (fromCurrency === toCurrency) return Promise.resolve(1)
    const memoKey = `${fromCurrency}-${toCurrency}`
    if (CACHED_RESULTS.has(memoKey)) return CACHED_RESULTS.get(memoKey)

    const query = fromCurrency + '_' + toCurrency
    const url =
      'https://api.currconv.com/api/v7/convert?q=' +
      query +
      '&compact=ultra&apiKey=' +
      CURRENCY_CONVERTER_API_KEY

    if (process.env.REACT_APP_ENV === 'test') {
      const result = Math.random()
      CACHED_RESULTS.set(memoKey, result)
      return Promise.resolve(result)
    } else {
      return api.get(url).then((res) => {
        const result = res.data[query]
        CACHED_RESULTS.set(memoKey, result)
        return result
      })
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}
export const getIntegrationList = (token, _, extra) => {
  return api.get('integration/list', getConfig(token, extra))
}
export const connectXero = (token, data) => {
  let url = 'integration/xero/connect?'
  if (data?.code) {
    url = `${url}code=${data?.code}`
  }
  if (data?.organization_id) {
    url = `${url}organization_id=${data?.organization_id}&token=${data?.token}`
  }
  return api.get(url, getConfig(token))
}
export const connectZoho = (token, data) => {
  let url = 'integration/zoho/connect?'
  if (data?.code) {
    url = `${url}code=${data?.code}`
  }
  if (data?.organization_id && data?.token) {
    url = `${url}organization_id=${data?.organization_id}&token=${data?.token}`
  }

  return api.get(url, getConfig(token))
}
export const connectFreshBooks = (token, data) => {
  return data?.code
    ? api.get(`integration/fresh/connect?code=${data?.code}`, getConfig(token))
    : api.get('integration/fresh/connect', getConfig(token))
}
export const disconnectXero = (token, data) => {
  return api.post('integration/disconnect', data, getConfig(token))
}

export const setupXero = (token, data) => {
  return api.post('integration/xero/update', data, getConfig(token))
}

export const setupZoho = (token, data) => {
  return api.post('integration/zoho/update', data, getConfig(token))
}

export const setupQuickbooks = (token, data) => {
  return api.post('integration/qb/update', data, getConfig(token))
}
export const setupFreshbooks = (token, data) => {
  return api.post('integration/fresh/update', data, getConfig(token))
}

export const enableOktaIntegration = (token, data) => {
  return api.post('integration/okta/enable', data, getConfig(token))
}

export const updateOktaIntegration = (token, data) => {
  return api.post('integration/okta/update', data, getConfig(token))
}

export const disableOktaIntegration = (token, data) => {
  return api.post('integration/okta/disable', data, getConfig(token))
}

export const validateOktaMetadataUrl = (token, data) => {
  return api.post(
    'https://4s0fysfjri.execute-api.eu-central-1.amazonaws.com/auth-service/api/v1/saml/metadata_url/validate',
    data,
    getConfig(token),
  )
}

export const saveDataToHibob = (token, data) => {
  return api.post('integration/hibob/save', data, getConfig(token))
}
export const saveDataToBamboo = (token, data) => {
  return api.post('integration/bamboohr/save', data, getConfig(token))
}
export const saveDataToSapling = (token, data) => {
  return api.post('integration/sapling/save', data, getConfig(token))
}

export const updateDataToHibob = (token, data) => {
  return api.post('integration/hibob/update', data, getConfig(token))
}

export const updateDataToSapling = (token, data) => {
  return api.post('integration/sapling/update', data, getConfig(token))
}
export const updateDataToBamboo = (token, data) => {
  return api.post('integration/bamboohr/update', data, getConfig(token))
}

export const getHibobEditData = (token, _, extra) => {
  return api.get('integration/hibob/edit', getConfig(token, extra))
}

export const getSaplingEditData = (token, _, extra) => {
  return api.get('integration/sapling/edit', getConfig(token, extra))
}

export const getbambooEditData = (token, _, extra) => {
  return api.get('integration/bamboohr/edit', getConfig(token, extra))
}

export const getHibobContractors = (token) => {
  return api.get('integration/hibob/contractors', getConfig(token))
}
export const getBambooContractors = (token) => {
  return api.get('integration/bamboohr/contractors', getConfig(token))
}
export const getSaplingContractors = (token) => {
  return api.get('integration/sapling/contractors', getConfig(token))
}

export const saveHibobContractors = (token, data) => {
  return api.post('integration/hibob/send', data, getConfig(token))
}

export const saveBambooContractors = (token, data) => {
  return api.post('integration/bamboohr/send', data, getConfig(token))
}

export const saveSaplingContractors = (token, data) => {
  return api.post('integration/sapling/send', data, getConfig(token))
}

export const getDefaultXeroIntegrationData = (token, data) => {
  return api.post('integration/xero/complete', data, getConfig(token))
}

export const getDefaultZohoIntegrationData = (token, data) => {
  return api.post('integration/zoho/complete', data, getConfig(token))
}

export const getXeroIntegrationData = (token) => {
  return api.get('integration/xero/edit', getConfig(token))
}

export const getZohoIntegrationData = (token) => {
  return api.get('integration/zoho/edit', getConfig(token))
}

export const getXeroHistory = (token) => {
  return api.get('integration/xero/invoices', getConfig(token))
}

export const getZohoHistory = (token) => {
  return api.get('integration/zoho/invoices', getConfig(token))
}

export const syncAllInvoices = (token) => {
  return api.get('integration/xero/synchronize', getConfig(token))
}

export const syncAllZohoInvoices = (token) => {
  return api.get('integration/zoho/synchronize', getConfig(token))
}

export function getQBIntegrationData(token, _, extra) {
  return api.get('integration/qb/edit', getConfig(token, extra))
}

export const getFreshIntegrationData = (token) => {
  return api.get('integration/fresh/edit', getConfig(token))
}

export const getQBHistory = (token) => {
  return api.get('integration/qb/invoices', getConfig(token))
}

export const getFreshHistory = (token) => {
  return api.get('integration/fresh/invoices', getConfig(token))
}

export const qbSyncAllInvoices = (token) => {
  return api.get('integration/qb/synchronize', getConfig(token))
}

export const freshSyncAllInvoices = (token) => {
  return api.get('integration/fresh/synchronize', getConfig(token))
}

export const getXeroOrganizations = (token) => {
  return api.get('integration/xero/organisations', getConfig(token))
}

export const getZohoOrganizations = (token) => {
  return api.get('integration/zoho/organizations', getConfig(token))
}

export const connectNetSuite = (token, data) => {
  return api.post('integration/netsuite/connect', data, getConfig(token))
}

export const editNetSuite = (token) => {
  return api.get('integration/netsuite/edit', getConfig(token))
}

export const updateNetSuite = (token, data) => {
  return api.post('integration/netsuite/update', data, getConfig(token))
}

export const completeNetSuite = (token) => {
  return api.get('integration/netsuite/complete', getConfig(token))
}

export const synchronizeNetSuite = (token) => {
  return api.get('integration/netsuite/synchronize', getConfig(token))
}

export const getNetSuiteInvoices = (token) => {
  return api.get('integration/netsuite/invoices', getConfig(token))
}

export function searchNetSuiteVendors(token, data, extra) {
  const sp = searchParamsFromObject(data)
  return api.get(
    `integration/netsuite/search_vendors${sp}`,
    getConfig(token, extra),
  )
}

export const inviteUsers = (token, data) => {
  return api.post('client/invite/bulksend', data, getConfig(token))
}

export const approveAccount = (token, data) => {
  return api.post('admin/client/approve', data, getConfig(token))
}
export const addCashPoint = (token, data) => {
  return api.post('contractor/cash/add', data, getConfig(token))
}

export const uploadWork = (token, data) => {
  const formData = new FormData()
  formData.append('file', data?.file)
  formData.append('type', data?.type)
  return api.post(
    'contract/upload',
    formData,
    getConfigV2(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const downloadWork = (token, data) => {
  return api.post(
    'contract/work/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const getInsuranceOverview = (token) => {
  return api.get('insurance/overview', getConfig(token))
}

export const getInsurancePlans = (token, data, extra) => {
  return api.post('insurance/plans', data, getConfig(token, extra))
}

export const syncInsurancePlan = (token, data, extra) => {
  return api.post(
    `/admin/insurance/${data?.insurance_id}/sync`,
    data,
    getConfig(token, extra),
  )
}

export const createInsuranceRequest = (token, data) => {
  return apiV2.post('insurance/api/v1/members', data, getConfigV2(token))
}
export const getInsuranceQuotation = (token, data) => {
  return api.post(
    `insurance/payment/${data?.payment_id}/quotation`,
    data,
    getConfig(token),
  )
}

export const createInsuranceTrx = (token, data) => {
  return api.post('insurance/transaction/create', data, getConfig(token))
}

export const getAdminInsuranceList = (token, data) => {
  return api.post('admin/insurance/list', data, getConfig(token))
}

export const deactivateInsurance = (token, data) => {
  return api.post('admin/insurance/deactivate', data, getConfig(token))
}

export const deductInsurancePayment = (token, data) => {
  return api.post(
    `admin/insurance/${data.insurance_id}/payments/${data.payment_id}/deduct`,
    data,
    getConfig(token),
  )
}

export const getAdminContractLessInsuranceList = (token, data) => {
  return api.get(
    `admin/insurance/contract_less?page=${data?.page || 1}`,
    getConfig(token),
  )
}

export const deductAllPaymentInsurance = (token, data) => {
  return api.post(
    `admin/insurance/${data.insurance_id}/payments/deduct`,
    data,
    getConfig(token),
  )
}

export const getAdminInsurancePaymentList = (token, data) => {
  return api.post('admin/insurance/payment/list', data, getConfig(token))
}
export const getUpcomingOverdueInsurancePayments = (token, data) => {
  return api.get(
    `admin/insurance/payments/overdue_and_upcoming?page=${data?.page || 1}`,
    getConfig(token),
  )
}
export const deactivateInsuranceContractor = (token, data) => {
  return apiV2.post(
    '/insurance/api/v1/members/cancel_by_policy',
    data,
    getConfigV2(token),
  )
}

export const activateAllowanceInsuranceClient = (token, data) => {
  return api.post(
    `contract/${data?.contract_id}/allowance/create_for_insurance`,
    data,
    getConfig(token),
  )
}

export const deactivateAllowanceInsuranceClient = (token, data) => {
  return api.post(
    `contract/${data?.contract_id}/allowance/${data?.allowance_id}/deactivate`,
    data,
    getConfig(token),
  )
}

export const markContractorKycVerified = (token, data) => {
  return api.post('admin/contractor/verify/kyc', data, getConfig(token))
}

export const markContractorKycUnverified = (token, data) => {
  return api.post('admin/contractor/kyc/unverify', data, getConfig(token))
}

export const resetContractKyc = (token, data) => {
  return api.put(`admin/users/${data.user_id}/reset_kyc`, {}, getConfig(token))
}

export const screenContractor = (token, data) => {
  return api.post(
    'admin/contractor/kyc/contractor_screening',
    data,
    getConfig(token),
  )
}

export const getRecurringExpensesList = (token, data) => {
  return api.post('contract/expense/recurring/list', data, getConfig(token))
}

export const getAdminRecurringExpensesList = (token, data) => {
  return api.post(
    'admin/contract/expense/recurring/list',
    data,
    getConfig(token),
  )
}

export const addRecurringExpense = (token, data) => {
  return api.post('contract/expense/recurring/add', data, getConfig(token))
}

export const updateRecurringExpense = (token, data) => {
  return api.post('contract/expense/recurring/update', data, getConfig(token))
}

export const deleteRecurringExpense = (token, data) => {
  return api.post('contract/expense/recurring/delete', data, getConfig(token))
}

export const getAdminDashboard = (token) => {
  return api.get('admin/dashboard', getConfig(token))
}
export const refreshAdminDashboard = (token) => {
  return api.get('admin/dashboard/refresh', getConfig(token))
}
export const convertAmount = (token, data) => {
  return api.post('/admin/convert', data, getConfig(token))
}
export const simulateFee = (token, data) => {
  return api.post('/admin/contractor/withdraw/simulate', data, getConfig(token))
}
export const cancelTimeOff = (token, data) => {
  return api.post('/contract/timeoff/cancel', data, getConfig(token))
}
export const exportTransaction = (token, data) => {
  return api.post(
    '/transaction/export',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const markDeTransactionAsConfirmed = (token, data) => {
  return api.post('transaction/confirm', data, getConfig(token))
}

export const downloadTransactionDEWSFile = (
  token,
  { transaction_id: transactionID },
) => {
  return api.get(
    `/transaction/${transactionID}/contribution_file/download`,
    getConfig(token),
  )
}

export const adminDownloadTransactionWPSFile = (
  token,
  { transaction_id: transactionID },
) => {
  return api.get(
    `admin/transaction/${transactionID}/download_wps_payments`,
    getConfig(token),
  )
}

export const adminDownloadMultiPaymentFile = (
  token,
  { transaction_id: transactionID, payroll_type: type },
) => {
  return api.get(
    `admin/transaction/${transactionID}/download_multi_payment_file?payroll_type=${type}`,
    getConfig(token),
  )
}

export const makeWorkTransfer = (token, data) => {
  return api.post('/admin/transfer/confirm', data, getConfig(token))
}

export const deValidateEmployeeIdentifier = (token, data) => {
  return api.post(
    'contract/employee_identifier/validate',
    data,
    getConfig(token),
  )
}

/**
 * @param {*} data - { contract_type: fixed | payg | milestone | full_time, adjustment: addition | deduction, contracts_ids: ID[],  }
 */
export function exportContractsTemplate(token, data) {
  return api.post('/contract/list/export', data, getConfig(token))
}

export const getMarkup = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('/admin/currency/markups' + sp, getConfig(token))
}

export const updateMarkup = (token, data) => {
  return api.post('/admin/currency/markup/update', data, getConfig(token))
}

export const updateContractorMethods = (token, data) => {
  return api.post('/admin/contractor/withdraw/methods', data, getConfig(token))
}

export const getContractorWithdrawMethods = (token, data) => {
  return api.get(
    'contractor/withdraw/methods/' + data.currency,
    getConfig(token),
  )
}

export const getContractorWithdrawAccounts = (token, data) => {
  return api.get(
    'contractor/withdraw/accounts' +
      (data?.source ? `?source=${data?.source}` : ''),
    getConfig(token),
  )
}

export const getCashbackHistory = (token, data, extra) => {
  const { page, limit = 10 } = data
  const sp = searchParamsFromObject({ page, limit })

  return apiV2.get(`/cards/api/v1/cashback${sp}`, getConfigV2(token, {}, extra))
}

export const getBankAccountDetails = (token, data) => {
  return api.post('/contractor/bank/details', data, getConfig(token))
}

export const getContractorProfileRequiredFields = (token, data) => {
  return api.post('/contractor/dynamic_fields', data, getConfig(token))
}

export const getBankDetails = (token, { id }) => {
  return api.get(`admin/bankaccounts/${id}/details`, getConfig(token))
}

export const getDependentDetails = (token, data) => {
  return api.post('admin/insurance/details', data, getConfig(token))
}
export const logoutAdmin = (token) => {
  return api.get('admin/logout', getConfig(token))
}

export const addComment = (token, data) => {
  return api.post('/admin/comments/add', data, getConfig(token))
}

export const archiveComment = (token, data) => {
  return api.post('/admin/comments/archive', data, getConfig(token))
}

export const getAdminPermissions = (token, _, extra) => {
  return api.get('/admin/permissions/list', getConfig(token, extra))
}

export const getAdminTeamList = (token) => {
  return api.get('admin/team/list', getConfig(token))
}

export const getAdminTeamRoles = (token) => {
  return api.get('admin/team/role/permissions', getConfig(token))
}

export const changeAdminUserStatus = (token, data) => {
  return api.post('admin/team/update', data, getConfig(token))
}

export const changeAdminUserRole = (token, data) => {
  return api.post('admin/team/role/update', data, getConfig(token))
}

export const updateAdminPermissions = (token, data) => {
  return api.post('admin/team/role/permissions/update', data, getConfig(token))
}

export const addNewAdmin = (token, data) => {
  return api.post('admin/team/create', data, getConfig(token))
}

export const getPaymentAccount = (token) => {
  return api.get('admin/company/payment/accounts', getConfig(token))
}

export const getIPGeolocation = (_, data) => {
  const sp = searchParamsFromObject({
    ...data,
    api_key: process.env.REACT_APP_IP_GEOLOCATION,
  })

  const url = `https://ipgeolocation.abstractapi.com/v1/${sp}`

  return api.get(url)
}

export const validatePhoneNumber = (token, data) => {
  return api.post('validation/phone_number', data, getConfig(token))
}

function searchParamsFromObjectArray(data, addQuestionMark = true) {
  const params = new URLSearchParams()

  Object.entries(data).forEach(([key, value]) => {
    if (!(Array.isArray(value) && value?.length > 0)) return
    value.forEach((v) => params.append(`${key}[]`, v))
  })

  let sp = params?.toString()

  if (sp && addQuestionMark) {
    sp = ['?', sp].join('')
  }

  if (sp && !addQuestionMark) {
    sp = ['&', sp].join('')
  }

  return sp ?? ''
}

export const getAllProviderRules = (token, data) => {
  const sp = searchParamsFromObject(data)

  return api.get('admin/payment_provider_rules' + sp, getConfig(token))
}
export const addProviderRule = (token, data) => {
  return api.post('admin/payment_provider_rules', data, getConfig(token))
}
export const editProviderRule = (token, data) => {
  const { id, ...payload } = data
  return api.post(
    'admin/payment_provider_rules/' + id,
    { ...payload, _method: 'put' },
    getConfig(token),
  )
}
export const deleteProviderRule = (token, data) => {
  return api.post(
    'admin/payment_provider_rules/' + data?.id,
    { _method: 'delete' },
    getConfig(token),
  )
}

export const getAllBeneficiaries = (token, data, extra) => {
  const sp = searchParamsFromObject({ ...data })
  return api.get(
    `admin/bankaccounts/beneficiaries${sp}` +
      (data?.page ? '?page=' + data?.page : ''),
    getConfig(token, extra),
  )
}

export const addBeneficiary = (token, data) => {
  return api.post(
    'admin/bankaccounts/beneficiaries/add',
    data,
    getConfig(token),
  )
}

export const sendPaymentReminder = (token, data) => {
  return api.post('admin/transaction/reminder/payment', data, getConfig(token))
}

export const getPaymentReminderHistory = (token, data) => {
  return api.post('admin/transaction/reminder/history', data, getConfig(token))
}

export const uploadCardKycDocument = (token, data) => {
  const { progressFuncs, ...restOfData } = data

  const formData = getFormData(restOfData)

  return apiV2.post(
    '/cards/api/v1/kyc/documents',
    formData,
    getConfigV2(
      token,
      { 'Content-Type': 'multipart/form-data' },
      { ...progressFuncs },
    ),
  )
}
export const submitKycInfos = (token, data) => {
  return apiV2.post('/cards/api/v1/kyc', data, getConfigV2(token))
}
export const finalizeKycProcess = (token, data) => {
  return apiV2.post('/cards/api/v1/kyc/finalize', data, getConfigV2(token))
}
export const getCardKycDetails = (token, data) => {
  return apiV2.get(
    '/cards/api/v1/admin/kyc/details?rp_user_id=' + data?.rp_user_id,
    getConfigV2(token),
  )
}

export const cardActivity = (token, _, extra) => {
  return apiV2.get(
    '/cards/api/v1/cards?with_suspended=true',
    getConfigV2(token, extra),
  )
}
export const getCardDetails = (token, body) => {
  const { cardId, rpUserId } = body

  return apiV2.get(
    `/cards/api/v1/cards/${cardId}`,
    getConfigV2(token, { rp_user_id: rpUserId }),
  )
}
export const cardSecurityCodes = (token, extra) => {
  return apiV2.get(
    `/cards/api/v1/cards/${extra?.id}?mask_number=disabled`,
    getConfigV2(token, extra),
  )
}
export const cardTransactions = (token, data) => {
  const { page, limit } = data
  const sp = searchParamsFromObject({ page, limit })

  return apiV2.get(`/cards/api/v1/cards/transactions${sp}`, getConfigV2(token))
}
export const userIssuePhysicalCard = (token, data) => {
  return apiV2.post(
    '/cards/api/v1/cards/physical_cards/issue',
    data,
    getConfigV2(token),
  )
}

export const getCardEnumerations = (token) => {
  return apiV2.get('/cards/api/v1/enumerations', getConfigV2(token))
}

export const cardsKycList = (token, data, extra) => {
  const sp = searchParamsFromObject({ ...data, limit: 50 })

  return apiV2.get(
    '/cards/api/v1/admin/kyc' + sp,
    getConfigV2(token, {}, extra),
  )
}

export const adminCardsTransactions = (token, data, extra) => {
  const sp = searchParamsFromObject({ ...data, limit: 50 })

  return apiV2.get(
    '/cards/api/v1/admin/cards/transactions' + sp,
    getConfigV2(token, {}, extra),
  )
}
export const adminCardsList = (token, data, extra) => {
  const sp = searchParamsFromObject({ ...data, limit: 50 })

  return apiV2.get(
    '/cards/api/v1/admin/cards' + sp,
    getConfigV2(token, {}, extra),
  )
}
export const adminPhysicalCardsList = (token, data, extra) => {
  const sp = searchParamsFromObject({ ...data, limit: 50 })

  return apiV2.get(
    '/cards/api/v1/admin/cards/orders/physical' + sp,
    getConfigV2(token, {}, extra),
  )
}

export const adminDownloadPhysicalRequestDetails = (token, data) => {
  return apiV2.get(
    `/cards/api/v1/admin/cards/orders/physical/${data?.id}/download/pdf`,
    getConfigV2(token, {}, { responseType: 'blob' }),
  )
}

export const adminCardsUnpaidFees = (token, data, extra) => {
  const sp = searchParamsFromObject({ ...data, limit: 50 })

  return apiV2.get(
    '/cards/api/v1/admin/cards/unpaid_fees' + sp,
    getConfigV2(token, {}, extra),
  )
}
export const updateAdminCardsUnpaidFees = (token, data) => {
  const { itemId, ...payload } = data

  return apiV2.post(
    `/cards/api/v1/admin/cards/unpaid_fees/${itemId}/update`,
    payload,
    getConfigV2(token),
  )
}
export const adminCardsConfig = (token, data) => {
  const sp = searchParamsFromObject(data)

  return api.get('admin/card_configurations' + sp, getConfig(token))
}

export const adminCardsConfigPut = (token, data) => {
  const { id, ...payload } = data
  return api.post(
    'admin/card_configurations/' + id,
    { ...payload, _method: 'put' },
    getConfig(token),
  )
}

export const requestNewCard = (token, data) => {
  return apiV2.post('/cards/api/v1/cards/order', data, getConfigV2(token))
}
export const resendCardActivationCode = (token, data) => {
  const { card_id: cardId, ...payload } = data
  return apiV2.post(
    `/cards/api/v1/cards/${cardId}/activation-code/send`,
    payload,
    getConfigV2(token),
  )
}
export const activatePhysicalCard = (token, data) => {
  const { card_id: cardId, ...payload } = data
  return apiV2.post(
    `/cards/api/v1/cards/${cardId}/activate`,
    payload,
    getConfigV2(token),
  )
}
export const resetCardPinCode = (token, data) => {
  const { card_id: cardId, ...payload } = data
  return apiV2.post(
    `/cards/api/v1/cards/${cardId}/pins`,
    payload,
    getConfigV2(token),
  )
}
export const adminPhysicalCardMarkIssued = (token, data) => {
  const { card_order_id: cardId, ...payload } = data
  return apiV2.post(
    `/cards/api/v1/admin/cards/orders/physical/${cardId}/issue`,
    payload,
    getConfigV2(token),
  )
}

export const adminSetReadyToShip = (token, data) => {
  const { id } = data
  return apiV2.post(
    `/cards/api/v1/admin/cards/orders/physical/${id}/set_ready_to_ship`,
    data,
    getConfigV2(token),
  )
}

export const adminPhysicalCardMarkShipped = (token, data) => {
  const { card_order_id: cardId, ...payload } = data
  return apiV2.post(
    `/cards/api/v1/admin/cards/orders/physical/${cardId}/ship`,
    payload,
    getConfigV2(token),
  )
}

export const requestCardForExistingUser = (token, data) => {
  return apiV2.post('/cards/api/v1/cards/saved/order', data, getConfigV2(token))
}

export const adminAcceptCardKyc = (token, data) => {
  return apiV2.post('/cards/api/v1/admin/kyc/accept', data, getConfigV2(token))
}
export const adminRejectCardKyc = (token, data) => {
  return apiV2.post('/cards/api/v1/admin/kyc/reject', data, getConfigV2(token))
}
export const adminSubmitCardKyc = (token, data) => {
  return apiV2.post('/cards/api/v1/admin/kyc/submit', data, getConfigV2(token))
}

export const adminDownloadEddForm = (token, data) => {
  const { rp_user_id: rpUserId } = data

  return apiV2.get(
    '/cards/api/v1/admin/kyc/edd_form/download' + `?rp_user_id=${rpUserId}`,
    getConfigV2(token, {}, { responseType: 'blob' }),
  )
}

export const prepareTopup = (token, data) => {
  const { currency, amount } = data // aed
  const sp = searchParamsFromObject({ currency, amount })

  return api.get('cards/quotes/usd' + sp, getConfig(token))
}
export const confirmTopup = (token, data) => {
  const { cardId, ...body } = data

  return apiV2.post(
    `/cards/api/v1/cards/${cardId}/topup`,
    body,
    getConfigV2(token),
  )
}
export const confirmOffload = (token, data) => {
  return apiV2.post(
    `/cards/api/v1/cards/${data?.card_id}/offload`,
    { usd_amount: data.usd_amount },
    getConfigV2(token),
  )
}
export const updateCardStatus = (token, extra) => {
  return apiV2.post(
    `/cards/api/v1/cards/${extra?.id}/${extra.status}`,
    extra.status === 'block'
      ? { card_id: extra?.id, block_reason: extra?.block_reason }
      : {},
    getConfigV2(token, extra),
  )
}

// START Deprecated baas API
export const prepareConvert = (token, data) => {
  return api.post('contractor/baas/convert/prepare', data, getConfig(token))
}
export const confirmConvert = (token, data) => {
  return api.post('contractor/baas/convert/confirm', data, getConfig(token))
}
// END Deprecated baas API

export const referralLink = (token, data, extra) => {
  return apiV2.get(
    `/referral/api/v1/participant/${data?.id}`,
    getConfigV2(token, {}, extra),
  )
}
export const requestNewLink = (token, data) => {
  return apiV2.post('/referral/api/v1/participant', data, getConfigV2(token))
}
export const getReferrals = (token, data, extra) => {
  return apiV2.get(
    `/referral/api/v1/participant/${data?.id}/referral`,
    getConfigV2(token, {}, extra),
  )
}
export const sendReferralInvites = (token, data) => {
  return apiV2.post(
    `/referral/api/v1/participant/${data?.id}/invite`,
    data,
    getConfigV2(token),
  )
}

export const perksPreConditions = (token) => {
  return api.get('perks/pre_conditions', getConfig(token))
}
export const requestPerks = (token) => {
  return api.post('perks/activations/request', {}, getConfig(token))
}

export const getContractorBalancesAdmin = (token, data) => {
  return api.post('admin/wallet/adjustment/list', data, getConfig(token))
}

export const exportContractorBalancesAdmin = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/reports/wallet_adjustments' + sp, getConfig(token))
}
export const exportClientBalancesAdmin = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/reports/client_balances' + sp, getConfig(token))
}

export const getClientBalancesAdmin = (token, data) => {
  return api.post('admin/client_balances/requests/list', data, getConfig(token))
}
export const getClientBalancesAdminNew = (token, data) => {
  return api.post('admin/client_balances/list', data, getConfig(token))
}
export const requestAWalletAdjustment = (token, data) => {
  return api.post('admin/wallet/adjustment/request', data, getConfig(token))
}

export const getWalletConversionMarkup = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/wallet_conversion_markup' + sp, getConfig(token))
}

export const createWalletConversionMarkup = (token, data) => {
  return api.post('admin/wallet_conversion_markup', data, getConfig(token))
}

export const deleteWalletConversionMarkup = (token, data) => {
  return api.get(
    `admin/wallet_conversion_markup/${data?.id}/delete`,
    getConfig(token),
  )
}

export const getAdminPaymentMethods = (token) => {
  return api.get('admin/payment_methods', getConfig(token))
}

export const updateAdminPaymentMethods = (token, data) => {
  return api.post('admin/payment_methods', data, getConfig(token))
}

export const requestAClientBalanceAdjustment = (token, data) => {
  return api.post(
    'admin/client_balances/requests/create',
    data,
    getConfig(token),
  )
}
export const getWalletAdjustmentApprovers = (token) => {
  return api.get('admin/wallet/adjustment/approvers', getConfig(token))
}
export const getClientRequestApprovers = (token) => {
  return api.get('admin/client_balances/requests/approvers', getConfig(token))
}
export const approveWalletAdjustment = (token, data) => {
  return api.post('admin/wallet/adjustment/approve', data, getConfig(token))
}
export const approveClientAdjustment = (token, data) => {
  return api.post(
    'admin/client_balances/requests/approve',
    data,
    getConfig(token),
  )
}
export const rejectWalletAdjustment = (token, data) => {
  return api.post('admin/wallet/adjustment/reject', data, getConfig(token))
}
export const rejectClientAdjustment = (token, data) => {
  return api.post(
    'admin/client_balances/requests/reject',
    data,
    getConfig(token),
  )
}
export const assignAdjustmentApprover = (token, data) => {
  return api.post('admin/wallet/adjustment/assign', data, getConfig(token))
}
export const assignRequestApprover = (token, data) => {
  return api.post(
    'admin/client_balances/requests/assign',
    data,
    getConfig(token),
  )
}
export const cardsPreConditions = (token) => {
  return api.get('cards/pre_conditions', getConfig(token))
}

export const getPerks = (token, _, extra) => {
  return apiV2.get('perks/api/v1/offers', getConfigV2(token, {}, extra))
}

export const getPerk = (token, data, extra) => {
  return apiV2.get(
    'perks/api/v1/offers/' + data?.id,
    getConfigV2(token, {}, extra),
  )
}

export const applyPerkOffer = (token, data) => {
  const { offerId, ...body } = data

  return apiV2.post(
    `perks/api/v1/offers/${offerId}/applications`,
    body,
    getConfigV2(token),
  )
}

export const getOfferSecret = (token, data) => {
  const { offerId } = data

  return apiV2.get(`perks/api/v1/offers/${offerId}/secret`, getConfigV2(token))
}

export const getOfferCategories = (token, _, extra) => {
  return apiV2.get(`perks/api/v1/categories`, getConfigV2(token, {}, extra))
}

export const getHeadcountPerCountry = (token) => {
  return api.get('charts/headcount_per_country', getConfig(token))
}

export const getHeadcountOnMap = (token) => {
  return api.get('charts/headcount_on_map', getConfig(token))
}

export const getHeadcountPerMonthChart = (token) => {
  return api.get('charts/headcount_per_month', getConfig(token))
}

export const getRetentionChart = (token) => {
  return api.get('charts/retention', getConfig(token))
}

export const getAverageContractCostPerMonthChart = (token) => {
  return api.get('charts/average_contract_cost_per_month', getConfig(token))
}
export const getSendingPerMonth = (token) => {
  return api.get('charts/spendings_per_month', getConfig(token))
}

export const getTimeOffPerMonthReport = (token, data) => {
  const qp = searchParamsFromObject(data)
  return api.get('reports/time_off_per_month/preview' + qp, getConfig(token))
}

export const getTimeOffReport = (token, data) => {
  const { contract_ids: contractIds, ...restOfData } = data
  const qp1 = searchParamsFromObject(restOfData, true, true)
  const qp2 = searchParamsFromObjectArray({ contract_ids: contractIds }, !qp1)
  return api.get('reports/time_off/preview' + qp1 + qp2, getConfig(token))
}

export const getTimeOffHistoryReport = (token, data) => {
  const { contract_ids: contractIds, ...restOfData } = data
  const qp1 = searchParamsFromObject(restOfData, true, true)
  const qp2 = searchParamsFromObjectArray({ contract_ids: contractIds }, !qp1)
  return api.get(
    'reports/time_off_history/preview' + qp1 + qp2,
    getConfig(token),
  )
}

export const getDirectManagersList = (token) => {
  return api.get('direct_managers', getConfig(token))
}

export const exportTimeOffPerMonthReport = (token, data) => {
  const qp = searchParamsFromObject(data)
  return api.get('reports/time_off_per_month/export' + qp, getConfig(token))
}

export const exportTimeOffReport = (token, data) => {
  const { contract_ids: contractIds, ...restOfData } = data
  const qp1 = searchParamsFromObject(restOfData, true, true)
  const qp2 = searchParamsFromObjectArray({ contract_ids: contractIds }, !qp1)
  return api.get('reports/time_off/export' + qp1 + qp2, getConfig(token))
}

export const exportTimeOffHistoryReport = (token, data) => {
  const { contract_ids: contractIds, ...restOfData } = data
  const qp1 = searchParamsFromObject(restOfData, true, true)
  const qp2 = searchParamsFromObjectArray({ contract_ids: contractIds }, !qp1)
  return api.get(
    'reports/time_off_history/export' + qp1 + qp2,
    getConfig(token),
  )
}

export const getContractsReport = (token, data) => {
  const qp = searchParamsFromObjectArray(
    {
      status_ids: data?.status_ids,
      contract_types: data?.contract_types,
      selected_columns: data?.selected_columns,
      months: data?.months,
    },
    false,
  )

  return api.get(
    'reports/contracts/preview' + `?no_work=${data?.no_work || 0}` + qp,
    getConfig(token),
  )
}

export const exportContractsReport = (token, data) => {
  const qp = searchParamsFromObjectArray(
    {
      status_ids: data?.status_ids,
      contract_types: data?.contract_types,
      selected_columns: data?.selected_columns,
      months: data?.months,
    },
    false,
  )

  return api.get(
    'reports/contracts/export' +
      `?format=${data?.format || 'csv'}` +
      `&no_work=${data?.no_work || 0}` +
      qp,
    getConfig(token),
  )
}

export const checkExportStatus = (token, data) => {
  return api.get('exports/' + data?.exportId, getConfig(token))
}

export const getInvoicesReport = (token, data) => {
  const qp = searchParamsFromObjectArray(
    {
      selected_columns: data?.selected_columns,
      months: data?.months,
    },
    false,
  )
  return api.get(
    'reports/invoices/preview' + `?type=detailed` + qp,
    getConfig(token),
  )
}

export const exportInvoicesReport = (token, data) => {
  const qp = searchParamsFromObjectArray(
    {
      selected_columns: data?.selected_columns,
      months: data?.months,
    },
    false,
  )
  return api.get(
    'reports/invoices/export' +
      `?format=${data?.format || 'csv'}&type=detailed&` +
      qp,
    getConfig(token),
  )
}

export const getTransactionsReport = (token, data) => {
  const qp = searchParamsFromObjectArray(
    {
      selected_columns: data?.selected_columns,
      months: data?.months,
    },
    false,
  )
  return api.get(
    'reports/transactions/preview?type=detailed' + qp,
    getConfig(token),
  )
}

export const exportTransactionsReport = (token, data) => {
  const qp = searchParamsFromObjectArray(
    {
      selected_columns: data?.selected_columns,
      months: data?.months,
    },
    false,
  )
  return api.get(
    'reports/transactions/export' +
      `?format=${data?.format || 'csv'}&type=detailed` +
      qp,
    getConfig(token),
  )
}

export const getExpensesReport = (token, data) => {
  const qp = searchParamsFromObjectArray({
    selected_columns: data?.selected_columns,
    months: data?.months,
  })
  return api.get('reports/expenses/preview' + qp, getConfig(token))
}

export const exportExpensesReport = (token, data) => {
  const qp = searchParamsFromObjectArray(
    {
      selected_columns: data?.selected_columns,
      months: data?.months,
    },
    false,
  )
  return api.get(
    'reports/expenses/export' +
      `?format=${data?.format || 'csv'}&type=detailed` +
      qp,
    getConfig(token),
  )
}

export const getTeamsReport = (token, data) => {
  const {
    contract_status_ids: contractStatusIds,
    selected_columns: selectedColumns,
    country_ids: countryIds,
    contract_ids: contractIds,
    contract_types: contractTypes,
    department_ids: departmentIds,
    direct_manager_contract_ids: directManagerContractIds,
    ...restOfData
  } = data
  const qp1 = searchParamsFromObject(restOfData)
  const qp2 = searchParamsFromObjectArray(
    {
      contract_status_ids: contractStatusIds,
      selected_columns: selectedColumns,
      country_ids: countryIds,
      contract_ids: contractIds,
      contract_types: contractTypes,
      department_ids: departmentIds,
      direct_manager_contract_ids: directManagerContractIds,
    },
    !qp1,
  )

  return api.get('reports/team/preview' + qp1 + qp2, getConfig(token))
}

export const exportTeamsReport = (token, data) => {
  const {
    contract_status_ids: contractStatusIds,
    selected_columns: selectedColumns,
    country_ids: countryIds,
    contract_ids: contractIds,
    contract_types: contractTypes,
    department_ids: departmentIds,
    direct_manager_contract_ids: directManagerContractIds,
    ...restOfData
  } = data
  const qp1 = searchParamsFromObject(restOfData, false)
  const qp2 = searchParamsFromObjectArray(
    {
      contract_status_ids: contractStatusIds,
      selected_columns: selectedColumns,
      country_ids: countryIds,
      contract_ids: contractIds,
      contract_types: contractTypes,
      department_ids: departmentIds,
      direct_manager_contract_ids: directManagerContractIds,
    },
    false,
  )

  return api.get(
    'reports/team/export' + `?format=${data?.format || 'csv'}` + qp1 + qp2,
    getConfig(token),
  )
}

export const getWorksReport = (token, data) => {
  const qp = searchParamsFromObjectArray({
    work_status_ids: data?.work_status_ids,
    selected_columns: data?.selected_columns,
    months: data?.months,
  })

  return api.get('reports/work/preview' + qp, getConfig(token))
}

export const exportWorksReport = (token, data) => {
  const qp = searchParamsFromObjectArray(
    {
      work_status_ids: data?.work_status_ids,
      selected_columns: data?.selected_columns,
      months: data?.months,
    },
    false,
  )

  return api.get(
    'reports/work/export' + `?format=${data?.format || 'csv'}` + qp,
    getConfig(token),
  )
}

export const getForbiddenDomains = (token) => {
  return api.get('admin/forbidden-domains/list', getConfig(token))
}

export const updateForbiddenDomain = (token, data) => {
  return api.post(
    `admin/forbidden-domains/${data?.forbidden_domain_id}/edit`,
    data,
    getConfig(token),
  )
}

export const deleteForbiddenDomain = (token, data) => {
  return api.delete(
    `admin/forbidden-domains/${data?.forbidden_domain_id}/delete`,
    getConfig(token),
  )
}

export const createForbiddenDomain = (token, data) => {
  return api.post('admin/forbidden-domains/create', data, getConfig(token))
}

export const getEorQuotePrefill = (token, data) => {
  return api.post(
    'admin/contract/fulltime/quote/prefill',
    data,
    getConfig(token),
  )
}
export const getEorQuotePrefillForGenerator = (token, data) => {
  return api.post('admin/eor/quote/prefill', data, getConfig(token))
}

export const getContractorActiveInsuranceAllowances = (token) => {
  return api.get('allowance/insurance', getConfig(token))
}

export const getContractorContractAllowances = (token, data) => {
  return api.get(
    `/contract/${data?.contract_id}/allowance/list`,
    getConfig(token),
  )
}

// #region ############## Direct employee ##############
export const deTerminationCosts = (token, data) => {
  return api.get(
    `/direct_employees/contracts/${data?.contract_id}/termination_costs?termination_date=${data?.termination_date}`,
    getConfig(token),
  )
}

export const deScheduledTerminate = (token, data) => {
  const { contract_id: contractId, ...body } = data
  return api.post(
    `contract/${contractId}/schedule_termination`,
    body,
    getConfig(token),
  )
}

export const cancelScheduledTerminate = (token, data) => {
  const { contract_id: contractId, ...body } = data
  return api.post(
    `contract/${contractId}/cancel_scheduled_termination`,
    body,
    getConfig(token),
  )
}

export const deSupportedCountries = (token) => {
  return api.get('direct_employees/countries', getConfig(token))
}

export const deGetCountryJurisdictions = (token, data) => {
  const id = data?.country_id
  return api.get(
    'direct_employees/jurisdictions/country/' + id,
    getConfig(token),
  )
}

export const getAdminJurisdictionContributionPlans = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get(
    '/admin/jurisdictions/contribution_plans' + sp,
    getConfig(token, extra),
  )
}

export const deGetPayrollCutoffDates = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('/admin/jurisdictions/cutoff_dates' + sp, getConfig(token))
}

export const deGetJurisdictions = (token, data) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/jurisdictions' + sp, getConfig(token))
}
export const deGetJurisdiction = (token, data) => {
  return api.get('admin/jurisdictions/' + data?.id, getConfig(token))
}
export const deCreateJurisdiction = (token, data) => {
  return api.post('admin/jurisdictions', data, getConfig(token))
}
export const deUpdateJurisdiction = (token, data) => {
  return api.post('admin/jurisdictions', data, getConfig(token))
}

export const deDeleteJurisdictionCurrency = (token, data) => {
  return api.post(
    'admin/jurisdictions/currencies/delete',
    data,
    getConfig(token),
  )
}
export const deDeleteJurisdictionBankCountry = (token, data) => {
  return api.post(
    'admin/jurisdictions/bank_countries/delete',
    data,
    getConfig(token),
  )
}
export const deDeleteJurisdictionTodoItem = (token, data) => {
  return api.post(
    'admin/jurisdictions/todo_items/delete',
    data,
    getConfig(token),
  )
}
export const deDeleteJurisdictionDocument = (token, data) => {
  return api.post(
    'admin/jurisdictions/documents/delete',
    data,
    getConfig(token),
  )
}

// #endregion ############## Direct employee ##############

// #region ############## PAY IN ##############

// #region     ########## BREX ##########
export const getPayInAuthUrl = (token, data) => {
  const sp = searchParamsFromObject(data)

  return apiV2.get(
    '/payin/api/v1/brex/auth/url/authorize' + sp,
    getConfigV2(token),
  )
}

export const getPayInAuthCode = (token, data) => {
  const sp = searchParamsFromObject(data)
  return apiV2.post('/payin/api/v1/brex/auth/code' + sp, {}, getConfigV2(token))
}

export const getPayInBrexAccounts = (token, data) => {
  const sp = searchParamsFromObject(data)
  return apiV2.get(
    '/payin/api/v1/brex/transfer/accounts' + sp,
    getConfigV2(token),
  )
}

export const addPayInBrexAccount = (token, data) => {
  const { userId, companyId, ...body } = data
  const sp = searchParamsFromObject({ userId, companyId })

  return apiV2.post(
    '/payin/api/v1/brex/transfer/accounts' + sp,
    body,
    getConfigV2(token),
  )
}

export const getSavedBrexAccounts = (token, data) => {
  const sp = searchParamsFromObject(data)
  return apiV2.get(
    '/payin/api/v1/brex/transfer/accounts/saved' + sp,
    getConfigV2(token),
  )
}
export const deleteSavedBrexAccount = (token, data) => {
  const sp = searchParamsFromObject(data)
  return apiV2.delete(
    '/payin/api/v1/brex/transfer/accounts/saved' + sp,
    getConfigV2(token),
  )
}

export const savePayInPaymentIds = (token, data) => {
  return apiV2.post(
    '/payin/api/v1/brex/auth/payments',
    data,
    getConfigV2(token),
  )
}
export const getPayInPaymentIds = (token, data) => {
  const sp = searchParamsFromObject(data)

  return apiV2.get('/payin/api/v1/brex/auth/payments' + sp, getConfigV2(token))
}

export const createBrexTransfer = (token, data) => {
  const { userId, companyId, ...body } = data
  const sp = searchParamsFromObject({ userId, companyId })

  return apiV2.post(
    '/payin/api/v1/brex/transfer' + sp,
    body,
    getConfigV2(token),
  )
}
export const getNotificationsSettings = (token) => {
  return apiV2.get(
    '/notifications/api/v1/preferences/client',
    getConfigV2(token),
  )
}
export const optInNotificationStatus = (token, data) => {
  return apiV2.post(
    `/notifications/api/v1/preferences/topic/${data?.topicId}/opt_in`,
    data,
    getConfig(token),
  )
}
export const optOutNotificationStatus = (token, data) => {
  return apiV2.post(
    `/notifications/api/v1/preferences/topic/${data?.topicId}/opt_out`,
    data,
    getConfig(token),
  )
}

export const getSlackConnectionUrl = (token) => {
  return api.get('/client/slack/oauth/connection_url', getConfig(token))
}

export const sendSlackAuthCode = (token, data) => {
  return api.post('/client/slack/oauth/connect', data, getConfig(token))
}
export const updateSlackNotificationConfig = (token, data) => {
  return api.put('/client/slack/config', data, getConfig(token))
}
export const disconnectSlackAuth = (token, data) => {
  return api.post('/client/slack/oauth/disconnect', data, getConfig(token))
}

export const getContractHistory = (token, data) => {
  const sp = searchParamsFromObject(data, true, true)
  return apiV2.get(
    `/audittrail/api/v1/contract/${data?.id}${sp}`,
    getConfigV2(token),
  )
}

export const getCompanyHistory = (token, data) => {
  const sp = searchParamsFromObject(data, true, true)
  return apiV2.get(
    `/audittrail/api/v1/company/${data?.id}${sp}`,
    getConfigV2(token),
  )
}
// #endregion     ########## BREX ##########

// #region     ########## Paysend ##########
export const getPaysendAccounts = (token) => {
  return api.get('cards/paysend/card_accounts', getConfig(token))
}

export const addPaysendAccount = (token, data) => {
  return api.post('cards/paysend/card_accounts', data, getConfig(token))
}

// #endregion     ########## Paysend ##########

// #endregion ############## PAY IN ##############

// #region     ########## Refund & Deposits ##########

export const getDepositBalance = (token, _, extra) => {
  return api.get('deposit/balance', getConfig(token, extra))
}

export const getDepositBankAccounts = (token, _, extra) => {
  return api.get('deposit/bank_accounts', getConfig(token, extra))
}

export const getDepositList = (token, data, extra) => {
  const { selected_custom_fields: selectedCustomFields, ...qp } = data
  const sp = searchParamsFromObject(qp)
  const sp1 = searchParamsFromObjectArray(
    { selected_custom_fields: selectedCustomFields },
    !sp,
  )
  return api.get('deposit/list' + sp + sp1, getConfig(token, extra))
}

export const getRefundsList = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('deposit/balance/list' + sp, getConfig(token, extra))
}

export const deactivateBankAccount = (token, data) => {
  return api.get('client/deactivate_bank_account/' + data?.id, getConfig(token))
}

export const getDepositListAdmin = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/deposit/list' + sp, getConfig(token, extra))
}
export const exportDepositListAdmin = (token, data) => {
  const sp = searchParamsFromObject(data)

  return api.get(
    'admin/deposit/export' + sp,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const getDepositSummaryAdmin = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/deposit/summary' + sp, getConfig(token, extra))
}

export const getDepositPendingInvoicesAdmin = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/deposit/pending_invoices' + sp, getConfig(token, extra))
}

export const getDepositBankAccountsAdmin = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/deposit/bank_accounts' + sp, getConfig(token, extra))
}

export const getRefundQuoteAdmin = (token, data) => {
  return api.post('admin/deposit/refund/quote', data, getConfig(token))
}

export const refundTransactionAdmin = (token, data) => {
  return api.post('admin/deposit/refund/transaction', data, getConfig(token))
}

export const updatePartnerPaymentAdmin = (token, data) => {
  return api.post(
    'admin/deposit/update_partner_payment',
    data,
    getConfig(token),
  )
}

export const getAdminForms = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('admin/forms' + sp, getConfig(token, extra))
}

export const createAdminForm = (token, data) => {
  return api.post('admin/forms', data, getConfig(token))
}

export const updateFormName = (token, data) => {
  return api.put('admin/forms/' + data?.id, data, getConfig(token))
}

export const enableAdminForm = (token, data) => {
  return api.post(`admin/forms/${data?.form_id}/enable`, data, getConfig(token))
}

export const disableAdminForm = (token, data) => {
  return api.post(
    `admin/forms/${data?.form_id}/disable`,
    data,
    getConfig(token),
  )
}

export const deleteAdminForm = (token, data) => {
  return api.delete(`admin/forms/${data?.form_id}`, getConfig(token))
}

export const getAdminForm = (token, data, extra) => {
  return api.get('admin/forms/' + data?.id, getConfig(token, extra))
}

export const addFormStep = (token, data) => {
  return api.post(
    `admin/forms/${data?.form_id}/form_steps`,
    data,
    getConfig(token),
  )
}

export const updateFormStep = (token, data) => {
  return api.put(
    `admin/forms/${data?.form_id}/form_steps/${data?.form_step_id}`,
    data,
    getConfig(token),
  )
}

export const deleteFormStep = (token, data) => {
  return api.delete(
    `admin/forms/${data?.form_id}/form_steps/${data?.form_step_id}`,
    getConfig(token),
  )
}
export const addFormField = (token, data) => {
  return api.post(
    `admin/forms/${data?.form_id}/form_fields`,
    data,
    getConfig(token),
  )
}

export const deleteFormField = (token, data) => {
  return api.delete(
    `admin/forms/${data?.form_id}/form_fields/${data?.form_field_id}`,
    getConfig(token),
  )
}
export const reOrderFields = (token, data) => {
  return api.post(
    `admin/forms/${data?.form_id}/form_fields/order`,
    data,
    getConfig(token),
  )
}

export const updateFormField = (token, data) => {
  return api.put(
    `admin/forms/${data?.form_id}/form_fields/${data?.form_field_id}`,
    data,
    getConfig(token),
  )
}

export const addFormCondition = (token, data) => {
  return api.post(
    `admin/forms/${data?.form_id}/form_conditions`,
    data,
    getConfig(token),
  )
}

export const updateFormCondition = (token, data) => {
  return api.put(
    `admin/forms/${data?.form_id}/form_conditions/${data?.form_condition_id}`,
    data,
    getConfig(token),
  )
}

export const deleteFormCondition = (token, data) => {
  return api.delete(
    `admin/forms/${data?.form_id}/form_conditions/${data?.form_condition_id}`,
    getConfig(token),
  )
}

// #endregion     ########## Refund & Deposits ##########

// #region        ########## Approval flows ##########

export const getApprovalFlows = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get('approval_flows' + sp, getConfig(token, extra))
}

export const createApprovalFlow = (token, data) => {
  return api.post('approval_flows', data, getConfig(token))
}

export const updateApprovalFlow = (token, data) => {
  const { id, ...body } = data
  return api.post('approval_flows/' + id + '/update', body, getConfig(token))
}

export const deleteApprovalFlow = (token, data) => {
  return api.post(
    'approval_flows/' + data?.id + '/delete',
    {},
    getConfig(token),
  )
}

export const getApprovalFlowTimeline = (token, data, extra) => {
  const sp = searchParamsFromObject(data)
  return api.get(
    'contract/approval_flow/timeline' + sp,
    getConfig(token, extra),
  )
}

export const getApprovalFlowContracts = (token, data, extra) => {
  return api.get(
    `approval_flows/${data?.id}/contracts`,
    getConfig(token, extra),
  )
}

// #endregion     ########## Approval flows ##########

// #region     ########## Users & Roles ##########
export const getRoles = (token, _, extra) => {
  return api.get('roles', getConfig(token, extra))
}

export const getRoleGroups = (token, _, extra) => {
  return api.get('roles/groups', getConfig(token, extra))
}

export const createUserRole = (token, data) => {
  return api.post('roles', data, getConfig(token))
}

export const updateUserRole = (token, data) => {
  const { id, ...payload } = data
  return api.put('roles/' + id, payload, getConfig(token))
}

export const deleteUserRole = (token, data) => {
  return api.delete(`/roles/${data?.id}`, getConfig(token))
}

// #endregion     ########## Users & Roles ##########
