import { Clock, ClockCountdown } from '@phosphor-icons/react'
import React from 'react'

export const qualificationOptions = (t) => {
  return [
    { label: t('Employee'), value: 'Employee' },
    { label: t('Manager'), value: 'Manager' },
  ]
}

export const employmentTypeOptions = (t) => {
  return [
    { label: t('Full-time'), value: 'Full-time' },
    { label: t('Part-time'), value: 'Part-time' },
  ]
}
export const employmentTypeOptionsDe = (t) => {
  return [
    { label: t('Full-time'), value: 'full-time' },
    { label: t('Part-time'), value: 'part-time' },
  ]
}
export const eorEmploymentTypeOptions = (t) => {
  return [
    { label: t('Full-time'), value: 'Full-time' },
    { label: t('Part-time'), value: 'Part-time' },
  ]
}

export const definiteValue = 'Definite'
export const definiteValueDe = 'definite'
export const partTimeValue = 'Part-time'

export const employmentTermOptions = (t) => {
  return [
    {
      label: t('Definite'),
      value: definiteValue,
      icon: () => <Clock width='100%' height='100%' />,
    },
    {
      label: t('Indefinite'),
      value: 'Indefinite',
      icon: () => <ClockCountdown width='100%' height='100%' />,
    },
  ]
}
export const employmentTermOptionsDe = (t) => {
  return [
    {
      label: t('Definite'),
      value: definiteValueDe,
      icon: () => <Clock width='100%' height='100%' />,
    },
    {
      label: t('Indefinite'),
      value: 'indefinite',
      icon: () => <ClockCountdown width='100%' height='100%' />,
    },
  ]
}
export const genderOptions = (t) => {
  return [
    { label: t('Male'), value: 'male' },
    { label: t('Female'), value: 'female' },
  ]
}
