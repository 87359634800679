import { t } from 'i18next'
import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { ReactComponent as Log } from '../../../../assets/images/cor-benefit-moda-logo.svg'
import { ReactComponent as Dots } from '../../../../assets/images/cor-benefit-modal-dots.svg'
import {
  X,
  Money,
  ArrowSquareOut,
  PlugsConnected,
  ShieldCheck,
} from '@phosphor-icons/react'

export function PremiumBenefitModal(props) {
  const benefits = [
    {
      title: t('Effortless Compliance'),
      description:
        // prettier-ignore
        t('RemotePass handles contracts, tax filings, and local labor laws—keeping you risk-free and compliant') +'.',
      icon: (
        <ShieldCheck size={30} weight='duotone' className='tw-text-green' />
      ),
    },
    {
      title: t('Seamless Payments'),
      description:
        // prettier-ignore
        t('Pay contractors securely in their preferred currency with automated payroll and tax deductions')+'.',
      icon: <Money size={30} weight='duotone' className='tw-text-orange' />,
    },
    {
      title: t('Ease of Onboarding'),
      description: // prettier-ignore
        t('Quickly onboard contractors with streamlined documentation, and a hassle-free setup') +'.',
      icon: (
        <PlugsConnected size={30} weight='duotone' className='tw-text-cyan' />
      ),
    },
  ]
  const { isOpen, toggle } = props
  return (
    <Modal isOpen={isOpen} size='xl' toggle={toggle}>
      <ModalBody className='d-flex flex-column p-0'>
        <div className='tw-relative !tw-overflow-hidden tw-bg-cyan-30'>
          <X
            size={20}
            onClick={toggle}
            className='tw-absolute tw-end-[20px] tw-top-[20px] tw-cursor-pointer'
          />

          <Dots className='tw-absolute tw-start-[-20px] tw-h-[500px] tw-w-[500px]' />
          <div className='tw-flex tw-w-full tw-justify-center'>
            <Log className='tw-z-[999] tw-h-[370px] tw-w-[370px]' />
          </div>
        </div>
        <div className='tw-flex tw-flex-col tw-gap-1 tw-p-6'>
          <div className='tw-flex tw-flex-row tw-items-center tw-gap-1'>
            <span className='tw-text-xl tw-font-bold'>
              {t('Contractor of record')}
            </span>
            <a
              href='https://help.remotepass.com/en/articles/8512670-how-to-onboard-contractors-using-remotepass'
              target='_blank'
              rel='noopener noreferrer'
            >
              <ArrowSquareOut
                onClick={() => {}}
                size={20}
                className='tw-cursor-pointer tw-text-secondary'
              />
            </a>
          </div>
          <span className='tw-text-60'>
            {
              // prettier-ignore
              t(
              'Seamlessly onboard and pay contractors while maintaining full control over their management,all through RemotePass.'
            )
            }
          </span>
          <div className='tw-mt-4 tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-3'>
            {benefits.map(({ icon, title, description }) => (
              <div
                key={title}
                className='tw-flex tw-flex-col tw-gap-4 tw-rounded tw-border tw-border-surface-30 tw-bg-surface-10 tw-p-6'
              >
                {icon}
                <div className='tw-flex tw-flex-col'>
                  <span className='tw-text-base tw-font-bold'>{title}</span>
                  <span className='tw-text-80 tw-me-10'>{description}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
